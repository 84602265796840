import { useReactiveVar } from '@apollo/client'
import { Box, Container, useTheme } from '@mui/material'
import { Suspense } from 'react'
import { Outlet, useMatch } from 'react-router-dom'
import { sidebarOpenState } from '../../cache'
import { Navbar } from '../../components/navbar'
import { SettingsSidebar } from '../../components/settings-sidebar'
import { Sidebar } from '../../components/sidebar'
import { SIDEBAR_WIDTH } from '../../components/sidebar-list/styled'
import { useSiteSelector } from '../../hooks/site-selector'
import { RootRoute } from '../../pages'
import { NAVBAR_HEIGHT, PAGE_BOTTOM_OFFSET } from '../../utils/constants'
import { SitooGlobalStyles } from '../../styles/global'

export const DefaultLayout = () => {
  const theme = useTheme()
  const isSidebarOpen = useReactiveVar(sidebarOpenState)
  useSiteSelector()

  const isSettings = useMatch({ path: RootRoute.Settings, end: false })

  return (
    <>
      <SitooGlobalStyles />

      <Navbar />

      {isSettings ? <SettingsSidebar /> : <Sidebar />}

      <Suspense>
        <Box
          sx={{
            [theme.breakpoints.up('sm')]: {
              pl: isSidebarOpen ? SIDEBAR_WIDTH : 2,
              transition: (theme) =>
                theme.transitions.create(['padding'], {
                  easing: theme.transitions.easing.sharp,
                  duration: theme.transitions.duration.shorter,
                }),
            },
          }}
        >
          <Container
            sx={{
              [theme.breakpoints.up('xs')]: { pl: 0, pr: 0 },
              mt: `${NAVBAR_HEIGHT}px`,
              mb: `${PAGE_BOTTOM_OFFSET}px`,
            }}
            maxWidth={false}
          >
            <Outlet />
          </Container>
        </Box>
      </Suspense>
    </>
  )
}
