import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { Dayjs } from 'dayjs'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  OrderState,
  PaymentState,
  UpdateOrderInput,
  UpdateOrderDocument,
  GetWarehousesDocument,
} from '../../../../generated/graphql'
import { useDayJs } from '../../../../hooks/day-js'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useMe } from '../../../../hooks/me'
import { useTracking } from '../../../../hooks/tracking'

export type OrderInformation = Pick<
  UpdateOrderInput,
  | 'comment'
  | 'commentinternal'
  | 'datereserved'
  | 'orderdate'
  | 'orderstateid'
  | 'paymentstateid'
  | 'warehouseid'
>

type Props = {
  open: boolean
  dataTestid?: string
  onClose(): void
  onSuccess?(): void
  onError?(): void
  orderId?: number
  order?: OrderInformation
}

type Form = OrderInformation & {
  orderdateFormatted: Dayjs | null
  orderreservedFormatted: Dayjs | null
}

export const InformationEditDialog = (props: Props) => {
  const { t } = useTranslation(['orders', 'shared'])
  const dialogName = 'order-information-edit'
  const [updateOrderInformationMutation, { loading }] =
    useMutation(UpdateOrderDocument)
  const { data: warehousesData, loading: loadingWarehouses } = useQuery(
    GetWarehousesDocument,
  )
  const { me, loading: loadingMe } = useMe()
  const warehouses = (warehousesData?.allWarehouses || []).filter(
    (x) =>
      me?.warehouseIds?.includes(x.id) || props.order?.warehouseid === x.id,
  )
  const { getDateTimeFormat } = useLocalizedDate()
  const dayJs = useDayJs()

  const isLoading = loading || loadingWarehouses || loadingMe
  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const orderWithFormattedDate = useMemo(
    () => ({
      ...props.order,
      orderdateFormatted: props.order?.orderdate
        ? dayJs(props.order?.orderdate)
        : null,
      orderreservedFormatted: props.order?.datereserved
        ? dayJs(props.order.datereserved)
        : null,
    }),
    [dayJs, props.order],
  )

  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const onSubmit = async ({
    orderdateFormatted,
    orderreservedFormatted,
    ...order
  }: Form) => {
    try {
      await updateOrderInformationMutation({
        variables: {
          orderId: props.orderId || 0,
          data: {
            ...order,
            orderdate: orderdateFormatted?.toJSON() || null,
            datereserved: orderreservedFormatted?.toJSON() || null,
            commentinternal: order.commentinternal ? order.commentinternal : '',
            comment: order.comment ? order.comment : '',
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      if (props.onSuccess) {
        props.onSuccess()
      }
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      if (props.onError) {
        props.onError()
      }
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      formContext.reset(orderWithFormattedDate)
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      formContext.reset(orderWithFormattedDate)
      trackDialogOpen({ name: dialogName })
    }
  }, [
    props.open,
    prevOpen,
    trackDialogOpen,
    formContext,
    orderWithFormattedDate,
  ])

  const orderStates = [
    {
      value: OrderState.Open,
      label: t(`orders:order_state.${OrderState.Open}`),
    },
    {
      value: OrderState.Closed,
      label: t(`orders:order_state.${OrderState.Closed}`),
    },
    {
      value: OrderState.Cancelled,
      label: t(`orders:order_state.${OrderState.Cancelled}`),
    },
  ]

  const paymentStates = [
    {
      value: PaymentState.None,
      label: t(`orders:payment_state.${PaymentState.None}`),
    },
    {
      value: PaymentState.Pending,
      label: t(`orders:payment_state.${PaymentState.Pending}`),
    },
    {
      value: PaymentState.Reserved,
      label: t(`orders:payment_state.${PaymentState.Reserved}`),
    },
    {
      value: PaymentState.Successful,
      label: t(`orders:payment_state.${PaymentState.Successful}`),
    },
    {
      value: PaymentState.Cancelled,
      label: t(`orders:payment_state.${PaymentState.Cancelled}`),
    },
    {
      value: PaymentState.Failed,
      label: t(`orders:payment_state.${PaymentState.Failed}`),
    },
  ]

  if (!props.order || !props.orderId) {
    return null
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="edit-order-information-dialog"
    >
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formContext.handleSubmit(onSubmit)}
      >
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('orders:edit_information_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={formContext.control}
            name="orderstateid"
            render={({ field }) => (
              <>
                <InputLabel>
                  {t('orders:view_panel.order_state_label')}
                </InputLabel>
                <Select
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  sx={{ mb: 2 }}
                  data-testid="order-state"
                  inputProps={{
                    ['data-testid']: 'order-state-input',
                  }}
                >
                  {orderStates.map(({ value, label }) => (
                    <MenuItem
                      value={value}
                      key={value}
                      data-testid={`order-state-button-${value}`}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />
          <Controller
            control={formContext.control}
            name="paymentstateid"
            render={({ field }) => (
              <>
                <InputLabel>
                  {t('orders:view_panel.payment_state_label')}
                </InputLabel>
                <Select
                  value={field.value}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  sx={{ mb: 2 }}
                  data-testid="order-payment-state"
                  inputProps={{
                    ['data-testid']: 'order-payment-state-input',
                  }}
                >
                  {paymentStates.map(({ value, label }) => (
                    <MenuItem
                      value={value}
                      key={value}
                      data-testid={`order-payment-state-button-${value}`}
                    >
                      {label}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />

          <Controller
            control={formContext.control}
            name="orderdateFormatted"
            rules={{
              required: t('shared:validation.field_required', {
                field: t('orders:view_panel.order_date_label'),
              }),
              validate: (v) =>
                !v || !v.isValid()
                  ? t('shared:validation.field_invalid', {
                      field: t('orders:view_panel.order_date_label'),
                    })
                  : true,
            }}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('orders:view_panel.order_date_label'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'order-date',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? field.value?.tz() : null}
                format={getDateTimeFormat({ seconds: true })}
                onChange={(newValue) => {
                  if (newValue?.isValid()) {
                    field.onChange(newValue)
                    formContext.setValue('orderdate', newValue.toJSON())
                  }
                }}
              />
            )}
          />

          <Controller
            control={formContext.control}
            name="warehouseid"
            render={({ field }) => (
              <>
                <InputLabel>
                  {t('orders:view_panel.warehouse_label')}
                </InputLabel>
                <Select<number | string>
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value || null)
                  }}
                  displayEmpty
                  data-testid="order-warehouse"
                  inputProps={{
                    ['data-testid']: 'order-warehouse-input',
                  }}
                  sx={{ mb: 2 }}
                >
                  <MenuItem value={''}>{t('shared:label.none')}</MenuItem>
                  {warehouses.map(({ id, name }) => (
                    <MenuItem
                      value={id}
                      key={id}
                      data-testid={`order-warehouse-button-${id}`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
              </>
            )}
          />

          <Controller
            control={formContext.control}
            name="orderreservedFormatted"
            rules={{
              validate: (v) =>
                v && !v.isValid()
                  ? t('shared:validation.field_invalid', {
                      field: t('orders:view_panel.reserve_until_label'),
                    })
                  : true,
            }}
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('orders:view_panel.reserve_until_label'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'order-date-reserved',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? field.value.tz() : null}
                format={getDateTimeFormat({ seconds: true })}
                onChange={(newValue) => {
                  if (newValue?.isValid()) {
                    field.onChange(newValue)
                    formContext.setValue('datereserved', newValue.toJSON())
                  }
                }}
              />
            )}
          />

          <TextField
            label={t('orders:view_panel.internal_comment_label')}
            multiline
            rows={4}
            error={!!formContext.formState.errors.commentinternal?.message}
            helperText={formContext.formState.errors.commentinternal?.message}
            sx={{ mb: 2 }}
            inputProps={{
              ['data-testid']: 'order-comment-internal',
            }}
            {...formContext.register('commentinternal')}
          />

          <TextField
            label={t('orders:view_panel.comment_label')}
            multiline
            rows={4}
            error={!!formContext.formState.errors.comment?.message}
            helperText={formContext.formState.errors.comment?.message}
            sx={{ mb: 2 }}
            inputProps={{
              ['data-testid']: 'order-comment',
            }}
            {...formContext.register('comment')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            loading={isLoading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formContext.formState.isValid}
            data-testid="save-order-information"
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
