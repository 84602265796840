import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { translateCampaignTypeTitle } from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type ProductCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const ProductCampaignSelection = (
  props: ProductCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-3-for-2' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_270),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_270)}
        data-testid="buy-3-pay-2-or-buy-2-pay-1-half-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-x-related-products-discount',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_260),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_260)}
        data-testid="buy-x-get-discounts-from-list-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-buy-x-fixed-price' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_220),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_220)}
        data-testid="buy-x-for-m-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-buy-x-pay-y' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_230),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_230)}
        data-testid="buy-x-pay-for-y-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-x-pay-y-get-percentage-discount-rest',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_240),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_240)}
        data-testid="buy-x-pay-for-y-and-get-z-discount-on-rest-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-minimum-x-get-percentage-discount',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_250),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_250)}
        data-testid="buy-minimum-quantity-x-get-y-discount-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-price-discount' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_200),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_200)}
        data-testid="product-discount-m-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-product-percentage-discount' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_210),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_210)}
        data-testid="product-discount-x-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-product-buy-for-m-get-discount-list',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_265),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_265)}
        data-testid="buy-for-m-get-discount-list-listItem"
      />
    </div>
  )
}
