import { useEffect, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import yaml from 'js-yaml'
import { YamlEditor } from '../../../../components/yaml-editor'
import { CampaignDiscountOption } from '../../../../generated/graphql'
import { CampaignFormContext } from '../..'

export const sanitizeDiscountOptionsValues = (
  options: CampaignDiscountOption[] | undefined | null,
) => {
  if (!Array.isArray(options)) {
    return options
  }

  return options
    .filter(Boolean)
    .map(({ __typename, ...props }) => sanitizeDiscountOptionValues(props))
}

export const sanitizeDiscountOptionValues = (
  options: CampaignDiscountOption,
) => {
  for (const property in options) {
    const prop = property as keyof CampaignDiscountOption
    if (
      !options[prop] ||
      prop === '__typename' ||
      (Array.isArray(options[prop]) &&
        (options[prop] as Array<unknown>).length === 0)
    ) {
      delete options[prop]
    } else if (prop === 'productattributes') {
      options[prop] = Object.fromEntries(
        Object.entries(options[prop] || {}).filter(
          (x) => x[0] !== '__typename' && !!x[1],
        ),
      )
    }
  }

  return options
}

export const DiscountOptionsField = () => {
  const { getValues, setValue, control, setError, clearErrors } =
    useFormContext<CampaignFormContext>()
  const { t } = useTranslation('campaigns')
  const options = sanitizeDiscountOptionsValues(
    getValues('campaign.discountoptions'),
  )
  const [yamlOptions, setYamlOptions] = useState(yaml.dump(options))
  useEffect(() => {
    setValue('campaign.discountoptions', options)
  }, [setValue, options])

  return (
    <Controller
      control={control}
      name="campaign.discountoptions"
      render={({ field, fieldState: { error } }) => (
        <>
          <YamlEditor
            {...field}
            ref={(ref) => {
              field.ref({
                focus: () => {
                  ref?.refEditor.scrollIntoView({
                    block: 'center',
                  })
                },
              })
            }}
            value={yamlOptions}
            data-testid="blabla"
            error={!!error}
            label={t('campaign_form.discount_options_label')}
            helperText={
              error?.message ?? t('campaign_form.discount_options_description')
            }
            sx={{
              px: 2,
              mb: 2,
              '.MuiFormHelperText-root': {
                whiteSpace: 'pre-line',
              },
            }}
            onChange={(value) => {
              try {
                setYamlOptions(value)

                const converted = value ? yaml.load(value) : {}
                field.onChange(converted)
                clearErrors('campaign.discountoptions')
              } catch {
                setError('campaign.discountoptions', {
                  message: t('campaign_form.validation.invalid_yaml'),
                  type: 'validate',
                })
              }
            }}
          />
        </>
      )}
    />
  )
}
