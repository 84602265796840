import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  IconButton,
  List,
  ListItem,
  ListItemText,
  Menu,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material'
import { Box, Stack } from '@mui/system'
import {
  DeleteIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DragHandleIcon,
} from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useMemo, useState } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AllPaymentTypesDocument,
  PaymentType,
  PosPaymentSettingsDocument,
  UpdatePaymentSettingsDocument,
} from '../../../../generated/graphql'
import { useDrag } from '../../../../hooks/drag'
import { useTracking } from '../../../../hooks/tracking'
import { PaymentSettingsKeys } from './payment-settings-tab'
import { getPaymentTypesFromIds } from './utils'

type Props = {
  open: boolean
  onClose(): void
  fieldName: PaymentSettingsKeys
}
type Form = {
  payments_refund_manual?: PaymentType[]
  payments_refund_validated?: PaymentType[]
  payments_refund_validated_omni?: PaymentType[]
  payments_sales_gift_card?: PaymentType[]
  payments_sales_non_delivered?: PaymentType[]
}

export const PaymentSettingsTabDialog = ({
  open,
  onClose,
  fieldName,
}: Props) => {
  const { t } = useTranslation(['pos_payment', 'shared'])
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const menuOpen = Boolean(anchorEl)

  const { control, getValues, formState, setValue } = useForm<Form>()
  const { fields, append, remove } = useFieldArray({
    control,
    name: fieldName,
  })

  const { data: paymentTypesData, loading: isLoadingPaymentTypes } = useQuery(
    AllPaymentTypesDocument,
  )

  const { data: paymentSettingsData, loading: isLoadingPaymentSettings } =
    useQuery(PosPaymentSettingsDocument, {
      onCompleted: (data) => {
        const currentIds = data.settings[fieldName] ?? []
        const currentTypes = getPaymentTypesFromIds(
          currentIds,
          paymentTypesData?.allPaymentTypes,
        )
        setValue(fieldName, currentTypes, { shouldDirty: false })
      },
    })

  const [updateSettings, { loading: isUpdatingSettings }] = useMutation(
    UpdatePaymentSettingsDocument,
  )

  const { trackButtonClick, trackDragEnd, trackDragStart } = useTracking()
  const { onDragEnd, onDragOver, onDragStart, dragId } = useDrag()

  const onReOrderProduct = (newList: PaymentType[]) => {
    setValue(fieldName, newList, { shouldDirty: true })
  }

  const options = useMemo(() => {
    return (
      paymentTypesData?.allPaymentTypes.filter(
        (x) => !fields.map((y) => y.paymenttypeid).includes(x.paymenttypeid),
      ) || []
    )
  }, [fields, paymentTypesData?.allPaymentTypes])

  const handleSave = async () => {
    trackButtonClick({ name: `limit-payment-settings-${fieldName}-save` })

    try {
      await updateSettings({
        variables: {
          settings: {
            [fieldName]: fields.map((x) => x.paymenttypeid),
          },
        },
      })

      enqueueSnackbar(t('pos_payment:settings_saved_success'))
    } catch {
      enqueueSnackbar(t('pos_payment:settings_saved_failure'), {
        variant: 'error',
      })
    }
    onClose()
  }

  const handleOnCloseDialog = () => {
    const currentIds = paymentSettingsData?.settings[fieldName] ?? []
    setValue(
      fieldName,
      getPaymentTypesFromIds(currentIds, paymentTypesData?.allPaymentTypes),
      { shouldDirty: false },
    )
    onClose()
  }

  const handleAddFieldClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseAddFieldMenu = () => {
    setAnchorEl(null)
  }

  const isLoading =
    isLoadingPaymentTypes || isLoadingPaymentSettings || isUpdatingSettings

  return (
    <Dialog open={open} onClose={handleOnCloseDialog} fullWidth maxWidth="xs">
      <DialogTitle>
        {t(`pos_payment:limit_payment_options.${fieldName}`)}
      </DialogTitle>
      <DialogContent>
        <List sx={{ '.MuiListItem-root': { p: 0 } }}>
          {fields.map((paymentOption, index) => (
            <ListItem
              key={paymentOption.paymenttypeid}
              divider={index + 1 !== fields.length}
              data-index={index}
              onDragStart={(ev) => {
                trackDragStart({
                  name: `limit-payment-settings-${fieldName}`,
                  position: index,
                  paymentType: paymentOption.paymenttypeid,
                  paymentName: paymentOption.name,
                })
                const target = ev.currentTarget
                target.classList.add('show-helper')
                setTimeout(() => {
                  target.classList.remove('show-helper')
                  onDragStart(paymentOption.paymenttypeid)
                })
              }}
              onDragOver={() =>
                onDragOver(index, fields, 'paymenttypeid', onReOrderProduct)
              }
              onDragEnd={() => {
                trackDragEnd({
                  name: `limit-payment-settings-${fieldName}`,
                  position: index,
                  paymentType: paymentOption.paymenttypeid,
                  paymentName: paymentOption.name,
                })
                onDragEnd()
              }}
              className={
                dragId === paymentOption.paymenttypeid ? 'dragging' : ''
              }
              sx={{
                '&.dragging': {
                  backgroundColor: (theme) => theme.palette.blue20,
                },
                '&.show-helper': {
                  '.drag-helper': {
                    display: 'block',
                    marginRight: (theme) => theme.spacing(1),
                  },
                },

                '.drag-helper': {
                  display: 'none',
                },
              }}
              secondaryAction={
                <>
                  <Stack direction="row" gap={1}>
                    <IconButton onClick={() => remove(index)}>
                      <DeleteIcon />
                    </IconButton>
                    <Box
                      sx={{
                        color: 'theme.palette.gray60',
                        cursor: 'move',
                        display: 'flex',
                        alignItems: 'center',
                      }}
                      draggable
                    >
                      <DragHandleIcon />
                      <Typography variant="body02" className="drag-helper">
                        {paymentOption.name}
                      </Typography>
                    </Box>
                  </Stack>
                </>
              }
            >
              <ListItemText
                primaryTypographyProps={{
                  sx: {
                    display: '-webkit-box',
                    WebkitLineClamp: 2,
                    WebkitBoxOrient: 'vertical',
                  },
                }}
                secondaryTypographyProps={{
                  whiteSpace: 'nowrap',
                }}
              >
                {
                  <Tooltip title={paymentOption?.name} placement="top-start">
                    <span>{paymentOption?.name}</span>
                  </Tooltip>
                }
              </ListItemText>
            </ListItem>
          ))}
          {fields.length === 0 && (
            <ListItem sx={{ mb: 2 }}>
              <ListItemText
                primaryTypographyProps={{ color: 'gray60', variant: 'body01' }}
              >
                {t('pos_payment:no_fields', {
                  paymentOption: t(
                    `pos_payment:limit_payment_options.${fieldName}`,
                  ),
                })}
              </ListItemText>
            </ListItem>
          )}
        </List>

        <Menu
          open={menuOpen}
          onClose={handleCloseAddFieldMenu}
          id="payment-type-menu"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          slotProps={{ paper: { sx: { width: '395px' } } }}
        >
          {options.map((paymentType) => (
            <MenuItem
              key={paymentType.paymenttypeid}
              onClick={() => {
                if (paymentType) {
                  append(paymentType)
                }
                setAnchorEl(null)
              }}
            >
              {paymentType.name}
            </MenuItem>
          ))}
        </Menu>

        <Button
          size="small"
          fullWidth
          color="tertiary"
          onClick={handleAddFieldClick}
          aria-controls={open ? 'payment-type-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          disabled={options.length === 0}
        >
          {t('pos_payment:add_field')}
        </Button>
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleOnCloseDialog}
          data-testid="dialog-cancel-payment-option"
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          type="submit"
          loading={isLoading}
          disabled={isLoading || !formState.isDirty}
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSave}
        >
          {t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
