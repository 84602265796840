import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../components/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { GetAllCashRegistersDocument } from '../generated/graphql'
import { useStateParams } from '../hooks/state-params'
import { formatQueryStringArray } from '../hooks/state-params/util'
import { usePrevious } from 'react-use'

type QueryState = {
  cashRegisterIds?: string[]
}

type Form = {
  cashRegisterIds?: string[]
}

export const CashRegisterIdFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const {
    setFilter,
    removeFilter,
    resetFilter,
    subscribeOnResetFilter,
    registerFilter,
    hasApply,
    filter,
  } = useContext(FilterContext)

  const filterKey = 'cashRegisterIds'

  const { data, loading: isLoading } = useQuery(GetAllCashRegistersDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const storeId = useMemo(() => filter['storeId']?.value, [filter])
  const prevStoreId = usePrevious(storeId)

  const options = useMemo(
    () =>
      [...(data?.allCashRegisters || [])]
        .sort((a, b) =>
          String(a.registerkey)?.localeCompare(String(b.registerkey)),
        )
        .filter((x) => (storeId ? x.warehouse?.storeid === storeId : true)),
    [data?.allCashRegisters, storeId],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryState>()

  const cashRegisterIds = useMemo(
    () => formatQueryStringArray(queryParams.cashRegisterIds),
    [queryParams.cashRegisterIds],
  )

  const { control, setValue, watch } = useForm<Form>({
    values: { cashRegisterIds },
    defaultValues: { cashRegisterIds: [] },
  })

  // Reset filter when storeId changes
  useEffect(() => {
    if (storeId !== prevStoreId) {
      void resetFilter(filterKey)
    }
  }, [prevStoreId, resetFilter, setValue, storeId])

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (cashRegisterIds.length) {
      setFilter(filterKey, {
        label: t('filter:register_filter.label'),
        labelValues: cashRegisterIds.map(
          (id) => options.find((x) => x.registerid === id)?.registerkey || id,
        ),
        value: cashRegisterIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, cashRegisterIds, removeFilter, setFilter, t, isLoading])

  useEffect(() => {
    const subscribe = watch(({ cashRegisterIds }) => {
      setQueryParams(
        { [filterKey]: formatQueryStringArray(cashRegisterIds) },
        hasApply !== true,
      )
    })

    return () => subscribe.unsubscribe()
  }, [hasApply, options, removeFilter, setQueryParams, t, watch])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setValue('cashRegisterIds', [])
      }
    })

    return () => unsubscribe()
  }, [setValue, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="cashRegisterIds"
      control={control}
      options={options.map(({ registerid }) => registerid)}
      disabled={isLoading}
      label={t('filter:register_filter.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'register-id-input' },
        },
      }}
      autocompleteProps={{
        noOptionsText: t('filter:register_filter.no_options'),
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (id) => {
          const option = options.find((x) => x.registerid === id)

          const name = option?.clientdescription
          const key = option?.registerkey as string

          return name ? `${key} - ${name}` : key
        },
      }}
    />
  )
}
