import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../components/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { GetCampaignsDocument } from '../generated/graphql'
import { useStateParams } from '../hooks/state-params'
import {
  formatQueryNumberArray,
  formatQueryStringArray,
} from '../hooks/state-params/util'
import { MAX_NUM_REQUEST } from '../utils/constants'

type QueryState = {
  campaignIds?: string[]
}

type Form = {
  campaignIds?: number[]
}

export const CampaignIdFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'campaignIds'

  const { data, loading: isLoading } = useQuery(GetCampaignsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { num: MAX_NUM_REQUEST },
  })

  const options = useMemo(
    () =>
      [...(data?.campaigns.items || [])].sort((a, b) =>
        a.vouchername.localeCompare(b.vouchername),
      ),
    [data?.campaigns],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryState>()

  const campaignIds = formatQueryNumberArray(queryParams.campaignIds)

  const { control, setValue, watch } = useForm<Form>({
    values: { campaignIds },
    defaultValues: { campaignIds: [] },
  })

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (campaignIds.length) {
      setFilter(filterKey, {
        label: t('filter:campaign_id.label'),
        labelValues: campaignIds.map(
          (id) => options.find((x) => x.id === id)?.vouchername || String(id),
        ),
        value: campaignIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [options, campaignIds, removeFilter, setFilter, t, isLoading])

  useEffect(() => {
    const subscribe = watch(({ campaignIds }) => {
      setQueryParams({
        [filterKey]: formatQueryStringArray(campaignIds),
      })
    })

    return () => subscribe.unsubscribe()
  }, [setQueryParams, watch])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setValue('campaignIds', [])
      }
    })

    return () => unsubscribe()
  }, [setValue, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="campaignIds"
      control={control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('filter:campaign_id.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'campaign-id-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) =>
          options.find((x) => x.id === option)?.vouchername || String(option),
      }}
    />
  )
}
