import { Box } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ProductCounter } from '../../../components/product-counter'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ShipmentV2Item } from '../shared'

type Row = ShipmentV2Item & {
  new_quantity_received?: number
}

type Props = {
  items: ShipmentV2Item[]
  updateItem: (data: {
    shipmentitemid: number
    quantityreceived: number
  }) => void
  apiRef: MutableRefObject<GridApiPro>
}

export const ReceivedPackageItems = ({ items, updateItem, apiRef }: Props) => {
  const { t } = useTranslation(['shared', 'shipments_v2'])

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'product_name',
        headerName: t('shipments_v2:packages.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.productname,
          caption: row.sku,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'quantity',
        headerName: t('shipments_v2:packages.received_title'),
        editable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <ReceivedItemChip
            label={t('shipments_v2:packages.received_of_total', {
              received: row.quantityreceived,
              total: row.quantity,
            })}
            quantity={Number(row.quantity)}
            quantityReceived={Number(row.quantityreceived)}
          />
        ),
      },
      {
        field: 'new_quantity_received',
        headerName: t('shipments_v2:packages.quantity_received_title'),
        editable: false,
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<Row, number>) => {
          return (
            <ProductCounter
              currentAmount={params.value || 0}
              maxAmount={
                Number(params.row.quantity) -
                Number(params.row.quantityreceived)
              }
              updateAmount={(newQuantity) => {
                if (newQuantity !== params.value) {
                  updateItem({
                    shipmentitemid: Number(params.row.shipmentitemid),
                    quantityreceived: newQuantity,
                  })

                  params.row.new_quantity_received = newQuantity
                }
              }}
            />
          )
        },
      },
    ],
    [updateItem, t],
  )

  const isCellEditable = (params: GridCellParams<Row>) => {
    return (
      params.field === 'new_quantity_received' &&
      Number(params.row.quantity) > Number(params.row.quantityreceived)
    )
  }

  const gridItems = useMemo(
    () => items?.map((x) => ({ ...x, new_quantity_received: 0 })),
    [items],
  )

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      try {
        const newQuantityReceived = newRow.new_quantity_received
        const oldQuantityReceived = oldRow.new_quantity_received

        if (newQuantityReceived === oldQuantityReceived) return newRow

        if (typeof newQuantityReceived === 'number')
          updateItem({
            shipmentitemid: Number(newRow.shipmentitemid),
            quantityreceived: newQuantityReceived,
          })

        return newRow
      } catch (error) {
        return oldRow
      }
    },
    [updateItem],
  )

  return (
    <DataGrid
      apiRef={apiRef}
      name="received-package-items-list"
      columns={dataGridColumns}
      rows={gridItems || []}
      rowCount={items?.length}
      getRowId={(row) => row.shipmentitemid ?? ''}
      rowHeight={50}
      ignoreRowHover
      sx={{
        height: '300px',
        '.MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
          boxShadow: 'none',
          padding: (theme) => theme.spacing(0, 1.25),
        },
        '.MuiDataGrid-row .MuiDataGrid-cell--editable:not(.MuiDataGrid-cell--editing):focus-within, .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':
          {
            outline: 'none',
          },
      }}
      noRowsOverlay={{
        title: t('shipments_v2:packages.no_rows_title'),
        description: t('shipments_v2:packages.no_rows_description'),
      }}
      disableColumnVisibilityControl
      pagination={false}
      hideFooter
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      disableAllFilters={true}
    />
  )
}
