import { useMutation, useQuery } from '@apollo/client'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionHeader,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ReasonCodeType,
  AllReasonCodesDocument,
  GetWarehouseBatchSummaryDocument,
  UpdateWarehouseBatchDocument,
  Warehousebatchstate,
} from '../../../../generated/graphql'
import { useMe } from '../../../../hooks/me'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessage } from '../../../../utils/error-mapping'

type Props = {
  open: boolean
  warehouseId: number
  warehouseBatchId: number
  onClose: () => void
  onSuccess: () => void
  onError?(): void
}

type Form = {
  reasonCode: string
  comment: string
  confirm: boolean
}

export const FinishStocktakingDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'stocktaking'])
  const {
    trackDialogOpen,
    trackDialogClose,
    trackButtonClickEvent,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = 'finish-stocktaking'
  const [updateWarehouseBatch, { loading }] = useMutation(
    UpdateWarehouseBatchDocument,
  )
  const { enqueueSnackbar } = useSnackbar()
  const { displayName } = useMe()

  const formContext = useForm<Form>()

  const {
    data: warehouseBatchSummaryData,
    loading: loadingWarehouseBatchSummary,
    refetch: refetchWarehouseBatchSummary,
  } = useQuery(GetWarehouseBatchSummaryDocument, {
    variables: {
      warehouseBatchId: props.warehouseBatchId,
      warehouseId: props.warehouseId,
    },
  })
  const totalBatchItems =
    warehouseBatchSummaryData?.warehouseBatch?.items.totalcount || 0

  const { data: reasonCodesData, loading: loadingReasonCodes } = useQuery(
    AllReasonCodesDocument,
    {
      variables: {
        type: ReasonCodeType.StockTaking,
      },
    },
  )
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const isLoading =
    loading || loadingReasonCodes || loadingWarehouseBatchSummary

  const submit = async (data: Form) => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-finish` })

    try {
      await updateWarehouseBatch({
        variables: {
          warehouseBatchId: props.warehouseBatchId,
          warehouseId: props.warehouseId,
          data: {
            warehousebatchstate: Warehousebatchstate.NUMBER_20,
            comment: data.comment,
            reasoncode: data.reasonCode,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      enqueueSnackbar(t('stocktaking:finish_dialog.success'))
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'stocktaking',
        t('stocktaking:finish_dialog.error'),
      )

      trackFormError({
        name: `${dialogName}-dialog`,

        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
      props.onError?.()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      void refetchWarehouseBatchSummary()
      formContext.reset({
        comment: '',
        reasonCode: '',
        confirm: false,
      })
      trackDialogOpen({ name: dialogName })
    }
  }, [
    props.open,
    prevOpen,
    trackDialogOpen,
    formContext,
    refetchWarehouseBatchSummary,
  ])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  const isConfirmed = formContext.watch('confirm')

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{t('stocktaking:finish_dialog.title')}</DialogTitle>

      <DialogContent>
        <SectionHeader variant="transparent" sx={{ px: 0 }}>
          {t('stocktaking:finish_dialog.summary')}
        </SectionHeader>

        <List
          sx={{
            mx: -3,
          }}
        >
          <ListItem
            secondaryAction={<ListItemText>{totalBatchItems}</ListItemText>}
            divider
            data-testid="summary-count"
          >
            <ListItemText>{t('stocktaking:finish_dialog.count')}</ListItemText>
          </ListItem>
          <ListItem
            secondaryAction={<ListItemText>{displayName}</ListItemText>}
          >
            <ListItemText>
              {t('stocktaking:finish_dialog.responsible')}
            </ListItemText>
          </ListItem>
        </List>

        <Typography
          variant="caption"
          sx={{ mb: 3, whiteSpace: 'pre-wrap' }}
          component="div"
        >
          {t('stocktaking:finish_dialog.description')}
        </Typography>

        {reasonCodes.length > 0 && (
          <Controller
            control={formContext.control}
            name="reasonCode"
            rules={{
              required: t('shared:validation.field_required', {
                field: t('stocktaking:finish_dialog.reason_code'),
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('stocktaking:finish_dialog.reason_code')}
                </InputLabel>
                <Select
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="reason-code-select"
                  inputProps={{ 'data-testid': 'reason-code-input' }}
                  displayEmpty
                  error={!!error?.message}
                >
                  <MenuItem value={''} disabled>
                    {t('shared:label.select')}
                  </MenuItem>
                  {reasonCodes.map(({ name, reasoncode, reasoncodeid }) => (
                    <MenuItem
                      value={reasoncode}
                      key={reasoncodeid}
                      data-testid={`reason-code-${reasoncodeid}-button`}
                    >
                      {name || reasoncode}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        )}

        <TextField
          fullWidth
          error={!!formContext.formState.errors.comment}
          helperText={formContext.formState.errors.comment?.message}
          label={t('stocktaking:finish_dialog.comment')}
          {...formContext.register('comment')}
          inputProps={{ 'data-testid': 'comment' }}
          multiline={true}
          rows={3}
        />
      </DialogContent>

      <DialogActions>
        <Box sx={{ flexGrow: 1, marginLeft: (theme) => theme.spacing(1) }}>
          <Controller
            control={formContext.control}
            name="confirm"
            rules={{
              validate: (v) => v,
            }}
            render={({ field }) => (
              <>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={
                        typeof field.value === 'boolean' ? field.value : false
                      }
                      onChange={(event) => {
                        field.onChange(event.target.checked)
                      }}
                      data-testid="confirm-stocktaking"
                    />
                  }
                  label={t('stocktaking:finish_dialog.confirmation_message')}
                />
              </>
            )}
          />
        </Box>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-finish-stocktaking"
        >
          {t('shared:action.cancel')}
        </Button>
        <Button
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={formContext.handleSubmit(submit)}
          data-testid="dialog-finish-finish-stocktaking"
          disabled={isLoading || !isConfirmed}
        >
          {t('shared:action.finish')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
