import { Button } from '@mui/material'
import {
  GridColDef,
  GridRenderEditCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../../components/data-grid'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  GetProductPurchasePricesQueryVariables,
  GetProductPurchasePricesQuery,
  GetProductsSort,
  AllPurchasePriceListsDocument,
  GetProductPurchasePricesDocument,
  UpdatePurchasePriceListItemsDocument,
  DeletePurchasePriceListItemsDocument,
} from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'
import { ArrayElement } from '../../../../utils/types'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { FolderIcon } from '@sitoo/mui-components'
import { useMemo, useState, useContext, useCallback } from 'react'
import { useTracking } from '../../../../hooks/tracking'
import { useSnackbar } from 'notistack'
import { BulkActions } from './bulk-actions'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../../..'
import { useMe } from '../../../../hooks/me'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
} from '../../../../utils/constants'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useQuery, useMutation } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<GetProductPurchasePricesQuery['products']['items']> & {
  purchasePriceValue: string | undefined
}

type PurchasePricesListProps = {
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const PurchasePricesList = (props: PurchasePricesListProps) => {
  const { t } = useTranslation(['purchase_prices', 'shared', 'products'])
  const {
    modules: { writePurchasePrices },
  } = useAuthorization()
  const {
    trackButtonClickEvent,
    trackInputBlur,
    trackInputFocus,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const { data: priceListsData, loading: isPricelistLoading } = useQuery(
    AllPurchasePriceListsDocument,
  )

  const { me } = useMe()

  const [selectedPriceListId, setSelectedPricelistId] = useState('')

  const [updatePurchasePriceListItems] = useMutation(
    UpdatePurchasePriceListItemsDocument,
  )

  const [deletePurchasePriceListItems] = useMutation(
    DeletePurchasePriceListItemsDocument,
  )

  const apiRef = useGridApiRef()

  const {
    formatCurrency,
    formatStringCurrencyToNumber,
    formatEditableCurrency,
  } = useMoney()

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'images',
      ...ColumnProps.image,
      headerName: '',
      valueGetter: (_value, row): string => row.images?.[0] || '',
      cellClassName: 'image-column',
      headerClassName: 'image-column',
      renderCell: DataGridImageRenderer,
    },
    {
      field: 'id',
      ...ColumnProps.productId,
      headerName: t('purchase_prices:product_id'),
    },
    {
      field: 'sku',
      ...ColumnProps.sku,
      headerName: t('purchase_prices:sku'),
    },
    {
      field: 'title',
      ...ColumnProps.productTitle,
      headerName: t('purchase_prices:product'),
      valueGetter: (_value, row): CaptionRendererValue => ({
        title: row.title,
        caption: row.variant?.[0]?.value,
      }),
      renderCell: CaptionRenderer,
    },
    {
      field: 'activepos',
      ...ColumnProps.productActive,
      headerName: t('products:status'),
      renderCell: ProductStateRenderer,
    },
    {
      field: 'custom-price',
      ...ColumnProps.price,
      headerName: t('products:price'),
      valueGetter: (_value, row) => {
        const moneyPrice = row.pricelistitems?.find(
          (price) =>
            price.pricelist?.currencycode === me?.currentSite.currencycode,
        )?.moneyprice
        return moneyPrice || ''
      },
      valueFormatter: (value: string) => formatCurrency(value),
    },
    {
      field: 'moneypriceorg',
      ...ColumnProps.price,
      headerName: t('purchase_prices:suggested_retail_price'),
      valueFormatter: (value: string) => formatCurrency(value || ''),
    },
    {
      field: 'purchasePrice',
      ...ColumnProps.editablePrice,
      headerName: `${t('purchase_prices:purchase_price')} ${t(
        'purchase_prices:exclude',
      )}`,
      editable:
        writePurchasePrices &&
        priceListsData?.allPurchasePriceLists.find(
          (x) => x.id === selectedPriceListId,
        )?.owner_eshop_id === me?.siteId,
      valueGetter: (_value, row) => {
        const moneyPrice = row.purchasePriceValue
        return moneyPrice ? formatEditableCurrency(moneyPrice) : undefined
      },

      valueFormatter: (value, row, column): string => {
        return value ? formatCurrency(value) : ''
      },

      valueSetter: (value: string, row) => {
        const newPrice = value
          ? formatStringCurrencyToNumber(value).toFixed(2)
          : undefined

        return { ...row, purchasePriceValue: newPrice }
      },

      renderCell: (params: GridRenderEditCellParams<Row, string>) =>
        StringRenderer({ ...params, value: params.formattedValue }),

      renderEditCell: (params: GridRenderEditCellParams<Row>) =>
        StringEditRenderer(
          params,
          trackInputFocus,
          trackInputBlur,
          'purchase-price-list',
        ),
    },
  ]

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('purchase_prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('purchase_prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('purchase_prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('purchase_prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('purchase_prices:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('purchase_prices:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetProductPurchasePricesQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      priceListId: '',
      includeproductvariants: true,
      fallbackPriceToCommon: true,
    }

    if (
      !config.filter?.['priceListId'] ||
      typeof config.filter['priceListId'].value !== 'string'
    ) {
      setSelectedPricelistId('')
      return
    } else {
      setSelectedPricelistId(config.filter['priceListId'].value || '')
      variables.priceListId = config.filter['priceListId'].value
    }

    if (typeof config.filter?.['text']?.value === 'string') {
      variables.search = config.filter?.['text']?.value
    }

    const categoryIds = config.filter?.['categoryIds']?.value
    if (categoryIds) {
      variables.categoryIds = Array.isArray(categoryIds)
        ? categoryIds
        : [categoryIds]
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (config.filter?.['state']?.value) {
      variables.includeInactive = config.filter?.['state']?.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
    }

    return variables
  }, [apiRef, filter, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchProductPrices,
  } = useQuery(GetProductPurchasePricesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || isPricelistLoading

  const rows = useMemo(() => {
    return data?.products.items?.map((item) => ({
      ...item,
      purchasePriceValue:
        typeof item.purchasePrice?.price === 'number'
          ? item.purchasePrice.price.toFixed(2)
          : undefined,
    }))
  }, [data])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const processRowUpdate = async (newRow: Row, oldRow: Row) => {
    const priceListId = newRow.purchasePrice?.priceListId || selectedPriceListId
    const newPrice = newRow.purchasePriceValue
    const oldPrice = oldRow.purchasePriceValue

    if (newPrice === oldPrice) return newRow

    try {
      if (newPrice) {
        await updatePurchasePriceListItems({
          variables: {
            priceListId,
            items: [
              {
                price: Number(newPrice),
                sku: newRow.sku,
                productId: newRow.id,
              },
            ],
          },
        })
      } else {
        await deletePurchasePriceListItems({
          variables: {
            priceListId: selectedPriceListId,
            skus: [newRow.sku],
          },
        })
      }

      trackFormSuccess({
        name: 'purchase-price-list-row',
      })
      enqueueSnackbar(
        t('purchase_prices:success_update_purchase_price_snackbar', {
          count: 1,
        }),
      )

      return newRow
    } catch (error) {
      trackFormError({
        name: 'purchase-price-list-row',
      })
      enqueueSnackbar(
        t('purchase_prices:failure_update_purchase_price_snackbar', {
          count: 1,
        }),
        { variant: 'error' },
      )

      return oldRow
    }
  }

  return (
    <DataGrid
      name="purchase-price-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={rows}
      rowCount={data?.products.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      processRowUpdate={processRowUpdate}
      slots={{ footer: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('purchase_prices:grid.empty_purchase_price_title'),
        description: t('purchase_prices:grid.empty_purchase_price_description'),
        action: (
          <Button
            onClick={trackButtonClickEvent(
              { name: 'purchase-price-list-add-product' },
              () =>
                navigate(generatePath(RootRoute.SettingsPurchasePriceLists)),
            )}
          >
            {t('purchase_prices:grid.empty_purchase_price_action')}
          </Button>
        ),
      }}
      bulkAction={
        <>
          {writePurchasePrices && (
            <BulkActions refetch={refetchProductPrices} />
          )}
        </>
      }
      hasTextFilter
      onShowFilter={props.onShowFilter}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      rowHeight={50}
      disableColumnFilter
      checkboxSelection
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={{
        id: false,
        activepos: false,
        moneypriceorg: false,
      }}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
