import { Box, IconButton } from '@mui/material'
import { GridColDef } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { DeleteIcon } from '@sitoo/mui-components'
import { MutableRefObject, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../../..'
import { ArrayElement } from '../../../../../utils/types'
import { useTracking } from '../../../../../hooks/tracking'
import { ColumnProps } from '../../../../../components/data-grid/utils/column-props'
import { DataGridImageRenderer } from '../../../../../components/data-grid-image'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../../components/data-grid/utils/caption-renderer'
import { DataGrid } from '../../../../../components/data-grid'

type Props = {
  items: ArrayElement<
    CampaignFormContext['campaign']['productoptions']
  >['sitooProducts']
  removeItem: (index: number) => void
  apiRef: MutableRefObject<GridApiPro>

  readOnly?: boolean
}

type Row = ArrayElement<
  ArrayElement<
    CampaignFormContext['campaign']['productoptions']
  >['sitooProducts']
>

export const PackageItems = (props: Props) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const { items } = props
  const { trackButtonClick } = useTracking()

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: 'product_image',
        headerName: '',
        ...ColumnProps.image,
        valueGetter: (_value, row) => row?.productImages?.at(0)?.fileUrl,
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        ...ColumnProps.productTitle,
        headerName: t('campaigns:campaign_form.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: [row.title, row.variant?.at(0)?.name]
            .filter(Boolean)
            .join(' '),
          caption: row.sku,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'remove',
        headerName: '',
        flex: 0.4,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              const index = items?.findIndex(({ id }) => id === params.id)
              if (typeof index === 'number') {
                props.removeItem(index)
              }
              trackButtonClick({ name: 'delete-package-item' })
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      },
    ]

    return columns
  }, [items, props, t, trackButtonClick])

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="set-product-items-list"
      columns={dataGridColumns}
      rows={items}
      rowCount={items?.length}
      rowHeight={50}
      ignoreRowHover
      sx={{
        height: '300px',
      }}
      noRowsOverlay={{
        title: t('campaigns:campaign_form.no_rows_title'),
        description: t('campaigns:campaign_form.no_rows_description'),
      }}
      disableColumnVisibilityControl
      pagination={false}
      hideFooter
    />
  )
}
