import { Button, InputLabel, Select, TextField } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ProductBrand } from '..'
import { useAuthorization } from '../../../../../hooks/authorization'
import { useTracking } from '../../../../../hooks/tracking'
import { CountriesInput } from '../../../../../components/countries-input'

type BrandProps = {
  open: boolean
  brand?: ProductBrand

  onClose: () => void
  onSuccess: (brand: ProductBrand) => void
}

export const BrandDialog = (props: BrandProps) => {
  const { t } = useTranslation(['shared', 'settings', 'countries'])
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const prevOpen = usePrevious(props.open)
  const {
    modules: { writeSettingsBrands },
  } = useAuthorization()
  const dialogName = props.brand ? 'edit-brand' : 'add-brand'
  const isNewBrand = !props.brand
  const defaultValue: Omit<ProductBrand, 'id'> = {
    name: '',
  }

  const formContext = useForm<ProductBrand>()

  const submit = (data: ProductBrand) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess(data)
  }

  useEffect(
    () => formContext.reset(props.brand || defaultValue),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.open],
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  return (
    <Dialog
      open={props.open}
      maxWidth="xs"
      data-testid="add-edit-brand-dialog"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        {!writeSettingsBrands
          ? t('settings:brand.view_brand')
          : isNewBrand
            ? t('settings:brand.add_brand')
            : t('settings:brand.edit_brand')}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!!formContext.formState.errors.name}
          fullWidth
          helperText={formContext.formState.errors.name?.message}
          label={t('settings:brand.name')}
          {...formContext.register('name', {
            required: t('shared:validation.field_required', {
              field: t('settings:brand.name'),
            }),
          })}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-name',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.email}
          fullWidth
          helperText={formContext.formState.errors.email?.message}
          label={t('settings:brand.email')}
          {...formContext.register('email')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-email',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.phone}
          fullWidth
          helperText={formContext.formState.errors.phone?.message}
          label={t('settings:brand.phone')}
          {...formContext.register('phone')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-phone',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.address}
          fullWidth
          helperText={formContext.formState.errors.address?.message}
          label={t('settings:brand.address')}
          {...formContext.register('address')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-address',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.zip}
          fullWidth
          helperText={formContext.formState.errors.zip?.message}
          label={t('settings:brand.zip_code')}
          {...formContext.register('zip')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-zip',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.city}
          fullWidth
          helperText={formContext.formState.errors.city?.message}
          label={t('settings:brand.city')}
          {...formContext.register('city')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-city',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.state}
          fullWidth
          helperText={formContext.formState.errors.state?.message}
          label={t('settings:brand.state_region')}
          {...formContext.register('state')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-state',
          }}
          disabled={!writeSettingsBrands}
        />
        <CountriesInput control={formContext.control} name="countryid" />
        <TextField
          error={!!formContext.formState.errors.url1}
          fullWidth
          helperText={formContext.formState.errors.url1?.message}
          label={t('settings:brand.home_page')}
          {...formContext.register('url1')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-home-page',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.url2}
          fullWidth
          helperText={formContext.formState.errors.url2?.message}
          label={t('settings:brand.external_link')}
          {...formContext.register('url2')}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'brand-external-link',
          }}
          disabled={!writeSettingsBrands}
        />
        <TextField
          error={!!formContext.formState.errors.description}
          fullWidth
          multiline
          helperText={formContext.formState.errors.description?.message}
          label={t('settings:brand.description')}
          {...formContext.register('description')}
          inputProps={{
            'data-testid': 'brand-description',
          }}
          disabled={!writeSettingsBrands}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-brand"
        >
          {t(
            writeSettingsBrands
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsBrands && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-brand"
          >
            {isNewBrand ? t('shared:action.add') : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
