/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Divider } from '@mui/material'
import { Container } from '@mui/system'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo } from 'react'
import { FormProvider, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '..'
import { PageHeader } from '../../components/page-header'
import { RouteLeavingGuard } from '../../components/route-leaving-guard'
import {
  AddServiceOrderDocument,
  ServiceOrder,
  ServiceOrderDocument,
  ServiceOrderUser,
  UpdateServiceOrderDocument,
} from '../../generated/graphql'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useMe } from '../../hooks/me'
import { useServerValidation } from '../../hooks/server-validation'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { getErrorMessage } from '../../utils/error-mapping'
import { serviceOrderViewPanelVar } from '../service-orders'
import { CustomerField } from './fields/customer-field'
import { InfoField } from './fields/info-field'
import { NoteField } from './fields/note-field'
import { ProductField } from './fields/product-field'
import { ServicesField } from './fields/services-field'
import { StoreField } from './fields/store-field'
import { BaseServiceOrderFormContext } from './shared'
import { containsDirtyFields } from '../../utils/contains-dirty-fields'

export const ServiceOrderPage = () => {
  const generatePath = useAbsolutePath()
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation(['service_orders'])
  usePageTitle(t('page_title'))
  const navigate = useNavigate()
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { id: serviceOrderId } = useParams()
  const { me } = useMe()
  const formContext = useForm<BaseServiceOrderFormContext>()
  const setFormError = useServerValidation<BaseServiceOrderFormContext>(
    'service_orders',
    formContext.setError,
    {
      resolveFieldFromProperty: (property) => property,
    },
  )
  const { data, loading: serviceOrderLoading } = useQuery(
    ServiceOrderDocument,
    {
      variables: { serviceOrderId: String(serviceOrderId) },
      skip: !serviceOrderId,
    },
  )

  const [addServiceOrder, { loading: addLoading }] = useMutation(
    AddServiceOrderDocument,
  )
  const [updateServiceOrder, { loading: updateLoading }] = useMutation(
    UpdateServiceOrderDocument,
  )

  useEffect(() => {
    if (data) {
      formContext.reset(
        {
          ...data.serviceOrder,
          store: {
            ...data.serviceOrder.store,
            id: data.serviceOrder.store.id,
          },
        },
        { keepDefaultValues: false },
      )
    }
  }, [data, formContext])

  const generateNewServiceOrder = (
    submittedData: BaseServiceOrderFormContext,
  ): ServiceOrder => {
    const author: ServiceOrderUser = {
      email: me?.user?.email || '',
      employee_id: me?.user?.id,
      name: [me?.user?.namefirst, me?.user?.namelast].join(' '),
    }
    const currency = String(me?.sites[0]?.currencycode)

    return {
      ...submittedData,
      author,
      owner: author,
      currency,
      store: {
        id: submittedData.store?.id || null,
        name: submittedData.store?.name || null,
      },
    } as ServiceOrder
  }

  const generateUpdatedServiceOrder = (
    submittedData: BaseServiceOrderFormContext,
  ): ServiceOrder | undefined => {
    const {
      created,
      modified,
      customer: { id: customerId, ...customerFields },
      product: {
        id: productId,
        images,
        totalAfterShare,
        totalBeforeShare,
        ...productFields
      },
      ...restOfServiceOrder
    } = submittedData

    return {
      ...restOfServiceOrder,
      product: productFields,
      customer: customerFields,
      store: {
        id: submittedData.store?.id || null,
        name: submittedData.store?.name || null,
      },
    } as ServiceOrder
  }

  const onSubmit = async (submittedData: BaseServiceOrderFormContext) => {
    try {
      let serviceOrderIdToOpen = String(serviceOrderId)
      if (serviceOrderId) {
        trackButtonClick({ name: 'service-order-save' })
        const updatedServiceOrder = generateUpdatedServiceOrder(submittedData)

        if (updatedServiceOrder) {
          await updateServiceOrder({
            variables: {
              serviceOrder: {
                ...updatedServiceOrder,
                id: serviceOrderId,
              },
            },
          })
        }
      } else {
        trackButtonClick({ name: 'service-order-add' })
        const newServiceOrder = generateNewServiceOrder(submittedData)
        const { data: addedServiceOrderData } = await addServiceOrder({
          variables: {
            serviceOrder: { ...newServiceOrder },
          },
        })
        serviceOrderIdToOpen = String(addedServiceOrderData?.addServiceOrder.id)
      }

      trackFormSuccess({ name: 'service-order' })
      serviceOrderViewPanelVar({
        isOpen: true,
        serviceOrderId: serviceOrderIdToOpen,
      })
      enqueueSnackbar(
        t(
          serviceOrderId
            ? 'service_orders:service_order_message.success_update'
            : 'service_orders:service_order_message.success_add',
        ),
      )
      navigate(generatePath(RootRoute.ServiceOrders))
    } catch (error) {
      setFormError(error)
      const errorMessage = getErrorMessage(
        error,
        'service_orders',
        t(
          serviceOrderId
            ? 'service_orders:service_order_message.failure_update'
            : 'service_orders:service_order_message.failure_add',
        ),
      )

      trackFormError({
        name: 'service-orders',
        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
    }
  }

  const onError: SubmitErrorHandler<BaseServiceOrderFormContext> = (
    _errors,
  ) => {
    enqueueSnackbar(t('service_orders:error.generic'), {
      variant: 'error',
    })
  }
  const isSubmitting = addLoading || updateLoading

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={({ pathname }) => pathname !== location.pathname}
        when={
          !isSubmitting &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={
          serviceOrderId
            ? t('edit_service_order_title')
            : t('add_service_order_title')
        }
        backTo={generatePath(RootRoute.ServiceOrders)}
        backText={t('shared:menu.service_orders')}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <>
            <LoadingButton
              loading={isSubmitting}
              onClick={formContext.handleSubmit(onSubmit, onError)}
              data-testid="submit-service-order-form-button"
            >
              {serviceOrderId
                ? t('action_title_save_service_order')
                : t('action_title_add_service_order')}
            </LoadingButton>
          </>
        }
      />

      <Container data-testid="service-order-page">
        <FormProvider {...formContext}>
          {data?.serviceOrder && (
            <InfoField
              serviceOrder={data.serviceOrder}
              isLoading={serviceOrderLoading}
            />
          )}
          <StoreField />
          <ProductField />
          <ServicesField />
          <Divider />
          <CustomerField />
          <Divider />
          <NoteField />
        </FormProvider>
      </Container>
    </>
  )
}
