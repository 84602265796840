import { TextField, TextFieldProps, useForkRef } from '@mui/material'
import { useRef } from 'react'
import { FieldValues, useController, UseControllerProps } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Props = Omit<TextFieldProps, 'name'>

export const TextFieldInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { t } = useTranslation(['shared'])

  const { trackInputFocus, trackInputBlur } = useTracking()

  const { name, control, rules, ...textFieldProps } = props

  const { field, formState, fieldState } = useController({
    name,
    control,
    rules,
  })

  const isSubmitting = formState.isSubmitting

  const formError = fieldState.error

  const requiredText =
    formError?.type === 'required' && rules?.required === true
      ? t('shared:error.field_is_required', { property: name })
      : undefined

  const inputRef = useRef<HTMLInputElement>(null)
  const handleInputRef = useForkRef(field.ref, inputRef)

  return (
    <TextField
      {...textFieldProps}
      value={field.value}
      onChange={(event) => {
        field.onChange(event)
        props.onChange?.(event)
      }}
      onFocus={(event) => {
        trackInputFocus({ name })(event)
        props.onFocus?.(event)
      }}
      onBlur={(event) => {
        trackInputBlur({ name })(event)
        props.onBlur?.(event)
      }}
      error={!!formError}
      name={field.name}
      disabled={isSubmitting || textFieldProps.disabled}
      helperText={
        requiredText ?? formError?.message ?? textFieldProps.helperText
      }
      ref={inputRef}
      inputRef={handleInputRef}
    />
  )
}
