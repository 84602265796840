import { Container, List, ListItem, Paper } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { FormProvider, useForm } from 'react-hook-form'
import {
  PosCartSettingsDocument,
  Settings,
  SettingsCartItemPriceDisplayModeEnum,
  SettingsCartPrintPickListModeEnum,
  SettingsCashCountingModeEnum,
  UpdatePosCartSettingsDocument,
} from '../../../generated/graphql'
import { useMemo } from 'react'
import { PosSettingsForm } from '../pos-settings/pos-settings-form'
import { useMutation, useQuery } from '@apollo/client'
import { SelectInput } from '../../../components/select-input'
import { usePageTitle } from '../../../hooks/title'

type Form = Omit<Settings, 'eshopid'>

export const SettingsPosCartPage = () => {
  const { t } = useTranslation(['shared', 'pos_settings'])
  usePageTitle(t('shared:menu.pos_cart'))

  const defaultValues: Form = {
    cart_item_price_display_mode: SettingsCartItemPriceDisplayModeEnum.Default,
    cash_counting_mode: SettingsCashCountingModeEnum.EnterTotalAmount,
    cart_print_pick_list_mode: SettingsCartPrintPickListModeEnum.Active,
  }

  const formContext = useForm<Form>({ defaultValues })

  const { control, reset } = formContext

  useQuery(PosCartSettingsDocument, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => reset(data.settings, { keepDefaultValues: false }),
  })

  const [updateSettings] = useMutation(UpdatePosCartSettingsDocument)

  const onSubmit = async (data: Form) => {
    await updateSettings({ variables: { settings: data } })
  }

  const cartItemOptions = useMemo(
    () =>
      Object.values(SettingsCartItemPriceDisplayModeEnum).map((value) => ({
        value,
        name: t(`pos_settings:cart_item_price_display_mode.${value}`),
      })),
    [t],
  )

  const cashCountingOptions = useMemo(
    () =>
      Object.values(SettingsCashCountingModeEnum).map((value) => ({
        value,
        name: t(`pos_settings:cash_counting_mode.${value}`),
      })),
    [t],
  )

  const cartPickListOptions = useMemo(
    () =>
      Object.values(SettingsCartPrintPickListModeEnum).map((value) => ({
        value,
        name: t(`pos_settings:cart_print_pick_list_mode.${value}`),
      })),
    [t],
  )

  return (
    <>
      <PageHeader title={t('shared:menu.pos_cart')} />

      <Container>
        <Paper elevation={0} sx={{ pt: 1 }}>
          <FormProvider {...formContext}>
            <PosSettingsForm onSubmit={onSubmit}>
              <List>
                <ListItem divider>
                  <SelectInput
                    name="cart_item_price_display_mode"
                    control={control}
                    options={cartItemOptions}
                    label={t('pos_settings:cart_item_price_display_mode_label')}
                    helperText={t(
                      'pos_settings:cart_item_price_display_mode_helper',
                    )}
                  />
                </ListItem>

                <ListItem divider>
                  <SelectInput
                    name="cash_counting_mode"
                    control={control}
                    options={cashCountingOptions}
                    label={t('pos_settings:cash_counting_mode_label')}
                    helperText={t('pos_settings:cash_counting_mode_helper')}
                  />
                </ListItem>

                <ListItem>
                  <SelectInput
                    name="cart_print_pick_list_mode"
                    control={control}
                    options={cartPickListOptions}
                    label={t('pos_settings:cart_print_pick_list_mode_label')}
                    helperText={t(
                      'pos_settings:cart_print_pick_list_mode_helper',
                    )}
                  />
                </ListItem>
              </List>
            </PosSettingsForm>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}
