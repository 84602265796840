import { Box, Stack, TextField } from '@mui/material'
import { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'

type QueryParamsState = {
  paymentAmount?: [string, string]
  orderAmount?: [string, string]
}

type Props = {
  filterKey: 'paymentAmount' | 'orderAmount'
}

export const AmountFilter = (props: Props) => {
  const { t } = useTranslation('filter')
  const { filterKey } = props

  const i18nKey =
    filterKey === 'paymentAmount' ? 'payment_amount' : ('order_amount' as const)

  const {
    hasApply,
    setFilter,
    removeFilter,
    subscribeOnResetFilter,
    registerFilter,
  } = useContext(FilterContext)

  const { trackInput } = useTracking()

  const [queryParams, setQueryParams] =
    useStateParams<QueryParamsState>(undefined)

  const min = queryParams?.[filterKey]?.[0] || ''
  const max = queryParams?.[filterKey]?.[1] || ''

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [filterKey, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })
    return () => {
      unsubscribe()
    }
  }, [filterKey, setQueryParams, subscribeOnResetFilter])

  const updateQueryParams = (min: string, max: string) => {
    setQueryParams(
      { [filterKey]: min || max ? [min, max] : undefined },
      hasApply !== true,
    )
  }

  useEffect(() => {
    if (min || max) {
      setFilter(filterKey, {
        value: [min, max],
        label: t(`filter:${i18nKey}.label`),
        labelValues: [[min || 'x', max || 'x'].join(' - ')],
      })
    } else {
      removeFilter(filterKey)
    }
  }, [filterKey, i18nKey, max, min, removeFilter, setFilter, setQueryParams, t])

  return (
    <Stack direction="row" alignItems="end" spacing={1}>
      <TextField
        data-testid="order-min-amount-input"
        placeholder={t(`filter:${i18nKey}.min`)}
        {...trackInput({ name: 'payment-amount-input' })}
        value={min}
        onChange={(event) => {
          updateQueryParams(event.target.value, max)
        }}
        inputProps={{ ['test-id']: 'payment-amount-filter-input' }}
        label={t(`filter:${i18nKey}.label`)}
      />

      <Box sx={{ color: (theme) => theme.palette.text.secondary, pb: 1 }}>
        -
      </Box>

      <TextField
        data-testid="order-max-amount-input"
        placeholder={t(`filter:${i18nKey}.max`)}
        value={max}
        {...trackInput({ name: 'payment-amount-input' })}
        onChange={(event) => {
          updateQueryParams(min, event.target.value)
        }}
        inputProps={{ ['test-id']: 'payment-amount-filter-input' }}
      />
    </Stack>
  )
}
