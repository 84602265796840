import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid, Tab, Tabs } from '@mui/material'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { RootRoute } from '../../..'
import { PageHeader } from '../../../../components/page-header'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { useTracking } from '../../../../hooks/tracking'
import { PaymentOptionsList } from './payment-options-list'
import { PaymentOptionsViewPanel } from './payment-options-view-panel'
import { PaymentSettingsTab } from './payment-settings-tab'
import { usePageTitle } from '../../../../hooks/title'

enum TabLabel {
  List = 1,
  Settings,
}

type ViewPanelState = {
  isOpen: boolean
  id?: number
}

export const paymentOptionsViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  id: undefined,
})
export const SettingsPosPaymentOptionsPage = () => {
  const [searchParams, setSearchParams] = useSearchParams()
  const tabParam = searchParams.get('tab')
  const [activeTab, setActiveTab] = useState(TabLabel.List)
  const { t } = useTranslation(['pos_payment', 'shared'])
  const { trackButtonClick } = useTracking()
  const viewPanelState = useReactiveVar(paymentOptionsViewPanelVar)
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  usePageTitle(t('shared:menu.pos_payment_options'))

  useEffect(() => {
    if (TabLabel[Number(tabParam)]) {
      setActiveTab(Number(tabParam))
    }
  }, [tabParam])

  const closeViewPanel = () => {
    trackButtonClick({ name: 'payment-options-view-panel-close' })
    paymentOptionsViewPanelVar({ isOpen: false })
  }

  const openViewPanel = (id: number) => {
    trackButtonClick({ name: 'payment-options-view-panel-open' })
    paymentOptionsViewPanelVar({ isOpen: true, id })
  }

  const onAdd = () => {
    trackButtonClick({ name: 'payment-options-add' })
    navigate(generatePath(RootRoute.SettingsPosPaymentOptionNew))
  }

  return (
    <Grid container flexWrap="nowrap">
      <Grid item flexGrow={1}>
        <PageHeader
          title={t('pos_payment:page_title')}
          isSticky={true}
          isFlexible={true}
          rightColumn={
            activeTab === TabLabel.List && (
              <Button data-testid="add-payment-option-button" onClick={onAdd}>
                {t('pos_payment:add_payment_option')}
              </Button>
            )
          }
        >
          <Tabs
            value={activeTab}
            onChange={(_e, value: TabLabel) => {
              if (value === TabLabel.Settings) {
                closeViewPanel()
              }
              setActiveTab(value)
              setSearchParams({ tab: String(value) })
            }}
            variant="standard"
            sx={{
              '.MuiButtonBase-root': {
                borderColor: 'transparent',
              },
            }}
          >
            <Tab
              label={t('pos_payment:tab_label_list')}
              value={TabLabel.List}
              data-testid="payment-options-tabs-list"
            />
            <Tab
              label={t('pos_payment:tab_label_settings')}
              value={TabLabel.Settings}
              data-testid="payment-options-tabs-settings"
            />
          </Tabs>
        </PageHeader>
        {activeTab === TabLabel.List && (
          <PaymentOptionsList onDetailPaymentOption={openViewPanel} />
        )}
        {activeTab === TabLabel.Settings && <PaymentSettingsTab />}
      </Grid>
      <Grid item>
        <PaymentOptionsViewPanel
          open={viewPanelState.isOpen}
          id={viewPanelState.id}
          onClose={closeViewPanel}
        />
      </Grid>
    </Grid>
  )
}
