/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import { Button, TextField } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { ShipmentInfo } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

type Props = {
  open: boolean
  onClose(): void
  onSubmit?(contact: ShipmentInfo): Promise<void> | void
  loading?: boolean
  info?: ShipmentInfo | null
}

type Form = Pick<ShipmentInfo, 'barcode' | 'reference_number' | 'note'>

export const SetGeneralInfoDialog = (props: Props) => {
  const { formState, reset, handleSubmit, register } = useForm<Form>()
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-general-info'

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()

  const onSubmit = async (formData: Form) => {
    try {
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.info })
    }
  }, [props.open, trackDialogOpen, props.info, reset])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="set-general-info-dialog"
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle type="extended">
          {t('shipments:set_general_info_section.title')}
        </DialogTitle>
        <DialogContent sx={{ p: 2 }}>
          <TextField
            error={!!formState?.errors.reference_number}
            fullWidth
            helperText={formState?.errors.reference_number?.message}
            label={t('shipments:shipment_form.reference_number')}
            {...register('reference_number')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors.barcode}
            fullWidth
            helperText={formState?.errors.barcode?.message}
            label={t('shipments:shipment_form.barcode')}
            {...register('barcode')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors.note}
            fullWidth
            helperText={formState?.errors.note?.message}
            label={t('shipments:shipment_form.note')}
            {...register('note')}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            data-testid="set-general-info-button"
            loading={props.loading}
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
