import { useMemo } from 'react'
import {
  AppsIcon,
  ArrowLeftIcon,
  AvatarName,
  DocumentationIcon,
  ErrorIcon,
  FolderIcon,
  ImportIcon,
  LockIcon,
  OrderIcon,
  SettingsIcon,
  UserDefaultIcon,
  WarehouseIcon,
  SalesTaxIcon,
  RegisterCashIcon,
  StarIcon,
  StoreIcon,
} from '@sitoo/mui-components'
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
} from '@mui/material'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../../pages'
import { useAuthorization } from '../../hooks/authorization'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { MenuSection, SidebarList } from '../sidebar-list'
import { useMe } from '../../hooks/me'
import { FeedbackDialog, feedbackDialogOpenVar } from '../feedback-dialog'

export const SettingsSidebar = () => {
  const { t } = useTranslation(['shared'])
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { modules } = useAuthorization()
  const { displayName } = useMe()

  const menuSections: MenuSection[] = useMemo(
    () => [
      {
        name: t('shared:menu.settings'),
        items: [
          {
            name: t('shared:menu.profile'),
            icon: (
              <AvatarName
                variant="circular"
                sx={{
                  cursor: 'pointer',
                  fontSize: '10px',
                  width: '20px',
                  height: '20px',
                }}
              >
                {displayName}
              </AvatarName>
            ),
            to: RootRoute.SettingsProfile,
            active: modules.settingsGeneral,
          },
          {
            name: t('shared:menu.general'),
            icon: <SettingsIcon />,
            to: RootRoute.SettingsGeneral,
            active: modules.settingsGeneral,
          },
          {
            name: t('shared:menu.pos_settings'),
            icon: <RegisterCashIcon />,
            items: [
              {
                name: t('shared:menu.pos_general'),
                active: modules.settingsPosGeneral,
                to: RootRoute.SettingsPosGeneral,
              },
              {
                name: t('shared:menu.pos_cart'),
                active: modules.settingsPosCart,
                to: RootRoute.SettingsPosCart,
              },
              {
                name: t('shared:menu.dashboards'),
                active: modules.settingsPosDashboards,
                to: RootRoute.SettingsPosDashboards,
              },
              {
                name: t('shared:menu.pos_required_fields'),
                active: modules.settingsPosRequiredFields,
                to: RootRoute.SettingsPosRequiredFields,
              },
            ],
          },
          {
            name: t('shared:menu.pos_payment'),
            icon: <RegisterCashIcon />,
            items: [
              {
                name: t('shared:menu.pos_payment_options'),
                active: modules.settingsPosPaymentOptions,
                to: RootRoute.SettingsPosPaymentOptions,
              },
            ],
          },
          {
            name: t('shared:menu.apps'),
            icon: <AppsIcon />,
            items: [
              {
                name: t('shared:menu.adyen_terminal_api'),
                active: modules.settingsAdyenTerminalApi,
                to: RootRoute.SettingsAdyenTerminalApi,
              },
              {
                name: t('shared:menu.adyen_tap_to_pay'),
                active: modules.settingsAdyenTapToPay,
                to: RootRoute.SettingsAdyenTapToPay,
              },
              {
                name: t('shared:menu.ctu_norway'),
                active: modules.settingsCtuNorway,
                to: RootRoute.SettingsCtuNorway,
              },
            ],
          },
        ],
      },
      {
        name: t('shared:menu.data_setup'),
        items: [
          {
            name: t('shared:menu.products'),
            icon: <FolderIcon />,
            items: [
              {
                name: t('shared:menu.product_groups'),
                to: RootRoute.SettingsProductsGroups,
                active: modules.settingsProductGroups,
              },
              {
                name: t('shared:menu.brands'),
                to: RootRoute.SettingsProductsBrands,
                active: modules.settingsBrands,
              },
              {
                name: t('shared:menu.categories_navigation'),
                to: RootRoute.SettingsProductsCategories,
                active: modules.settingsCategoriesNavigation,
              },
              {
                name: t('shared:menu.custom_attributes'),
                to: RootRoute.SettingsProductsCustomAttributes,
                active: modules.settingsCustomAttributes,
              },
            ],
          },
          {
            name: t('shared:menu.price_lists'),
            icon: <OrderIcon />,
            to: RootRoute.SettingsPriceLists,
            active: modules.settingsPriceList,
          },
          {
            name: t('shared:menu.purchase_price_lists'),
            icon: <OrderIcon />,
            to: RootRoute.SettingsPurchasePriceLists,
            active: modules.settingsPurchasePriceList,
          },
          {
            name: t('shared:menu.reason_codes'),
            icon: <ErrorIcon />,
            to: RootRoute.SettingsReasonCodes,
            active: modules.settingsReasonCodes,
          },
          {
            name: t('shared:menu.sales_taxes'),
            icon: <SalesTaxIcon />,
            active: modules.settingsSalesTaxes,
            items: [
              {
                name: t('shared:menu.sales_taxes'),
                active: modules.settingsSalesTaxes,
                to: RootRoute.SettingsSalesTaxes,
              },
              {
                name: t('shared:menu.sales_tax_groups'),
                active: modules.settingsSalesTaxes,
                to: RootRoute.SettingsSalesTaxGroups,
              },
            ],
          },
          {
            name: t('shared:menu.stores'),
            icon: <StoreIcon />,
            to: RootRoute.SettingsStores,
            active: modules.settingsStores,
          },
          {
            name: t('shared:menu.users'),
            icon: <UserDefaultIcon />,
            active: modules.users,
            items: [
              {
                name: t('shared:menu.user_tags'),
                to: RootRoute.SettingsUserTags,
                active: modules.users,
              },
            ],
          },

          {
            name: t('shared:menu.warehouses'),
            icon: <WarehouseIcon />,
            to: RootRoute.SettingsWarehouses,
            active: modules.settingsWarehouses,
          },
          {
            name: t('shared:menu.custom_api'),
            icon: <AppsIcon />,
            to: RootRoute.SettingsCustomAPI,
            active: modules.settingsRewards,
          },
        ],
      },
      {
        name: t('shared:menu.security'),
        items: [
          {
            name: t('shared:menu.authentication'),
            icon: <LockIcon />,
            to: RootRoute.SettingsAuthentication,
            active: modules.settingsAuthentication,
          },
          {
            name: t('shared:menu.log_events'),
            icon: <DocumentationIcon />,
            to: RootRoute.SettingsAccessLogs,
            active: modules.settingsAccessLogs,
          },
        ],
      },
      {
        name: t('shared:menu.import'),
        items: [
          {
            name: t('shared:menu.import_data'),
            icon: <ImportIcon />,
            to: RootRoute.SettingsImportData,
            active: modules.settingsImportPrices || modules.settingsImportUsers,
          },
        ],
      },
    ],
    [
      t,
      displayName,
      modules.settingsGeneral,
      modules.settingsPosGeneral,
      modules.settingsPosCart,
      modules.settingsPosDashboards,
      modules.settingsPosRequiredFields,
      modules.settingsPosPaymentOptions,
      modules.settingsAdyenTerminalApi,
      modules.settingsAdyenTapToPay,
      modules.settingsCtuNorway,
      modules.settingsProductGroups,
      modules.settingsBrands,
      modules.settingsCategoriesNavigation,
      modules.settingsCustomAttributes,
      modules.settingsPriceList,
      modules.settingsPurchasePriceList,
      modules.settingsReasonCodes,
      modules.settingsSalesTaxes,
      modules.settingsStores,
      modules.users,
      modules.settingsWarehouses,
      modules.settingsRewards,
      modules.settingsAuthentication,
      modules.settingsAccessLogs,
      modules.settingsImportPrices,
      modules.settingsImportUsers,
    ],
  )

  return (
    <SidebarList
      isSettings
      menuSections={menuSections}
      header={
        <List>
          <ListItem
            sx={{
              background: 'transparent',
              marginTop: (theme) => theme.spacing(2),
              cursor: 'pointer',
              ':hover': {
                background: (theme) => theme.palette.gray70,
              },
            }}
            onClick={() => navigate(generatePath(RootRoute.Home))}
          >
            <ListItemIcon>
              <ArrowLeftIcon sx={{ color: (theme) => theme.palette.white }} />
            </ListItemIcon>

            <ListItemText>{t('shared:menu.home')}</ListItemText>
          </ListItem>
        </List>
      }
      footer={
        modules.directFeedback && (
          <List sx={{ mb: 2 }}>
            <FeedbackDialog />

            <MenuItem
              onClick={() => feedbackDialogOpenVar(true)}
              sx={{ pl: 3 }}
            >
              <ListItemIcon sx={{ color: 'inherit' }}>
                <StarIcon />
              </ListItemIcon>
              <ListItemText>
                {t('shared:feedback_dialog.leave_feedback')}
              </ListItemText>
            </MenuItem>
          </List>
        )
      }
    />
  )
}
