import { Chip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

export const ProductStateRenderer = (
  params: Pick<GridRenderCellParams<{ activepos?: boolean | null }>, 'row'>,
): React.ReactElement => (
  <ProductState isActive={params.row.activepos || false} />
)

export type ProductStateProps = {
  isActive: boolean
}

export const ProductState = (props: ProductStateProps): React.ReactElement => {
  const { t } = useTranslation('shared')
  const color = props.isActive ? 'green' : 'red'
  const label = props.isActive ? t('label.active') : t('label.inactive')

  return <Chip size="small" color={color} label={label} />
}
