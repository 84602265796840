/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../hooks/tracking'

type Form = { name: string }

type Props = {
  open: boolean
  onClose(): void
  onSubmit?(formData: Form): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  dataTestid?: string
}

export const FilterPresetSaveDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'filter'])

  const dialogName = 'save-filter-preset'

  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, register } = useForm<Form>({
    mode: 'onChange',
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })

      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      reset()
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, reset])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="xs"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('filter:filter_preset.dialog_save_label')}
        </DialogTitle>

        <DialogContent>
          <TextField
            error={!!formState?.errors?.name}
            fullWidth
            helperText={formState?.errors?.name?.message}
            label={t('filter:filter_preset.dialog_save_input_label')}
            {...register('name')}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-cancel` },
              onClose,
            )}
            variant="secondary"
            size="compact"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            data-testid="set-address"
            loading={loading}
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
