import {
  Box,
  Collapse,
  IconButton,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material'
import { ChevronSmallDownIcon, ChevronSmallUpIcon } from '@sitoo/mui-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { useTracking } from '../../../hooks/tracking'

type DescriptionProps = {
  description?: string | null
  isLoading?: boolean
}

export const Description = (props: DescriptionProps) => {
  const lineSize = useMemo(() => 18, [])
  const defaultCollapseSize = useMemo(() => lineSize * 2, [lineSize])
  const [collapseSize, setCollapseSize] = useState(defaultCollapseSize)
  const textRef = useRef<HTMLElement | null>(null)
  const [isShowingAll, setIsShowingAll] = useState(false)
  const [needsToCollapse, setNeedsToCollapse] = useState(false)
  const { trackButtonClickEvent } = useTracking()

  useEffect(() => {
    if (!textRef.current) return

    const minimumCollapseSize =
      lineSize * Math.ceil(textRef.current.offsetHeight / lineSize)
    const newCollapseSize =
      minimumCollapseSize > defaultCollapseSize
        ? defaultCollapseSize
        : minimumCollapseSize

    setCollapseSize(newCollapseSize)
    setNeedsToCollapse(textRef.current.offsetHeight > newCollapseSize)
  }, [defaultCollapseSize, lineSize, props.description])

  if (!props.isLoading && !props.description) return null

  return (
    <Stack
      sx={{
        padding: (theme) => theme.spacing(1, 2),
        background: (theme) => theme.palette.background.paper,
      }}
      spacing={1}
      direction="row"
    >
      {props.isLoading ? (
        <Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
          <Typography variant="caption">
            <Skeleton />
          </Typography>
          <Typography variant="caption">
            <Skeleton />
          </Typography>
        </Box>
      ) : (
        <>
          <Collapse
            in={isShowingAll}
            collapsedSize={collapseSize}
            sx={{
              width: '100%',

              '.MuiCollapse-wrapperInner': {
                textOverflow: 'ellipsis',
                overflow: 'hidden',
              },
            }}
          >
            <Typography
              data-testid="product-description"
              variant="caption"
              ref={textRef}
              sx={{
                whiteSpace: 'pre-wrap',
              }}
              variantMapping={{
                caption: 'div',
              }}
            >
              {props.description}
            </Typography>
          </Collapse>
          {needsToCollapse && (
            <Box>
              <IconButton
                onClick={trackButtonClickEvent(
                  { name: 'product-panel-description-toggle' },
                  () => setIsShowingAll((p) => !p),
                )}
              >
                {isShowingAll ? (
                  <ChevronSmallUpIcon />
                ) : (
                  <ChevronSmallDownIcon />
                )}
              </IconButton>
            </Box>
          )}
        </>
      )}
    </Stack>
  )
}
