import { useQuery } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { Link, ShipmentIcon } from '@sitoo/mui-components'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import {
  ArchivedFilterTypeV2,
  GetShipmentsV2Document,
  GetShipmentsV2Query,
  GetShipmentsV2QueryVariables,
} from '../../../generated/graphql'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { ArrayElement } from '../../../utils/types'
import { ShipmentStateChip } from '../shipment-state-chip/shipment-state-chip'
import { BulkActions } from './bulk-actions'

type Row = ArrayElement<GetShipmentsV2Query['getShipmentsV2']['items']>

type Props = {
  onDetail(shipmentId: number): void
  selectedId?: number
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const ShipmentsList = (props: Props) => {
  const { t } = useTranslation([
    'shipments_v2',
    'shared',
    'filter',
    'countries',
  ])
  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const apiRef = useGridApiRef()

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'shipmentid',
        minWidth: 60,
        headerName: t('shipments_v2:shipment_id'),
      },
      {
        field: 'datenew',
        ...ColumnProps.date,
        headerName: t('shipments_v2:view_panel.created_at'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'dateintransit',
        ...ColumnProps.date,
        headerName: t('shipments_v2:view_panel.sent_at'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'state',
        minWidth: 120,
        headerName: t('shipments_v2:state'),
        renderCell: (params) => {
          return (
            <ShipmentStateChip
              label={t(
                `shipments_v2:shipment_state.${params.row.shipmentstate}`,
              )}
              state={params.row.shipmentstate}
            />
          )
        },
      },

      {
        field: 'origin',
        minWidth: 200,
        headerName: t('shipments_v2:sender'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.sender_name,
          caption: row.sender_address,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'destination',
        minWidth: 200,
        headerName: t('shipments_v2:receiver'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.receiver_name,
          caption: row.receiver_address,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'carrier',
        minWidth: 120,
        headerName: t('shipments_v2:carrier'),
        valueGetter: (_value, row): CaptionRendererValue => {
          const carrierName = row.carrier_name ? `(${row.carrier_name})` : ''

          return {
            title: [row.carrier_reference, carrierName].join(' '),
            caption: row.carrier_trackingurl ? (
              <Link
                href={row.carrier_trackingurl}
                target="_blank"
                rel="noopener noreferrer"
              >
                {t('shipments_v2:track_shipment')}
              </Link>
            ) : undefined,
          }
        },
        renderCell: CaptionRenderer,
      },
      {
        field: 'comment',
        minWidth: 320,
        headerName: t('shipments_v2:comment'),
        valueGetter: (_value, row) => row.comment,
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
    }

    const variables: GetShipmentsV2QueryVariables = {
      num: config.pagination?.pageSize,
      start: 0,
      archived: filter['showArchived']?.value as ArchivedFilterTypeV2,
    }

    return variables
  }, [filter])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetShipmentsV2Document, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || !isFilterReady

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (
      data?.getShipmentsV2.start !== null &&
      data?.getShipmentsV2.start !== undefined
    ) {
      return fetchMore({
        variables: {
          start: (data?.getShipmentsV2?.start ?? 0) + pageSize,
          num: pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const loadedAllShipments =
    (data?.getShipmentsV2.start ?? 0) + PAGE_SIZE >=
    (data?.getShipmentsV2.totalcount ?? 0)

  return (
    <DataGrid
      name="shipments-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.getShipmentsV2.items || []}
      getRowId={(row) => row.shipmentid ?? ''}
      rowCount={
        data?.getShipmentsV2.items?.length
          ? data.getShipmentsV2.items.length + 1
          : 0
      }
      onShowFilter={props.onShowFilter}
      loading={isLoading}
      onRowClick={(row) => props.onDetail(Number(row.id))}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <ShipmentIcon />,
        title: t('shipments_v2:grid.empty_title'),
        description: t('shipments_v2:grid.empty_description'),
      }}
      slots={{ footer: CursorPagination }}
      bulkAction={<BulkActions />}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      showMore={!loadedAllShipments}
      getRowClassName={(params) => {
        const classes = []
        if (params.id === props.selectedId) {
          classes.push('Mui-selected')
        }

        if (params.row.archived) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
