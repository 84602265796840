import { useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  TextField,
} from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import {
  ArrowRightSmallIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionHeader,
} from '@sitoo/mui-components'
import { useEffect, useMemo, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetShipmentV2Query,
  ShipmentV2ItemInput,
  ShipmentV2PackageInput,
  UpdateShipmentV2Document,
} from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ShipmentV2Item } from '../shared'
import { ReceivedPackageItems } from './received-package-items'

type ReceivedPackageItems = {
  items: { id: number; quantity: number }[]
  comment?: string
}

type Props = {
  open: boolean
  packageId: number | undefined | null
  shipment: GetShipmentV2Query['getShipmentV2']
  warehouseId?: number | null
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

export const SetReceivedPackageItemsDialog = (props: Props) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-received-package-items-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { displayName } = useMe()

  const prevOpen = usePrevious(props.open)

  const [updateShipment, { loading: updateLoading }] = useMutation(
    UpdateShipmentV2Document,
  )

  const shipmentPackage = useMemo(() => {
    return props.shipment.packages?.find(
      (p) => p.shipmentpackageid === props.packageId,
    )
  }, [props.packageId, props.shipment.packages])

  const { formState, reset, handleSubmit, watch, setValue, register } =
    useForm<ReceivedPackageItems>({
      mode: 'onChange',
      defaultValues: { items: [], comment: '' },
    })

  const apiRef = useGridApiRef()
  const items = watch('items')
  const [state, setState] = useState<'items' | 'confirm'>('items')

  const shipmentId = props.shipment.shipmentid

  const onSubmit = async (formData: ReceivedPackageItems) => {
    try {
      if (!shipmentId) return

      const getReceivedQuantity = (item: ShipmentV2Item): number => {
        const formItem = formData.items.find(
          ({ id }) => id === item.shipmentitemid,
        )
        const currentQuantity = item.quantityreceived || 0
        const newQuantity = formItem?.quantity || 0

        return currentQuantity + newQuantity
      }

      const getPackageItems = (
        items: ShipmentV2Item[],
      ): ShipmentV2ItemInput[] => {
        return items.map((shipmentItem) => ({
          ...shipmentItem,
          quantityreceived: getReceivedQuantity(shipmentItem),
        }))
      }

      const packages: ShipmentV2PackageInput[] =
        props.shipment.packages?.map((shipmentPackage) => {
          if (shipmentPackage.shipmentpackageid !== props.packageId) {
            return shipmentPackage
          }

          return {
            ...shipmentPackage,
            items: getPackageItems(shipmentPackage.items || []),
            comment: formData.comment,
          }
        }) || []

      await updateShipment({
        variables: {
          shipment: {
            shipmentid: shipmentId,
            packages: packages,
          },
        },
      })
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments_v2',
        t('shipments_v2:shipment_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      setState('items')
      reset(
        {
          items: [],
          comment: shipmentPackage?.comment || '',
        },
        { keepDefaultValues: false },
      )
    }
  }, [prevOpen, props.open, reset, trackDialogOpen, shipmentPackage?.comment])

  const gridItems = useMemo(
    () => shipmentPackage?.items || [],
    [shipmentPackage?.items],
  )

  const totalPreviousReceivedItems = shipmentPackage?.totalReceivedQuantity || 0
  const totalItems = shipmentPackage?.totalQuantity || 0

  const totalReceivedItems =
    totalPreviousReceivedItems + items.reduce((p, c) => p + c.quantity, 0)

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle type="extended" sx={{ pb: 2 }}>
        {t('shipments_v2:receive_items_dialog.title')}
      </DialogTitle>
      <DialogContent sx={{ px: state === 'items' ? 0 : undefined }}>
        {state === 'items' && gridItems ? (
          <>
            <ReceivedPackageItems
              apiRef={apiRef}
              items={gridItems}
              updateItem={(item) => {
                const existingItem = items.find(
                  (x) => x.id === item.shipmentitemid,
                )
                if (existingItem) {
                  existingItem.quantity = item.quantityreceived
                  setValue('items', items)
                } else {
                  setValue('items', [
                    ...items,
                    {
                      id: item.shipmentitemid,
                      quantity: item.quantityreceived,
                    },
                  ])
                }
              }}
            />
          </>
        ) : (
          <>
            <TextField
              fullWidth
              error={!!formState.errors.comment}
              helperText={formState.errors.comment?.message}
              label={t('shipments_v2:receive_items_dialog.note')}
              {...register('comment')}
              multiline={true}
              rows={3}
              sx={{ mb: 2 }}
            />
            <SectionHeader variant="transparent" sx={{ px: 0 }}>
              {t('shipments_v2:receive_items_dialog.summary')}
            </SectionHeader>
            <List sx={{ mx: -3 }}>
              <ListItem
                secondaryAction={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ReceivedItemChip
                      label={t('shipments_v2:packages.received_of_total', {
                        received: totalPreviousReceivedItems,
                        total: totalItems,
                      })}
                      quantity={totalItems}
                      quantityReceived={totalPreviousReceivedItems}
                    />
                    <ArrowRightSmallIcon
                      sx={{ color: (theme) => theme.palette.gray50 }}
                    />
                    <ReceivedItemChip
                      label={t('shipments_v2:packages.received_of_total', {
                        received:
                          totalReceivedItems > totalItems
                            ? totalItems
                            : totalReceivedItems,
                        total: totalItems,
                      })}
                      quantity={totalItems}
                      quantityReceived={
                        totalReceivedItems > totalItems
                          ? totalItems
                          : totalReceivedItems
                      }
                    />
                  </Box>
                }
              >
                <ListItemText
                  primary={t('shipments_v2:receive_items_dialog.items')}
                />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={<ListItemText primary={displayName} />}
              >
                <ListItemText
                  primary={t('shipments_v2:receive_items_dialog.responsible')}
                />
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            { name: `${dialogName}-dialog-cancel` },
            onClose,
          )}
          color="secondary"
          size="small"
          type="button"
        >
          {t('shared:action.cancel')}
        </Button>
        {state === 'items' && (
          <LoadingButton
            size="small"
            type="button"
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-receive` },
              () => setState('confirm'),
            )}
            disabled={items.length === 0 || !items.some((x) => x.quantity > 0)}
          >
            {t('shipments_v2:receive_items_dialog.receive')}
          </LoadingButton>
        )}

        {state === 'confirm' && (
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent(
              { name: `${dialogName}-dialog-confirm` },
              handleSubmit(onSubmit),
            )}
            disabled={
              !formState.isValid ||
              items.length === 0 ||
              !items.some((x) => x.quantity > 0)
            }
            loading={updateLoading}
          >
            {t('shipments_v2:receive_items_dialog.confirm')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
