import { useLazyQuery } from '@apollo/client'
import {
  Box,
  Button,
  Divider,
  List,
  ListItem,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import {
  DialogActions,
  DialogContent,
  DialogTitle,
  WarningIcon,
} from '@sitoo/mui-components'
import { UploadedFile } from '@sitoo/mui-components'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ImportInput } from '../../../components/import-input'
import {
  ConvertShipmentPackageItemsCsvDocument,
  ConvertShipmentPackageItemsCsvQuery,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'

export type ShipmentPackageItems =
  ConvertShipmentPackageItemsCsvQuery['convertShipmentPackageItemsCsv']['items']

export type Props = {
  onClose: () => void
  onSubmit: (items: ShipmentPackageItems) => void
  onError?(): void
}

export const ImportPackageItems = (props: Props) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'import-package-items'
  const [files, setFiles] = useState<UploadedFile[]>()
  const [step, setStep] = useState<0 | 1>(0)

  const [
    convertWarehouseBatchItemsToCsv,
    { data: csvPackageItemsData, loading: convertLoading },
  ] = useLazyQuery(ConvertShipmentPackageItemsCsvDocument)
  const isLoading = convertLoading
  const { trackButtonClick, trackFormSuccess } = useTracking()

  const onConvert = () => {
    const csvFile = files?.[0]
    const reader = new FileReader()

    if (csvFile?.file) {
      reader.readAsText(csvFile?.file, 'UTF-8')

      reader.onload = async (event) => {
        const content = event.target?.result
        if (typeof content === 'string') {
          await convertWarehouseBatchItemsToCsv({ variables: { csv: content } })
          trackFormSuccess({ name: dialogName })
          setStep(1)
        }
      }
    }
  }

  return (
    <>
      <DialogTitle type="extended">
        {t('shipments:import_dialog.title')}
      </DialogTitle>

      <DialogContent sx={{ px: 0 }}>
        {step === 0 && (
          <>
            <ImportInput setFiles={setFiles} />
            <Typography
              sx={{
                color: (t) => t.palette.text.disabled,
                whiteSpace: 'pre',
                p: 2,
              }}
            >
              {t('shipments:import_dialog.example_label')}
              {`\nsku;quantity\nproduct-sku-1;10\nproduct-sku-2;0\n`}
            </Typography>
          </>
        )}

        {step === 1 && (
          <>
            <Stack
              direction="column"
              alignItems="center"
              spacing={2}
              sx={{ mb: 2 }}
            >
              <WarningIcon fontSize="large" color="warning" />
              <Typography variant="heading">
                {t('shipments:import_dialog.confirm_import')}
              </Typography>
            </Stack>

            <List>
              <ListItem>
                <ListItemText
                  primary={
                    <Typography
                      variant="subheader"
                      color="gray60"
                      sx={{ textTransform: 'uppercase' }}
                    >
                      {t('shipments:import_dialog.summary')}
                    </Typography>
                  }
                />
              </ListItem>

              <ListItem>
                <ListItemText
                  primary={t('shipments:import_dialog.number_import_label')}
                />
                <Typography fontWeight="medium">
                  {csvPackageItemsData?.convertShipmentPackageItemsCsv?.items
                    ?.length || 0}
                </Typography>
              </ListItem>

              <Divider component="li" />

              <ListItem>
                <ListItemText
                  primary={t('shipments:import_dialog.skipped_import_label')}
                  secondary={csvPackageItemsData?.convertShipmentPackageItemsCsv.errors.map(
                    (error) => (
                      <Box
                        component="span"
                        key={error.property}
                        sx={{
                          px: 1,
                          color: (t) => t.palette.error.main,
                          display: 'block',
                        }}
                      >
                        {t(`shipments:error.${error.code.toLowerCase()}`, {
                          property: error.property,
                        })}
                      </Box>
                    ),
                  )}
                />
                <Typography fontWeight="medium">
                  {csvPackageItemsData?.convertShipmentPackageItemsCsv.errors
                    ?.length || 0}
                </Typography>
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>

      <DialogActions>
        {
          <Button
            onClick={() => {
              trackButtonClick({ name: `${dialogName}-back` })
              props?.onClose()
            }}
            color="secondary"
            size="small"
          >
            {t('shared:action.back')}
          </Button>
        }
        {step === 0 && (
          <Button
            onClick={onConvert}
            size="small"
            data-testid="dialog-submit"
            disabled={!files?.length || isLoading}
          >
            {t('shared:action.validate')}
          </Button>
        )}

        {step === 1 && (
          <Button
            onClick={() =>
              props.onSubmit(
                csvPackageItemsData?.convertShipmentPackageItemsCsv?.items ||
                  [],
              )
            }
            data-testid="dialog-submit"
            size="small"
            disabled={
              !csvPackageItemsData?.convertShipmentPackageItemsCsv.items
                .length || isLoading
            }
          >
            {t('shared:action.import')}
          </Button>
        )}
      </DialogActions>
    </>
  )
}
