import { Button, Divider, List, ListItem, ListItemText } from '@mui/material'
import { Box } from '@mui/system'
import { FormFieldset } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import { useFormContext } from 'react-hook-form'
import { EditShipmentFormContext } from '../../edit-shipment'
import { LoadingButton } from '@mui/lab'
import { useState } from 'react'
import { SetGeneralInfoDialog } from '../../set-general-info-dialog'
import { useMutation } from '@apollo/client'
import {
  ActionType,
  AddShipmentActionDocument,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  ShipmentInfo,
  ShipmentState,
} from '../../../../generated/graphql'
import { stripNullValues } from '../../../../utils/strip-null-values'
import { stripEmptyProperties } from '../../../../utils/strip-empty-properties'
import { enqueueSnackbar } from 'notistack'

export const EditGeneralInfo = () => {
  const { t } = useTranslation()
  const [isDialogOpen, setIsDialogOpen] = useState(false)
  const { watch } = useFormContext<EditShipmentFormContext>()

  const shipment = watch()

  const [addShipmentAction, { loading: isLoading }] = useMutation(
    AddShipmentActionDocument,
    {
      refetchQueries: [GetShipmentDocument, AllShipmentActionsDocument],
    },
  )

  const submitGeneralInfo = async (newInfo: ShipmentInfo) => {
    await addShipmentAction({
      variables: {
        shipmentId: shipment.id || '',
        data: {
          shipment_version: shipment.version || 0,
          action: ActionType.SetInfo,
          info: stripNullValues(
            stripEmptyProperties({
              ...shipment.info,
              ...newInfo,
            }),
          ),
        },
      },
    })

    enqueueSnackbar(t('shipments:shipment_message.success_update'))
    setIsDialogOpen(false)
  }

  return (
    <FormFieldset
      label={t('shipments:shipment_form.general_fieldset')}
      sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
    >
      <List>
        <ListItemSkeleton
          childrenSkeleton
          secondaryAction={
            <ListItemText primary={shipment?.info?.reference_number || '-'} />
          }
        >
          <ListItemText
            primary={t(`shipments:shipment_form.reference_number`)}
          />
        </ListItemSkeleton>
        <Divider />
        <ListItemSkeleton
          childrenSkeleton
          secondaryAction={
            <ListItemText primary={shipment?.info?.barcode || '-'} />
          }
        >
          <ListItemText primary={t(`shipments:shipment_form.barcode`)} />
        </ListItemSkeleton>
        <Divider />
        <ListItemSkeleton childrenSkeleton>
          <ListItemText
            primary={t(`shipments:shipment_form.note`)}
            secondary={shipment?.info?.note || '-'}
          />
        </ListItemSkeleton>

        <ListItemSkeleton
          sx={{ p: 0, minHeight: 'auto' }}
          hide={[
            ShipmentState.Closed,
            ShipmentState.ClosedIncomplete,
            ShipmentState.Cancelled,
          ].includes(shipment.state)}
        >
          <LoadingButton
            data-testid="edit-general-info-button"
            fullWidth
            color="tertiary"
            onClick={() => setIsDialogOpen(true)}
            loading={isLoading}
          >
            {t('shipments:shipment_form.edit_section')}
          </LoadingButton>
        </ListItemSkeleton>
      </List>
      <SetGeneralInfoDialog
        info={shipment.info}
        open={isDialogOpen}
        loading={isLoading}
        onClose={() => setIsDialogOpen(false)}
        onSubmit={submitGeneralInfo}
      />
    </FormFieldset>
  )
}
