import { Box } from '@mui/material'
import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
} from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { ProductCounter } from '../../../components/product-counter'
import { ArrayElement } from '../../../utils/types'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ShipmentPackage } from '../fields/packages'
import { getProductDetails } from '../../../utils/format/product'

type Row = ArrayElement<ShipmentPackage['items']> & {
  new_quantity_received?: number
}

type Props = {
  items: ShipmentPackage['items']
  updateItem: (data: { id: string; quantity_received: number }) => void
  apiRef: MutableRefObject<GridApiPro>
}

export const ReceivedPackageItems = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments'])
  const { items } = props

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'product_image',
        headerName: '',
        maxWidth: 46,
        minWidth: 46,
        valueGetter: (_value, row) => row.product?.images?.at(0),
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        headerName: t('shipments:packages.products_title'),
        flex: 3,
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.product_name,
          caption: getProductDetails(row),
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'quantity',
        headerName: t('shipments:packages.received_title'),
        editable: false,
        flex: 1,
        renderCell: ({ row }) => (
          <ReceivedItemChip
            label={t('shipments:packages.received_of_total', {
              received: row.quantity_received,
              total: row.quantity,
            })}
            quantity={row.quantity}
            quantityReceived={row.quantity_received}
          />
        ),
      },
      {
        field: 'new_quantity_received',
        headerName: t('shipments:packages.quantity_received_title'),
        editable: false,
        flex: 1.5,
        renderCell: (params: GridRenderCellParams<Row, number>) => {
          return (
            <ProductCounter
              currentAmount={params.value || 0}
              maxAmount={params.row.quantity - params.row.quantity_received}
              updateAmount={(newQuantity) => {
                if (newQuantity !== params.value) {
                  props.updateItem({
                    id: params.row.id,
                    quantity_received: newQuantity,
                  })

                  params.row.new_quantity_received = newQuantity
                }
              }}
            />
          )
        },
      },
    ],
    [props, t],
  )

  const isCellEditable = (params: GridCellParams<Row>) => {
    return (
      params.field === 'new_quantity_received' &&
      params.row.quantity > params.row.quantity_received
    )
  }

  const gridItems = useMemo(
    () => items?.map((x) => ({ ...x, new_quantity_received: 0 })),
    [items],
  )

  const processRowUpdate = useCallback(
    (newRow: Row, oldRow: Row) => {
      try {
        const newQuantityReceived = newRow.new_quantity_received
        const oldQuantityReceived = oldRow.new_quantity_received

        if (newQuantityReceived === oldQuantityReceived) return newRow

        if (typeof newQuantityReceived === 'number')
          props.updateItem({
            id: newRow.id,
            quantity_received: newQuantityReceived,
          })

        return newRow
      } catch (error) {
        return oldRow
      }
    },
    [props],
  )

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="received-package-items-list"
      columns={dataGridColumns}
      rows={gridItems}
      rowCount={items?.length}
      rowHeight={50}
      ignoreRowHover
      sx={{
        height: '300px',
        '.MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editing': {
          boxShadow: 'none',
          padding: (theme) => theme.spacing(0, 1.25),
        },
        '.MuiDataGrid-row .MuiDataGrid-cell--editable:not(.MuiDataGrid-cell--editing):focus-within, .MuiDataGrid-row .MuiDataGrid-cell.MuiDataGrid-cell--editing:focus-within':
          {
            outline: 'none',
          },
      }}
      noRowsOverlay={{
        title: t('shipments:packages.no_rows_title'),
        description: t('shipments:packages.no_rows_description'),
      }}
      disableColumnVisibilityControl
      pagination={false}
      hideFooter
      isCellEditable={isCellEditable}
      processRowUpdate={processRowUpdate}
      disableAllFilters={true}
    />
  )
}
