import { makeVar, useReactiveVar } from '@apollo/client'
import { Container, Grid2 as Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { GetJournalEntriesQuery } from '../../generated/graphql'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { ArrayElement } from '../../utils/types'
import { JournalFilterViewPanel } from './journal-filter-view-panel'
import { JournalList } from './journal-list'
import { JournalViewPanel } from './journal-view-panel'

type ViewPanelState = {
  isOpen: boolean
  journal?: ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>
}

export const journalViewPanelVar = makeVar<ViewPanelState>({
  isOpen: false,
  journal: undefined,
})

export const journalFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const JournalsPage = () => {
  const { t } = useTranslation(['journals'])
  usePageTitle(t('journals:page_title'))
  const viewPanelState = useReactiveVar(journalViewPanelVar)
  const { isOpen } = useReactiveVar(journalFilterViewPanelVar)
  const { trackButtonClick } = useTracking()

  const openViewPanel = (
    journal: ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>,
  ) => {
    if (isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({
      name: 'journal-view-panel-open',
      journalId: journal?.id,
    })
    journalViewPanelVar({ isOpen: true, journal })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'journal-view-panel-close' })
    journalViewPanelVar({ isOpen: false, journal: undefined })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'journal-filter-view-panel-close' })
    journalFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'journal-filter-view-panel-open' })
    journalFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container>
        <Grid size="grow">
          <PageHeader title={t('journals:page_title')} isFlexible />
          <Container maxWidth={false}>
            <JournalList
              onDetail={openViewPanel}
              currentDetailedJournal={viewPanelState.journal}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>

        <Grid>
          <JournalViewPanel
            open={viewPanelState.isOpen}
            journal={viewPanelState.journal}
            onClose={closeViewPanel}
          />

          <JournalFilterViewPanel
            isOpen={isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
