import { ConfirmationDialog } from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../hooks/tracking'

type Props = {
  open: boolean
  presetId?: string
  dataTestid?: string
  onClose?(): void
  onSuccess?(presetId: string): void
  onError?(): void
}

export const FilterPresetDeleteDialog = (props: Props) => {
  const { t } = useTranslation(['filter'])
  const dialogName = 'delete-filter-preset'
  const isLoading = false
  const { trackDialogOpen, trackDialogClose, trackButtonClick } = useTracking()
  const prevOpen = usePrevious(props.open)

  const onDelete = () => {
    trackButtonClick({ name: `${dialogName}-dialog-delete` })
    if (props.presetId) {
      props.onSuccess?.(props.presetId)
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })

      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <ConfirmationDialog
      confirmAction={onDelete}
      title={t('filter:filter_preset.dialog_delete_label')}
      text={t('filter:filter_preset.dialog_delete_description')}
      variant="destructive"
      open={props.open}
      dataTestid={props.dataTestid}
      onClose={onClose}
    />
  )
}
