import { useQuery } from '@apollo/client'
import { Button, Chip, Paper } from '@mui/material'
import { UserDefaultIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '../..'
import { ViewPanel, ViewPanelHeader } from '../../../components/view-panel'
import {
  GetUserDocument,
  UserPermissionsByIdDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useMe } from '../../../hooks/me'
import { useTracking } from '../../../hooks/tracking'
import { formatUserDisplayName } from '../../../utils/format/display-name'
import { UserAdditionalData } from './additional-data'
import { UserContact } from './contact'
import { UserDetails } from './information'

type Props = {
  open: boolean
  onClose: () => void
  userId: string | undefined
}

export const UserViewPanel = (props: Props) => {
  const { t } = useTranslation(['shared', 'users', 'login'])
  const panelRef = useRef<HTMLDivElement>(null)
  const { trackButtonClick } = useTracking()
  const { me } = useMe()
  const generatePath = useAbsolutePath()

  const { data: permissionData, loading: userPermissionsLoading } = useQuery(
    UserPermissionsByIdDocument,
    {
      variables: { targetUserId: String(props.userId) },
      skip: !props.userId,
      fetchPolicy: 'cache-and-network',
    },
  )
  const permissions = permissionData?.userPermissionsById

  const { data, loading: fetchLoading } = useQuery(GetUserDocument, {
    variables: { userId: props.userId as string },
    skip: !props.userId,
    fetchPolicy: 'cache-and-network',
  })
  const user = data?.user

  const isSelf = user?.id === me?.user?.id

  const canManage = props.userId && permissions?.editUser && !isSelf

  const canSeeActive = user?.role

  const isLoading = fetchLoading || userPermissionsLoading

  return (
    <ViewPanel open={props.open} ref={panelRef}>
      <ViewPanelHeader
        isLoading={isLoading}
        title={formatUserDisplayName(user)}
        section={t('users:page_title')}
        onClose={props.onClose}
        actions={
          <>
            {isSelf && (
              <Button
                component={Link}
                to={generatePath(RootRoute.SettingsProfile)}
                color="secondary"
                size="small"
                data-testid="edit-user-button"
                onClick={() => {
                  trackButtonClick({
                    name: 'user-panel-edit-self',
                    userId: props.userId,
                  })
                }}
              >
                {t('shared:action.edit')}
              </Button>
            )}

            {canManage && (
              <Button
                component={Link}
                to={generatePath(RootRoute.User, {
                  id: props.userId,
                })}
                color="secondary"
                size="small"
                data-testid="edit-user-button"
                onClick={() => {
                  trackButtonClick({
                    name: 'user-panel-edit-user',
                    userId: props.userId,
                  })
                }}
              >
                {t('shared:action.edit')}
              </Button>
            )}
          </>
        }
        onBack={() => {
          trackButtonClick({ name: 'user-panel-go-back' })
        }}
        sectionIcon={<UserDefaultIcon />}
        tags={
          <>
            {isSelf && (
              <Chip size="small" label={t(`users:you`)} color="blue" />
            )}
            {user?.role && (
              <Chip
                size="small"
                label={t(`shared:roles.${user?.role.toLocaleLowerCase()}`)}
                color="black"
              />
            )}
            {canSeeActive && user?.active && (
              <Chip
                size="small"
                label={t(`shared:label.enabled`)}
                color="green"
              />
            )}
          </>
        }
      />
      <Paper elevation={0}>
        <UserDetails
          isLoading={isLoading}
          user={user}
          permissions={permissions}
        />

        <UserContact isLoading={isLoading} user={user} />

        <UserAdditionalData isLoading={isLoading} user={user} />
      </Paper>
    </ViewPanel>
  )
}
