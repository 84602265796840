import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  List,
  ListItemText,
} from '@mui/material'
import { Stack } from '@mui/system'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import {
  GetUserQuery,
  UserPermissionsByIdQuery,
  UserRole,
} from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { MfaField } from '../../user/fields/mfa-field'
import { UserTagChips } from '../users-list/user-tag-chips'
import { WarehouseChips } from '../users-list/warehouse-chips'

type Props = {
  user?: GetUserQuery['user']
  isLoading: boolean
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
}

export const UserDetails = (props: Props) => {
  const { user, isLoading, permissions } = props
  const { t } = useTranslation(['shared', 'users'])
  const { me } = useMe()

  const isSelf = me?.user?.id === user?.id

  const viewAccess = permissions?.changeAccess || isSelf
  const canSeeActive = user?.role
  const mfaAccess = !!permissions?.viewMfa

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="information">
        <SectionHeader sx={{ p: 0 }}>
          {t('shared:label.information')}
        </SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
            <ListItemText
              data-testid="user-id-field"
              primary={t('users:user_id')}
              secondary={user?.id}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.personalid}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="personal-id-value"
                primary={user?.personalid}
              />
            }
          >
            <ListItemText
              data-testid="personal-id-label"
              primary={t('users:personal_id')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            data-testid="external-id-value"
            hide={!isLoading && !user?.externalid}
            isLoading={isLoading}
            divider
          >
            <ListItemText
              primary={t('users:external_id')}
              secondary={user?.externalid}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.customernumber}
            isLoading={isLoading}
            divider
            secondaryAction={<ListItemText primary={user?.customernumber} />}
          >
            <ListItemText primary={t('users:customer_number')} />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!canSeeActive}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="user-active-value"
                primary={
                  user?.active ? t('shared:label.yes') : t('shared:label.no')
                }
              />
            }
          >
            <ListItemText
              data-testid="user-active-label"
              primary={t('shared:label.enabled')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.companyid}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="companyid-value"
                primary={user?.companyid}
              />
            }
          >
            <ListItemText
              data-testid="companyid-label"
              primary={t('users:company_id')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.company}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="company-value"
                primary={user?.company}
              />
            }
          >
            <ListItemText
              data-testid="company-label"
              primary={t('users:company')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!isLoading && !user?.department}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="department-value"
                primary={user?.department}
              />
            }
          >
            <ListItemText
              data-testid="department-label"
              primary={t('users:department')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!viewAccess}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="refundcard-value"
                primary={
                  user?.posrefundcard
                    ? t('shared:label.yes')
                    : t('shared:label.no')
                }
              />
            }
          >
            <ListItemText
              data-testid="refundcard-label"
              primary={t('users:pos_refund_card')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!viewAccess}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="pos-all-stores-value"
                primary={
                  user?.posallstores
                    ? t('shared:label.yes')
                    : t('shared:label.no')
                }
              />
            }
          >
            <ListItemText
              data-testid="pos-all-stores-label"
              primary={t('users:pos_all_stores')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!viewAccess || user?.role !== UserRole.Staff}
            isLoading={isLoading}
            divider
            secondaryAction={
              <ListItemText
                data-testid="staff-pos-activation"
                primary={
                  user?.staff_pos_activation
                    ? t('shared:label.yes')
                    : t('shared:label.no')
                }
              />
            }
          >
            <ListItemText
              data-testid="staff-pos-activation-label"
              primary={t('users:pos_staff_activation')}
            />
          </ListItemSkeleton>

          <ListItemSkeleton
            hide={!mfaAccess || !viewAccess}
            isLoading={isLoading}
            divider
            childrenSkeleton
          >
            <MfaField
              email={String(user?.email)}
              userId={user?.id}
              hasMfa={user?.hasmfa}
              sx={{ py: 0 }}
            />
          </ListItemSkeleton>

          {user?.role !== UserRole.Admin && (
            <ListItemSkeleton
              hide={!viewAccess || (!isLoading && !user?.allWarehouses?.length)}
              isLoading={isLoading}
              divider
              childrenSkeleton
            >
              <ListItemText
                data-testid="user-warehouse-info"
                primary={t('users:warehouse')}
                secondary={<WarehouseChips warehouses={user?.allWarehouses} />}
              />
            </ListItemSkeleton>
          )}
          <ListItemSkeleton
            isLoading={isLoading}
            childrenSkeleton
            divider
            data-testid="user-tags"
          >
            <ListItemText
              secondary={<UserTagChips tags={user?.resolvedTags} />}
              secondaryTypographyProps={{ component: 'div' }}
            >
              {t('users:user_tags.tags')}
            </ListItemText>
          </ListItemSkeleton>
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
