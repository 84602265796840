import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ReasonCodeType,
  AllReasonCodesDocument,
  Warehousebatchstate,
  UpdateWarehouseBatchDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessage } from '../../../../utils/error-mapping'

type Props = {
  open: boolean
  warehouseId: number
  warehouseBatchId: number
  onClose: () => void
  onSuccess: () => void
  onError?(): void
}

type Form = {
  reasonCode: string
  comment: string
}

export const FinishDeliveryOutDialog = (props: Props) => {
  const { t } = useTranslation(['shared', 'delivery_out'])
  const {
    trackDialogOpen,
    trackDialogClose,
    trackButtonClickEvent,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = 'finish-delivery-out'
  const [updateWarehouseBatch, { loading }] = useMutation(
    UpdateWarehouseBatchDocument,
  )
  const { enqueueSnackbar } = useSnackbar()

  const formContext = useForm<Form>()

  const { data: reasonCodesData, loading: loadingReasonCodes } = useQuery(
    AllReasonCodesDocument,
    {
      variables: {
        type: ReasonCodeType.ManualOut,
      },
    },
  )
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const isLoading = loading || loadingReasonCodes

  const submit = async (data: Form) => {
    trackButtonClickEvent({ name: `${dialogName}-dialog-finish` })

    try {
      await updateWarehouseBatch({
        variables: {
          warehouseBatchId: props.warehouseBatchId,
          warehouseId: props.warehouseId,
          data: {
            warehousebatchstate: Warehousebatchstate.NUMBER_20,
            comment: data.comment,
            reasoncode: data.reasonCode,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      enqueueSnackbar(t('delivery_out:finish_dialog.success'))
      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'delivery_out',
        t('delivery_out:finish_dialog.error'),
      )

      trackFormError({
        name: `${dialogName}-dialog`,

        errorMessage,
      })

      enqueueSnackbar(errorMessage, { variant: 'error' })
      props.onError?.()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      formContext.reset({
        comment: '',
        reasonCode: '',
      })
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, formContext])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>{t('delivery_out:finish_dialog.title')}</DialogTitle>

      <DialogContent>
        {reasonCodes.length > 0 && (
          <Controller
            control={formContext.control}
            name="reasonCode"
            rules={{
              required: t('shared:validation.field_required', {
                field: t('delivery_out:finish_dialog.reason_code'),
              }),
            }}
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('delivery_out:finish_dialog.reason_code')}
                </InputLabel>
                <Select
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                  data-testid="reason-code-select"
                  inputProps={{ 'data-testid': 'reason-code-input' }}
                  displayEmpty
                >
                  <MenuItem value={''} disabled>
                    {t('shared:label.select')}
                  </MenuItem>
                  {reasonCodes.map(({ name, reasoncode, reasoncodeid }) => (
                    <MenuItem
                      value={reasoncode}
                      key={reasoncodeid}
                      data-testid={`reason-code-${reasoncodeid}-button`}
                    >
                      {name || reasoncode}
                    </MenuItem>
                  ))}
                </Select>

                <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
        )}

        <TextField
          fullWidth
          error={!!formContext.formState.errors.comment}
          helperText={formContext.formState.errors.comment?.message}
          label={t('delivery_out:finish_dialog.comment')}
          {...formContext.register('comment')}
          inputProps={{ 'data-testid': 'comment' }}
          multiline={true}
          rows={3}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-finish-delivery-out"
        >
          {t('shared:action.cancel')}
        </Button>
        <Button
          color="primary"
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={formContext.handleSubmit(submit)}
          data-testid="dialog-finish-finish-delivery-out"
          disabled={isLoading}
        >
          {t('shared:action.finish')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
