import { PermissionRole, UserRole } from '../../generated/graphql'

/**
 * Converts a permission role to a user role.
 *
 * @param {PermissionRole} role - The permission role to convert.
 * @returns {UserRole} The corresponding user role.
 */
export const convertToUserRole = (
  role: PermissionRole | undefined,
): UserRole => {
  switch (role) {
    case PermissionRole.SaGlobal:
    case PermissionRole.SaLocal:
    case PermissionRole.Admin:
      return UserRole.Admin
    case PermissionRole.Staff:
      return UserRole.Staff
    case PermissionRole.StoreManager:
      return UserRole.StoreManager
    default:
      return UserRole.StoreStaff
  }
}

/**
 * Converts a user role to a permission role.
 *
 * @param {UserRole} role - The user role to convert.
 * @returns {PermissionRole} The corresponding permission role.
 */
export const convertToPermissionRole = (role: UserRole): PermissionRole => {
  switch (role) {
    case UserRole.Admin:
      return PermissionRole.Admin
    case UserRole.Staff:
      return PermissionRole.Staff
    case UserRole.StoreManager:
      return PermissionRole.StoreManager
    default:
      return PermissionRole.StoreStaff
  }
}
