import { makeVar, useReactiveVar } from '@apollo/client'
import { Button, Grid, Container } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import { RootRoute } from '..'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { useAuthorization } from '../../hooks/authorization'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { ProductsFilterPanel } from './product-filter-panel'
import { ProductList } from './product-list'
import { ProductViewPanel } from './product-view-panel'

export type ProductViewPanelState = {
  productId: number
  siteId?: number
  isOpen: boolean
  previous?: ProductViewPanelState
}

export const reactiveProductViewPanelVar = makeVar<ProductViewPanelState>({
  isOpen: false,
  productId: 0,
})

export const productsFilterViewPanelVar = makeVar({
  isOpen: false,
})

export const ProductsPage = () => {
  const { t } = useTranslation(['shared', 'products'])
  usePageTitle(t('products:page_title'))

  const viewPanelState = useReactiveVar(reactiveProductViewPanelVar)
  const filterViewPanelState = useReactiveVar(productsFilterViewPanelVar)
  const { trackButtonClickEvent, trackButtonClick } = useTracking()
  const generatePath = useAbsolutePath()
  const {
    modules: { writeProducts },
  } = useAuthorization()

  const detailProduct = (
    productId: number,
    siteId?: number,
    previous?: ProductViewPanelState,
  ) => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({ name: 'products-detail-product', productId })
    reactiveProductViewPanelVar({ isOpen: true, productId, siteId, previous })
  }

  const closeDetailProduct = () => {
    trackButtonClick({ name: 'products-close-detail-product' })
    reactiveProductViewPanelVar({ isOpen: false, productId: 0 })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'products-filter-view-panel-close' })
    productsFilterViewPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'products-filter-view-panel-open' })
    productsFilterViewPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterViewPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('products:title')}
            isFlexible
            rightColumn={
              <>
                {writeProducts && (
                  <Button
                    component={Link}
                    onClick={trackButtonClickEvent({
                      name: 'products-add-product',
                    })}
                    to={generatePath(RootRoute.ProductNew)}
                    data-testid="add-product-button"
                  >
                    {t('products:add_product_title')}
                  </Button>
                )}
              </>
            }
          />
          <Container maxWidth={false}>
            <ProductList
              onDetailProduct={detailProduct}
              onCloseDetailProduct={closeDetailProduct}
              currentDetailedProductId={viewPanelState.productId}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid item>
          <ProductViewPanel
            onClose={closeDetailProduct}
            onDetailProduct={detailProduct}
            open={viewPanelState.isOpen}
            selectedProduct={viewPanelState}
          />
          <ProductsFilterPanel
            isOpen={filterViewPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
