/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Chip,
  Box,
  Checkbox,
  InputLabel,
  MenuItem,
  Select,
  Button,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetProductsQuery,
  SingleProductsInput,
  GetCategoriesDocument,
  UpdateProductsDocument,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { formatCategoryName } from '../../../utils/format/category'
import { ArrayElement } from '../../../utils/types'

export type SetCategoryDialogProps = {
  open: boolean
  products: Pick<
    ArrayElement<GetProductsQuery['products']['items']>,
    'id' | 'allcategories' | 'defaultcategoryid'
  >[]
  dataTestid?: string
  onClose(): void
  onSuccess?(successfulProductIds: number[], failedProductIds: number[]): void
  onError?(successfulProductIds: number[], failedProductIds: number[]): void
}

enum SetCategoryAction {
  AddCategory,
  SetMainCategory,
  RemoveFromCategory,
}
type Form = { categoryIds: number[]; action: SetCategoryAction }

export const SetCategoryDialog = (props: SetCategoryDialogProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const dialogName = 'set-category'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [updateProductsMutation, { loading }] = useMutation(
    UpdateProductsDocument,
  )
  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const { data: categoryData } = useQuery(GetCategoriesDocument, {
    variables: { num: MAX_NUM_REQUEST },
  })
  const categories = categoryData?.categories.items

  const onSubmit = async (formData: Form) => {
    const successfulProductIds: number[] = []
    const failedProductIds: number[] = []
    try {
      const requestData: SingleProductsInput[] = props.products.map(
        (product) => {
          const productInput: SingleProductsInput = {
            productid: product.id,
          }

          if (formData.action === SetCategoryAction.AddCategory) {
            productInput.categories = Array.from(
              new Set([
                ...(product.allcategories?.map((c) => c.id) || []),
                ...formData.categoryIds,
              ]),
            )
          } else if (formData.action === SetCategoryAction.RemoveFromCategory) {
            productInput.categories = Array.from(
              new Set(
                product.allcategories
                  ?.filter((c) => {
                    return !formData.categoryIds.includes(c.id)
                  })
                  .map((c) => c.id),
              ),
            )

            if (
              product.defaultcategoryid &&
              formData.categoryIds.includes(product.defaultcategoryid)
            ) {
              productInput.defaultcategoryid = null
            }
          } else if (formData.action === SetCategoryAction.SetMainCategory) {
            productInput.defaultcategoryid =
              formData.categoryIds[0] || product.defaultcategoryid
          }

          return productInput
        },
      )

      const mutationResponse = await updateProductsMutation({
        variables: {
          data: requestData,
        },
      })

      for (const updateProductResponse of mutationResponse.data
        ?.updateProducts || []) {
        if (updateProductResponse.success) {
          successfulProductIds.push(updateProductResponse.product.id)
        } else {
          failedProductIds.push(updateProductResponse.product.id)
        }
      }

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })

      if (props.onSuccess) {
        props.onSuccess(successfulProductIds, failedProductIds)
      }
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      if (props.onError) {
        props.onError(successfulProductIds, failedProductIds)
      }
    } finally {
      formContext.reset()
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('products:category_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={formContext.control}
            name="action"
            defaultValue={SetCategoryAction.AddCategory}
            render={({ field }) => (
              <>
                <InputLabel>{t('products:category_dialog.action')}</InputLabel>
                <Select
                  value={field.value}
                  onChange={(event) => {
                    formContext.resetField('categoryIds', {
                      defaultValue: [],
                    })
                    field.onChange(event.target.value)
                  }}
                >
                  <MenuItem value={SetCategoryAction.AddCategory}>
                    {t('products:category_dialog.add_category')}
                  </MenuItem>
                  <MenuItem value={SetCategoryAction.SetMainCategory}>
                    {t('products:category_dialog.set_main_category')}
                  </MenuItem>
                  <MenuItem value={SetCategoryAction.RemoveFromCategory}>
                    {t('products:category_dialog.remove_from_category')}
                  </MenuItem>
                </Select>
              </>
            )}
          />
          <Controller
            control={formContext.control}
            name="categoryIds"
            defaultValue={[]}
            rules={{
              validate: (v) => v?.length > 0,
            }}
            render={({ field }) => (
              <>
                <InputLabel
                  sx={{
                    marginTop: (theme) => theme.spacing(2),
                  }}
                >
                  {t('products:category_dialog.category')}
                </InputLabel>
                {formContext.getValues().action ===
                SetCategoryAction.SetMainCategory ? (
                  <Select<number | string>
                    value={
                      field.value && field.value.length > 0
                        ? field.value[0]
                        : ''
                    }
                    onChange={(event) => {
                      field.onChange([event.target.value])
                    }}
                    renderValue={(selected) => {
                      if (typeof selected === 'string') {
                        return <em>{t('shared:label.select')}</em>
                      }

                      return formatCategoryName(selected, categories)
                    }}
                    displayEmpty
                  >
                    {categories?.map(({ id }) => (
                      <MenuItem value={id} key={id}>
                        {formatCategoryName(id, categories, true)}
                      </MenuItem>
                    ))}
                  </Select>
                ) : (
                  <Select
                    value={field.value}
                    displayEmpty
                    multiple
                    placeholder={t(
                      'products:product_form.categories_placeholder',
                    )}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                    }}
                    renderValue={(selected) => {
                      if (selected.length === 0) {
                        return <em>{t('shared:label.select')}</em>
                      }

                      return (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 1 }}>
                          {selected?.map((value) => (
                            <Chip
                              key={value}
                              label={formatCategoryName(value, categories)}
                              color="blue"
                            />
                          ))}
                        </Box>
                      )
                    }}
                  >
                    {categories?.map(({ id }) => (
                      <MenuItem key={id} value={id}>
                        <Checkbox
                          checked={field.value?.includes(id)}
                          sx={{ p: (theme) => theme.spacing(0, 1, 0, 0) }}
                        />
                        {formatCategoryName(id, categories, true)}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </>
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={loading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            loading={loading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formContext.formState.isValid}
          >
            {t('shared:action.set')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
