import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { GetCategoriesDocument } from '../../../generated/graphql'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { formatCategoryName } from '../../../utils/format/category'
import { useForm } from 'react-hook-form'
import { AutocompleteInput } from '../../../components/autocomplete-input'
import {
  formatQueryNumberArray,
  formatQueryStringArray,
} from '../../../hooks/state-params/util'
import { useStateParams } from '../../../hooks/state-params'

type QueryState = {
  categoryIds?: undefined | string | string[]
}

type Form = {
  categoryIds: number[]
}

export const ProductCategoryFilter = () => {
  const { t } = useTranslation(['products', 'shared'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'categoryIds'

  const { data: categoriesData, loading: isLoading } = useQuery(
    GetCategoriesDocument,
    { variables: { num: MAX_NUM_REQUEST } },
  )

  const categories = useMemo(
    () => categoriesData?.categories.items || [],
    [categoriesData?.categories.items],
  )

  const options = useMemo(
    () => categories.map(({ id, title }) => ({ label: title, id: Number(id) })),
    [categories],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryState>()

  const categoryIds = formatQueryNumberArray(queryParams.categoryIds)

  const { control, setValue, watch } = useForm<Form>({
    values: { categoryIds },
    defaultValues: { categoryIds: [] },
  })

  useEffect(() => {
    if (categoryIds.length) {
      setFilter(filterKey, {
        label: t('products:category_filter_tag_label'),
        labelValues: categoryIds.map(
          (id) =>
            formatCategoryName(Number(id), categories, true) || String(id),
        ),
        value: categoryIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [categories, categoryIds, removeFilter, setFilter, t])

  useEffect(() => {
    const subscribe = watch(({ categoryIds }) => {
      setQueryParams({
        [filterKey]: formatQueryStringArray(categoryIds),
      })
    })

    return () => subscribe.unsubscribe()
  }, [setQueryParams, watch])

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setValue('categoryIds', [])
      }
    })

    return () => unsubscribe()
  }, [setValue, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="categoryIds"
      control={control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('products:product_category')}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) => {
          return formatCategoryName(option, categories, true) || String(option)
        },
      }}
    />
  )
}
