import { Button } from '@mui/material'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useCallback, useContext, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../../components/data-grid'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  GetProductPricesQuery,
  GetProductPricesQueryVariables,
  GetProductsSort,
  DeletePricelistItemsDocument,
  UpdatePricelistItemsDocument,
  GetProductPricesDocument,
} from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'
import {
  DEFAULT_PRICELIST_ID,
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
} from '../../../../utils/constants'
import { ArrayElement } from '../../../../utils/types'
import {
  StringRenderer,
  StringEditRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { BulkActions } from './bulk-actions'
import { FolderIcon } from '@sitoo/mui-components'
import { useTracking } from '../../../../hooks/tracking'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'notistack'
import { RootRoute } from '../../..'
import { useAuthorization } from '../../../../hooks/authorization'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import { useMe } from '../../../../hooks/me'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useMutation, useQuery } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<GetProductPricesQuery['products']['items']> & {
  ['price']: string | undefined
}

type PricesListProps = {
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const PricesList = (props: PricesListProps) => {
  const { t } = useTranslation(['prices', 'shared', 'products'])
  const {
    modules: { writePrices },
  } = useAuthorization()
  const {
    trackButtonClickEvent,
    trackInputBlur,
    trackInputFocus,
    trackFormError,
    trackFormSuccess,
  } = useTracking()

  const navigate = useNavigate()
  const { enqueueSnackbar } = useSnackbar()

  const apiRef = useGridApiRef()

  const [updatePricelistItems, { loading: updateLoading }] = useMutation(
    UpdatePricelistItemsDocument,
  )

  const [deletePricelistItemsMutation] = useMutation(
    DeletePricelistItemsDocument,
  )
  const { me, loading: loadingMe } = useMe()

  const [selectedPricelistId, setSelectedPricelistId] =
    useState(DEFAULT_PRICELIST_ID)

  const [productSiteId, setProductSiteId] = useState<number | undefined>(
    undefined,
  )

  const {
    formatCurrency,
    formatEditableCurrency,
    formatStringCurrencyToNumber,
  } = useMoney()

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'images',
        ...ColumnProps.image,
        headerName: '',
        valueGetter: (_value, row): string => row.images?.[0] || '',
        cellClassName: 'image-column',
        headerClassName: 'image-column',
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'id',
        ...ColumnProps.productId,
        headerName: t('prices:product_id'),
      },
      {
        field: 'sku',
        ...ColumnProps.sku,
        headerName: t('prices:sku'),
      },
      {
        field: 'title',
        ...ColumnProps.productTitle,
        headerName: t('prices:product'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.title,
          caption: row.variant?.[0]?.value,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'activepos',
        ...ColumnProps.productActive,
        headerName: t('products:status'),
        renderCell: ProductStateRenderer,
      },
      {
        field: 'computedPurchasePrice',
        ...ColumnProps.price,
        headerName: `${t('prices:purchase_price')} ${t('prices:exclude')}`,
        valueFormatter: (_value, row) =>
          formatCurrency(row?.moneypricein || ''),
      },
      {
        field: 'moneypriceorg',
        ...ColumnProps.price,
        headerName: t('prices:suggested_retail_price'),
        editable: false,
        // Getting the value when we edit
        valueGetter: (_value, row): string =>
          row.moneypriceorg ? formatEditableCurrency(row.moneypriceorg) : '',
        //  Value to formatted to be displayed
        // It runs after the getter
        valueFormatter: (value: string): string | undefined =>
          value ? formatCurrency(value) : undefined,
        renderCell: (params: GridRenderCellParams<Row, string>) =>
          StringRenderer({ ...params, value: params.formattedValue }),
      },
      {
        field: 'cmr',
        minWidth: 120,
        headerName: t('prices:cmr'),
        valueGetter: (_value, row): string => {
          const priceIn = row.computedPurchasePrice?.price || 0

          // $gross * 100 / (100 + vatvalue)
          const priceWithoutVat =
            (Number(
              row.pricelistitems?.find(
                (x) => x.pricelistid === selectedPricelistId,
              )?.moneyprice || '0',
            ) *
              100) /
            (100 + (row.productgroup?.value || 0))

          if (priceWithoutVat === 0) {
            return '0%'
          }

          const roundedToFixed = (input: number, digits: number) => {
            const rounded = Math.pow(10, digits)
            const total = Math.round(input * rounded) / rounded

            return total.toFixed(Number.isInteger(total) ? 0 : digits)
          }
          const cmr = roundedToFixed(
            ((priceWithoutVat - priceIn) * 100) / priceWithoutVat,
            1,
          )

          return priceWithoutVat > priceIn ? `${cmr}%` : '0%'
        },
      },
      {
        field: 'discount',
        minWidth: 120,
        headerName: t('prices:discount'),
        valueGetter: (_value, row): string => {
          const price = Number(
            row.pricelistitems?.find(
              (x) => x.pricelistid === selectedPricelistId,
            )?.moneyprice || 0,
          )

          const priceOrg = Number(row.moneypriceorg || 0)

          if (price === 0 || priceOrg === 0) return ''

          const roundedToFixed = (input: number, digits: number) => {
            const rounded = Math.pow(10, digits)
            const total = Math.round(input * rounded) / rounded

            return total.toFixed(Number.isInteger(total) ? 0 : digits)
          }

          return `${roundedToFixed(100 * (1 - price / priceOrg), 1)}%`
        },
      },
      {
        field: 'price',
        ...ColumnProps.editablePrice,
        headerName: t('prices:price'),
        editable: writePrices,
        valueGetter: (_value, row): string | undefined => {
          return row.price ? formatEditableCurrency(row.price) : undefined
        },

        valueFormatter: (value: string): string | undefined => {
          return value ? formatCurrency(value) : undefined
        },

        valueSetter: (value: string, row) => {
          // Don't allow deleting price for a default price list
          // Unless it's franchise and we're updating a product from the common store
          if (
            !value &&
            selectedPricelistId === DEFAULT_PRICELIST_ID &&
            ((productSiteId && me?.siteId === productSiteId) ||
              productSiteId === undefined)
          ) {
            return { ...row, price: '0.00' }
          }

          const newPrice = value
            ? formatStringCurrencyToNumber(value).toFixed(2)
            : undefined

          return { ...row, price: newPrice }
        },

        renderCell: (params: GridRenderCellParams<Row, string>) =>
          StringRenderer({ ...params, value: params.formattedValue }),

        renderEditCell: (props) =>
          StringEditRenderer(
            props,
            trackInputFocus,
            trackInputBlur,
            'prices-list',
          ),
      },
    ],
    [
      formatCurrency,
      formatEditableCurrency,
      formatStringCurrencyToNumber,
      productSiteId,
      me?.siteId,
      selectedPricelistId,
      t,
      trackInputBlur,
      trackInputFocus,
      writePrices,
    ],
  )

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('prices:product_id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('prices:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('prices:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('prices:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const updatePrice = useCallback(
    async (
      productId: number,
      productSku: string,
      price: string | undefined,
      priceListId: number,
    ) => {
      try {
        let newPrice = price
        if (price) {
          await updatePricelistItems({
            variables: {
              pricelistId: priceListId,
              productSiteId: productSiteId,
              items: [
                {
                  productid: productId,
                  moneyprice: Number(price || 0).toFixed(2),
                  sku: productSku,
                },
              ],
            },
          })
        } else {
          const response = await deletePricelistItemsMutation({
            variables: {
              pricelistId: priceListId,
              productSiteId: productSiteId,
              items: [{ productid: productId, sku: productSku }],
            },
          })

          if (
            response.data?.deletePricelistItems[0]?.pricelistitems?.[0]
              ?.moneyprice
          ) {
            newPrice =
              response.data?.deletePricelistItems[0]?.pricelistitems?.[0]
                ?.moneyprice
          }
        }

        trackFormSuccess({ name: 'prices-list-row', field: 'price' })
        enqueueSnackbar(t('prices:success_update_price_snackbar', { count: 1 }))

        return newPrice
      } catch (ex) {
        trackFormError({ name: 'prices-list-row', field: 'price' })
        enqueueSnackbar(
          t('prices:failure_update_price_snackbar', { count: 1 }),
          { variant: 'error' },
        )
        throw ex
      }
    },
    [
      deletePricelistItemsMutation,
      enqueueSnackbar,
      productSiteId,
      t,
      trackFormError,
      trackFormSuccess,
      updatePricelistItems,
    ],
  )

  const processRowUpdate = useCallback(
    async (newRow: Row, oldRow: Row) => {
      try {
        const productId = newRow['id']
        const newPrice = newRow.price
        const oldPrice = oldRow.price

        if (oldPrice === newPrice) return newRow

        newRow.price = await updatePrice(
          productId,
          newRow['sku'],
          newPrice,
          selectedPricelistId,
        )

        return newRow
      } catch (error) {
        return oldRow
      }
    },
    [selectedPricelistId, updatePrice],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }
    const variables: GetProductPricesQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      currency: '',
      includeproductvariants: true,
    }

    if (
      !config.filter?.['pricelistCurrency'] ||
      typeof config.filter['pricelistCurrency'].value !== 'string'
    ) {
      return
    } else {
      variables.currency = config.filter['pricelistCurrency'].value
    }

    if (typeof config.filter?.['text']?.value === 'string') {
      variables.search = config.filter?.['text']?.value
    }

    const categoryIds = config.filter?.['categoryIds']?.value
    if (categoryIds) {
      variables.categoryIds = Array.isArray(categoryIds)
        ? categoryIds
        : [categoryIds]
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (config.filter?.['state']?.value) {
      variables.includeInactive = config.filter?.['state']?.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
      setProductSiteId(Number(config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value))
    } else {
      setProductSiteId(undefined)
    }

    setSelectedPricelistId(
      Number(config.filter?.['pricelistId']?.value || DEFAULT_PRICELIST_ID),
    )

    return variables
  }, [apiRef, filter, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchProductPrices,
  } = useQuery(GetProductPricesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading || updateLoading || loadingMe

  const rows = useMemo(() => {
    return data?.products.items?.map((item) => ({
      ...item,
      price: item.pricelistitems?.find(
        (price) => price.pricelistid === selectedPricelistId,
      )?.moneyprice,
    }))
  }, [data, selectedPricelistId])

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <DataGrid
      name="price-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={rows}
      rowCount={data?.products.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ footer: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('prices:grid.empty_price_title'),
        description: t('prices:grid.empty_price_description'),
        action: (
          <Button
            onClick={trackButtonClickEvent(
              { name: 'prices-list-add-product' },
              () => navigate(RootRoute.ProductNew),
            )}
          >
            {t('prices:add_product')}
          </Button>
        ),
      }}
      bulkAction={
        <>
          {writePrices && (
            <BulkActions
              pricelistId={selectedPricelistId}
              productSiteId={productSiteId}
              refetch={refetchProductPrices}
            />
          )}
        </>
      }
      hasTextFilter
      onShowFilter={props.onShowFilter}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={{ id: false, activepos: false }}
      processRowUpdate={processRowUpdate}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
