import {
  Box,
  Button,
  Divider,
  IconButton,
  InputLabel,
  ListItem,
  ListItemText,
  Select,
  Switch,
  TextField,
} from '@mui/material'
import {
  DeleteIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  DragHandleIcon,
  PlusIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ProductCustomAttribute } from '..'
import { CustomAttributeTypeEnum } from '../../../../../generated/graphql'
import { useAuthorization } from '../../../../../hooks/authorization'
import { useDrag } from '../../../../../hooks/drag'
import { useTracking } from '../../../../../hooks/tracking'

type Form = ProductCustomAttribute & {
  enumsWithId?: { listId: string; value: string }[]
}

type ProductGroupProps = {
  open: boolean
  customAttribute?: ProductCustomAttribute
  existingIds: string[]

  onClose: () => void
  onSuccess: (customAttribute: ProductCustomAttribute) => void
}

export const CustomAttributeDialog = (props: ProductGroupProps) => {
  const { t } = useTranslation(['shared', 'settings'])
  const {
    modules: { writeSettingsCustomAttributes },
  } = useAuthorization()

  const { trackDragEnd, trackDragStart, trackDialogClose, trackDialogOpen } =
    useTracking()
  const { onDragEnd, onDragOver, onDragStart } = useDrag()
  const prevOpen = usePrevious(props.open)
  const dialogName = props.customAttribute
    ? 'edit-custom-attribute'
    : 'add-custom-attribute'

  const hasCustomAttribute = !props.customAttribute
  const isNewCustomAttribute =
    !props.customAttribute || !props.customAttribute.__typename

  const defaultValue: ProductCustomAttribute = {
    id: '',
    title: '',
    type: CustomAttributeTypeEnum.String,
  }

  const formContext = useForm<Form>({
    defaultValues: defaultValue,
  })

  const customAttributeType = formContext.watch('type')
  const customAttributeEnums = formContext.watch('enums') || []
  const customAttributeEnumsWithListId = formContext.watch('enumsWithId') || []

  const submit = ({ enumsWithId, enums, searchable, ...data }: Form) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess({
      ...data,
      searchable:
        data.type === CustomAttributeTypeEnum.String ? searchable : undefined,
      enums: data.type === CustomAttributeTypeEnum.String ? enums : null,
    })
  }

  useEffect(
    () => {
      const customAttribute: Form = props.customAttribute || defaultValue

      if (customAttribute.enums) {
        customAttribute.enumsWithId = customAttribute.enums.map((x, i) => ({
          listId: `${i}`,
          value: x,
        }))
      }

      formContext.reset(customAttribute)
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.open],
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  return (
    <Dialog
      open={props.open}
      data-testid="add-edit-custom-attribute-dialog"
      maxWidth="xs"
      fullWidth
      onClose={onClose}
    >
      <DialogTitle>
        {!writeSettingsCustomAttributes
          ? t('settings:custom_attribute.view_custom_attribute')
          : hasCustomAttribute
            ? t('settings:custom_attribute.add_custom_attribute')
            : t('settings:custom_attribute.edit_custom_attribute')}
      </DialogTitle>
      <DialogContent>
        <TextField
          error={!!formContext.formState.errors.id}
          fullWidth
          helperText={formContext.formState.errors.id?.message}
          label={t('settings:custom_attribute.id')}
          {...formContext.register('id', {
            required: t('shared:validation.field_required', {
              field: t('settings:custom_attribute.id'),
            }),

            validate: (customAttributeId) =>
              props.existingIds.some(
                (x) =>
                  x.toLocaleLowerCase() ===
                  customAttributeId.toLocaleLowerCase(),
              ) && isNewCustomAttribute
                ? t('shared:validation.field_already_exists', {
                    field: t('settings:custom_attribute.id'),
                  })
                : true,

            pattern: {
              value: /^[a-zA-Z][a-zA-Z0-9_-]{0,31}$/i,
              message: t(
                'settings:custom_attribute.validation.invalid_characters',
                {
                  field: t('settings:custom_attribute.id'),
                },
              ),
            },
            maxLength: {
              value: 31,
              message: t('shared:validation.max_length', {
                field: t('settings:custom_attribute.id'),
                amount: 31,
              }),
            },
          })}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'custom-attribute-id',
          }}
          disabled={!isNewCustomAttribute || !writeSettingsCustomAttributes}
        />
        <TextField
          error={!!formContext.formState.errors.title}
          fullWidth
          helperText={formContext.formState.errors.title?.message}
          label={t('settings:custom_attribute.title')}
          {...formContext.register('title', {
            required: t('shared:validation.field_required', {
              field: t('settings:custom_attribute.title'),
            }),
          })}
          sx={{ mb: 2 }}
          inputProps={{
            'data-testid': 'custom-attribute-title',
          }}
          disabled={!writeSettingsCustomAttributes}
        />
        <Controller
          control={formContext.control}
          name="type"
          render={({ field }) => (
            <>
              <InputLabel>{t('settings:custom_attribute.type')}</InputLabel>
              <Select
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                data-testid="custom-attribute-type"
                inputProps={{
                  'data-testid': 'custom-attribute-type-input',
                }}
                sx={{ mb: 2 }}
                disabled={
                  !isNewCustomAttribute || !writeSettingsCustomAttributes
                }
                native
              >
                <option
                  data-testid={`custom-attribute-type-option-string`}
                  value={CustomAttributeTypeEnum.String}
                >
                  {t('settings:custom_attribute.type_options.string')}
                </option>
                <option
                  data-testid={`custom-attribute-type-option-integer`}
                  value={CustomAttributeTypeEnum.Integer}
                >
                  {t('settings:custom_attribute.type_options.integer')}
                </option>
              </Select>
            </>
          )}
        />

        {customAttributeType === CustomAttributeTypeEnum.String && (
          <>
            <Controller
              control={formContext.control}
              name="searchable"
              render={({ field }) => (
                <ListItem
                  secondaryAction={
                    <Switch
                      checked={
                        typeof field.value === 'boolean' ? field.value : false
                      }
                      onChange={(event) => {
                        field.onChange(event.target.checked)
                      }}
                      disabled={
                        !isNewCustomAttribute || !writeSettingsCustomAttributes
                      }
                      name="custom-attribute-searchable"
                      data-testid="custom-attribute-searchable-toggle"
                    />
                  }
                  sx={{
                    paddingLeft: '0 !important',
                    paddingRight: '0 !important',
                  }}
                >
                  <ListItemText>
                    {t('settings:custom_attribute.searchable')}
                  </ListItemText>
                </ListItem>
              )}
            />

            {(writeSettingsCustomAttributes ||
              customAttributeEnumsWithListId.length > 0) && (
              <>
                <Divider sx={{ mx: -3 }} />
                <SectionHeader variant="transparent" sx={{ mt: 2, px: 0 }}>
                  {t('settings:custom_attribute.allowed_values')}
                </SectionHeader>
              </>
            )}

            {customAttributeEnumsWithListId.map(({ listId, value }, index) => (
              <Box
                key={listId}
                sx={{
                  display: 'flex',
                  pt: 0.5,
                  pb: 0.5,
                }}
                onDragStart={(ev) => {
                  trackDragStart({
                    name: 'custom-attribute-dialog-value',

                    position: index,
                    value,
                  })
                  const target = ev.currentTarget
                  target.classList.add('show-helper')
                  setTimeout(() => {
                    target.classList.remove('show-helper')
                  })
                  onDragStart(listId)
                }}
                onDragOver={() => {
                  onDragOver(
                    index,
                    customAttributeEnumsWithListId,
                    'listId',
                    (newList) => {
                      formContext.setValue('enumsWithId', newList)
                      formContext.setValue(
                        'enums',
                        newList.map((x) => x.value),
                        {
                          shouldDirty: true,
                        },
                      )
                    },
                  )
                }}
                onDragEnd={() => {
                  trackDragEnd({
                    name: 'custom-attribute-dialog-value',

                    position: index,
                    value,
                  })
                  onDragEnd()
                }}
              >
                <TextField
                  inputProps={{
                    'data-testid': `custom-attribute-value-${index}`,
                  }}
                  value={value}
                  onChange={(ev) => {
                    customAttributeEnums[index] = ev.target.value
                    formContext.setValue('enums', customAttributeEnums)

                    customAttributeEnumsWithListId[index]!.value =
                      ev.target.value
                    formContext.setValue(
                      'enumsWithId',
                      customAttributeEnumsWithListId,
                    )
                  }}
                  disabled={!writeSettingsCustomAttributes}
                />
                {writeSettingsCustomAttributes && (
                  <>
                    <IconButton
                      onClick={() => {
                        customAttributeEnums.splice(index, 1)
                        formContext.setValue('enums', customAttributeEnums)

                        customAttributeEnumsWithListId.splice(index, 1)
                        formContext.setValue(
                          'enumsWithId',
                          customAttributeEnumsWithListId,
                        )
                      }}
                      data-testid={`delete-custom-attribute-value-${index}`}
                    >
                      <DeleteIcon />
                    </IconButton>
                    <IconButton
                      draggable
                      data-testid={`drag-custom-attribute-value-${index}`}
                    >
                      <DragHandleIcon
                        sx={{
                          color: (theme) => theme.palette.gray60,
                        }}
                      />
                    </IconButton>
                  </>
                )}
              </Box>
            ))}

            {writeSettingsCustomAttributes && (
              <Button
                color="secondary"
                fullWidth
                onClick={() => {
                  const value = ''
                  formContext.setValue('enums', [
                    ...customAttributeEnums,
                    value,
                  ])

                  customAttributeEnumsWithListId.push({
                    listId: `${customAttributeEnumsWithListId.length}`,
                    value,
                  })
                  formContext.setValue(
                    'enumsWithId',
                    customAttributeEnumsWithListId,
                  )
                }}
                data-testid="add-value"
                sx={{ mt: 2 }}
              >
                <PlusIcon sx={{ marginRight: (theme) => theme.spacing(1) }} />
                {t('settings:custom_attribute.add_new')}
              </Button>
            )}
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-custom-attribute"
        >
          {t(
            writeSettingsCustomAttributes
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsCustomAttributes && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-custom-attribute"
          >
            {hasCustomAttribute
              ? t('shared:action.add')
              : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
