import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { AutocompleteInput } from '../components/autocomplete-input'
import { FilterContext } from '../components/data-grid/context'
import { AllProductGroupsDocument } from '../generated/graphql'
import { useStateParams } from '../hooks/state-params'
import {
  formatQueryNumberArray,
  formatQueryStringArray,
} from '../hooks/state-params/util'

type QueryState = {
  productGroupIds?: string[]
}

type Form = {
  productGroupIds?: number[]
}

export const ProductGroupFilter = () => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'productGroupIds'

  const { data, loading: isLoading } = useQuery(AllProductGroupsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const options = useMemo(
    () =>
      [...(data?.allProductGroups || [])].sort((a, b) =>
        a.name.localeCompare(b.name),
      ),
    [data?.allProductGroups],
  )

  const [queryParams, setQueryParams] = useStateParams<QueryState>()

  const productGroupIds = formatQueryNumberArray(queryParams.productGroupIds)

  const { control, setValue, watch } = useForm<Form>({
    values: { productGroupIds },
    defaultValues: { productGroupIds: [] },
  })

  useEffect(() => {
    registerFilter({ key: filterKey, isReady: !isLoading })
  }, [isLoading, registerFilter])

  useEffect(() => {
    if (isLoading) return

    if (productGroupIds.length) {
      setFilter(filterKey, {
        label: t('filter:product_group.label'),
        labelValues: productGroupIds.map(
          (id) => options.find((x) => x.id === id)?.name || String(id),
        ),
        value: productGroupIds,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [isLoading, options, productGroupIds, removeFilter, setFilter, t])

  useEffect(() => {
    const subscribe = watch(({ productGroupIds }) => {
      setQueryParams({
        [filterKey]: formatQueryStringArray(productGroupIds),
      })
    })

    return () => subscribe.unsubscribe()
  }, [options, removeFilter, setQueryParams, t, watch])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setValue('productGroupIds', [])
      }
    })

    return () => unsubscribe()
  }, [setValue, subscribeOnResetFilter])

  return (
    <AutocompleteInput
      multiple
      name="productGroupIds"
      control={control}
      options={options.map(({ id }) => id)}
      disabled={isLoading}
      label={t('filter:product_group.label')}
      textFieldProps={{
        slotProps: {
          htmlInput: { ['data-testid']: 'product-group-input' },
        },
      }}
      autocompleteProps={{
        limitTags: 5,
        getOptionKey: (option) => option,
        getOptionLabel: (option) =>
          options.find((x) => x.id === option)?.name || String(option),
      }}
    />
  )
}
