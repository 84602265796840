/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { TextField, Button } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  GetProductsQuery,
  PurchasePriceListItemInput,
  UpdatePurchasePriceListItemsDocument,
} from '../../../../generated/graphql'
import { useMoney } from '../../../../hooks/money'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'

type Product = Pick<
  ArrayElement<GetProductsQuery['products']['items']>,
  'id' | 'sku'
>
export type SetPurchasePriceDialogProps = {
  open: boolean
  priceListId: string
  products: Product[]
  dataTestid?: string
  onClose(): void
  onSuccess?(priceListId: string, products: Product[]): void
  onError?(priceListId: string, products: Product[]): void
}

type Form = {
  value: string
}

export const SetPurchasePriceDialog = (props: SetPurchasePriceDialogProps) => {
  const { t } = useTranslation(['purchase_prices', 'shared'])
  const dialogName = 'set-purchase-price'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormSuccess,
    trackFormError,
  } = useTracking()
  const [updatePurchasePriceListItems, { loading: isLoading }] = useMutation(
    UpdatePurchasePriceListItemsDocument,
  )
  const prevOpen = usePrevious(props.open)
  const formContext = useForm<Form>({
    mode: 'onChange',
  })
  const { formatEditableCurrency, formatStringCurrencyToNumber } = useMoney()

  const onSubmit = async (formData: Form) => {
    try {
      await updatePurchasePriceListItems({
        variables: {
          priceListId: props.priceListId,
          items: props.products.map(
            (x): PurchasePriceListItemInput => ({
              productId: x.id,
              sku: x.sku,
              price: formatStringCurrencyToNumber(formData.value),
            }),
          ),
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })
      if (props.onSuccess) {
        props.onSuccess(props.priceListId, props.products)
      }
    } catch {
      trackFormError({ name: `${dialogName}-dialog` })
      if (props.onError) {
        props.onError(props.priceListId, props.products)
      }
    } finally {
      formContext.reset()
    }
  }

  const onClose = () => {
    if (!isLoading) {
      trackDialogClose({ name: dialogName })
      if (props.onClose) {
        props.onClose()
      }
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      formContext.reset()
    }
  }, [props.open, prevOpen, trackDialogOpen, formContext])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={formContext.handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('purchase_prices:set_purchase_price_dialog.dialog_title')}
        </DialogTitle>
        <DialogContent>
          <TextField
            {...formContext.register('value', {
              required: t('shared:validation.field_required', {
                field: t(
                  'purchase_prices:set_purchase_price_dialog.purchase_price',
                ),
              }),
              validate: (v) =>
                Number.isNaN(formatStringCurrencyToNumber(v))
                  ? t('shared:validation.field_invalid', {
                      field: t(
                        'purchase_prices:set_purchase_price_dialog.purchase_price',
                      ),
                    })
                  : true,
            })}
            label={t(
              'purchase_prices:set_purchase_price_dialog.purchase_price',
            )}
            placeholder={formatEditableCurrency(0)}
            inputProps={{
              ['data-testid']: 'purchase-price-field',
            }}
            error={!!formContext.formState.errors.value?.message}
            helperText={formContext.formState.errors.value?.message}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: 'set-purchase-price-dialog-cancel',
              },
              onClose,
            )}
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            loading={isLoading}
            onClick={trackButtonClickEvent({
              name: 'set-purchase-price-dialog-set',
            })}
            disabled={!formContext.formState.isValid}
            data-testid="set-purchase-price"
          >
            {t('shared:action.set')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
