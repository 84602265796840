/* eslint-disable @typescript-eslint/no-misused-promises */
import LoadingButton from '@mui/lab/LoadingButton'
import { Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { Information } from '../information'
import { BaseShipmentFormContext } from '../shared'
import { useNewShipmentFormSubmit } from './submit'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

export const NewShipmentV2Page = () => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  usePageTitle(t('shipments_v2:page_title_add_shipment'))
  const formContext = useForm<BaseShipmentFormContext>({
    defaultValues: {
      packages: [],
    },
  })
  const { onSubmit, isSubmitting, onError } = useNewShipmentFormSubmit({
    setError: formContext.setError,
  })
  const generatePath = useAbsolutePath()

  return (
    <>
      <RouteLeavingGuard
        when={
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shipments_v2:page_title_add_shipment')}
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <>
            <LoadingButton
              loading={isSubmitting}
              onClick={formContext.handleSubmit(onSubmit, onError)}
            >
              {t('shipments_v2:action_title_add_shipment')}
            </LoadingButton>
          </>
        }
      />

      <Container data-testid="shipment-add-page">
        <FormProvider {...formContext}>
          <Information />
        </FormProvider>
      </Container>
    </>
  )
}
