import { Maybe } from 'graphql/jsutils/Maybe'
import { Roles } from '../../hooks/authorization'
import { useMe } from '../../hooks/me'

export const LOCAL_CAMPAIGN_PREFIX = 'LOCAL'

export const isLocalVoucherCode = (voucherCode: Maybe<string>) => {
  return !!voucherCode?.match(/^LOCAL/)
}

export const parseVoucherCode = (voucherCode: Maybe<string>) => {
  return voucherCode?.replace(/^LOCAL/, '')
}

export const useLocalCampaignFlow = () => {
  const { me } = useMe()

  return (
    !!me?.currentSite.allow_store_manager_campaigns &&
    me.role === Roles.StoreManager
  )
}
