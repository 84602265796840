import { Avatar, Box, Stack, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { QuestionIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { AllPaymentTypesQuery } from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'

export const PaymentMethodRenderer = (
  params: GridRenderCellParams<
    ArrayElement<AllPaymentTypesQuery['allPaymentTypes']>,
    string
  >,
) => <PaymentMethod {...params.row} />

type PaymentMethodProps = {
  src?: string
  paymentmethodid?: string
}

export const PaymentMethod = (props: PaymentMethodProps) => {
  const { t } = useTranslation(['pos_payment'])
  return (
    <Box sx={{ display: 'flex', alignItems: 'center', height: '100%' }}>
      {/* 
      // TODO: Add icons when we have them

      <Box sx={{ display: 'flex' }}>
        <Avatar
          variant="square"
          src={props.src}
          sx={{
            width: 30,
            height: 30,
            margin: 'auto',
            borderRadius: '2px',
            img: {
              objectFit: 'contain',
            },
            background: 'white',
          }}
        >
          <Box
            sx={{
              background: (t) => t.palette.gray20,
              height: '100%',
              width: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <QuestionIcon sx={{ color: (t) => t.palette.gray50 }} />
          </Box>
        </Avatar>
      </Box> */}
      <Stack
        direction="column"
        spacing={0} /* ml={1} //TODO: Use this margin once we have icons */
      >
        <Typography>
          {t(`pos_payment:payment_method.${props.paymentmethodid}`)}
        </Typography>
        {/* TODO: Implement payment type status */}
        {/* <Typography variant="caption">{'Status: Unknown'}</Typography> */}
      </Stack>
    </Box>
  )
}
