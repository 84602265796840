import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { translateCampaignTypeTitle } from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type PricelistCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const PricelistCampaignSelection = (
  props: PricelistCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-price-list-activate-price-lists' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_400),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_400)}
        data-testid="activate-pricelists-tags-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-price-list-activate-price-lists-block-other-tags',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_410),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_410)}
        data-testid="activate-pricelists-block-other-tags-listItem"
      />
    </div>
  )
}
