import { MenuItem, SelectProps, TextField, TextFieldProps } from '@mui/material'
import {
  Controller,
  FieldValues,
  useController,
  UseControllerProps,
} from 'react-hook-form'

type Option = {
  name: string
  value: string
}

type Props = {
  options: Option[]
  label?: string
  helperText?: string
  dataTestid?: string
  slotProps?: TextFieldProps['slotProps']
}

export const SelectInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { control, name, options, label, helperText, dataTestid } = props
  const { disabled, slotProps } = props
  const { formState } = useController({ name, control })
  const { isSubmitting } = formState

  return (
    <Controller
      control={control}
      name={name}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          disabled={disabled || isSubmitting}
          select
          label={label}
          error={!!error?.message}
          helperText={error?.message ?? helperText}
          slotProps={{
            ...slotProps,
            htmlInput: {
              ['data-testid']: `select-input-${name}`,
              ...slotProps?.htmlInput,
            },
          }}
          sx={{ mb: 2 }}
        >
          {options.map(({ name, value }) => (
            <MenuItem value={value} key={value}>
              {name}
            </MenuItem>
          ))}
        </TextField>
      )}
    />
  )
}
