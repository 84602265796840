import {
  Box,
  Button,
  Grid,
  IconButton,
  InputLabel,
  List,
  ListItem,
  TextField,
} from '@mui/material'
import { DeleteIcon, FormFieldset, PlusIcon } from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useForm, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { BaseShipmentFormContext } from '../../shared'

type AdditionalDataForm = {
  additionalData: [string, string][]
}

export const AdditionalDataField = () => {
  const { watch, setValue } = useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared'])
  const [isInitialized, setInitialized] = useState(false)

  const additionalDataFormContext = useForm<AdditionalDataForm>({
    defaultValues: { additionalData: [] },
  })
  const additionalData = additionalDataFormContext.watch('additionalData')

  const infoAdditionalData = watch('info.additional_data') as
    | Record<string, string>
    | null
    | undefined

  useEffect(() => {
    if (!isInitialized || infoAdditionalData === undefined) {
      setInitialized(true)

      if (infoAdditionalData && typeof infoAdditionalData === 'object')
        additionalDataFormContext.setValue(
          'additionalData',
          Object.entries(infoAdditionalData),
        )
    }
  }, [isInitialized, infoAdditionalData, additionalDataFormContext])

  useEffect(() => {
    setValue(
      'info.additional_data',
      additionalData
        ? Object.fromEntries(additionalData.filter(([key]) => !!key))
        : null,
    )
  }, [additionalData, setValue])

  return (
    <FormFieldset
      label={t('shipments:shipment_form.additional_data_fieldset')}
      sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
    >
      <List>
        {additionalData.length > 0 && (
          <>
            <ListItem sx={{ py: 2 }}>
              <Box>
                <Box>
                  <InputLabel>
                    {t('shipments:shipment_form.additional_data_fieldset')}
                  </InputLabel>
                </Box>
                <Box>
                  <Grid container spacing={2}>
                    {additionalData.map(([key, value], i) => (
                      <Grid key={i} item container spacing={2}>
                        <Grid item xs>
                          <TextField
                            inputProps={{
                              'data-testid': `additional-data-key-${i}`,
                            }}
                            value={key}
                            placeholder={t('shipments:shipment_form.enter_key')}
                            onChange={(ev) => {
                              additionalData[i] = [ev.target.value, value]
                              additionalDataFormContext.setValue(
                                'additionalData',
                                additionalData,
                              )
                            }}
                          />
                        </Grid>
                        <Grid item xs>
                          <TextField
                            inputProps={{
                              'data-testid': `additional-data-value-${i}`,
                            }}
                            value={value}
                            placeholder={t(
                              'shipments:shipment_form.enter_value',
                            )}
                            onChange={(ev) => {
                              additionalData[i] = [key, ev.target.value]
                              additionalDataFormContext.setValue(
                                'additionalData',
                                additionalData,
                              )
                            }}
                          />
                        </Grid>
                        <Grid item>
                          <IconButton
                            data-testid="delete-value"
                            onClick={() => {
                              additionalData.splice(i, 1)
                              additionalDataFormContext.setValue(
                                'additionalData',
                                additionalData,
                              )
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    ))}
                  </Grid>
                </Box>
              </Box>
            </ListItem>
          </>
        )}
        <ListItem sx={{ p: 0, minHeight: 'auto' }}>
          <Button
            color="tertiary"
            fullWidth
            onClick={() => {
              additionalDataFormContext.setValue('additionalData', [
                ...additionalData,
                ['', ''],
              ])
            }}
            data-testid="add-value"
          >
            <PlusIcon sx={{ mr: 1 }} />
            {t('shared:action.add_new')}
          </Button>
        </ListItem>
      </List>
    </FormFieldset>
  )
}
