/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  AllReasonCodesDocument,
  ReasonCodeType,
  ShipmentStateV2,
  UpdateShipmentV2Document,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'

export type StateDialogProps = {
  open: boolean
  state?: ShipmentStateV2
  shipmentId: number
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
}

type Form = {
  state: ShipmentStateV2
  reasonCode?: string
}

export const SetStateDialog = (props: StateDialogProps) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-state-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const { formState, reset, handleSubmit, control, watch } = useForm<Form>({
    mode: 'onChange',
  })

  const [updateShipment, { loading: updateLoading }] = useMutation(
    UpdateShipmentV2Document,
  )

  const { data: reasonCodesData, loading: reasonCodesLoading } = useQuery(
    AllReasonCodesDocument,
    {
      variables: {
        type: ReasonCodeType.ShipmentIn,
      },
    },
  )
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const shipmentStates: ShipmentStateV2[] = useMemo(() => {
    switch (props.state) {
      case ShipmentStateV2.New:
        return [
          ShipmentStateV2.New,
          ShipmentStateV2.InTransit,
          ShipmentStateV2.Cancelled,
        ]
      case ShipmentStateV2.InTransit:
        return [ShipmentStateV2.Received, ShipmentStateV2.Cancelled]

      case ShipmentStateV2.Received:
        return [ShipmentStateV2.Received]

      default:
        return []
    }
  }, [props.state])

  const isLoading = updateLoading || reasonCodesLoading
  const onSubmit = async (formData: Form) => {
    try {
      await updateShipment({
        variables: {
          shipment: {
            shipmentid: props.shipmentId,
            shipmentstate: formData.state,
          },
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments_v2',
        t('shipments_v2:shipment_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ state: shipmentStates?.[0] }, { keepDefaultValues: false })
    }
  }, [
    props.open,
    prevOpen,
    trackDialogOpen,
    props.state,
    reset,
    shipmentStates,
  ])

  const state = watch('state')

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('shipments_v2:set_state_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={control}
            name="state"
            render={({ field, fieldState: { error } }) => (
              <>
                <InputLabel>
                  {t('shipments_v2:set_state_dialog.state')}
                </InputLabel>
                <Select
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value)
                  }}
                >
                  {shipmentStates.map((state) => (
                    <MenuItem value={state} key={state}>
                      {t(`shipments_v2:shipment_state.${state}`)}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText error={!!error?.message}>
                  {error?.message}
                </FormHelperText>
              </>
            )}
          />
          {ShipmentStateV2.Cancelled === state && reasonCodes.length > 0 && (
            <Controller
              control={control}
              name="reasonCode"
              rules={{
                validate: (v) =>
                  ShipmentStateV2.Cancelled === state && !v
                    ? t('shared:validation.field_required', {
                        field: t('shipments_v2:set_state_dialog.reason_code'),
                      })
                    : true,
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputLabel sx={{ mt: 2 }}>
                    {t('shipments_v2:set_state_dialog.reason_code')}
                  </InputLabel>
                  <Select
                    value={field.value || ''}
                    onChange={(event) => {
                      field.onChange(event.target.value)
                    }}
                    displayEmpty
                    error={!!error?.message}
                  >
                    <MenuItem value={''} disabled>
                      {t('shared:label.select')}
                    </MenuItem>
                    {reasonCodes.map(({ reasoncode, reasoncodeid, name }) => (
                      <MenuItem value={reasoncode} key={reasoncodeid}>
                        {name || reasoncode}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!error?.message}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            loading={isLoading}
          >
            {t('shared:action.set')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
