import { Container, Grid, Paper } from '@mui/material'
import { GridValidRowModel } from '@mui/x-data-grid-pro'
import { JSXElementConstructor } from 'react'
import { Outlet } from 'react-router-dom'
import { DataGrid, DataGridProps } from '../data-grid'
import { SortItem } from '../data-grid/filters/sort'
import { CustomRowCount } from '../data-grid/custom-row-count'

type Props<
  TRow extends GridValidRowModel,
  TPageHeaderProps,
  TFilterPanelProps,
  TViewPanelProps,
> = DataGridProps<TRow> & {
  sortItems?: SortItem[]
  slots?: {
    PageHeader?: JSXElementConstructor<TPageHeaderProps> | null
    FilterPanel?: JSXElementConstructor<TFilterPanelProps> | null
    ViewPanel?: JSXElementConstructor<TViewPanelProps> | null
  }
  slotProps?: {
    pageHeader?: TPageHeaderProps
    filterPanel?: TFilterPanelProps
    viewPanel?: TViewPanelProps
  }
}

export const SettingsList = <
  TRow extends GridValidRowModel,
  TPageHeaderProps,
  TFilterPanelProps,
  TViewPanelProps,
>(
  props: Props<TRow, TPageHeaderProps, TFilterPanelProps, TViewPanelProps>,
) => {
  const { name, rows, slots, slotProps, sortItems, ...gridProps } = props

  const gridName = `${name}-settings-list`

  const PageHeader = slots?.PageHeader
  const FilterPanel = slots?.FilterPanel
  const ViewPanel = slots?.ViewPanel

  return (
    <Grid container flexWrap="nowrap">
      <Outlet />

      <Grid item flexGrow={1}>
        {PageHeader && slotProps?.pageHeader && (
          <PageHeader {...slotProps.pageHeader} />
        )}
        <Container maxWidth={false}>
          <Paper elevation={0}>
            <DataGrid
              {...gridProps}
              sx={{ '.MuiDataGrid-row': { cursor: 'pointer' } }}
              name={gridName}
              rows={rows}
              pagination={false}
              rowCount={rows?.length ?? 0}
              rowHeight={50}
              sorting={sortItems}
              slots={{
                footer: CustomRowCount,
              }}
            />
          </Paper>
        </Container>
      </Grid>

      <Grid item>
        {FilterPanel && slotProps?.filterPanel && (
          <FilterPanel {...slotProps.filterPanel} />
        )}
        {ViewPanel && slotProps?.viewPanel && (
          <ViewPanel {...slotProps.viewPanel} />
        )}
      </Grid>
    </Grid>
  )
}
