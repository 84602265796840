import { useMutation, useQuery } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import { ListItemText, Paper, Typography, Chip, Container } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useEffect, useState } from 'react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import {
  Pricelist,
  DeletePricelistsDocument,
  GetPricelistsDocument,
  UpdatePricelistsDocument,
} from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { MAX_NUM_REQUEST } from '../../../utils/constants'
import { ProductSettingsList } from '../products/product-settings-list'
import { PriceListDialog } from './price-list-dialog'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

export type PricelistsForm = {
  pricelists: Pricelist[]
  deletePricelists: number[]
}

export const SettingsPriceListsPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.price_lists'))
  const {
    modules: { writeSettingsPriceList },
  } = useAuthorization()
  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const formContext = useForm<PricelistsForm>({
    defaultValues: { pricelists: [], deletePricelists: [] },
  })
  const {
    fields: pricelists,
    remove: removePriceLists,
    update: updatePriceLists,
    append: appendPricelist,
  } = useFieldArray({
    control: formContext.control,
    name: 'pricelists',
    keyName: 'pricelistid',
  })

  const [selectedPricelist, setSelectedPricelist] = useState<Pricelist | null>(
    null,
  )

  const [showDialog, setShowDialog] = useState(false)

  const {
    loading: fetchLoading,
    refetch,
    data: pricelistsData,
  } = useQuery(GetPricelistsDocument, {
    variables: { num: MAX_NUM_REQUEST, start: 0 },
    fetchPolicy: 'cache-and-network',
  })

  const [updatePricelist, { loading: updateLoading }] = useMutation(
    UpdatePricelistsDocument,
    {
      refetchQueries: ['AllPricelistTags'],
    },
  )

  const [deletePricelist, { loading: deleteLoading }] = useMutation(
    DeletePricelistsDocument,
    {
      refetchQueries: ['AllPricelistTags'],
    },
  )

  const isLoading = fetchLoading || updateLoading || deleteLoading

  useEffect(() => {
    formContext.reset({
      pricelists: pricelistsData?.pricelists.items || [],
      deletePricelists: [],
    })
  }, [formContext, pricelistsData])

  const onPricelistUpdate = (pricelist: Pricelist) => {
    if (pricelist.id) {
      updatePriceLists(
        pricelists.findIndex((x) => x.id === pricelist.id),
        pricelist,
      )
    } else {
      appendPricelist({
        ...pricelist,
        id: Math.max(...pricelists.map(({ id }) => id)) + 1,
      })
    }
    setShowDialog(false)
  }

  const edit = (pricelist: Pricelist) => {
    trackButtonClick({ name: 'settings-price-list-edit' })

    setSelectedPricelist(pricelist)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({ name: 'settings-price-list-add' })

    setSelectedPricelist(null)
    setShowDialog(true)
  }

  const remove = (pricelist: Pricelist) => {
    trackButtonClick({ name: 'settings-price-list-remove' })

    removePriceLists(pricelists.findIndex(({ id }) => id === pricelist.id))

    if (pricelist.__typename) {
      formContext.setValue('deletePricelists', [
        ...formContext.getValues('deletePricelists'),
        pricelist.id,
      ])
    }
  }

  const onSubmit = async (data: PricelistsForm) => {
    try {
      trackButtonClick({ name: 'settings-price-list-save' })

      await updatePricelist({
        variables: {
          pricelists: data.pricelists.map(
            ({ __typename, id, active, ...props }) => ({
              ...props,
              active: id === 1 ? undefined : active,
              pricelistid: __typename ? id : undefined,
            }),
          ),
        },
      })

      if (data.deletePricelists.length > 0) {
        await deletePricelist({
          variables: {
            data: { ids: data.deletePricelists },
          },
        })
      }

      await refetch()

      trackFormSuccess({
        name: 'settings-price-list',
      })
      enqueueSnackbar(
        t('settings:price_lists.success_saved', {
          count: (data?.deletePricelists.length || 0) + data.pricelists.length,
        }),
      )
    } catch {
      trackFormError({
        name: 'settings-price-list',
      })
    }
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsPriceList}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.price_lists')}
        rightColumn={
          <>
            {writeSettingsPriceList && (
              <LoadingButton
                disabled={!formContext.formState.isDirty}
                type="submit"
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={formContext.handleSubmit(onSubmit)}
                loading={isLoading}
                data-testid="save-changes"
              >
                {t('shared:action.save')}
              </LoadingButton>
            )}
          </>
        }
      />
      <FormProvider {...formContext}>
        <Container>
          <Paper elevation={0}>
            <ProductSettingsList
              items={pricelists}
              onEdit={edit}
              canAdd
              onAdd={add}
              addText={t('settings:price_lists.add_price_list')}
              baseDataTestId="pricelists"
              onDelete={remove}
              readOnly={!writeSettingsPriceList}
              loading={isLoading}
              emptyTitle={t('settings:price_lists.empty_title')}
              emptyDescription={t('settings:price_lists.empty_description')}
              itemRender={(x) => (
                <>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: { whiteSpace: 'break-spaces' },
                    }}
                  >
                    {x.pricelistname}
                    <Typography>{x.currencycode}</Typography>
                    <Typography>{x.externalid}</Typography>
                    <Typography>{x.pricelistdescription}</Typography>
                    {!x.active && (
                      <Chip
                        label={t('settings:price_lists.deactivated')}
                        size="small"
                        color="red"
                        sx={{ mr: 0.5 }}
                      />
                    )}
                    {x.tags?.map((tag) => (
                      <Chip
                        key={tag}
                        label={tag}
                        color="grayLight"
                        size="small"
                        sx={{ mr: 0.5 }}
                      />
                    ))}
                  </ListItemText>
                </>
              )}
            />
          </Paper>
        </Container>
      </FormProvider>

      <PriceListDialog
        open={showDialog}
        pricelist={selectedPricelist}
        onClose={() => setShowDialog(false)}
        onSuccess={onPricelistUpdate}
      />
    </>
  )
}
