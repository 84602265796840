export const formatQueryNumberArray = (param?: string | string[]): number[] => {
  if (Array.isArray(param)) {
    return param.map(Number)
  }

  if (param) {
    return [Number(param)]
  }

  return []
}

export const formatQueryStringArray = (
  param?: number | (number | string | undefined)[],
): string[] => {
  if (Array.isArray(param)) {
    return param.map(String)
  }
  if (param) {
    return [String(param)]
  }

  return []
}
