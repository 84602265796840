import { useMutation, useQuery } from '@apollo/client'
import { Box } from '@mui/system'
import {
  GRID_REORDER_COL_DEF,
  GridColDef,
  GridRowOrderChangeParams,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AllPaymentTypesDocument,
  AllPaymentTypesQuery,
  PaymentType,
  UpdatePaymentTypeDocument,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { PaymentMethodRenderer } from './payment-method-renderer'

import { Tooltip } from '@mui/material'
import { PaymentCustomIcon, QuestionIcon } from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { SettingsList } from '../../../../components/settings-list'

type Row = ArrayElement<AllPaymentTypesQuery['allPaymentTypes']>

type Props = {
  onDetailPaymentOption(id: number): void
}

export const PaymentOptionsList = ({ onDetailPaymentOption }: Props) => {
  const apiRef = useGridApiRef()
  const { t } = useTranslation(['pos_payment'])
  const { data, loading: fetchLoading } = useQuery(AllPaymentTypesDocument)

  const [rows, setRows] = useState<Row[]>()

  useEffect(() => {
    if (data?.allPaymentTypes)
      setRows(appendDragAndDropName(data.allPaymentTypes))
  }, [data, setRows])

  const [updatePaymentType, { loading: isUpdating }] = useMutation(
    UpdatePaymentTypeDocument,
    { refetchQueries: [AllPaymentTypesDocument] },
  )

  const appendDragAndDropName = (rows: Row[]) => {
    return rows.map((row) => ({ ...row, __reorder__: row.name }))
  }

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        ...GRID_REORDER_COL_DEF,
        align: 'left',
        display: 'flex',
        maxWidth: 40,
        renderHeader: () => (
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            <Tooltip
              title={t('pos_payment:sort_order_tooltip')}
              sx={{ display: 'flex' }}
            >
              <Box>
                <QuestionIcon />
              </Box>
            </Tooltip>
          </Box>
        ),
      },
      {
        field: 'name',
        minWidth: 140,
        headerName: t('pos_payment:name'),
      },
      {
        field: 'externalid',
        minWidth: 140,
        headerName: t('pos_payment:external_id'),
      },
      {
        field: 'paymentmethodid',
        minWidth: 320,
        headerName: t('pos_payment:method'),
        renderCell: PaymentMethodRenderer,
      },
    ],
    [t],
  )

  const handleRowOrderChange = async (params: GridRowOrderChangeParams) => {
    if (params.targetIndex !== params.oldIndex) {
      const { __reorder__, ...paymentType } = params.row
      try {
        await updatePaymentType({
          variables: {
            paymentType: {
              ...(paymentType as PaymentType),
              sortorder: params.targetIndex,
            },
          },
        })
        enqueueSnackbar(t('pos_payment:successful_sort_order_update'), {
          variant: 'success',
          autoHideDuration: 1000,
        })
      } catch (error) {
        enqueueSnackbar(t('pos_payment:failure_sort_order_update'), {
          variant: 'error',
        })
      }
    }
  }

  const isLoading = fetchLoading || isUpdating

  return (
    <SettingsList
      apiRef={apiRef}
      name="payment-options-list"
      columns={dataGridColumns}
      rows={rows}
      rowCount={data?.allPaymentTypes.length || 0}
      getRowId={(row) => row.paymenttypeid}
      hideFooter
      loading={isLoading}
      onRowClick={(p: GridRowParams<PaymentType>) => {
        onDetailPaymentOption(p.row.paymenttypeid)
      }}
      noRowsOverlay={{
        icon: <PaymentCustomIcon />,
        title: t('pos_payment:no_payment_options_title'),
        description: t('pos_payment:no_payment_options_description'),
      }}
      rowHeight={50}
      hasPageHeader
      rowReordering
      // eslint-disable-next-line @typescript-eslint/no-misused-promises
      onRowOrderChange={handleRowOrderChange}
    />
  )
}
