import { Box } from '@mui/material'
import { forwardRef } from 'react'
import { BulkGridHeaderActions } from './bulk-grid-header-actions'
import { CustomToolbar } from './custom-toolbar'
import { ToolbarPropsOverrides } from '@mui/x-data-grid-pro'

type CustomGridHeaderProps = ToolbarPropsOverrides

export const CustomGridHeader = forwardRef<
  HTMLDivElement,
  CustomGridHeaderProps
>(function CustomGridHeader(props, ref) {
  return (
    <Box ref={ref} sx={{ position: 'relative' }}>
      <BulkGridHeaderActions>{props.bulkAction}</BulkGridHeaderActions>

      <CustomToolbar
        disableColumnVisibilityControl={props.disableColumnVisibilityControl}
      >
        {props.toolbar}
      </CustomToolbar>
    </Box>
  )
})
