/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import { TextField, Button } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ShipmentInfoCarrier } from '../../../generated/graphql'
import { useDayJs } from '../../../hooks/day-js'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'

export type CarrierDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  carrier?: ShipmentInfoCarrier | null
  onClose(): void
  onSubmit?(address: ShipmentInfoCarrier): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  dataTestid?: string
}

type Form = ShipmentInfoCarrier

export const SetCarrierDialog = (props: CarrierDialogProps) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-carrier'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const {
    formState,
    reset,
    handleSubmit,
    register,
    control,
    setError,
    clearErrors,
  } = useForm<Form>({
    mode: 'onChange',
  })
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const { getDateTimeFormat } = useLocalizedDate()
  const dayJs = useDayJs()

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })

      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.carrier }, { keepDefaultValues: false })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.carrier, reset])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid={props.dataTestid}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            props.action === 'add'
              ? 'shipments:set_carrier_dialog.add_title'
              : 'shipments:set_carrier_dialog.edit_title',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!formState?.errors?.name}
            fullWidth
            helperText={formState?.errors?.name?.message}
            label={t('shipments:shipment_form.name')}
            {...register('name')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.service}
            fullWidth
            helperText={formState?.errors?.service?.message}
            label={t('shipments:shipment_form.service')}
            {...register('service')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.reference}
            fullWidth
            helperText={formState?.errors?.reference?.message}
            label={t('shipments:shipment_form.reference')}
            {...register('reference')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.tracking_url}
            fullWidth
            helperText={formState?.errors?.tracking_url?.message}
            placeholder="https://wwww.site.com"
            label={t('shipments:shipment_form.tracking_url')}
            required
            {...register('tracking_url', {
              required: t('shared:validation.field_required', {
                field: t('shipments:shipment_form.tracking_url'),
              }),
            })}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.tracking_url_title}
            fullWidth
            helperText={formState?.errors?.tracking_url_title?.message}
            label={t('shipments:shipment_form.tracking_url_title')}
            {...register('tracking_url_title')}
            sx={{ mb: 2 }}
          />
          <Controller
            control={control}
            name="estimated_pickup_at"
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('shipments:shipment_form.estimated_pickup_at'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'carrier-estimated-pickup-date',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? dayJs(field.value).tz() : null}
                format={getDateTimeFormat()}
                onChange={(newValue) => {
                  if (newValue && !newValue.isValid()) {
                    setError('estimated_pickup_at', {
                      message: t('shared:validation.field_invalid', {
                        field: t('shipments:shipment_form.estimated_pickup_at'),
                      }),
                      type: 'validate',
                    })
                  } else {
                    clearErrors('estimated_pickup_at')
                  }

                  if (newValue?.isValid()) {
                    field.onChange(newValue.toJSON())
                  }
                }}
              />
            )}
          />

          <Controller
            control={control}
            name="estimated_delivery_at"
            render={({ field, fieldState: { error } }) => (
              <DateTimePicker
                sx={{ mb: 2 }}
                slotProps={{
                  textField: {
                    label: t('shipments:shipment_form.estimated_delivery_at'),
                    error: !!error?.message,
                    helperText: error?.message,
                    inputProps: {
                      'data-testid': 'carrier-estimated-delivery-date',
                    },
                  },
                  openPickerButton: {
                    edge: 'start',
                  },
                }}
                value={field.value ? dayJs(field.value).tz() : null}
                format={getDateTimeFormat()}
                onChange={(newValue) => {
                  if (newValue && !newValue.isValid()) {
                    setError('estimated_delivery_at', {
                      message: t('shared:validation.field_invalid', {
                        field: t(
                          'shipments:shipment_form.estimated_delivery_at',
                        ),
                      }),
                      type: 'validate',
                    })
                  } else {
                    clearErrors('estimated_delivery_at')
                  }

                  if (newValue?.isValid()) {
                    field.onChange(newValue.toJSON())
                  }
                }}
              />
            )}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            color="secondary"
            size="small"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            data-testid="set-carrier"
            loading={loading}
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
