import { LoadingButton } from '@mui/lab'
import { Box, Container, ListItemText } from '@mui/material'
import { useSnackbar } from 'notistack'
import { useState, useEffect } from 'react'
import { useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AllCustomAttributesQuery,
  CustomAttributeTypeEnum,
  SingleCustomAttributeInput,
  AddUpdateCustomAttributesDocument,
  AllCustomAttributesDocument,
  DeleteCustomAttributesDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { PageHeader } from '../../../../components/page-header'
import { ProductSettingsList } from '../product-settings-list'
import { ArrayElement } from '../../../../utils/types'
import { CustomAttributeDialog } from './custom-attribute-dialog'
import { RouteLeavingGuard } from '../../../../components/route-leaving-guard'
import { usePageTitle } from '../../../../hooks/title'
import { useAuthorization } from '../../../../hooks/authorization'
import { useMutation, useQuery } from '@apollo/client'
import { containsDirtyFields } from '../../../../utils/contains-dirty-fields'

export type ProductCustomAttribute = ArrayElement<
  AllCustomAttributesQuery['allCustomAttributes']
>

type ProductCustomAttributeWithId = ProductCustomAttribute & { listId?: string }

type ProductCustomAttributesForm = {
  productCustomAttributes: ProductCustomAttribute[]
  updateCustomAttributes: ProductCustomAttributeWithId[]
  deleteCustomAttributes: string[]
}

export const SettingsProductsCustomAttributesPage = () => {
  const { t } = useTranslation(['shared', 'settings', 'countries'])
  usePageTitle(t('shared:menu.custom_attributes'))
  const {
    modules: { writeSettingsCustomAttributes },
  } = useAuthorization()

  const { trackButtonClick, trackFormError, trackFormSuccess } = useTracking()
  const formContext = useForm<ProductCustomAttributesForm>({
    defaultValues: {
      updateCustomAttributes: [],
      deleteCustomAttributes: [],
      productCustomAttributes: [],
    },
  })
  const {
    fields,
    remove: removeCustomAttribute,
    update: updateCustomAttribute,
    append: appendCustomAttribute,
  } = useFieldArray({
    control: formContext.control,
    name: 'productCustomAttributes',
    keyName: 'listId',
  })
  const { enqueueSnackbar } = useSnackbar()

  const [showDialog, setShowDialog] = useState(false)
  const [selectedCustomAttribute, setSelectedCustomAttribute] =
    useState<ProductCustomAttribute | null>(null)
  const [allProductCustomAttributes, setAllProductCustomAttributes] = useState<
    ProductCustomAttribute[]
  >([])

  const {
    loading: fetchLoading,
    data: customAttributesData,
    refetch,
  } = useQuery(AllCustomAttributesDocument)

  useEffect(() => {
    formContext.reset({
      productCustomAttributes: customAttributesData?.allCustomAttributes || [],
      deleteCustomAttributes: [],
      updateCustomAttributes: [],
    })
    setAllProductCustomAttributes(
      customAttributesData?.allCustomAttributes || [],
    )
  }, [customAttributesData, formContext])

  const edit = (productCustomAttribute: ProductCustomAttribute) => {
    trackButtonClick({
      name: 'settings-custom-attribute-edit',
    })

    setSelectedCustomAttribute(productCustomAttribute)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({
      name: 'settings-custom-attribute-add',
    })

    setSelectedCustomAttribute(null)
    setShowDialog(true)
  }

  const remove = (
    productCustomAttribute: ProductCustomAttribute,
    index: number,
  ) => {
    trackButtonClick({
      name: 'settings-custom-attribute-remove',

      productCustomAttributeId: productCustomAttribute.id,
    })

    removeCustomAttribute(index)

    if (productCustomAttribute.__typename) {
      formContext.setValue('deleteCustomAttributes', [
        ...formContext.getValues('deleteCustomAttributes'),
        productCustomAttribute.id,
      ])
    }
  }

  const [addUpdateCustomAttributesMutation, { loading: addLoading }] =
    useMutation(AddUpdateCustomAttributesDocument)

  const [deleteCustomAttributesMutation, { loading: deleteLoading }] =
    useMutation(DeleteCustomAttributesDocument)

  const isLoading = fetchLoading || addLoading || deleteLoading

  const onSubmit = async (data: ProductCustomAttributesForm) => {
    try {
      trackButtonClick({
        name: 'settings-custom-attribute-save',
      })
      let success = false

      if (data.updateCustomAttributes.length > 0) {
        const { data: updateResponse } =
          await addUpdateCustomAttributesMutation({
            variables: {
              data: data.updateCustomAttributes.map(
                ({ __typename, listId, ...x }): SingleCustomAttributeInput => x,
              ),
            },
          })

        success = !!updateResponse?.addUpdateCustomAttributes.some(
          (x) => x.success === true,
        )

        const allFailedCustomAttributes =
          updateResponse?.addUpdateCustomAttributes.filter(
            (x) => x.success === false,
          ) || []

        const missingEnumCustomAttributes = allFailedCustomAttributes?.filter(
          (x) => x.error?.code === 'CUSTOM_ATTRIBUTE_MISSING_ENUM_VALUES',
        )
        if (missingEnumCustomAttributes.length > 0) {
          enqueueSnackbar(
            t('settings:custom_attribute.error.custom_attribute_missing_enum', {
              count: missingEnumCustomAttributes.length,
              customAttribute: missingEnumCustomAttributes
                .map((x) => x.customAttribute.title)
                .join(', '),
            }),
            {
              variant: 'error',
            },
          )
        }

        const customAttributesLimitReached = allFailedCustomAttributes?.find(
          (x) => x.error?.code === 'CUSTOM_ATTRIBUTES_LIMIT_REACHED',
        )

        if (customAttributesLimitReached) {
          enqueueSnackbar(
            t(
              'settings:custom_attribute.error.custom_attributes_limit_reached',
            ),
            {
              variant: 'error',
            },
          )
        }

        const inUseFailedCustomAttributes = allFailedCustomAttributes?.filter(
          (x) => x.error?.code === 'CUSTOM_ATTRIBUTE_IN_USE',
        )

        if (inUseFailedCustomAttributes.length > 0) {
          enqueueSnackbar(
            t(
              'settings:custom_attribute.error.custom_attribute_in_use_update',
              {
                count: inUseFailedCustomAttributes.length,
                customAttribute: inUseFailedCustomAttributes
                  .map(
                    (x) =>
                      allProductCustomAttributes.find(
                        (y) => y.id === x.customAttribute.id,
                      )?.title,
                  )
                  .join(', '),
              },
            ),
            {
              variant: 'error',
            },
          )
        }

        const otherFailedCustomAttributes = allFailedCustomAttributes.filter(
          (x) =>
            ![
              ...missingEnumCustomAttributes,
              ...inUseFailedCustomAttributes,
            ].find((y) => y.customAttribute.id === x.customAttribute.id),
        )
        if (otherFailedCustomAttributes.length > 0) {
          enqueueSnackbar(
            t(
              'settings:custom_attribute.error.custom_attribute_add_update_generic',
              {
                count: otherFailedCustomAttributes.length,
                customAttribute: otherFailedCustomAttributes
                  .map((x) => x.customAttribute.title)
                  .join(', '),
              },
            ),
            {
              variant: 'error',
            },
          )
        }
      }

      if (data.deleteCustomAttributes.length > 0) {
        const { data: deleteResponse } = await deleteCustomAttributesMutation({
          variables: {
            data: {
              ids: data.deleteCustomAttributes,
            },
          },
        })

        if (!success) {
          success = !!deleteResponse?.deleteCustomAttributes.some(
            (x) => x.success === true,
          )
        }

        const allFailedCustomAttributes =
          deleteResponse?.deleteCustomAttributes.filter(
            (x) => x.success === false,
          ) || []

        const inUseFailedCustomAttributes = allFailedCustomAttributes?.filter(
          (x) => x.error?.code === 'CUSTOM_ATTRIBUTE_IN_USE',
        )
        if (inUseFailedCustomAttributes.length > 0) {
          enqueueSnackbar(
            t(
              'settings:custom_attribute.error.custom_attribute_in_use_delete',
              {
                count: inUseFailedCustomAttributes.length,
                customAttribute: inUseFailedCustomAttributes
                  .map(
                    (x) =>
                      allProductCustomAttributes.find(
                        (y) => y.id === x.customAttributeId,
                      )?.title,
                  )
                  .join(', '),
              },
            ),
            {
              variant: 'error',
            },
          )
        }

        const otherFailedCustomAttributes = allFailedCustomAttributes.filter(
          (x) =>
            !inUseFailedCustomAttributes.find(
              (y) => y.customAttributeId === x.customAttributeId,
            ),
        )
        if (otherFailedCustomAttributes.length > 0) {
          enqueueSnackbar(
            t(
              'settings:custom_attribute.error.custom_attribute_delete_generic',
              {
                count: otherFailedCustomAttributes.length,
                customAttribute: otherFailedCustomAttributes
                  .map(
                    (x) =>
                      allProductCustomAttributes.find(
                        (y) => y.id === x.customAttributeId,
                      )?.title,
                  )
                  .join(', '),
              },
            ),
            {
              variant: 'error',
            },
          )
        }
      }

      if (success) {
        trackFormSuccess({ name: 'settings-custom-attribute' })
        enqueueSnackbar(
          t('settings:custom_attribute.success_saved', {
            count:
              data.deleteCustomAttributes.length +
              data.updateCustomAttributes.length,
          }),
        )
      }

      await refetch()
    } catch {
      trackFormError({ name: 'settings-custom-attribute' })
    }
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsCustomAttributes}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.custom_attributes')}
        rightColumn={
          <>
            {writeSettingsCustomAttributes && (
              <LoadingButton
                disabled={!formContext.formState.isDirty}
                // eslint-disable-next-line @typescript-eslint/no-misused-promises
                onClick={formContext.handleSubmit(onSubmit)}
                data-testid="save-changes"
                loading={isLoading}
              >
                {t('shared:action.save')}
              </LoadingButton>
            )}
          </>
        }
      />
      <Container>
        <Box
          sx={{
            background: (theme) => theme.palette.background.paper,
          }}
        >
          <ProductSettingsList
            items={fields}
            baseDataTestId="custom-attribute"
            addText={t('settings:custom_attribute.add_custom_attribute')}
            loading={isLoading}
            canAdd
            onAdd={add}
            onDelete={(item, index) => remove(item, index)}
            onEdit={(item) => edit(item)}
            readOnly={!writeSettingsCustomAttributes}
            itemRender={(x) => (
              <ListItemText
                secondary={[
                  x.id,
                  `${
                    x.type === CustomAttributeTypeEnum.Integer
                      ? t('settings:custom_attribute.type_options.integer')
                      : t('settings:custom_attribute.type_options.string')
                  }${
                    x.searchable
                      ? ` (${t('settings:custom_attribute.searchable')})`
                      : ''
                  }`,
                  x.enums?.join(', '),
                ]
                  .filter((x) => !!x)
                  .join('\n')}
                secondaryTypographyProps={{
                  sx: {
                    whiteSpace: 'break-spaces',
                  },
                }}
              >
                {x.title}
              </ListItemText>
            )}
            emptyTitle={t('settings:custom_attribute.empty_title')}
            emptyDescription={t('settings:custom_attribute.empty_description')}
          />
        </Box>

        <CustomAttributeDialog
          open={showDialog}
          customAttribute={
            selectedCustomAttribute !== null
              ? selectedCustomAttribute
              : undefined
          }
          onClose={() => setShowDialog(false)}
          existingIds={[
            ...allProductCustomAttributes.map((x) => x.id),
            ...fields.map((x) => x.id),
          ]}
          onSuccess={(customAttribute) => {
            if (selectedCustomAttribute !== null) {
              updateCustomAttribute(
                fields.findIndex((x) => x.id === selectedCustomAttribute.id),
                customAttribute,
              )
              setSelectedCustomAttribute(null)
            } else {
              appendCustomAttribute(customAttribute)
            }

            const updatedCustomAttributes = formContext.getValues(
              'updateCustomAttributes',
            )
            const customAttributeIndex = updatedCustomAttributes.findIndex(
              (x) => x.id === customAttribute.id,
            )
            if (customAttributeIndex === -1) {
              formContext.setValue('updateCustomAttributes', [
                ...updatedCustomAttributes,
                customAttribute,
              ])
            } else {
              updatedCustomAttributes[customAttributeIndex] = customAttribute
              formContext.setValue(
                'updateCustomAttributes',
                updatedCustomAttributes,
              )
            }

            setShowDialog(false)
          }}
        />
      </Container>
    </>
  )
}
