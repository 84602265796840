import { useQuery } from '@apollo/client'
import { useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../components/data-grid/context'
import { StoresInput } from '../components/stores-input'
import { GetStoresDocument } from '../generated/graphql'
import { useStateParams } from '../hooks/state-params'

type QueryParamsState = {
  storeId?: string
}

export type StoreFilterContext = {
  storeId?: string
}

type Props = {
  useExternalId?: boolean
  hasAllStores?: boolean
}

export const StoreIdFilter = (props: Props) => {
  const { t } = useTranslation('filter')
  const { useExternalId, hasAllStores } = props
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'storeId'

  const { data, loading: isLoading } = useQuery(GetStoresDocument)

  const stores = useMemo(() => {
    return (
      data?.stores
        .filter((store) =>
          useExternalId ? store.externalid && store.warehouses?.length : true,
        )
        .map((store) => ({
          id: useExternalId ? String(store.externalid) : store.id,
          name: store.name,
        })) || []
    )
  }, [data?.stores, useExternalId])

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()

  const storeId = queryParams[filterKey]
    ? useExternalId
      ? queryParams[filterKey]
      : Number(queryParams[filterKey])
    : undefined

  const { control, watch } = useForm({
    values: { store: { id: storeId || null } },
  })

  useEffect(() => {
    if (isLoading) return

    const subscribe = watch((value) => {
      setQueryParams({
        [filterKey]: value.store?.id ? String(value.store?.id) : undefined,
      })
    })

    return () => subscribe.unsubscribe()
  }, [watch, isLoading, setQueryParams, storeId])

  useEffect(() => {
    if (isLoading) return

    const selectedStore = stores?.find(({ id }) => id === storeId)

    if (storeId) {
      setFilter(filterKey, {
        label: t('filter:store_filter.filter_store', {
          store: selectedStore?.name || '',
        }),
        value: storeId,
      })
    } else if (hasAllStores) {
      setFilter(filterKey, {
        isDefault: true,
        label: t(`filter:store_filter.all_stores`),
        value: undefined,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [storeId, removeFilter, setFilter, t, stores, isLoading, hasAllStores])

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <StoresInput
      name="store"
      control={control}
      stores={stores}
      isLoading={isLoading}
    />
  )
}
