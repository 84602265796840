import { useMutation, useQuery } from '@apollo/client'
import { Button } from '@mui/material'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { enqueueSnackbar } from 'notistack'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { UserTagsInput } from '../../../components/user-tags-input'
import {
  GetUserTagsDocument,
  GetUsersDocument,
  UpdateUsersDocument,
  UserTag,
} from '../../../generated/graphql'

export enum UpdateUsersTagsActions {
  Add,
  Remove,
}

type Props = {
  open: boolean
  onClose(): void
  action: UpdateUsersTagsActions
}

type Form = {
  tags: UserTag[]
}

export const UpdateUsersTagsDialog = ({ open, onClose, action }: Props) => {
  const { t } = useTranslation(['users', 'shared'])
  const { data: userTagData } = useQuery(GetUserTagsDocument)
  const { control, handleSubmit, watch, reset } = useForm<Form>()

  const apiRef = useGridApiContext()

  const selectedUsers = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<{
      id: string
      tags: string[]
    }>[]
  ).map((c) => ({ userid: c.id, tags: c.tags }))

  const [updateUsers, { loading: isUpdatingUsers }] = useMutation(
    UpdateUsersDocument,
    { refetchQueries: [GetUsersDocument] },
  )

  const handleOnClose = () => {
    reset()
    onClose()
  }

  const onSubmit = async (submittedData: Form) => {
    const submittedTagIds = submittedData.tags.map((tag) => tag.id)

    if (action === UpdateUsersTagsActions.Add) {
      selectedUsers.forEach((user) => {
        user.tags = [...new Set([...user.tags, ...submittedTagIds])]
      })
    }

    if (action === UpdateUsersTagsActions.Remove) {
      selectedUsers.forEach((user) => {
        user.tags = user.tags.filter((tag) => !submittedTagIds.includes(tag))
      })
    }

    try {
      await updateUsers({ variables: { users: selectedUsers } })
      enqueueSnackbar(
        t('users:user_message.success_update', { count: selectedUsers.length }),
      )
    } catch (error) {
      enqueueSnackbar(
        t('users:user_message.failure_update', { count: selectedUsers.length }),
        { variant: 'error' },
      )
    }
    handleOnClose()
  }

  const isDisabled = !watch('tags')?.length || isUpdatingUsers

  return (
    <Dialog open={open} onClose={handleOnClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {action === UpdateUsersTagsActions.Add
          ? t('users:user_tags.add_tags_to_users')
          : t('users:user_tags.remove_tags_from_users')}
      </DialogTitle>
      <DialogContent>
        <UserTagsInput
          name="tags"
          control={control}
          userTags={userTagData?.getUserTags}
        />
      </DialogContent>
      <DialogActions>
        <Button
          color="secondary"
          onClick={handleOnClose}
          data-testid="dialog-cancel-user-tag"
        >
          {t('shared:action.cancel')}
        </Button>

        <Button
          color={action === UpdateUsersTagsActions.Add ? 'primary' : 'error'}
          // eslint-disable-next-line @typescript-eslint/no-misused-promises
          onClick={handleSubmit(onSubmit)}
          data-testid="dialog-add-user-tags"
          disabled={isDisabled}
        >
          {action === UpdateUsersTagsActions.Add
            ? t('shared:action.add')
            : t('shared:action.remove')}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
