import {
  Box,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Switch,
} from '@mui/material'
import {
  GRID_CHECKBOX_SELECTION_FIELD,
  gridColumnDefinitionsSelector,
  GridColumnResizeParams,
  gridColumnVisibilityModelSelector,
  useGridApiContext,
  useGridSelector,
} from '@mui/x-data-grid-pro'
import {
  MoreVerticalIcon,
  ReturnIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { MouseEventHandler, ReactNode, useCallback, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DataGridImageRenderer } from '../data-grid-image'
import { COLUMN_HEADER_HEIGHT, RESET_COLUMN_WIDTH_EVENT } from './utils'

type CustomToolbarProps = {
  children?: ReactNode
  disableColumnVisibilityControl?: boolean
}

export const CustomToolbar = (props: CustomToolbarProps) => {
  const { t } = useTranslation('shared')
  const apiRef = useGridApiContext()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }

  const resetGridState = useCallback(
    (event: React.MouseEvent<HTMLDivElement>) => {
      apiRef.current.publishEvent(
        'columnResize',
        {
          colDef: { field: RESET_COLUMN_WIDTH_EVENT },
        } as GridColumnResizeParams,
        event.nativeEvent,
      )
      closeMenu()
    },
    [apiRef],
  )

  const columns = useGridSelector(apiRef, gridColumnDefinitionsSelector).filter(
    (column) => column.headerName,
  )

  const columnVisibility = useGridSelector(
    apiRef,
    gridColumnVisibilityModelSelector,
  )

  return (
    <Box
      sx={{
        position: 'absolute',
        zIndex: 1,
        height: COLUMN_HEADER_HEIGHT - 1,
        right: 0,
        background: (theme) => theme.palette.background.paper,
        display: 'grid',
        alignItems: 'center',
      }}
    >
      {props.children}

      {!props.disableColumnVisibilityControl && (
        <>
          <IconButton onClick={handleClick} data-testid="custom-toolbar-button">
            <MoreVerticalIcon />
          </IconButton>
          <Menu
            data-testid="user-menu"
            anchorEl={anchorEl}
            open={isOpen}
            onClose={closeMenu}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            MenuListProps={{
              'aria-labelledby': 'user-menu-button',
            }}
          >
            <List sx={{ width: 280 }} disablePadding>
              <ListItemButton
                onClick={resetGridState}
                data-testid="reset-column-width-button"
              >
                <ListItemText>
                  {t('shared:label.reset_grid_state')}
                </ListItemText>
                <ListItemIcon>
                  <ReturnIcon />
                </ListItemIcon>
              </ListItemButton>

              <ListItem>
                <ListItemText>
                  <SectionHeader variant="transparent" sx={{ p: 0 }}>
                    {t('label.columns')}
                  </SectionHeader>
                </ListItemText>
              </ListItem>

              {columns.map((column) => {
                if (
                  column.field === GRID_CHECKBOX_SELECTION_FIELD ||
                  column.renderCell?.name === DataGridImageRenderer.name
                ) {
                  return null
                }

                return (
                  <ListItemButton
                    data-testid={`${column.field}-button`}
                    key={column.field}
                    disabled={column.hideable === false}
                    onClick={() => {
                      apiRef.current.setColumnVisibility(
                        column.field,
                        columnVisibility[column.field] === undefined
                          ? false
                          : columnVisibility[column.field] === false,
                      )
                    }}
                  >
                    <ListItemText>{column.headerName}</ListItemText>

                    <Switch
                      data-testid={`${column.field}-switch`}
                      checked={
                        columnVisibility[column.field] === undefined ||
                        columnVisibility[column.field] === true
                      }
                      disabled={column.hideable === false}
                    />
                  </ListItemButton>
                )
              })}
            </List>
          </Menu>
        </>
      )}
    </Box>
  )
}
