import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  GetOrdersQueryVariables,
  GetOrdersSort,
  OrderState,
  OrderType,
  GetOrdersDocument,
  GetOrdersQuery,
} from '../../../generated/graphql'
import { OrdersIcon } from '@sitoo/mui-components'
import { PaymentStateRenderer } from './payment-state'
import { TotalRenderer } from './total'
import { useMemo, useContext, useCallback } from 'react'
import { DEFAULT_ORDER_ID } from '../../../utils/constants'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { OrderAdditionalData } from '../util'
import { IdType } from './order-id-filter'
import { PaymentsRenderer } from './payments-renderer'
import { DateTimeRenderer } from '../../../components/data-grid/utils/date-time-renderer'
import { BulkActions } from './bulk-actions'
import { ArrayElement } from '../../../utils/types'

export type Row = ArrayElement<GetOrdersQuery['orders']['items']>

type OrderListProps = {
  orderState?: OrderState
  onDetailOrder(orderId: number): void
  currentDetailedOrderId?: number
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const exportOrdersLoadingVar = makeVar(false)

export const OrderList = (props: OrderListProps) => {
  const { t } = useTranslation(['orders', 'shared'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()

  const sortItems = useMemo<SortItem<GetOrdersSort>[]>(
    () => [
      {
        field: 'orderid',
        sort: 'asc',
        title: t('orders:id'),
        type: 'number',
        value: GetOrdersSort.orderid_asc,
      },
      {
        field: 'orderid',
        sort: 'desc',
        title: t('orders:id'),
        type: 'number',
        value: GetOrdersSort.orderid_desc,
        isDefault: true,
      },
    ],
    [t],
  )

  const columnVisibilityModel = useMemo(
    () => ({
      id: false,
      'reserve-until': false,
      paymentstateid: false,
      'custom-customer': false,
      'custom-staff': false,
      'custom-register': false,
      orderdatetime: false,
    }),
    [],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const apiRef = useGridApiRef()

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        page: 0,
        start: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetOrdersQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      orderstate: props.orderState,
      //  Get only orders
      ordertype: OrderType.Order,
    }

    if (
      Array.isArray(config.filter?.['id']?.value) &&
      config.filter?.['id']?.value.length === 2
    ) {
      const [type, value] = config.filter?.['id']?.value as [
        IdType,
        string | string[],
      ]

      if (type === IdType.OrderId) {
        variables.orderid = Array.isArray(value)
          ? value.map((x) => Number(x) || DEFAULT_ORDER_ID)
          : Number(value) || DEFAULT_ORDER_ID
      }

      if (type === IdType.ExternalId) {
        variables.externalid = value
      }

      if (type === IdType.ReceiptId) {
        variables.receiptid = Array.isArray(value) ? value?.[0] : value
      }

      if (type === IdType.CreditOrderId) {
        variables.creditorderid =
          (Array.isArray(value) ? Number(value?.[0]) : Number(value)) ||
          DEFAULT_ORDER_ID
      }

      return variables
    }

    if (typeof config.filter?.['date']?.value === 'string') {
      variables.orderdatefrom = config.filter['date']?.value
    } else if (Array.isArray(config.filter?.['date']?.value)) {
      const value = config.filter?.['date']?.value

      if (typeof value?.[0] === 'string') {
        variables.orderdatefrom = value[0]
      }

      if (typeof value?.[1] === 'string') {
        variables.orderdateto = value[1]
      }
    }

    if (Array.isArray(config.filter?.['warehouseIds']?.value)) {
      variables.warehouseId = config.filter?.['warehouseIds']?.value
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }
    return variables
  }, [apiRef, filter, props.orderState, sortItems])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetOrdersDocument, {
    fetchPolicy: 'cache-and-network',
    notifyOnNetworkStatusChange: true,
    variables: queryVariables,
    skip: !isFilterReady,
  })

  const isExportLoading = useReactiveVar(exportOrdersLoadingVar)

  const isLoading = fetchLoading || !isFilterReady || isExportLoading

  const dataGridColumns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'orderid',
        minWidth: 80,
        headerName: t('orders:id'),
        valueGetter: (_value, row): string => {
          return `#${row.orderid}`
        },
      },
      {
        field: 'externalid',
        minWidth: 120,
        headerName: t('orders:external_id'),
      },
      {
        field: 'custom-receipt-id',
        minWidth: 120,
        headerName: t('orders:receipt_id'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['pos-receiptid'] || ''
        },
      },
      {
        field: 'orderdate',
        ...ColumnProps.date,
        headerName: t('orders:date'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
      {
        field: 'orderdatetime',
        ...ColumnProps.date,
        headerName: t('orders:date_time'),
        valueGetter: (_value, row) => row.orderdate,
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'paymentstateid',
        minWidth: 120,
        headerName: t('orders:payment_state_label'),
        renderCell: PaymentStateRenderer,
      },
      {
        field: 'payments',
        minWidth: 240,
        headerName: t('orders:payments'),
        renderCell: PaymentsRenderer,
      },
      {
        field: 'moneytotal_gross_all',
        minWidth: 120,
        headerName: t('orders:total'),
        renderCell: TotalRenderer,
      },
      {
        field: 'custom-customer',
        minWidth: 120,
        headerName: t('orders:customer'),
        valueGetter: (_value, row): string => {
          return [row.namefirst, row.namelast].filter(Boolean).join(' ')
        },
      },
      {
        field: 'custom-store',
        minWidth: 120,
        headerName: t('orders:store'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['store-name'] || ''
        },
      },
      {
        field: 'custom-staff',
        minWidth: 120,
        headerName: t('orders:staff'),
        valueGetter: (_value, row): string => {
          const additionalData = row.additionaldata as OrderAdditionalData

          return additionalData['pos-staff-externalid'] || ''
        },
      },
      {
        field: 'custom-register',
        minWidth: 120,
        headerName: t('orders:register'),
        valueGetter: (_value, row): string =>
          row.cashRegister?.registerkey || '',
      },
      {
        field: 'datereserved',
        ...ColumnProps.date,
        headerName: t('orders:reserve_until'),
        renderCell: (params) => (
          <RelativeDateRenderer
            {...params}
            formatDate={formatDate}
            formatRelativeDate={formatRelativeDate}
          />
        ),
      },
    ],
    [formatDate, formatRelativeDate, t],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.orders.totalcount) {
      return fetchMore({
        variables: {
          start: (data.orders?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  return (
    <DataGrid
      name="orders-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.orders.items}
      rowCount={data?.orders.totalcount || 0}
      loading={isLoading}
      getRowId={(row) => row.orderid}
      onRowClick={(row) => props.onDetailOrder(Number(row.id))}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <OrdersIcon />,
        title: t('orders:grid.empty_title'),
        description: t('orders:grid.empty_description'),
      }}
      bulkAction={<BulkActions />}
      slots={{
        footer: CursorPagination,
      }}
      sx={{
        '.filter-row-container': {
          gridTemplateColumns: 'auto auto auto 1fr auto',
        },
      }}
      onShowFilter={props.onShowFilter}
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      showMore={
        Number(data?.orders.items?.length) < Number(data?.orders.totalcount)
      }
      getRowClassName={(params) => {
        const classes = []
        if (params.id === props.currentDetailedOrderId) {
          classes.push('Mui-selected')
        }

        if (params.row.orderstateid !== OrderState.Open) {
          classes.push('disabled')
        }

        return classes.join(' ')
      }}
    />
  )
}
