import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, MenuItem, TextField } from '@mui/material'
import {
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { Controller, SubmitErrorHandler, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { paymentOptionsViewPanelVar } from '.'
import { RootRoute } from '../../..'
import { FieldHighlight } from '../../../../components/field-highlight'
import {
  AddPaymentTypeDocument,
  AllPaymentTypesDocument,
  DeletePaymentTypeDocument,
  GetPaymentTypeDocument,
  GetPaymentTypeQuery,
  PosPaymentMethod,
  UpdatePaymentTypeDocument,
} from '../../../../generated/graphql'
import { useAbsolutePath } from '../../../../hooks/absolute-path'
import { useServerValidation } from '../../../../hooks/server-validation'
import { useTracking } from '../../../../hooks/tracking'
import { getErrorMessages } from '../../../../utils/error-mapping'
import { SelectInput } from '../../../../components/select-input'
import { TextFieldInput } from '../../../../components/text-field-input'

type PaymentType = GetPaymentTypeQuery['getPaymentType']

type PaymentOptionForm = Omit<
  PaymentType,
  'paymenttypeid' | 'paymentmethodid'
> & { paymentmethodid: PosPaymentMethod | '' }

export const PaymentOptionDialog = () => {
  const { t } = useTranslation(['shared', 'pos_payment'])
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const { id } = useParams()
  const paymentOptionId = Number(id)
  const isNewPaymentOption = !paymentOptionId
  const [showPaymentOptionDialog, setShowPaymentOptionDialog] = useState(true)
  const [showDeleteDialog, setDeleteDialog] = useState(false)
  const dialogName = 'payment-option-dialog'
  const formId = 'payment-option-form'

  const { data, loading: fetchLoading } = useQuery(GetPaymentTypeDocument, {
    variables: { id: Number(paymentOptionId) },
    fetchPolicy: 'cache-and-network',
    skip: !paymentOptionId,
  })

  const [updatePaymentType, { loading: isUpdatingPaymentType }] = useMutation(
    UpdatePaymentTypeDocument,
    { refetchQueries: [AllPaymentTypesDocument] },
  )
  const [addPaymentType, { loading: isAddingPaymentType }] = useMutation(
    AddPaymentTypeDocument,
    { refetchQueries: [AllPaymentTypesDocument] },
  )

  const [deletePaymentType, { loading: isDeletingPaymentType }] = useMutation(
    DeletePaymentTypeDocument,
    {
      variables: {
        deletePaymentTypeId: paymentOptionId,
      },
      refetchQueries: [AllPaymentTypesDocument],
    },
  )

  const { formState, reset, control, register, handleSubmit, watch, setError } =
    useForm<PaymentOptionForm>({ defaultValues: { paymentmethodid: '' } })

  const setFormError = useServerValidation<PaymentOptionForm>('', setError)

  useEffect(() => {
    if (isNewPaymentOption) {
      reset(undefined, { keepDefaultValues: true })
    }
    if (data?.getPaymentType) {
      reset(data.getPaymentType)
    }
  }, [data, isNewPaymentOption, reset])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  const paymentMethods = useMemo(() => {
    return Object.values(PosPaymentMethod).map((value) => ({
      value,
      name: t(`pos_payment:payment_method.${value}`),
    }))
  }, [t])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    navigate(generatePath(RootRoute.SettingsPosPaymentOptions))
  }

  const onSubmit = async (paymentOption: PaymentOptionForm) => {
    if (paymentOption.paymentmethodid === '') {
      setError('paymentmethodid', {
        message: t('shared:error.field_is_required', {
          property: 'paymentmethodid',
        }),
      })
      return
    }
    try {
      if (isNewPaymentOption) {
        await addPaymentType({
          variables: {
            paymentType: paymentOption as PaymentType,
          },
        })
      } else {
        await updatePaymentType({
          variables: {
            paymentType: {
              ...(paymentOption as PaymentType),
              paymenttypeid: paymentOptionId,
            },
          },
        })
      }
      trackDialogClose({ name: dialogName })
      enqueueSnackbar(
        t(
          isNewPaymentOption
            ? 'pos_payment:dialog_add_success'
            : 'pos_payment:dialog_update_success',
        ),
      )
      onClose()
    } catch (error) {
      setFormError(error)

      const errorMessage = getErrorMessages(error, {
        resolve: (errorCode) => `pos_payment:error.${errorCode}`,
        fallbackError: isNewPaymentOption
          ? t('pos_payment:dialog_add_failure')
          : t('pos_payment:dialog_update_failure'),
      }).join(', ')

      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    }
  }

  const handleDeletePaymentType = async () => {
    try {
      await deletePaymentType()
      trackDialogClose({ name: dialogName })
      enqueueSnackbar(t('pos_payment:dialog_delete_success'))
      paymentOptionsViewPanelVar({ isOpen: false })
      navigate(generatePath(RootRoute.SettingsPosPaymentOptions), {
        replace: true,
      })
    } catch {
      enqueueSnackbar(
        t('pos_payment:dialog_delete_failure', { variant: 'error' }),
      )
    }
  }

  const onError: SubmitErrorHandler<PaymentOptionForm> = (_errors) => {
    enqueueSnackbar(t('pos_payment:error.generic'), { variant: 'error' })
  }

  const onDeleteDialogOpen = () => {
    setDeleteDialog(true)
    setShowPaymentOptionDialog(false)
  }

  const onDeleteDialogClose = () => {
    setDeleteDialog(false)
    setShowPaymentOptionDialog(true)
  }

  const isLoading =
    fetchLoading ||
    isUpdatingPaymentType ||
    isAddingPaymentType ||
    isDeletingPaymentType

  return (
    <>
      <Dialog
        open={showPaymentOptionDialog}
        maxWidth="xs"
        fullWidth
        onClose={onClose}
      >
        <DialogTitle>
          {isNewPaymentOption
            ? t('pos_payment:add_payment_option')
            : t('pos_payment:edit_payment_option')}
        </DialogTitle>
        <DialogContent>
          {/* eslint-disable-next-line @typescript-eslint/no-misused-promises */}
          <form onSubmit={handleSubmit(onSubmit, onError)} id={formId}>
            <TextFieldInput
              name="name"
              control={control}
              rules={{ required: true }}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'payment-option-input-name' }}
              label={t('pos_payment:name')}
            />

            <SelectInput
              name="paymentmethodid"
              control={control}
              options={paymentMethods}
              label={t('pos_payment:method')}
              helperText={t('pos_payment:method_helper_text')}
              disabled={!isNewPaymentOption}
            />

            <TextFieldInput
              name="externalid"
              control={control}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'payment-option-input-externalid' }}
              label={t('pos_payment:external_id')}
            />

            {!isLoading && !watch('externalid') && (
              <FieldHighlight
                label={t('shared:validation.external_id_warning')}
                sx={{ mt: -1, mb: 2 }}
                dataTestId="no-external-id-highlight"
              />
            )}
            {!isNewPaymentOption && (
              <LoadingButton
                fullWidth
                loading={isLoading}
                color="error"
                sx={{ mt: 2 }}
                onClick={onDeleteDialogOpen}
              >
                {t('shared:action.delete')}
              </LoadingButton>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            data-testid="dialog-cancel-payment-option"
          >
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={handleSubmit(onSubmit, onError)}
            data-testid="dialog-add-save-payment-option-button"
            form={formId}
          >
            {isNewPaymentOption
              ? t('shared:action.add')
              : t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>
      <ConfirmationDialog
        confirmAction={handleDeletePaymentType}
        title={t('pos_payment:dialog_delete_label')}
        text={t('pos_payment:dialog_delete_description', {
          paymentOption: data?.getPaymentType.name,
        })}
        variant="destructive"
        open={showDeleteDialog}
        onClose={onDeleteDialogClose}
      />
    </>
  )
}
