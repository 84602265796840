import { IconButton } from '@mui/material'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { DeleteIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteProductDialog } from '../../delete-product-dialog'

type DeleteProductProps = {
  onSuccess: (productIds: number[]) => void
  refetch(): unknown
}

export const DeleteProduct = (props: DeleteProductProps) => {
  const { t } = useTranslation(['products'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedProductIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<{
      id: number
    }>[]
  ).map((c) => c.id)

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (
    successCampaignIds: number[],
    failedCampaignIds: number[],
  ) => {
    props.refetch()

    if (successCampaignIds.length) {
      enqueueSnackbar(
        t('products:success_delete_product_snackbar', {
          count: successCampaignIds.length,
        }),
      )
      props.onSuccess(successCampaignIds)
    }

    if (failedCampaignIds.length) {
      enqueueSnackbar(
        t('products:failure_delete_product_snackbar', {
          count: failedCampaignIds.length,
        }),
        { variant: 'error' },
      )
    }

    onClose()
  }

  const onError = () => {
    props.refetch()
    onClose()
  }

  return (
    <>
      <DeleteProductDialog
        open={isDialogOpen}
        onClose={onClose}
        productIds={selectedProductIds}
        onSuccess={onSuccess}
        onError={onError}
      />
      <IconButton
        onClick={() => setIsDialogOpen(true)}
        data-testid="bulk-delete-products-button"
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}
