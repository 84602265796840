import { Typography, Box } from '@mui/material'
import { useGridApiContext } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'

export const CustomRowCount = () => {
  const { t } = useTranslation('shared')

  const apiRef = useGridApiContext()
  const rowsInTable = apiRef.current.getRowModels().size

  if (rowsInTable === 0) return null

  return (
    <Box
      sx={{
        width: '100%',
        bgcolor: 'gray10',
        borderTop: (theme) => `2px solid ${theme.palette.gray30}`,
        display: 'flex',
        alignItems: 'center',
        p: 0.5,
      }}
    >
      <Typography
        variant="caption"
        color="gray70"
        sx={{
          pointerEvents: 'none',
        }}
      >
        {t('shared:grid.showing')} <b>{rowsInTable}</b>{' '}
        {t('shared:grid.result', { count: rowsInTable })}
      </Typography>
    </Box>
  )
}
