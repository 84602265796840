import { Typography } from '@mui/material'
import { Box } from '@mui/system'
import { useTranslation } from 'react-i18next'
import { GetOrderQuery } from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'

type OrderHeaderProps = {
  order?: GetOrderQuery['order']
  isReturn: boolean
}

export const OrderHeader = ({ order, isReturn }: OrderHeaderProps) => {
  const { t } = useTranslation(['orders'])
  const { formatDate } = useLocalizedDate()

  if (!order) return

  const returnIsValidated = !!order.additionaldata['pos-originalsales-orderid']

  return (
    <Box data-testid="order-print-header">
      <Typography variant="displayLarge">
        {t('print.order', { orderId: order.orderid })}
      </Typography>
      {isReturn && (
        <Typography variant="displayLarge">
          {returnIsValidated
            ? t('orders:print.validated_return')
            : t('orders:print.unvalidated_return')}
        </Typography>
      )}
      <Typography variant="caption">
        {t('print.order_date', {
          orderDate: formatDate(order.orderdate),
        })}
      </Typography>
    </Box>
  )
}
