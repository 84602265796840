import { useQuery } from '@apollo/client'
import { ListItemText, Stack } from '@mui/material'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../components/data-grid/context'
import { UserTagsInput } from '../../../components/user-tags-input'
import { GetUserTagsDocument, UserTag } from '../../../generated/graphql'
import { useStateParams } from '../../../hooks/state-params'

type QueryParamsState = {
  userTagIds?: string[]
}

export type UserTagFilterContext = {
  tags?: UserTag[]
}

export const UserTagsFilter = () => {
  const { t } = useTranslation('users')

  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)

  const filterKey = 'userTagIds'

  const [queryParams, setQueryParams] = useStateParams<QueryParamsState>()
  const { data: userTags, loading: isLoading } = useQuery(GetUserTagsDocument)

  const userTagIds = queryParams[filterKey] || undefined

  const tagsFromParams = useMemo(
    () => userTags?.getUserTags.filter((tag) => userTagIds?.includes(tag.id)),
    [userTagIds, userTags?.getUserTags],
  )

  const { control, watch } = useForm<UserTagFilterContext>({
    values: { tags: tagsFromParams },
  })

  useEffect(() => {
    if (isLoading) return
    const subscribe = watch(({ tags }) => {
      setQueryParams({
        [filterKey]: tags ? tags.map((tag) => tag?.id as string) : undefined,
      })
    })
    return () => subscribe.unsubscribe()
  }, [watch, setQueryParams, isLoading, userTagIds?.length])

  useEffect(() => {
    if (userTagIds?.length) {
      const labelArray = tagsFromParams?.map((tag) => tag.label)
      setFilter(filterKey, {
        label: t('users:user_tags.filter_tag', {
          label: labelArray?.join(', ') || '',
        }),
        value: tagsFromParams,
      })
    } else {
      setFilter(filterKey, {
        isDefault: true,
        label: t(`users:user_tags.all_tags`),
        value: undefined,
      })
    }
  }, [removeFilter, setFilter, t, tagsFromParams, userTagIds])

  useEffect(() => {
    registerFilter({ key: filterKey })
  }, [isLoading, registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) {
        setQueryParams({ [filterKey]: undefined })
      }
    })

    return () => unsubscribe()
  }, [setQueryParams, subscribeOnResetFilter])

  return (
    <Stack gap={1} p={2} pb={1}>
      <ListItemText>{t('users:user_tags.tags')}</ListItemText>
      <UserTagsInput
        name="tags"
        control={control}
        userTags={userTags?.getUserTags}
      />
    </Stack>
  )
}
