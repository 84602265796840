import { useQuery } from '@apollo/client'
import { Autocomplete, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { AllPricelistTagsDocument } from '../../../../generated/graphql'

/**
 * @deprecated Should be decoupled to a common component. See `StoreTagsInput`
 */
export const PricelistTagsField = () => {
  const { t } = useTranslation('campaigns')
  const { data } = useQuery(AllPricelistTagsDocument)
  const { control, formState } = useFormContext<CampaignFormContext>()

  return (
    <Controller
      control={control}
      name="campaign.pricelisttags"
      rules={{ required: true }}
      render={({ field }) => (
        <Autocomplete
          autoHighlight
          multiple
          options={data?.allPricelistTags || []}
          onChange={(_event, value) => {
            field.onChange(value)
          }}
          value={field.value || []}
          data-testid="campaign-pricelisttags-field"
          renderInput={(params) => (
            <TextField
              {...params}
              error={!!formState.errors.campaign?.pricelisttags}
              sx={{ px: 2, mb: 2 }}
              placeholder={
                field.value?.length
                  ? undefined
                  : t(
                      'campaign_form.pricelist_tags_selection_custom_placeholder',
                    )
              }
            />
          )}
        />
      )}
    />
  )
}
