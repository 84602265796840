import {
  useCallback,
  useDeferredValue,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { useSearchParams } from 'react-router-dom'
import { PaginationProps } from '../types'

type UsePaginationProps = {
  pagination: PaginationProps
  updateSearchParams?: boolean
}

export const PAGE_KEY = 'page'

export const getPaginationFromSearchParams = (
  pagination: string | undefined | null,
) => {
  return Number.isNaN(Number(pagination || undefined))
    ? 0
    : Number(pagination) - 1
}

export const usePagination = (props: UsePaginationProps) => {
  const [pagination, setPagination] = useState<PaginationProps>(
    props.pagination,
  )
  const [searchParams, setSearchParams] = useSearchParams()

  const updateSearchParams =
    props.updateSearchParams === undefined ? true : props.updateSearchParams

  const searchParamsPage = useMemo(
    () => getPaginationFromSearchParams(searchParams.get(PAGE_KEY)),
    [searchParams],
  )

  useEffect(() => {
    if (!updateSearchParams) return

    if (pagination.page !== searchParamsPage) {
      setPagination((p) => ({ ...p, page: searchParamsPage }))
    }
  }, [pagination.page, searchParamsPage, updateSearchParams])

  // We need to debounce the pagination state, because MUI Grid calculates the final page
  // and it can lead to some triggers in the query
  const deferredPagination = useDeferredValue(pagination)

  const onPageChange = useCallback(
    (page: number) => {
      if (updateSearchParams) {
        if (page > 0 && searchParams.get(PAGE_KEY) !== String(page + 1)) {
          searchParams.set(PAGE_KEY, String(page + 1))
          setSearchParams(searchParams)
        } else if (searchParams.get(PAGE_KEY) !== null) {
          searchParams.delete(PAGE_KEY)
          setSearchParams(searchParams)
        }
      }

      setPagination((prev) => {
        if (prev.page === page) return prev
        return { ...prev, page }
      })
    },
    [searchParams, setSearchParams, updateSearchParams],
  )

  const onPageSizeChange = useCallback((pageSize: number) => {
    setPagination((prev) => {
      if (prev.pageSize === pageSize) return prev

      return { ...prev, pageSize }
    })
  }, [])

  return {
    pagination,
    onPageChange,
    onPageSizeChange,
    deferredPagination,
  }
}
