/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { validateEmail } from '../../../utils/validate'
import { GenericShipmentContactFields } from '../shared'

export type ContactDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  contact?: GenericShipmentContactFields | null
  onClose(): void
  onSubmit?(contact: GenericShipmentContactFields): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
}

type Form = GenericShipmentContactFields

export const SetContactDialog = (props: ContactDialogProps) => {
  const { t } = useTranslation(['shipments_v2', 'shared'])
  const dialogName = 'set-contact-v2'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, register, setError } = useForm<Form>({
    mode: 'onChange',
  })
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })
      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.contact })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.contact, reset])

  return (
    <Dialog open={props.open} onClose={onClose} maxWidth="sm" fullWidth>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t(
            props.action === 'add'
              ? 'shipments_v2:set_contact_dialog.add_title'
              : 'shipments_v2:set_contact_dialog.edit_title',
          )}
        </DialogTitle>
        <DialogContent>
          <TextField
            error={!!formState?.errors?.referencename}
            fullWidth
            helperText={formState?.errors?.referencename?.message}
            label={t('shipments_v2:shipment_form.name')}
            {...register('referencename')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.referenceemail}
            fullWidth
            helperText={formState?.errors?.referenceemail?.message}
            label={t('shipments_v2:shipment_form.email')}
            {...register('referenceemail', {
              validate: (value) =>
                value && !validateEmail(value)
                  ? t('shipments_v2:error.invalid_email')
                  : true,
            })}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.referencemobile}
            fullWidth
            helperText={
              formState?.errors?.referencemobile?.message ||
              t('shipments_v2:shipment_form.mobile_description')
            }
            label={t('shipments_v2:shipment_form.mobile')}
            {...register('referencemobile', {
              setValueAs: (value: string) => value?.replace(/ /g, ''),
            })}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.instructions}
            fullWidth
            helperText={formState?.errors?.instructions?.message}
            label={t('shipments_v2:shipment_form.instructions')}
            {...register('instructions')}
            multiline
            rows={4}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            variant="secondary"
            size="compact"
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-set`,
            })}
            disabled={!formState.isValid}
            loading={loading}
          >
            {t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
