import { Select, MenuItem, Typography } from '@mui/material'
import { t } from 'i18next'
import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import {
  GetStoresDocument,
  GetWarehousesDocument,
} from '../../../generated/graphql'

export type QueryParamsState = {
  storeId?: number
  warehouseToId?: number
  warehouseFromId?: number
}

type Props = {
  storeId?: number
  warehouseToId?: number
  warehouseFromId?: number
  setQueryParams: (state: QueryParamsState) => void
}

export const StoreFilter = (props: Props) => {
  const { data: storesData } = useQuery(GetStoresDocument)

  const { data } = useQuery(GetWarehousesDocument)
  const allWarehouses = data?.allWarehouses

  const { setQueryParams } = props

  const stores = useMemo(
    () => storesData?.stores?.filter(({ warehouses }) => warehouses?.length),
    [storesData?.stores],
  )

  const storeId = useMemo(() => {
    return stores?.find(({ id }) => id === props.storeId)?.id
  }, [props.storeId, stores])

  const warehouses = allWarehouses
    ?.filter((warehouse) => warehouse.sellable)
    .filter((warehouse) => (storeId ? warehouse.storeid === storeId : true))

  const warehouseToId = useMemo(() => {
    if (props.warehouseToId === undefined) {
      return warehouses?.at(0)?.id
    }

    return warehouses?.find(({ id }) => id === props.warehouseToId)?.id
  }, [props.warehouseToId, warehouses])

  const warehouseFromId = useMemo(() => {
    if (warehouseToId === props.warehouseFromId) {
      return
    }

    return warehouses?.find(({ id }) => id === props.warehouseFromId)?.id
  }, [props.warehouseFromId, warehouseToId, warehouses])

  const hasWarehouses = Boolean(warehouses?.length)

  return (
    <>
      <Select<number | string>
        value={stores?.some(({ id }) => id === storeId) ? storeId : ''}
        onChange={(event) => {
          setQueryParams({
            storeId: event.target.value
              ? Number(event.target.value)
              : undefined,
            warehouseToId: undefined,
            warehouseFromId: undefined,
          })
        }}
        displayEmpty
        data-testid="store-replenishment-store-select"
        inputProps={{
          ['data-testid']: 'store-replenishment-store-input',
        }}
      >
        <MenuItem value={''} data-testid="store-replenishment-store-button-0">
          <Typography variant="body02">
            {t('stock:stock_levels.all_stores')}
          </Typography>
        </MenuItem>
        {stores?.map(({ id, name }) => (
          <MenuItem
            value={String(id)}
            key={id}
            data-testid={`store-replenishment-store-button-${id}`}
          >
            <Typography variant="body02">{name}</Typography>
          </MenuItem>
        ))}
      </Select>

      <Select<number | string>
        disabled={!hasWarehouses}
        value={warehouseToId || ''}
        onChange={(event) => {
          setQueryParams({
            storeId,
            warehouseToId: event.target.value
              ? Number(event.target.value)
              : undefined,
            warehouseFromId: undefined,
          })
        }}
        displayEmpty
        data-testid="store-replenishment-warehouse-select"
        inputProps={{
          ['data-testid']: 'store-replenishment-warehouse-input',
        }}
      >
        <MenuItem
          value=""
          disabled
          data-testid="store-replenishment-warehouse-button-0"
        >
          <Typography variant="body02">
            {t('stock:in_store_replenishment.warehouse_to')}
          </Typography>
        </MenuItem>

        {warehouses?.map(({ id, name }) => (
          <MenuItem
            value={String(id)}
            key={id}
            data-testid={`store-replenishment-warehouse-button-${id}`}
          >
            <Typography variant="body02">{name}</Typography>
          </MenuItem>
        ))}
      </Select>

      <Select<number | string>
        disabled={!hasWarehouses}
        value={
          warehouses?.map(({ id }) => id).includes(Number(warehouseFromId))
            ? warehouseFromId
            : ''
        }
        onChange={(event) => {
          setQueryParams({
            storeId,
            warehouseToId,
            warehouseFromId: event.target.value
              ? Number(event.target.value)
              : undefined,
          })
        }}
        displayEmpty
        data-testid="store-replenishment-warehouse-select"
        inputProps={{
          ['data-testid']: 'store-replenishment-warehouse-input',
        }}
      >
        <MenuItem
          value=""
          disabled
          data-testid="store-replenishment-warehouse-button-0"
        >
          <Typography variant="body02">
            {t('stock:in_store_replenishment.warehouse_from')}
          </Typography>
        </MenuItem>

        {warehouses
          ?.filter((warehouse) => warehouse.id !== Number(warehouseToId))
          .map(({ id, name }) => (
            <MenuItem
              value={String(id)}
              key={id}
              data-testid={`store-replenishment-warehouse-button-${id}`}
            >
              <Typography variant="body02">{name}</Typography>
            </MenuItem>
          ))}
      </Select>
    </>
  )
}
