import { useTheme } from '@emotion/react'
import {
  Menu,
  Button,
  Divider,
  Radio,
  RadioGroup,
  RadioGroupProps,
  TextField,
  Stack,
} from '@mui/material'
import { Box } from '@mui/system'
import { GridRenderCellParams, useGridApiContext } from '@mui/x-data-grid-pro'
import { FormLabelField, SectionHeader } from '@sitoo/mui-components'
import {
  useState,
  MouseEventHandler,
  useRef,
  useEffect,
  KeyboardEventHandler,
} from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Row } from '.'
import { ProductFormContext } from '..'
import { useTracking } from '../../../hooks/tracking'

export type VariantRendererProps = GridRenderCellParams<Row, Row['variant']> & {
  triggerChange?: () => void
}

export const VariantsRenderer = (props: VariantRendererProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const { watch, setValue } = useFormContext<ProductFormContext>()
  const apiRef = useGridApiContext()
  const anchorRef = useRef<HTMLDivElement>(null)
  const theme = useTheme()
  const {
    trackMenuOpen,
    trackMenuClose,
    trackButtonClickEvent,
    trackButtonClick,
  } = useTracking()
  const newVariantValueInputRef = useRef<HTMLInputElement[]>([])
  const [isMenuOpen, setMenuOpen] = useState(false)
  const variants = watch('product.childVariants')
  const variant = variants?.find(({ id }) => id === props.id)
  const variantVariants = variant?.variant
  const groups = watch('product.variantGroups')

  const attributes = props.formattedValue
    ?.map(({ value }) => value)
    .filter(Boolean)

  const value = attributes?.join(' / ')

  const onSetVariantClick: MouseEventHandler<HTMLButtonElement> = () => {
    setMenuOpen(!isMenuOpen)
  }

  const updateVariantValue = (name: string, value: string) => {
    variant?.variant?.forEach((v) => {
      if (v.name === name) {
        v.value = value
      }
    })

    if (variant?.variant?.length === 0) {
      variant.variant = [{ name, value }]
    }

    setValue('product.childVariants', variants)
    props.triggerChange?.()
  }

  const onVariantChange: RadioGroupProps['onChange'] = (event, value) => {
    updateVariantValue(event.target.name, value)
  }

  const addVariantValue = (index: number) => () => {
    const target = newVariantValueInputRef.current[index]
    if (target) {
      const { name, value } = target

      groups?.forEach((group) => {
        if (group.name === name && !group.options.includes(value)) {
          group.options.push(value)
        }
      })

      setValue('product.variantGroups', groups)
      props.triggerChange?.()

      target.value = ''
      updateVariantValue(name, value)

      trackButtonClick({
        name: 'product-variants-add-variant-value',

        variantName: name,
        variantValue: value,
      })
    }
  }

  const onAddVariantValue: (index: number) => KeyboardEventHandler =
    (index) => (event) => {
      event.stopPropagation()

      if (event.key === 'Enter') {
        addVariantValue(index)()
      }
    }

  useEffect(() => {
    return apiRef.current.subscribeEvent(
      'cellEditStart',
      (params, event) => {
        if (params.id === variant?.id && params.field === 'variant') {
          setMenuOpen(true)
          event.defaultMuiPrevented = true
        }
      },
      { isFirst: true },
    )
  }, [apiRef, variant])

  useEffect(() => {
    if (isMenuOpen) {
      trackMenuOpen({ name: 'product-variants-variants-group' })
    }
  }, [isMenuOpen, trackMenuOpen])

  return (
    <Box sx={{ width: '100%' }}>
      {attributes?.length ? (
        <div className="MuiDataGrid-cellContent">{value}</div>
      ) : (
        <div>
          <Button
            color="secondary"
            fullWidth
            data-testid="set-variant-group-button"
            aria-controls={isMenuOpen ? 'variants-group-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={isMenuOpen ? 'true' : undefined}
            onClick={trackButtonClickEvent(
              { name: 'product-variants-set-variant' },
              onSetVariantClick,
            )}
            className="MuiDataGrid-cellContent"
          >
            {t('products:product_form.set_variant_label')}
          </Button>
        </div>
      )}

      <Menu
        id="variants-group-menu"
        anchorEl={anchorRef.current}
        open={isMenuOpen}
        onClose={() => {
          trackMenuClose({ name: 'product-variants-variants-group' })
          setMenuOpen(false)
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        MenuListProps={{
          'aria-labelledby': 'variants-group-button',
        }}
        sx={{
          zIndex: theme.zIndex.tooltip + 1,
        }}
      >
        <Box
          sx={{
            minWidth: '280px',
            padding: (theme) => theme.spacing(0, 2),
          }}
        >
          {groups?.map(({ name, options }, index) => (
            <Box key={name}>
              <SectionHeader
                sx={{
                  px: 0,
                  background: 'inherit',
                }}
              >
                {name}
              </SectionHeader>
              <RadioGroup
                onChange={onVariantChange}
                value={
                  variantVariants?.find((v) => v.name === name)?.value || ''
                }
              >
                {options
                  .filter((o) => o !== '')
                  .map((option) => (
                    <FormLabelField
                      key={option}
                      label={option}
                      sx={{ borderBottom: 'none' }}
                      secondaryAction={<Radio value={option} name={name} />}
                    />
                  ))}
              </RadioGroup>
              <Stack direction="row" gap={1} sx={{ marginBottom: 2 }}>
                <TextField
                  name={name}
                  inputRef={(e: HTMLInputElement) =>
                    (newVariantValueInputRef.current[index] = e)
                  }
                  inputProps={{ 'data-testid': 'new-variant-value-input' }}
                  placeholder={t('products:product_form.add_new_variant_label')}
                  onKeyDown={onAddVariantValue(index)}
                />
                <Button
                  onClick={addVariantValue(index)}
                  data-testid="new-variant-value-add-button"
                >
                  {t('shared:action.add')}
                </Button>
              </Stack>
              {index < groups.length - 1 && <Divider />}
            </Box>
          ))}
        </Box>
      </Menu>

      <Box sx={{ position: 'absolute', bottom: 0, left: 0 }} ref={anchorRef} />
    </Box>
  )
}
