import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import {
  AllCustomAttributesQuery,
  CustomAttributeTypeEnum,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { CustomAttribute, CustomAttributeValue } from '../../fields/attributes'

type CustomAttributesDialogProps = {
  open: boolean
  onClose(): void
  onSuccess(newCustomAttribute: CustomAttribute): void
  supportedCustomAttributes: AllCustomAttributesQuery['allCustomAttributes']
  values?: CustomAttribute
  variantId: number
}

type FormContext = {
  attributes: CustomAttribute
}

export const CustomAttributesDialog = (props: CustomAttributesDialogProps) => {
  const { t } = useTranslation(['products', 'shared'])
  const { trackButtonClickEvent, trackDialogOpen, trackDialogClose } =
    useTracking()
  const dialogName = 'set-custom-attributes'
  const formContext = useForm<FormContext>()

  useEffect(() => {
    formContext.setValue('attributes', props.values)
  }, [formContext, props.values])

  useEffect(() => {
    if (props.open) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, trackDialogOpen])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose()
    formContext.reset({ attributes: props.values })
  }

  const onSubmit = (data: FormContext) => {
    props.onSuccess(data.attributes)
    onClose()
  }

  const setCustomAttribute = (
    attributeId: string,
    value: CustomAttributeValue,
  ) => {
    const productCustomAttributes = formContext.getValues('attributes')
    const current = productCustomAttributes ? productCustomAttributes : {}

    if (value === '') {
      delete current[attributeId]
    } else {
      current[attributeId] = value
    }

    formContext.setValue('attributes', current)
  }

  return (
    <Dialog onClose={onClose} open={props.open} maxWidth="sm" fullWidth>
      <DialogTitle
        type="extended"
        sx={{
          paddingBottom: (theme) => theme.spacing(2),
        }}
      >
        {t('products:product_form.add_attributes')}
      </DialogTitle>
      <DialogContent>
        <Controller
          control={formContext.control}
          name="attributes"
          render={({ field }) => (
            <>
              {props.supportedCustomAttributes.map((c) => (
                <Box
                  sx={{
                    display: 'flex',
                    gap: (theme) => theme.spacing(1),
                    marginBottom: (theme) => (c.enums ? theme.spacing(2) : 0),
                  }}
                  key={c.id}
                >
                  {c.enums ? (
                    <Box sx={{ width: '100%' }}>
                      <InputLabel>{c.title}</InputLabel>
                      <Select<number | string>
                        value={field.value?.[c.id] || ''}
                        onChange={(event) =>
                          setCustomAttribute(c.id, event.target.value as string)
                        }
                      >
                        {c.enums.map((value) => (
                          <MenuItem value={value} key={value}>
                            {value}
                          </MenuItem>
                        ))}
                      </Select>
                    </Box>
                  ) : (
                    <TextField
                      fullWidth
                      label={c.title}
                      sx={{ mb: 2 }}
                      value={
                        field.value?.[c.id] == null ? '' : field.value?.[c.id]
                      }
                      onChange={(event) =>
                        setCustomAttribute(
                          c.id,
                          c.type === CustomAttributeTypeEnum.Integer &&
                            event.target.value !== ''
                            ? Number(event.target.value)
                            : event.target.value,
                        )
                      }
                      type={
                        c.type === CustomAttributeTypeEnum.Integer
                          ? 'number'
                          : 'text'
                      }
                      InputLabelProps={{
                        shrink: true,
                      }}
                    />
                  )}
                </Box>
              ))}
            </>
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            {
              name: `${dialogName}-dialog-cancel`,
            },
            onClose,
          )}
          color="secondary"
          size="small"
          type="button"
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          type="button"
          size="small"
          onClick={trackButtonClickEvent(
            {
              name: `${dialogName}-dialog-set`,
            },
            formContext.handleSubmit(onSubmit),
          )}
          disabled={!formContext.formState.isValid}
        >
          {t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
