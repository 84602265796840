/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import { Button, FormControlLabel, MenuItem, TextField } from '@mui/material'
import {
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionHeader,
  Toggle,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import { CountriesInput } from '../../../components/countries-input'
import { CurrenciesInput } from '../../../components/currencies-input'
import { StoresInput } from '../../../components/stores-input'
import {
  AddUpdateSettingsWarehouseDocument,
  DeleteSettingsWarehouseDocument,
  GetStoresDocument,
  SettingsWarehouseDocument,
  SettingsWarehouseQuery,
  SettingsWarehousesDocument,
  WarehouseType,
  WarehouseUseType,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useScrollToError } from '../../../hooks/scroll-to-error'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'

type Warehouse = SettingsWarehouseQuery['warehouse']

export const WarehouseDialog = () => {
  const { t } = useTranslation([
    'shared',
    'settings',
    'countries',
    'warehouses',
  ])

  const [showDetailDialog, setDetailDialog] = useState(true)
  const [showDeleteDialog, setDeleteDialog] = useState(false)

  const { id } = useParams()
  const warehouseId = Number(id)
  const isNewWarehouse = !warehouseId

  const { trackDialogOpen, trackDialogClose } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const dialogName = warehouseId ? 'edit-warehouse' : 'add-warehouse'
  const formId = 'warehouse-form'

  const { data, loading: fetchLoading } = useQuery(SettingsWarehouseDocument, {
    variables: { warehouseId: Number(warehouseId) },
    fetchPolicy: 'cache-and-network',
    skip: !warehouseId,
  })

  const { data: storesData, loading: fetchStoresLoading } =
    useQuery(GetStoresDocument)

  const [addUpdateWarehouseMutation, { loading: addLoading }] = useMutation(
    AddUpdateSettingsWarehouseDocument,
    { refetchQueries: [SettingsWarehousesDocument] },
  )

  const [deleteWarehouseMutation, { loading: deleteLoading }] = useMutation(
    DeleteSettingsWarehouseDocument,
    {
      refetchQueries: [SettingsWarehousesDocument],
    },
  )

  const isLoading =
    fetchLoading || fetchStoresLoading || addLoading || deleteLoading

  const warehouseType = useMemo(() => {
    return Object.values(WarehouseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_type.${value}`),
    }))
  }, [t])

  const warehouseUseType = useMemo(() => {
    return Object.values(WarehouseUseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_use_type.${value}`),
    }))
  }, [t])

  const { formState, reset, control, register, handleSubmit } =
    useForm<Warehouse>({
      defaultValues: {
        warehousetype: WarehouseType.FIFO,
        usetype: WarehouseUseType.FRONT,
      },
    })

  const deleteItem = async () => {
    if (warehouseId) {
      try {
        await deleteWarehouseMutation({
          variables: { warehouseId: warehouseId },
        })
        enqueueSnackbar(t('settings:warehouse.delete_success'), {
          variant: 'success',
        })
      } catch (error) {
        const errorMessage = getErrorMessage(
          error,
          'warehouses',
          t('settings:warehouse.delete_error'),
        )
        enqueueSnackbar(errorMessage, {
          variant: 'error',
        })
      }
    }
    onDeleteDialogClose()
    onClose()
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    navigate(generatePath(RootRoute.SettingsWarehouses))
  }

  const onDeleteDialogOpen = () => {
    setDeleteDialog(true)
    setDetailDialog(false)
  }

  const onDeleteDialogClose = () => {
    setDeleteDialog(false)
    setDetailDialog(true)
  }

  const nameRef = useRef<HTMLDivElement>(null)
  const countryRef = useRef<HTMLDivElement>(null)
  const currencyRef = useRef<HTMLDivElement>(null)

  useScrollToError({ ref: nameRef, name: 'name', control })
  useScrollToError({ ref: countryRef, name: 'countryid', control })
  useScrollToError({ ref: currencyRef, name: 'currencycode', control })

  const onSubmit = async (warehouse: Warehouse) => {
    const { id, store, ...fields } = warehouse
    try {
      await addUpdateWarehouseMutation({
        variables: {
          warehouse: {
            ...fields,
            storeid: store?.id || null,
            warehouseid: warehouse.id,
            externalid: warehouse.externalid || '',
          },
        },
      })

      trackDialogClose({ name: dialogName })
      enqueueSnackbar(t('settings:warehouse.dialog_update_success'))
      onClose()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'warehouses',
        t('settings:warehouse.dialog_update_fail'),
      )
      enqueueSnackbar(errorMessage, {
        variant: 'error',
      })
    }
  }

  useEffect(() => {
    if (!warehouseId) {
      reset(undefined, { keepDefaultValues: true })
    }

    if (data?.warehouse) {
      reset(data?.warehouse, { keepDefaultValues: true })
    }
  }, [data, reset, warehouseId])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  return (
    <>
      <Dialog open={showDetailDialog} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>
          {isNewWarehouse
            ? t('settings:warehouse.add_warehouse')
            : t('settings:warehouse.edit_warehouse')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:warehouse.general_settings')}
            </SectionHeader>

            <Controller
              control={control}
              name="warehousetype"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  value={field.value}
                  onChange={(event) => field.onChange(event.target.value)}
                  data-testid="warehouse-type"
                  label={t('settings:warehouse.warehouse_type')}
                  error={!!error?.message}
                  helperText={error?.message}
                  sx={{ mb: 2 }}
                >
                  {warehouseType.map(({ name, value }) => (
                    <MenuItem value={value} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <Controller
              control={control}
              name="usetype"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  value={field.value || ''}
                  onChange={(event) => field.onChange(event.target.value)}
                  data-testid="warehouse-usettype"
                  error={!!error?.message}
                  helperText={error?.message}
                  label={t(`settings:warehouse.usetype`)}
                  sx={{ mb: 2 }}
                >
                  {warehouseUseType.map(({ name, value }) => (
                    <MenuItem value={value} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <StoresInput
              stores={storesData?.stores || []}
              isLoading={fetchStoresLoading}
              name="store"
              control={control}
              placeholder={t('shared:label.select_store')}
              sx={{ '.MuiFormControl-root': { mb: 2 } }}
            />

            <FormControlLabel
              label={t('settings:warehouse.sellable')}
              labelPlacement="start"
              sx={{
                width: '100%',
                justifyContent: 'space-between',
                ml: 0,
                mb: 2,
              }}
              slotProps={{
                typography: { fontWeight: 'medium' },
              }}
              control={
                <Toggle
                  {...register('sellable')}
                  dataTestid="sellable-toggle"
                />
              }
            />

            <TextField
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
              label={t('settings:warehouse.name')}
              {...register('name', {
                required: t('shared:validation.field_required', {
                  field: t('settings:warehouse.name'),
                }),
              })}
              sx={{ mb: 2 }}
              required
              inputRef={nameRef}
              inputProps={{ 'data-testid': 'warehouse-name' }}
            />

            <TextField
              error={!!formState.errors.externalid}
              helperText={formState.errors.externalid?.message}
              label={t('settings:warehouse.external_id')}
              {...register('externalid')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-externalid' }}
            />

            <CurrenciesInput
              innerRef={currencyRef}
              name="currencycode"
              control={control}
              isRequired
              sx={{ '.MuiFormControl-root': { mb: 2 } }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:warehouse.location')}
            </SectionHeader>

            <TextField
              error={!!formState.errors.address}
              helperText={formState.errors.address?.message}
              label={t('settings:warehouse.address')}
              {...register('address')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-address' }}
            />

            <TextField
              error={!!formState.errors.address2}
              helperText={formState.errors.address2?.message}
              label={t('settings:warehouse.address2')}
              {...register('address2')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-address2' }}
            />

            <TextField
              error={!!formState.errors.zip}
              helperText={formState.errors.zip?.message}
              label={t('settings:warehouse.zip')}
              {...register('zip')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-zip' }}
            />

            <TextField
              error={!!formState.errors.city}
              helperText={formState.errors.city?.message}
              label={t('settings:warehouse.city')}
              {...register('city')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-city' }}
            />

            <TextField
              error={!!formState.errors.state}
              helperText={formState.errors.state?.message}
              label={t('settings:warehouse.state')}
              {...register('state')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'warehouse-state' }}
            />

            <CountriesInput
              innerRef={countryRef}
              name="countryid"
              control={control}
              isRequired
              sx={{ mb: 2 }}
            />
          </form>

          {!isNewWarehouse && (
            <LoadingButton
              fullWidth
              loading={isLoading}
              color="error"
              sx={{ mt: 2 }}
              onClick={onDeleteDialogOpen}
            >
              {t('shared:action.delete')}
            </LoadingButton>
          )}
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            data-testid="dialog-cancel-warehouse"
          >
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            color="primary"
            onClick={handleSubmit(onSubmit)}
            data-testid="dialog-add-warehouse"
            form={formId}
          >
            {isNewWarehouse ? t('shared:action.add') : t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        confirmAction={deleteItem}
        title={t('settings:warehouse.dialog_delete_label')}
        text={t('settings:warehouse.dialog_delete_description', {
          warehouse: data?.warehouse.name,
        })}
        variant="destructive"
        open={showDeleteDialog}
        onClose={() => onDeleteDialogClose()}
      />
    </>
  )
}
