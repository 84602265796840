import { Chip } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import { GetUsersQuery, UserTag } from '../../../generated/graphql'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { ArrayElement } from '../../../utils/types'

type Props = {
  tags?: UserTag[] | null
  sx?: SxProps
}

export const UserTagsRenderer = (
  params: GridRenderCellParams<{
    resolvedTags?: ArrayElement<GetUsersQuery['users']['items']>['resolvedTags']
  }>,
): React.ReactElement => (
  <UserTagChips tags={params.row.resolvedTags} sx={{ flexWrap: 'nowrap' }} />
)

export const UserTagChips = ({ tags, sx }: Props) => {
  return (
    <Stack
      direction="row"
      gap={0.5}
      py={0.5}
      alignItems="center"
      height="100%"
      sx={{ flexWrap: 'wrap', ...sx }}
    >
      {tags?.length
        ? tags.map((tag) => (
            <Chip key={tag.id} size="small" label={tag.label} />
          ))
        : '-'}
    </Stack>
  )
}
