import { Container, List, ListItem, Paper, TextField } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { FormProvider, useForm } from 'react-hook-form'
import {
  GetPluginsDocument,
  PluginId,
  PluginCtuNorway,
  UpdateCtuNorwayDocument,
} from '../../../generated/graphql'
import { PosSettingsForm } from '../pos-settings/pos-settings-form'
import { useMutation, useQuery } from '@apollo/client'
import { usePageTitle } from '../../../hooks/title'
import { SwitchInput } from '../../../components/switch-input'
import { CurrenciesInput } from '../../../components/currencies-input'
import { CountriesInput } from '../../../components/countries-input'
import { DatePickerInput } from '../../../components/date-picker-input'

type Form = PluginCtuNorway

export const SettingsCtuNorwayPage = () => {
  const { t } = useTranslation(['shared', 'apps'])
  usePageTitle(t('shared:menu.ctu_norway'))

  const defaultValues: Form = {
    companyId: null,
    companyName: null,
    currencyCode: null,
    taxRegistrationNumber: null,
    taxRegistrationCountry: 'SE',
    dateFiscalFirst: null,
    dateFiscalSecond: null,
  }

  const formContext = useForm<Form>({ defaultValues })
  const { register, reset, formState } = formContext
  const { isSubmitting } = formState

  useQuery(GetPluginsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pluginId: PluginId.Ctunorway },
    onCompleted: (data) =>
      reset(data.plugin as Form, { keepDefaultValues: false }),
  })

  const [updateSettings] = useMutation(UpdateCtuNorwayDocument)

  const onSubmit = async (data: Form) => {
    await updateSettings({ variables: { data } })
  }

  return (
    <>
      <PageHeader title={t('shared:menu.ctu_norway')} />

      <Container>
        <Paper elevation={0} sx={{ pt: 1 }}>
          <FormProvider {...formContext}>
            <PosSettingsForm onSubmit={onSubmit}>
              <List>
                <ListItem divider>
                  <TextField
                    {...register('companyId')}
                    label={t('apps:ctu_norway.company_id_label')}
                    helperText={t('apps:ctu_norway.company_id_helper')}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <TextField
                    {...register('taxRegistrationNumber')}
                    label={t('apps:ctu_norway.tax_registration_number_label')}
                    helperText={t(
                      'apps:ctu_norway.tax_registration_number_helper',
                    )}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <TextField
                    {...register('companyName')}
                    label={t('apps:ctu_norway.company_name_label')}
                    helperText={t('apps:ctu_norway.company_name_helper')}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <CurrenciesInput
                    {...register('currencyCode')}
                    label={t('apps:ctu_norway.currency_code_label')}
                    helperText={t('apps:ctu_norway.currency_code_helper')}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <CountriesInput
                    {...register('taxRegistrationCountry')}
                    label={t('apps:ctu_norway.tax_country_code_label')}
                    helperText={t('apps:ctu_norway.tax_country_code_helper')}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <DatePickerInput
                    {...register('dateFiscalFirst')}
                    label={t('apps:ctu_norway.date_fiscal_first_label')}
                    helperText={t('apps:ctu_norway.date_fiscal_first_helper')}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <DatePickerInput
                    {...register('dateFiscalSecond')}
                    label={t('apps:ctu_norway.date_fiscal_second_label')}
                    helperText={t('apps:ctu_norway.date_fiscal_second_helper')}
                    sx={{ mb: 2 }}
                  />
                </ListItem>
              </List>
            </PosSettingsForm>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}
