import { useApolloClient, useQuery, useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  Button,
  TextField,
  List,
  ListItemText,
  ListItem,
  Divider,
  Box,
} from '@mui/material'
import { useGridApiRef } from '@mui/x-data-grid-pro'
import {
  ArrowRightSmallIcon,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionHeader,
} from '@sitoo/mui-components'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  GetShipmentQuery,
  ReasonCodeType,
  AddShipmentActionDocument,
  AllReasonCodesDocument,
  GetWarehousesDocument,
} from '../../../generated/graphql'
import { useMe } from '../../../hooks/me'
import { useTracking } from '../../../hooks/tracking'
import { getErrorMessage } from '../../../utils/error-mapping'
import { ArrayElement } from '../../../utils/types'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ReceivedPackageItems } from './received-package-items'

export type ReceivedPackageItems = {
  items: { itemId: string; quantity: number }[]
  reasonCode: string
  warehouseId?: number
  note?: string
}

export type ReceivedPackageItemsDialogProps = {
  open: boolean
  package?: ArrayElement<GetShipmentQuery['shipment']['packages']>
  shipmentVersion: number
  shipmentId: string
  warehouseId?: number | null
  onClose(): void
  onSuccess?(): void
  onError?(errorMessage: string): void
  dataTestid?: string
}

type Form = ReceivedPackageItems

export const SetReceivedPackageItemsDialog = (
  props: ReceivedPackageItemsDialogProps,
) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-received-package-items'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { displayName } = useMe()

  const { data } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const prevOpen = usePrevious(props.open)
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)
  const [loading, setLoading] = useState(false)
  const { formState, reset, handleSubmit, control, watch, setValue, register } =
    useForm<Form>({
      mode: 'onChange',
      defaultValues: {
        items: [],
      },
    })
  const apiRef = useGridApiRef()
  const items = watch('items')
  const [state, setState] = useState<'items' | 'confirm'>('items')
  const client = useApolloClient()

  const { data: reasonCodesData } = useQuery(AllReasonCodesDocument, {
    variables: {
      type: ReasonCodeType.ShipmentReceiveItems,
    },
  })
  const reasonCodes = reasonCodesData?.allReasonCodes || []

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)

      await addShipmentAction({
        variables: {
          shipmentId: props.shipmentId,
          data: {
            shipment_version: props.shipmentVersion,
            action: ActionType.AddReceivedItems,
            received_items: {
              items:
                formData?.items
                  .filter((x) => x.quantity > 0)
                  .map((x) => ({
                    item_id: x.itemId,
                    quantity: x.quantity,
                  })) || [],
              note: formData?.note,
              reason_code: formData?.reasonCode,
              warehouse_id: formData?.warehouseId,
            },
          },
        },
      })

      trackFormSuccess({ name: `${dialogName}-dialog` })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })

      props.onSuccess?.()
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_update'),
      )

      trackFormError({ name: `${dialogName}-dialog`, errorMessage })
      props.onError?.(errorMessage)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      setState('items')
      reset(
        {
          items: [],
          note: '',
          reasonCode: '',
          warehouseId: warehouses?.some((x) => x.id === props.warehouseId)
            ? props.warehouseId || undefined
            : undefined,
        },
        { keepDefaultValues: false },
      )
    }
  }, [
    prevOpen,
    props.open,
    props.warehouseId,
    warehouses,
    reset,
    trackDialogOpen,
  ])

  const gridItems = useMemo(() => {
    return props.package?.items
  }, [props.package])

  const totalPreviousReceivedItems =
    gridItems?.reduce((p, c) => p + c.quantity_received, 0) || 0
  const totalReceivedItems =
    totalPreviousReceivedItems + items.reduce((p, c) => p + c.quantity, 0)
  const totalItems = gridItems?.reduce((p, c) => p + c.quantity, 0) || 0

  if (!props.package) return null

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="set-receive-dialog"
    >
      <DialogTitle
        type="extended"
        sx={{
          paddingBottom: (theme) => theme.spacing(2),
        }}
      >
        {t('shipments:receive_items_dialog.title')}
      </DialogTitle>
      <DialogContent sx={{ px: state === 'items' ? 0 : undefined }}>
        {state === 'items' ? (
          <>
            <ReceivedPackageItems
              items={gridItems}
              updateItem={(item) => {
                const existingItem = items.find((x) => x.itemId === item.id)
                if (existingItem) {
                  existingItem.quantity = item.quantity_received
                  setValue('items', items)
                } else {
                  setValue('items', [
                    ...items,
                    { itemId: item.id, quantity: item.quantity_received },
                  ])
                }
              }}
              apiRef={apiRef}
            />
          </>
        ) : (
          <>
            <Controller
              control={control}
              name="warehouseId"
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputLabel>
                    {t(
                      'shipments:receive_items_dialog.receive_stock_warehouse',
                    )}
                  </InputLabel>
                  <Select<number | string>
                    value={field.value || ''}
                    onChange={(event) => {
                      const id = Number(event.target.value)

                      field.onChange(
                        event.target.value && !Number.isNaN(id)
                          ? id
                          : undefined,
                      )
                    }}
                    data-testid="warehouses-select"
                    inputProps={{ 'data-testid': 'warehouses-input' }}
                    displayEmpty
                    error={!!error?.message}
                  >
                    <MenuItem value={''}>
                      {t('shipments:receive_items_dialog.no_stock_change')}
                    </MenuItem>
                    {warehouses?.map(({ id, name }) => (
                      <MenuItem
                        value={id}
                        key={id}
                        data-testid={`warehouse-${id}-button`}
                      >
                        {name}
                      </MenuItem>
                    ))}
                  </Select>
                  <FormHelperText error={!!error?.message}>
                    {error?.message}
                  </FormHelperText>
                </>
              )}
            />
            {reasonCodes.length > 0 && (
              <Controller
                control={control}
                name="reasonCode"
                rules={{
                  required: true,
                }}
                render={({ field, fieldState: { error } }) => (
                  <>
                    <InputLabel sx={{ mt: 2 }}>
                      {t('shipments:receive_items_dialog.reason_code')}
                    </InputLabel>
                    <Select
                      data-testid="shipment-reason-code-select"
                      value={field.value || ''}
                      onChange={(event) => {
                        field.onChange(event.target.value)
                      }}
                      inputProps={{
                        'data-testid': 'shipment-reason-code-field',
                      }}
                      displayEmpty
                      error={!!error?.message}
                    >
                      <MenuItem value={''} disabled>
                        {t('shared:label.select')}
                      </MenuItem>
                      {reasonCodes.map(({ reasoncode, reasoncodeid, name }) => (
                        <MenuItem
                          value={reasoncode}
                          key={reasoncodeid}
                          data-testid={`reason-code-${reasoncodeid}-button`}
                        >
                          {name || reasoncode}
                        </MenuItem>
                      ))}
                    </Select>
                    <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                      {error?.message}
                    </FormHelperText>
                  </>
                )}
              />
            )}

            <TextField
              fullWidth
              error={!!formState.errors.note}
              helperText={formState.errors.note?.message}
              label={t('shipments:receive_items_dialog.note')}
              {...register('note')}
              inputProps={{ 'data-testid': 'note' }}
              multiline={true}
              rows={3}
              sx={{ mb: 2 }}
            />

            <SectionHeader variant="transparent" sx={{ px: 0 }}>
              {t('shipments:receive_items_dialog.summary')}
            </SectionHeader>
            <List sx={{ mx: -3 }}>
              <ListItem
                secondaryAction={
                  <Box
                    sx={{
                      display: 'flex',
                      gap: 1,
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    <ReceivedItemChip
                      label={t('shipments:packages.received_of_total', {
                        received: totalPreviousReceivedItems,
                        total: totalItems,
                      })}
                      quantity={totalItems}
                      quantityReceived={totalPreviousReceivedItems}
                    />
                    <ArrowRightSmallIcon
                      sx={{ color: (theme) => theme.palette.gray50 }}
                    />
                    <ReceivedItemChip
                      label={t('shipments:packages.received_of_total', {
                        received:
                          totalReceivedItems > totalItems
                            ? totalItems
                            : totalReceivedItems,
                        total: totalItems,
                      })}
                      quantity={totalItems}
                      quantityReceived={
                        totalReceivedItems > totalItems
                          ? totalItems
                          : totalReceivedItems
                      }
                    />
                  </Box>
                }
              >
                <ListItemText
                  primary={t('shipments:receive_items_dialog.items')}
                />
              </ListItem>
              <Divider />
              <ListItem
                secondaryAction={<ListItemText primary={displayName} />}
              >
                <ListItemText
                  primary={t('shipments:receive_items_dialog.responsible')}
                />
              </ListItem>
            </List>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            {
              name: `${dialogName}-dialog-cancel`,
            },
            onClose,
          )}
          color="secondary"
          size="small"
          type="button"
        >
          {t('shared:action.cancel')}
        </Button>
        {state === 'items' && (
          <LoadingButton
            size="small"
            type="button"
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-receive`,
              },
              () => {
                setState('confirm')
              },
            )}
            disabled={items.length === 0 || !items.some((x) => x.quantity > 0)}
            data-testid="receive"
          >
            {t('shipments:receive_items_dialog.receive')}
          </LoadingButton>
        )}

        {state === 'confirm' && (
          <LoadingButton
            type="submit"
            size="small"
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-confirm`,
              },
              handleSubmit(onSubmit),
            )}
            disabled={
              !formState.isValid ||
              items.length === 0 ||
              !items.some((x) => x.quantity > 0)
            }
            data-testid="confirm"
            loading={loading}
          >
            {t('shipments:receive_items_dialog.confirm')}
          </LoadingButton>
        )}
      </DialogActions>
    </Dialog>
  )
}
