import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { Chip, ChipProps } from '@mui/material'
import { Campaign } from '../../../generated/graphql'
import { getCampaignState, VoucherStates } from '../../../utils/campaigns'
import { t } from 'i18next'
import { Row } from '.'

type VoucherStateVariants = ChipProps['color']

const VariantMapping: {
  [K in VoucherStates]: VoucherStateVariants
} = {
  ['active']: 'green',
  ['planned']: 'orange',
  ['inactive']: 'red',
}

export const getVoucherStateAndVariant = (
  params: Pick<Campaign, 'activepos' | 'datestart' | 'dateend'>,
): VoucherStateProps => {
  const state = getCampaignState(params)

  const variant = VariantMapping[state]
  return {
    state,
    variant,
  }
}

export const voucherStateValueGetter = (_value: unknown, row: Row): string => {
  const { state } = getVoucherStateAndVariant(row)
  return t(`campaigns:campaign_state.${state}`)
}

export const voucherStateRenderer = (
  params: Pick<GridRenderCellParams<Row>, 'row'>,
): React.ReactElement => (
  <VoucherState {...getVoucherStateAndVariant(params.row)} />
)

export type VoucherStateProps = {
  state: VoucherStates
  variant: VoucherStateVariants
}

export const VoucherState = (props: VoucherStateProps): React.ReactElement => {
  const { t } = useTranslation('campaigns')

  const { state, variant } = props

  return (
    <Chip size="small" color={variant} label={t(`campaign_state.${state}`)} />
  )
}
