import { SvgIcon, Stack, Button } from '@mui/material'
import { useTranslation } from 'react-i18next'
import MicrosoftLogo from '../../icons/microsoft-logo.svg?react'
import GoogleLogo from '../../icons/google-logo.svg?react'
import {
  LoginOptionTypeEnum,
  PublicLoginOptionsQuery,
} from '../../generated/graphql'
import { ArrayElement } from '../../utils/types'
import { getRedirectParam } from '../../utils/redirect'
import { REDIRECT_QUERY_PARAM } from '../../utils/constants'
import { useLocalStorage } from 'react-use'

type LoginOptionProps = {
  loginOption: ArrayElement<PublicLoginOptionsQuery['publicLoginOptions']>
  label: string
  Icon: React.ElementType
}

export const OidcOption = (props: LoginOptionProps) => {
  const { loginOption, label, Icon } = props
  const [, setRedirectPage] = useLocalStorage(REDIRECT_QUERY_PARAM)

  const handleClick = () => {
    if (loginOption.oidc_auth_request_url) {
      const redirectParam = getRedirectParam(window.location.search)

      if (redirectParam) {
        setRedirectPage(redirectParam)
      }

      window.location.assign(loginOption.oidc_auth_request_url)
    }
  }

  return (
    <Button
      key={loginOption.id}
      color="secondary"
      size="large"
      fullWidth
      startIcon={<SvgIcon component={Icon} />}
      onClick={handleClick}
      data-testid={loginOption.type}
      sx={{ justifyContent: 'start' }}
    >
      {label}
    </Button>
  )
}

type LoginOptionsProps = {
  loginOptions?: PublicLoginOptionsQuery['publicLoginOptions']
}

export const OidcOptions = (props: LoginOptionsProps) => {
  const { t } = useTranslation(['login', 'shared'])
  const { loginOptions } = props

  const microsoftOption = loginOptions?.find(
    ({ type }) => type === LoginOptionTypeEnum.OidcPrivateAzureAd,
  )

  const googleOption = loginOptions?.find(
    ({ type }) => type === LoginOptionTypeEnum.OidcPrivateGoogle,
  )

  if (!microsoftOption && !googleOption) {
    return null
  }

  return (
    <Stack
      sx={{ backgroundColor: (theme) => theme.palette.gray20, px: 6, py: 5 }}
      gap={2}
    >
      {microsoftOption && (
        <OidcOption
          loginOption={microsoftOption}
          label={t('login:microsoft_label')}
          Icon={MicrosoftLogo}
        />
      )}

      {googleOption && (
        <OidcOption
          loginOption={googleOption}
          label={t('login:google_label')}
          Icon={GoogleLogo}
        />
      )}
    </Stack>
  )
}
