import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Box,
  Button,
  Chip,
  Divider,
  FormHelperText,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  QuestionIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { Fragment, useEffect } from 'react'
import { Controller, useFieldArray, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ProductCounter } from '../../../../components/product-counter'
import {
  AddOrderDeliveryInput,
  GetOrderQuery,
  AddOrderDeliveryDocument,
  GetWarehousesDocument,
} from '../../../../generated/graphql'
import { useMe } from '../../../../hooks/me'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'

export type DeliveryItem = Omit<
  ArrayElement<GetOrderQuery['order']['orderitems']>,
  'additionaldata'
> & {
  remainingQuantity: number
}

type Props = {
  open: boolean
  dataTestid?: string
  onClose(): void
  onSuccess?(): void
  onError?(): void
  orderId?: number
  warehouseId?: number | null
  items: DeliveryItem[]
}

type Form = Omit<AddOrderDeliveryInput, 'orderdeliveryitems'> & {
  orderdeliveryitems: (DeliveryItem & { deliveryQuantity: number })[]
}

export const DeliveryAddDialog = (props: Props) => {
  const { t } = useTranslation(['orders', 'shared'])
  const dialogName = 'order-delivery-add'
  const [addOrderDeliveryMutation, { loading }] = useMutation(
    AddOrderDeliveryDocument,
  )
  const { me, loading: loadingMe } = useMe()

  const { data } = useQuery(GetWarehousesDocument)

  const warehouses = data?.allWarehouses

  const isLoading = loading || loadingMe

  const {
    trackButtonClickEvent,
    trackDialogOpen,
    trackDialogClose,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)

  const formContext = useForm<Form>({
    mode: 'onChange',
  })

  const { fields: deliveryItems, update: updateDeliveryItem } = useFieldArray({
    control: formContext.control,
    name: 'orderdeliveryitems',
    keyName: 'itemId',
    rules: {
      validate: (v) => v?.some((x) => x.deliveryQuantity > 0),
    },
  })

  const onSubmit = async (delivery: Form) => {
    try {
      await addOrderDeliveryMutation({
        variables: {
          orderId: props.orderId || 0,
          data: {
            ...delivery,
            orderdeliveryitems: delivery.orderdeliveryitems
              .filter((x) => x.deliveryQuantity > 0)
              .map((x) => ({
                orderitemid: x.orderitemid!,
                quantity: x.deliveryQuantity,
              })),
            datecancelled: undefined,
          },
        },
      })

      trackFormSuccess({
        name: `${dialogName}-dialog`,
      })
      if (props.onSuccess) {
        props.onSuccess()
      }
    } catch {
      trackFormError({
        name: `${dialogName}-dialog`,
      })
      if (props.onError) {
        props.onError()
      }
    }
  }

  const onClose = () => {
    if (!loading) {
      trackDialogClose({ name: dialogName })
      formContext.reset({
        orderdeliveryitems: props.items.map((x) => ({
          ...x,
          deliveryQuantity: x.remainingQuantity,
        })),
      })
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      formContext.reset({
        warehouseid: warehouses?.some((x) => x.id === props.warehouseId)
          ? props.warehouseId || undefined
          : undefined,
        orderdeliveryitems: props.items.map((x) => ({
          ...x,
          deliveryQuantity: x.remainingQuantity,
        })),
      })
      trackDialogOpen({ name: dialogName })
    }
  }, [
    props.open,
    props.warehouseId,
    warehouses,
    prevOpen,
    trackDialogOpen,
    formContext,
    props.items,
  ])

  if (!props.orderId) {
    return null
  }

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="add-delivery-dialog"
    >
      <form
        // eslint-disable-next-line @typescript-eslint/no-misused-promises
        onSubmit={formContext.handleSubmit(onSubmit)}
      >
        <DialogTitle
          data-testid="add-order-delivery-title"
          type="extended"
          sx={{
            paddingBottom: (theme) => theme.spacing(2),
          }}
        >
          {t('orders:add_delivery_dialog.title')}
        </DialogTitle>
        <DialogContent>
          <Controller
            control={formContext.control}
            name="warehouseid"
            rules={{
              required: t('shared:validation.field_required', {
                field: t('orders:view_panel.warehouse_label'),
              }),
            }}
            render={({ field }) => (
              <>
                <SectionHeader sx={{ px: 0, background: 'inherit' }}>
                  {t('orders:view_panel.warehouse_label')}
                </SectionHeader>
                <Select<number | string>
                  value={field.value || ''}
                  onChange={(event) => {
                    field.onChange(event.target.value || null)
                  }}
                  displayEmpty
                  data-testid="order-warehouse"
                  inputProps={{
                    ['data-testid']: 'order-delivery-warehouse-input',
                  }}
                  sx={{ mb: 2 }}
                >
                  <MenuItem disabled value={''}>
                    {t('orders:add_delivery_dialog.select_warehouse')}
                  </MenuItem>
                  {warehouses?.map(({ id, name }) => (
                    <MenuItem
                      value={id}
                      key={id}
                      data-testid={`order-delivery-warehouse-button-${id}`}
                    >
                      {name}
                    </MenuItem>
                  ))}
                </Select>
                {!!formContext.formState.errors.orderdeliveryref?.message && (
                  <FormHelperText>
                    {formContext.formState.errors.orderdeliveryref?.message}
                  </FormHelperText>
                )}
              </>
            )}
          />

          <SectionHeader sx={{ px: 0, background: 'inherit' }}>
            {t('orders:add_delivery_dialog.select_products_to_deliver')}
          </SectionHeader>
          <List sx={{ mb: 2 }}>
            {deliveryItems.map((item, i) => {
              return (
                <Fragment key={item.orderitemid}>
                  <ListItem
                    sx={{
                      px: `0 !important`,
                    }}
                    data-testid={`order-delivery-item-${i}`}
                    secondaryAction={
                      item.remainingQuantity !== 0 && (
                        <ProductCounter
                          currentAmount={item.deliveryQuantity}
                          maxAmount={item.remainingQuantity}
                          updateAmount={(newQuantity) =>
                            updateDeliveryItem(i, {
                              ...item,
                              deliveryQuantity: newQuantity,
                            })
                          }
                        />
                      )
                    }
                  >
                    <ListItemIcon>
                      {item.product?.images &&
                      item.product?.images.length > 0 &&
                      typeof item.product.images[0] === 'string' ? (
                        <Box
                          component="img"
                          sx={{
                            maxWidth: 40,
                            maxHeight: 40,
                            width: 40,
                            objectFit: 'contain',
                          }}
                          src={item.product.images[0]}
                          alt=""
                        />
                      ) : (
                        <Box
                          sx={{
                            background: (t) => t.palette.gray20,
                            height: '40px',
                            width: '40px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <QuestionIcon
                            sx={{
                              color: (t) => t.palette.gray50,
                            }}
                          />
                        </Box>
                      )}
                    </ListItemIcon>
                    <ListItemText
                      data-testid="item-info"
                      primary={
                        item.product?.title || item.productname || item.sku
                      }
                      secondaryTypographyProps={{
                        sx: {
                          whiteSpace: 'pre-line',
                        },
                        component: 'div',
                      }}
                      secondary={
                        <>
                          {[item.productattributes, item.sku]
                            .filter(Boolean)
                            .join('\n')}
                          <br />
                          <Chip
                            data-testid="delivery-status-chip"
                            label={t(
                              'orders:add_delivery_dialog.x_of_y_delivered',
                              {
                                count: item.quantity,
                                delivered:
                                  item.quantity - item.remainingQuantity,
                              },
                            )}
                            size="small"
                            color={
                              item.remainingQuantity === 0 ? 'green' : 'orange'
                            }
                          />
                        </>
                      }
                    />
                  </ListItem>

                  {deliveryItems.length - 1 !== i && (
                    <Box
                      sx={{
                        position: 'relative',
                        height: '1px',
                        py: (theme) => theme.spacing(0.75),
                        width: (theme) => `calc(100% + ${theme.spacing(3)})`,
                      }}
                    >
                      <Divider
                        sx={{
                          width: (theme) =>
                            `calc(100% - 40px - ${theme.spacing(2)})`,
                          right: 0,
                          position: 'absolute',
                        }}
                      />
                    </Box>
                  )}
                </Fragment>
              )
            })}
          </List>

          <SectionHeader sx={{ px: 0, background: 'inherit' }}>
            {t('orders:view_panel.delivery_reference')}
          </SectionHeader>
          <TextField
            error={!!formContext.formState.errors.orderdeliveryref?.message}
            helperText={formContext.formState.errors.orderdeliveryref?.message}
            inputProps={{
              ['data-testid']: 'order-delivery-reference',
            }}
            {...formContext.register('orderdeliveryref')}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={trackButtonClickEvent(
              {
                name: `${dialogName}-dialog-cancel`,
              },
              onClose,
            )}
            data-testid="cancel-button"
            color="secondary"
            size="small"
            disabled={isLoading}
            type="button"
          >
            {t('shared:action.cancel')}
          </Button>
          <LoadingButton
            type="submit"
            size="small"
            loading={isLoading}
            onClick={trackButtonClickEvent({
              name: `${dialogName}-dialog-add`,
            })}
            disabled={!formContext.formState.isValid}
            data-testid="submit-add-order-delivery-dialog"
          >
            {t('shared:action.add')}
          </LoadingButton>
        </DialogActions>
      </form>
    </Dialog>
  )
}
