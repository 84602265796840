import { Tooltip, Typography, Box } from '@mui/material'
import {
  GridEditInputCell,
  GridRenderCellParams,
  GridRenderEditCellParams,
  GridValidRowModel,
} from '@mui/x-data-grid-pro'
import { trackInputFocus, trackInputBlur } from '../../../hooks/tracking/input'

export const StringRenderer = (
  params: GridRenderCellParams<GridValidRowModel, string | null | undefined>,
) => {
  return (
    <div
      className="MuiDataGrid-cellContent"
      data-testid={`string-field-${params.id}-${params.field}`}
    >
      {params.formattedValue ?? (
        <Box fontStyle="italic" color="gray60">
          {params.colDef.headerName}
        </Box>
      )}
    </div>
  )
}

export const StringEditRenderer = (
  props: GridRenderEditCellParams,
  onFocus: ReturnType<typeof trackInputFocus>,
  onBlur: ReturnType<typeof trackInputBlur>,
  trackingPrefix: string,
) => {
  const { hasChanged, ...otherProps } = props

  return (
    <StringEditInputCell
      {...otherProps}
      onFocus={onFocus}
      onBlur={onBlur}
      trackingPrefix={trackingPrefix}
    />
  )
}

type Props = GridRenderEditCellParams & {
  onFocus: ReturnType<typeof trackInputFocus>
  onBlur: ReturnType<typeof trackInputBlur>
  trackingPrefix: string
  selectOnFocus?: boolean

  error?: boolean | string
}

const StringEditInputCell = (props: Props) => {
  const {
    onFocus,
    onBlur,
    trackingPrefix,
    error,
    selectOnFocus = true,
    ...otherProps
  } = props

  return (
    <Box sx={{ width: '100%' }}>
      <Box sx={{ '.MuiInputBase-input': (theme) => theme.typography.body01 }}>
        <GridEditInputCell
          {...otherProps}
          error={!!error}
          hasFocus={true}
          onFocus={(event: React.SyntheticEvent<HTMLInputElement>) => {
            if (selectOnFocus) {
              event.currentTarget.select()
            }
            onFocus({ name: `${trackingPrefix}-${props.field}` })
          }}
          onBlur={onBlur({ name: `${trackingPrefix}-${props.field}` })}
          data-testid={`string-edit-field-${props.id}-${props.field}`}
        />
      </Box>
      <Box sx={{ width: '100%' }}>
        <Tooltip open={!!error} title={error || ''}>
          <div />
        </Tooltip>
      </Box>
    </Box>
  )
}
