import {
  GridColumnsInitialState,
  GridInitialState,
  GridApi,
  GridColumnVisibilityModel,
  GridColDef,
  GRID_CHECKBOX_SELECTION_FIELD,
} from '@mui/x-data-grid-pro'
import { MutableRefObject, useEffect } from 'react'
import {
  usePersistedValues,
  useScopeSpecificKey,
} from '../../../hooks/persisted-values'
import { LOCAL_STORAGE_STATE_KEY, RESET_COLUMN_WIDTH_EVENT } from '../utils'

type Props = {
  name: string
  apiRef: MutableRefObject<GridApi>
  columns: GridColDef[]
  columnVisibilityModel?: GridColumnVisibilityModel
}

export const usePersistedGridState = (props: Props) => {
  const { apiRef, name } = props

  const persistKey = useScopeSpecificKey(`${LOCAL_STORAGE_STATE_KEY}:${name}`)

  const [persistedGridState, setPersistedGridState] =
    usePersistedValues<GridInitialState>(persistKey)

  useEffect(() => {
    const persistState = () => {
      const state = apiRef.current.exportState({
        exportOnlyDirtyModels: true,
      })

      setPersistedGridState(state)
    }

    const unsubscribePin = apiRef.current.subscribeEvent(
      'pinnedColumnsChange',
      persistState,
    )

    const unsubscribeResize = apiRef.current.subscribeEvent(
      'columnResizeStop',
      persistState,
    )

    const unsubscribeOrderChange = apiRef.current.subscribeEvent(
      'columnOrderChange',
      persistState,
    )

    const unsubscribeColumnVisibility = apiRef.current.subscribeEvent(
      'columnVisibilityModelChange',
      persistState,
    )

    return () => {
      unsubscribePin()
      unsubscribeResize()
      unsubscribeOrderChange()
      unsubscribeColumnVisibility()
    }
  }, [apiRef, setPersistedGridState])

  useEffect(() => {
    const columns = persistedGridState?.columns
    const pinnedColumns = persistedGridState?.pinnedColumns

    apiRef.current.restoreState({ pinnedColumns, columns })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // Restore columns width to default values by triggering a custom event
  useEffect(() => {
    const unsubscribeColumnResize = apiRef.current.subscribeEvent(
      'columnResize',
      (event) => {
        if (event.colDef.field === RESET_COLUMN_WIDTH_EVENT) {
          setPersistedGridState({ columns: { dimensions: undefined } })
          const { columns } = props

          const dimensions = columns.reduce(
            (acc, column) => {
              const { maxWidth = -1, minWidth = -1, flex } = column

              acc[column.field] = { maxWidth, minWidth, flex, width: 0 }

              return acc
            },
            {} as Exclude<GridColumnsInitialState['dimensions'], undefined>,
          )

          const pinnedColumns = { left: [], right: [] }

          // Ordered Fields
          const currentOrderedFields = apiRef.current.exportState({
            exportOnlyDirtyModels: true,
          }).columns?.orderedFields
          const orderedFields = columns.map(({ field }) => field)

          if (currentOrderedFields?.includes(GRID_CHECKBOX_SELECTION_FIELD)) {
            orderedFields.unshift(GRID_CHECKBOX_SELECTION_FIELD)
          }

          if (props.columnVisibilityModel) {
            apiRef.current.setColumnVisibilityModel(props.columnVisibilityModel)
          }

          apiRef.current.restoreState({
            pinnedColumns,
            columns: { dimensions, orderedFields },
          })
        }
      },
    )

    return () => {
      unsubscribeColumnResize()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return persistedGridState
}
