import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useContext } from 'react'
import { useTranslation } from 'react-i18next'
import { FilterContext } from '../../../../../components/data-grid/context'
import { GetProductsQuery } from '../../../../../generated/graphql'
import { ArrayElement } from '../../../../../utils/types'
import { SetPurchasePriceDialog } from '../../set-purchase-price-dialog'

type Props = {
  isOpen: boolean
  onClose(): void
  refetch(): Promise<unknown>
}

export const SetPurchasePrice = (props: Props) => {
  const { t } = useTranslation(['prices'])
  const { enqueueSnackbar } = useSnackbar()
  const { filter } = useContext(FilterContext)
  const apiRef = useGridApiContext()
  const selectedProducts = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<
      Pick<ArrayElement<GetProductsQuery['products']['items']>, 'id' | 'sku'>
    >[]
  ).map(({ id, sku }) => ({ id, sku }))

  const onClose = () => {
    props.onClose()
  }

  const onSuccess = (_priceListId: string, products: unknown[]) => {
    void props.refetch()

    enqueueSnackbar(
      t('prices:purchase_prices:success_update_purchase_price_snackbar', {
        count: products.length,
      }),
    )

    onClose()
  }

  const onError = (_priceListId: string, products: unknown[]) => {
    void props.refetch()

    enqueueSnackbar(
      t('prices:purchase_prices:failure_update_purchase_price_snackbar', {
        count: products.length,
      }),
      { variant: 'error' },
    )
    onClose()
  }

  const priceListId = filter?.['priceListId']

  if (!priceListId || typeof priceListId !== 'string') {
    return null
  }

  return (
    <SetPurchasePriceDialog
      open={props.isOpen}
      onClose={onClose}
      products={selectedProducts}
      priceListId={priceListId}
      onSuccess={onSuccess}
      onError={onError}
    />
  )
}
