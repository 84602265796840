import { useCallback, useContext, useMemo } from 'react'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useTranslation } from 'react-i18next'
import {
  GetProductsBatchItemsQuery,
  GetProductsBatchItemsQueryVariables,
  GetProductsSort,
  DeleteWarehouseBatchItemsDocument,
  GetProductsBatchItemsDocument,
  UpdateWarehouseBatchItemsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import { StockRenderer } from '../../../../components/data-grid/utils/stock-renderer'
import {
  StringEditRenderer,
  StringRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  DEFAULT_PRODUCT_STATE,
  FRANCHISE_SITE_ID_PARAM,
} from '../../../../utils/constants'
import { DataGrid } from '../../../../components/data-grid'
import { FolderIcon } from '@sitoo/mui-components'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useMutation, useQuery } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<GetProductsBatchItemsQuery['products']['items']> & {
  quantityValue: string | undefined
}

type Props = {
  warehouseId: number
  warehouseBatchId: number
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const AllDeliveryOutList = (props: Props) => {
  const { t } = useTranslation(['shared', 'delivery_out', 'products'])
  const { warehouseId, warehouseBatchId } = props
  const { trackInputFocus, trackInputBlur, trackFormSuccess, trackFormError } =
    useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const {
    modules: { writeDeliveryOut },
  } = useAuthorization()

  const apiRef = useGridApiRef()

  const [updateWarehouseBatchItems] = useMutation(
    UpdateWarehouseBatchItemsDocument,
  )
  const [deleteWarehouseBatchItems] = useMutation(
    DeleteWarehouseBatchItemsDocument,
  )

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'images',
      ...ColumnProps.image,
      headerName: '',
      valueGetter: (_value, row): string => row.images?.[0] || '',
      cellClassName: 'image-column',
      headerClassName: 'image-column',
      renderCell: DataGridImageRenderer,
    },
    {
      field: 'id',
      ...ColumnProps.productId,
      headerName: t('delivery_out:product_id'),
    },
    {
      field: 'sku',
      ...ColumnProps.sku,
      headerName: t('delivery_out:sku'),
    },
    {
      field: 'title',
      ...ColumnProps.productTitle,
      headerName: t('delivery_out:product'),
      valueGetter: (_value, row): CaptionRendererValue => ({
        title: row.title,
        caption: row.variant?.[0]?.value,
      }),
      renderCell: CaptionRenderer,
    },
    {
      field: 'activepos',
      ...ColumnProps.productActive,
      headerName: t('products:status'),
      renderCell: ProductStateRenderer,
    },
    {
      field: 'decimaltotal',
      headerName: t('delivery_out:stock_label'),
      minWidth: 160,
      renderCell: StockRenderer,
      valueGetter: (_value, row) => {
        const total = row.warehouseItems?.find(
          (x) => x.warehouse?.id === warehouseId,
        )?.decimaltotal
        return Number(total || 0)
      },
    },
    {
      field: 'quantity',
      headerName: t('delivery_out:quantity_label'),
      editable: writeDeliveryOut,
      minWidth: 160,
      valueGetter: (_value, row): string | undefined => {
        const quantity = row.quantityValue
        return quantity ? String(Number(quantity)) : undefined
      },
      valueSetter: (value, row) => {
        const quantityValue = value ? Number(value).toFixed(3) : undefined

        return { ...row, quantityValue }
      },
      renderCell: StringRenderer,
      renderEditCell: (props) =>
        StringEditRenderer(
          props,
          trackInputFocus,
          trackInputBlur,
          'delivery-out-quantity',
        ),
    },
  ]

  const sortItems = useMemo<SortItem<GetProductsSort>[]>(
    () => [
      {
        field: 'id',
        sort: 'asc',
        title: t('delivery_out:product_id'),
        type: 'number',
        value: GetProductsSort.productid_asc,
      },
      {
        field: 'id',
        sort: 'desc',
        title: t('delivery_out:product_id'),
        type: 'number',
        value: GetProductsSort.productid_desc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'asc',
        title: t('delivery_out:sku'),
        type: 'text',
        value: GetProductsSort.sku_asc,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('delivery_out:sku'),
        type: 'text',
        value: GetProductsSort.sku_desc,
      },
      {
        field: 'title',
        sort: 'asc',
        title: t('delivery_out:product'),
        type: 'text',
        value: GetProductsSort.title_asc,
      },
      {
        field: 'title',
        sort: 'desc',
        title: t('delivery_out:product'),
        type: 'text',
        value: GetProductsSort.title_desc,
      },
    ],
    [t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetProductsBatchItemsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      includeVariants: true,
      warehouseId,
      warehouseBatchId,
    }

    if (typeof config.filter?.['text']?.value === 'string') {
      variables.search = config.filter?.['text']?.value
    }

    const categoryIds = config.filter?.['categoryIds']?.value
    if (categoryIds) {
      variables.categoryIds = Array.isArray(categoryIds)
        ? categoryIds
        : [categoryIds]
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    if (config.filter?.['state']?.value) {
      variables.includeInactive = config.filter?.['state']?.value === 'all'
    } else {
      variables.includeInactive = DEFAULT_PRODUCT_STATE === 'all'
    }

    if (config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value) {
      variables.productSiteId = Number(
        config.filter?.[FRANCHISE_SITE_ID_PARAM]?.value,
      )
    }

    return variables
  }, [apiRef, filter, sortItems, warehouseBatchId, warehouseId])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetProductsBatchItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.products.totalcount) {
      return fetchMore({
        variables: {
          start: (data.products?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const rows = useMemo(() => {
    return data?.products.items?.map((item) => {
      const quantityValue =
        item.warehouseBatchItem?.decimalquantity || undefined

      return { ...item, quantityValue }
    })
  }, [data])

  const processRowUpdate = useCallback(
    async (newRow: Row, oldRow: Row) => {
      const newQuantity = newRow.quantityValue
      const oldQuantity = oldRow.quantityValue

      if (newQuantity === oldQuantity) return newRow

      try {
        if (newQuantity) {
          await updateWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              warehouseBatchItems: [
                {
                  sku: newRow.sku,
                  decimalquantity: newQuantity,
                },
              ],
            },
          })
        } else {
          await deleteWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              skus: [newRow.sku],
            },
          })
        }

        trackFormSuccess({ name: 'delivery-out-list-row' })
        enqueueSnackbar(
          t('delivery_out:success_update_delivery_out_snackbar', {
            count: 1,
          }),
        )

        return newRow
      } catch (error) {
        trackFormError({ name: 'delivery-out-list-row' })
        enqueueSnackbar(
          t('delivery_out:failure_update_delivery_out_snackbar', {
            count: 1,
          }),
          { variant: 'error' },
        )

        return oldRow
      }
    },
    [
      deleteWarehouseBatchItems,
      enqueueSnackbar,
      t,
      trackFormError,
      trackFormSuccess,
      updateWarehouseBatchItems,
      warehouseBatchId,
      warehouseId,
    ],
  )

  return (
    <DataGrid
      name="all-delivery-out-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      processRowUpdate={processRowUpdate}
      rows={rows}
      rowCount={data?.products.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ footer: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('products:grid.title'),
        description: t('products:grid.description'),
      }}
      rowHeight={50}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      hasTextFilter
      onShowFilter={props.onShowFilter}
      disableColumnFilter
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={{
        id: false,
        activepos: false,
      }}
      showMore={
        Number(data?.products.items?.length) < Number(data?.products.totalcount)
      }
    />
  )
}
