import {
  Container,
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  Switch,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import {
  PosGeneralSettingsDocument,
  SettingsPosInventoryFeaturesEnum,
  Settings,
  SettingsBankBagIdModeEnum,
  SettingsReceiptStaffDisplayEnum,
  UpdateGeneralSettingsDocument,
} from '../../../generated/graphql'
import { useMemo } from 'react'
import { useForm, FormProvider } from 'react-hook-form'
import { SwitchInput } from '../../../components/switch-input'
import { PosSettingsForm } from './pos-settings-form'
import { useQuery, useMutation } from '@apollo/client'
import { SelectInput } from '../../../components/select-input'
import { AutocompleteInput } from '../../../components/autocomplete-input'
import { usePageTitle } from '../../../hooks/title'

type Form = Omit<Settings, 'receipt_staff_display' | 'eshopid'> & {
  receipt_staff_display?: Settings['receipt_staff_display'] | ''
}

const ReceiptStaffDisplayDefault = ''

export const SettingsPosGeneralPage = () => {
  const { t } = useTranslation(['shared', 'pos_settings'])
  usePageTitle(t('shared:menu.pos_general'))

  const defaultValues: Form = {
    bank_bag_id_mode: SettingsBankBagIdModeEnum.Disabled,
    receipt_staff_display: ReceiptStaffDisplayDefault,
    compress_variant_info: false,
    scanner_ean13_remove_leading_zero: false,
    limit_open_cash_drawer: false,
    warn_close_with_open_orders: false,
    pos_inventory_features: null,
  }

  const formContext = useForm<Form>({ defaultValues })

  const { control, setValue, watch, reset } = formContext

  useQuery(PosGeneralSettingsDocument, {
    fetchPolicy: 'cache-and-network',
    onCompleted: (data) => reset(data.settings, { keepDefaultValues: false }),
  })

  const [updateSettings] = useMutation(UpdateGeneralSettingsDocument)

  const onSubmit = async (data: Form) => {
    const receiptStaffDisplay = data.receipt_staff_display
    await updateSettings({
      variables: {
        settings: {
          ...data,
          receipt_staff_display:
            receiptStaffDisplay === '' ? null : receiptStaffDisplay,
        },
      },
    })
  }

  const showPosFeatures = watch('pos_inventory_features') !== null

  const toggleInventoryFeatures = (
    _event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => {
    if (checked) {
      setValue('pos_inventory_features', [], { shouldDirty: true })
    } else {
      setValue('pos_inventory_features', null, { shouldDirty: true })
    }
  }

  const receiptStaffDisplayOptions = useMemo(
    () => [
      {
        value: ReceiptStaffDisplayDefault,
        name: t('pos_settings:settings_receipt_staff_display_options.Default'),
      },
      ...Object.values(SettingsReceiptStaffDisplayEnum).map((value) => ({
        value,
        name: t(`pos_settings:settings_receipt_staff_display_options.${value}`),
      })),
    ],
    [t],
  )

  const bankBagIdModeOptions = useMemo(
    () => [
      ...Object.keys(SettingsBankBagIdModeEnum).map((value) => ({
        value,
        name: t(`pos_settings:bank_bag_id_options.${value}`),
      })),
    ],
    [t],
  )

  return (
    <>
      <PageHeader title={t('shared:menu.pos_general')} />

      <Container>
        <Paper elevation={0} sx={{ pt: 1 }}>
          <FormProvider {...formContext}>
            <PosSettingsForm onSubmit={onSubmit}>
              <List>
                <ListItem>
                  <SelectInput
                    name="bank_bag_id_mode"
                    control={control}
                    options={bankBagIdModeOptions}
                    label={t('pos_settings:bank_bag_id_label')}
                    helperText={t('pos_settings:bank_bag_id_helper')}
                  />
                </ListItem>

                <ListItem divider>
                  <SelectInput
                    name="receipt_staff_display"
                    control={control}
                    options={receiptStaffDisplayOptions}
                    label={t('pos_settings:receipt_staff_display_label')}
                    helperText={t('pos_settings:receipt_staff_display_helper')}
                  />
                </ListItem>

                <ListItem
                  component="label"
                  divider
                  secondaryAction={
                    <SwitchInput
                      control={control}
                      name="compress_variant_info"
                      data-testid="compress-variant-info"
                    />
                  }
                >
                  <ListItemText
                    data-testid="compress-variant-info-label"
                    secondary={t('pos_settings:compress_variant_info_helper')}
                  >
                    {t('pos_settings:compress_variant_info_label')}
                  </ListItemText>
                </ListItem>

                <ListItem
                  component="label"
                  divider
                  secondaryAction={
                    <SwitchInput
                      control={control}
                      name="scanner_ean13_remove_leading_zero"
                      data-testid="scanner-ean13-remove-leading-zero"
                    />
                  }
                >
                  <ListItemText
                    data-testid="scanner-ean13-remove-leading-zero-label"
                    secondary={t(
                      'pos_settings:convert_ean13_leading_zero_helper',
                    )}
                  >
                    {t('pos_settings:convert_ean13_leading_zero_label')}
                  </ListItemText>
                </ListItem>

                <ListItem
                  component="label"
                  divider
                  secondaryAction={
                    <SwitchInput
                      control={control}
                      name="limit_open_cash_drawer"
                      data-testid="limit_open_cash_drawer"
                    />
                  }
                >
                  <ListItemText
                    data-testid="limit-open-cash-drawer-label"
                    secondary={t('pos_settings:limit_open_cash_drawer_helper')}
                  >
                    {t('pos_settings:limit_open_cash_drawer_label')}
                  </ListItemText>
                </ListItem>

                <Stack gap={0.5}>
                  <ListItem
                    component="label"
                    secondaryAction={
                      <Switch
                        checked={showPosFeatures}
                        onChange={toggleInventoryFeatures}
                        data-testid="pos-inventory-features-input"
                      />
                    }
                  >
                    <ListItemText
                      data-testid="pos-inventory-features-label"
                      secondary={t(
                        'pos_settings:limit_pos_inventory_features_helper',
                      )}
                    >
                      {t('pos_settings:limit_pos_inventory_features_label')}
                    </ListItemText>
                  </ListItem>

                  {showPosFeatures && (
                    <AutocompleteInput
                      multiple
                      name="pos_inventory_features"
                      control={control}
                      options={Object.keys(SettingsPosInventoryFeaturesEnum)}
                      sx={{ px: 2, mb: 2 }}
                      textFieldProps={{
                        placeholder: t(
                          'pos_settings:select_inventroy_features_placeholder',
                        ),
                      }}
                      autocompleteProps={{
                        getOptionLabel: (option) =>
                          t(
                            `pos_settings:pos_inventory_feature_options.${option}`,
                          ),
                      }}
                    />
                  )}
                </Stack>

                <Divider />

                <ListItem
                  component="label"
                  divider
                  secondaryAction={
                    <SwitchInput
                      control={control}
                      name="warn_close_with_open_orders"
                      data-testid="warn-close-with-open-orders-input"
                    />
                  }
                >
                  <ListItemText
                    data-testid="warn-close-with-open-orders-label"
                    secondary={t(
                      'pos_settings:warn_close_with_open_orders_helper',
                    )}
                  >
                    {t('pos_settings:warn_close_with_open_orders_label')}
                  </ListItemText>
                </ListItem>
              </List>
            </PosSettingsForm>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}
