import { createContext, useState } from 'react'
import { DataGridFilterValue } from '../types'
import { useFilterContext } from '../hooks/use-filter-context'

export type ResetFilterFunction = (key?: string) => void | Promise<void>

export type FilterEventEmitter<TFunction> = {
  events: TFunction[]
  subscribe: (callback: TFunction) => () => void
  dispatch: TFunction
}

export type FilterContext<T = Record<string, unknown>> = {
  filter: Record<keyof T, DataGridFilterValue<T[keyof T]> | undefined>
  hasApply: boolean
  isFilterReady: boolean
  registerFilter: (filters: Filter | Filter[]) => void
  resetFilter: ResetFilterFunction
  setFilter: (key: keyof T, value: DataGridFilterValue<T[keyof T]>) => void
  applyFilter: () => void | Promise<void>
  subscribeOnResetFilter: FilterEventEmitter<ResetFilterFunction>['subscribe']
  removeFilter: (key: keyof T) => void
  refetch: () => void
}

export const FilterContext = createContext<FilterContext>({
  filter: {},
  hasApply: false,
  isFilterReady: false,
  registerFilter: () => {},
  resetFilter: () => {},
  setFilter: () => {},
  removeFilter: () => {},
  applyFilter: () => {},
  subscribeOnResetFilter: () => () => {},
  refetch: () => {},
})

export type StateParamsContext<T = Record<string, unknown>> = [
  queryParams: T | undefined,
  setQueryParams: (state: T) => void,
]

export const StateParamsContext = createContext<StateParamsContext>([
  undefined,
  () => {},
])

type ProviderProps<T> = {
  children: React.ReactNode
  value?: T
  hasApply?: boolean
}

type Filter = {
  key: string
  isReady?: boolean
}

export const FilterProvider = <T,>(props: ProviderProps<T>) => {
  const filterContext = useFilterContext<T>({
    hasApply: props.hasApply,
  })
  const [state, setState] = useState({})

  return (
    <StateParamsContext.Provider value={[state, setState]}>
      <FilterContext.Provider
        value={(props.value as FilterContext) || filterContext}
      >
        {props.children}
      </FilterContext.Provider>
    </StateParamsContext.Provider>
  )
}
