import { Box } from '@mui/material'
import { GridFooterContainerProps } from '@mui/x-data-grid-pro'
import { CustomPagination } from './custom-pagination'
import { CustomRowCount } from './custom-row-count'

export const CustomFooter = (props: GridFooterContainerProps) => {
  return (
    <Box sx={{ borderTop: (theme) => `1px solid ${theme.palette.divider}` }}>
      <CustomPagination {...props} />
      <CustomRowCount />
    </Box>
  )
}
