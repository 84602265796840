import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { CAMPAIGN_TYPE_CART } from '../../../../utils/campaigns'

export const DiscountCodeField = () => {
  const { register, formState, getValues } =
    useFormContext<CampaignFormContext>()
  const { t } = useTranslation(['campaigns', 'shared'])
  const type = getValues('campaign.vouchertype')

  const isCartCampaign = CAMPAIGN_TYPE_CART.types.includes(type)

  return (
    <FormFieldset>
      <TextField
        label={t('campaigns:campaign_form.discount_code_label')}
        helperText={t('campaigns:campaign_form.discount_code_description')}
        error={!!formState?.errors.campaign?.voucherpassword}
        data-testid="campaign-voucherpassword-field"
        {...register('campaign.voucherpassword', {
          required: isCartCampaign,
        })}
      />
    </FormFieldset>
  )
}
