import {
  Divider,
  ListItem,
  ListItemText,
  Stack,
  TextField,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { Maybe, UserPermissionsByIdQuery } from '../../../generated/graphql'
import { validateEmail } from '../../../utils/validate'
import { BaseUserContext } from '../shared'
import { FieldHighlight } from '../../../components/field-highlight'
import { SwitchInput } from '../../../components/switch-input'
import { MfaField } from './mfa-field'

type Props = {
  email: string
  hasMfa: Maybe<boolean> | undefined
  permissions: UserPermissionsByIdQuery['userPermissionsById'] | undefined
  isNewUser?: boolean
  loading: boolean
  userId: string | null | undefined
}

export const LoginField = (props: Props) => {
  const { permissions, isNewUser, loading, email, hasMfa, userId } = props
  const { t } = useTranslation(['users', 'shared'])
  const { register, formState, watch, control } =
    useFormContext<BaseUserContext>()

  return (
    <>
      <FormFieldset
        label={t('user_form.login_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { pb: 0 } }}
      >
        <Stack gap={2} sx={{ pb: 2 }}>
          {permissions?.changeEmail !== null && (
            <TextField
              error={!!formState.errors.email}
              helperText={
                formState.errors.email?.message ||
                t('users:user_form.email_helper')
              }
              label={t('email')}
              data-testid="user-email-input"
              disabled={!isNewUser && !permissions?.changeEmail}
              required
              {...register('email', {
                required: t('shared:validation.field_required', {
                  field: t('shared:label.email'),
                }),
                validate: (value) =>
                  value && !validateEmail(value)
                    ? t('shared:validation.field_invalid', {
                        field: t('shared:label.email'),
                      })
                    : true,
              })}
            />
          )}
          {!loading && permissions?.changePin !== null && (
            <TextField
              error={!!formState.errors.pin}
              helperText={
                formState.errors.pin?.message || t('users:user_form.pin_helper')
              }
              label={t('pin')}
              inputProps={{ maxLength: 6 }}
              data-testid="user-pin-input"
              disabled={!isNewUser && !permissions?.changePin}
              {...register('pin')}
            />
          )}
        </Stack>

        {!loading && permissions?.viewMfa && (
          <>
            <Divider sx={{ mx: -2 }} />
            <MfaField
              email={email}
              removeColor="error"
              hasMfa={hasMfa}
              userId={userId}
            />
          </>
        )}

        {!loading && permissions?.changeActive !== null && (
          <>
            <Divider sx={{ mx: -2 }} />
            <ListItem
              component="div"
              secondaryAction={
                <SwitchInput
                  name="active"
                  control={control}
                  data-testid="user-activepos-field"
                  disabled={!permissions?.changeActive}
                />
              }
              sx={{ p: 0, py: 1 }}
            >
              <ListItemText
                data-testid="user-activepos-field-label"
                secondary={t('user_form.enabled_helper')}
              >
                {t('enabled')}
              </ListItemText>
            </ListItem>
          </>
        )}
      </FormFieldset>

      <FormFieldset sx={{ '.MuiFormFieldset-Paper': { pb: 0 } }}>
        <TextField
          error={!!formState.errors.externalid}
          helperText={
            formState.errors.externalid?.message ||
            t('users:user_form.external_id_helper')
          }
          label={t('external_id')}
          data-testid="user-external-id-input"
          disabled={!permissions?.editUser}
          sx={{ mb: 2 }}
          {...register('externalid')}
        />

        {!loading && !watch('externalid') && (
          <FieldHighlight
            label={t('shared:validation.external_id_warning')}
            dataTestId="no-external-id-highlight"
            sx={{ mx: -2 }}
          />
        )}
      </FormFieldset>
    </>
  )
}
