import { exists, t } from 'i18next'
import { extractGraphqlErrors } from '../extract-graphql-errors'

const getInputLabel = (property: unknown) => {
  try {
    return typeof property === 'string'
      ? document.querySelector<HTMLInputElement>(`[name*="${property}"]`)
          ?.labels?.[0]?.textContent
      : undefined
  } catch {
    return undefined
  }
}

/**
 * @deprecated use getErrorMessages
 */
export const getErrorMessage = (
  error: unknown,
  messagePrefix: string,
  fallbackMessage?: string,
  opts?: {
    resolveProperty?: (property: string) => string
  },
): string => {
  const defaultError =
    typeof fallbackMessage === 'string'
      ? fallbackMessage
      : t('shared:error.default')

  try {
    const allErrors = extractGraphqlErrors(error)

    if (!allErrors.length) {
      return defaultError
    }

    const code = allErrors[0]?.extensions?.['code']
    const sizeLimit = allErrors[0]?.extensions?.['sizeLimit']
    const property =
      opts?.resolveProperty && allErrors[0]?.extensions?.['property']
        ? opts.resolveProperty(allErrors[0]?.extensions['property'] as string)
        : allErrors[0]?.extensions?.['property']
    const value = allErrors[0]?.extensions?.['value']

    if (typeof code !== 'string') {
      return defaultError
    }

    const lowerErrorCode = code.toLowerCase()

    const inputLabel = getInputLabel(property)

    if (exists(`${messagePrefix}:error.${lowerErrorCode}`)) {
      return t(`${messagePrefix}:error.${lowerErrorCode}`, {
        property: inputLabel || property,
        sizeLimit,
        value,
      })
    }

    if (exists(`shared:error.${lowerErrorCode}`)) {
      return t(`shared:error.${lowerErrorCode}`, {
        property: inputLabel || property,
        sizeLimit,
        value,
      })
    }

    return defaultError
  } catch {
    return defaultError
  }
}

type ErrorMessageProps = {
  fallbackError?: string
  resolve?: (property: string) => string
}

/**
 * Extracts GraphqlErrors from an Error and returns a list of corresponding
 * translations
 */
export const getErrorMessages = (
  error: unknown,
  opts?: ErrorMessageProps,
): string[] => {
  const fallbackError = opts?.fallbackError || t('shared:error.default')
  const defaultResolve = (property: string) => `shared:error.${property}`

  const allErrors = extractGraphqlErrors(error)
  const errors: string[] = []

  for (const graphqlError of allErrors) {
    const errorCode = String(graphqlError.extensions?.['code']).toLowerCase()
    const property = graphqlError.extensions?.['property']

    const resolve = opts?.resolve || defaultResolve
    const path = resolve(errorCode)

    errors.push(
      t(path, {
        property: property,
        defaultValue: fallbackError,
      }),
    )
  }

  return errors
}
