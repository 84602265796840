import {
  Autocomplete,
  AutocompleteProps as MuiAutocompleteProps,
  AutocompleteValue,
  ChipTypeMap,
  SxProps,
  TextField,
  TextFieldProps,
} from '@mui/material'
import { ElementType, useMemo } from 'react'
import {
  FieldPath,
  FieldValues,
  UseControllerProps,
  useController,
} from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useTracking } from '../../hooks/tracking'

type Option = {
  id: string | number
  label: string
}

/**
 * Example of MUI and react-hook-form integration:
 * https://github.com/dohomi/react-hook-form-mui/blob/master/packages/rhf-mui/src/AutocompleteElement.tsx#L41 GitHub
 */
type AutocompleteProps<
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
> = {
  name: TName
  multiple?: Multiple
  options: TValue[]
  sx?: SxProps
  required?: boolean
  isLoading?: boolean
  label?: TextFieldProps['label']

  autocompleteProps?: Omit<
    MuiAutocompleteProps<
      TValue,
      Multiple,
      DisableClearable,
      FreeSolo,
      ChipComponent
    >,
    'name' | 'options' | 'loading' | 'renderInput'
  >

  textFieldProps?: Omit<TextFieldProps, 'name' | 'required' | 'label'>
}

export const AutocompleteInput = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
  // eslint-disable-next-line @typescript-eslint/no-redundant-type-constituents
  TValue = Option | string | unknown,
  Multiple extends boolean | undefined = false,
  DisableClearable extends boolean | undefined = false,
  FreeSolo extends boolean | undefined = false,
  ChipComponent extends ElementType = ChipTypeMap['defaultComponent'],
>(
  props: AutocompleteProps<
    TFieldValues,
    TName,
    TValue,
    Multiple,
    DisableClearable,
    FreeSolo,
    ChipComponent
  > &
    UseControllerProps<TFieldValues>,
) => {
  const {
    name,
    control,
    sx,
    multiple,
    rules,
    required,
    label,
    autocompleteProps,
    textFieldProps,
    options,
    isLoading,
  } = props

  const { trackInputFocus, trackInputBlur } = useTracking()

  const { t } = useTranslation(['shared'])

  const validationRules = {
    ...rules,
    required: required
      ? t('shared:validation.field_required', { field: label ?? name })
      : undefined,
  }

  const { field, fieldState, formState } = useController({
    name,
    control,
    disabled: autocompleteProps?.disabled,
    rules: validationRules,
  })

  const { isSubmitting } = formState

  const formError = fieldState.error

  const value = useMemo(() => {
    const fieldValue = field.value as TValue | TValue[] | null

    if (Array.isArray(fieldValue)) {
      return options.filter((option) => fieldValue.includes(option))
    }

    if (fieldValue) {
      return options.find((option) => option === fieldValue) ?? null
    }

    return multiple ? [] : null
  }, [field.value, multiple, options])

  return (
    <Autocomplete
      {...autocompleteProps}
      sx={sx}
      multiple={multiple}
      fullWidth
      autoHighlight
      value={
        value as AutocompleteValue<TValue, Multiple, DisableClearable, FreeSolo>
      }
      options={options}
      disabled={isLoading ?? isSubmitting}
      onChange={(_event, value) => field.onChange(value)}
      data-testid={`${name}-autocomplete-container`}
      renderInput={(params) => (
        <TextField
          type="text"
          {...textFieldProps}
          {...params}
          label={label}
          onFocus={trackInputFocus({ name: name })}
          onBlur={trackInputBlur({ name: name })}
          error={!!formError}
          helperText={formError?.message ?? textFieldProps?.helperText}
          slotProps={{
            htmlInput: {
              ...textFieldProps?.slotProps?.htmlInput,
              ...params.inputProps,
              ['data-testid']: `autocomplete-input-${name}`,
            },
          }}
        />
      )}
    />
  )
}
