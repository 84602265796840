import { useApolloClient, useMutation } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { Fragment, useCallback, useMemo, useState } from 'react'
import { useFieldArray, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  ActionType,
  AllShipmentActionsDocument,
  GetShipmentDocument,
  ShipmentState,
  AddShipmentActionDocument,
} from '../../../../generated/graphql'
import { useLocalizedDate } from '../../../../hooks/localized-date'
import { useMoney } from '../../../../hooks/money'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { ArrayElement } from '../../../../utils/types'
import { PackageItemIcon } from '../../../shipments/shipment-view-panel/packages'
import { ReceivedItemChip } from '../../../shipments/shipment-view-panel/received-item-chip'
import { SetPackageArrivedDialog } from '../../set-package-arrived-dialog'
import { SetPackageInfoDialog } from '../../set-package-info-dialog'
import { SetPackageItemsDialog } from '../../set-package-items-dialog'
import { SetReceivedPackageItemsDialog } from '../../set-received-package-items-dialog'
import { BaseShipmentFormContext } from '../../shared'
import { formatPackage } from '../../new-shipment/submit'

export type ShipmentPackage = ArrayElement<BaseShipmentFormContext['packages']>

export const Packages = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined

  const { t } = useTranslation('shipments')
  const [isInfoDialogOpen, setInfoDialogOpen] = useState(false)
  const [isItemsDialogOpen, setItemsDialogOpen] = useState(false)
  const [isReceivedPackageItemsDialogOpen, setReceivedPackageItemsDialogOpen] =
    useState(false)
  const [selectedPackage, setSelectedPackage] = useState<ShipmentPackage>()
  const [isSetPackageArrivedDialogOpen, setSetPackageArrivedDialogOpen] =
    useState(false)
  const [selectedSetPackageArrived, setSelectedSetPackageArrived] = useState<
    string[]
  >([])
  const { enqueueSnackbar } = useSnackbar()
  const client = useApolloClient()

  const { formatCurrency } = useMoney()
  const { formatRelativeDate } = useLocalizedDate()

  const { control, watch, formState } =
    useFormContext<BaseShipmentFormContext>()

  const shipment = watch()
  const shipmentState = useMemo(
    () => shipment.state || ShipmentState.New,
    [shipment.state],
  )

  const [loadingAddPackage, setLoadingAddPackage] = useState(false)
  const [addShipmentAction] = useMutation(AddShipmentActionDocument)
  const { fields, update, append, remove } = useFieldArray({
    control: control,
    name: 'packages',
    keyName: 'rowId',
  })

  const onEditInfo = useCallback(
    (packageId: string) => () => {
      const selectedPackage = fields.find(({ id }) => id === packageId)
      setSelectedPackage(selectedPackage)
      setInfoDialogOpen(true)
    },
    [fields],
  )

  const onAddPackageNewShipment = useCallback(() => {
    append({
      id: new Date().getTime().toString(),
      total_quantity: 0,
      total_quantity_received: 0,
    })
  }, [append])

  const onAddPackageExistingShipment = useCallback(async () => {
    try {
      setLoadingAddPackage(true)
      await addShipmentAction({
        variables: {
          shipmentId: shipment.id || '',
          data: {
            shipment_version: shipment.version || 0,
            action: ActionType.AddPackage,
            package: {},
          },
        },
      })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })
      enqueueSnackbar(t('shipments:shipment_message.success_update'))
    } catch (error) {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_update'),
      )
      enqueueSnackbar(errorMessage, { variant: 'error' })
    } finally {
      setLoadingAddPackage(false)
    }
  }, [
    addShipmentAction,
    client,
    enqueueSnackbar,
    shipment.id,
    shipment.version,
    t,
  ])

  const onDeletePackageNewShipment = useCallback(
    (shipmentPackage: ShipmentPackage) => {
      const packageIndex = fields.findIndex(
        ({ id }) => id === shipmentPackage.id,
      )
      remove(packageIndex)
      setInfoDialogOpen(false)
    },
    [fields, remove],
  )

  const onDeletePackageExistingShipment = useCallback(
    async (shipmentPackage: ShipmentPackage) => {
      await addShipmentAction({
        variables: {
          shipmentId: shipment.id || '',
          data: {
            shipment_version: shipment.version || 0,
            action: ActionType.DeletePackage,
            package_id: shipmentPackage.id,
            package: formatPackage(shipmentPackage, shipmentState),
          },
        },
      })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })
      enqueueSnackbar(t('shipments:shipment_message.success_update'))
    },
    [
      addShipmentAction,
      client,
      enqueueSnackbar,
      shipment.id,
      shipmentState,
      shipment.version,
      t,
    ],
  )

  const onServerError = useCallback(
    (error: unknown) => {
      const errorMessage = getErrorMessage(
        error,
        'shipments',
        t('shipments:shipment_message.failure_update'),
      )
      enqueueSnackbar(errorMessage, { variant: 'error' })
    },
    [enqueueSnackbar, t],
  )

  const onPackageInfoUpdateNewShipment = useCallback(
    (data: ShipmentPackage) => {
      const index = fields.findIndex(({ id }) => id === data.id)
      update(index, data)
    },
    [fields, update],
  )

  const onPackageInfoUpdateExistingShipment = useCallback(
    async (shipmentPackage: ShipmentPackage) => {
      await addShipmentAction({
        variables: {
          shipmentId: shipment.id || '',
          data: {
            shipment_version: shipment.version || 0,
            action: ActionType.UpdatePackage,
            package_id: shipmentPackage.id,
            package: formatPackage(shipmentPackage, shipmentState),
          },
        },
      })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })
      enqueueSnackbar(t('shipments:shipment_message.success_update'))
    },
    [
      addShipmentAction,
      client,
      enqueueSnackbar,
      shipment.id,
      shipmentState,
      shipment.version,
      t,
    ],
  )

  const onEditItems = useCallback(
    (packageId: string) => () => {
      const selectedPackage = fields.find(({ id }) => id === packageId)
      setSelectedPackage(selectedPackage)
      setItemsDialogOpen(true)
    },
    [fields],
  )

  const onPackageItemsUpdateNewShipment = useCallback(
    (packageItems: ShipmentPackage['items'], packageId?: string) => {
      for (const [index, field] of fields.entries()) {
        if (field.id === packageId) {
          const totalQuantity =
            packageItems?.reduce((acc, item) => {
              acc += item.quantity
              return acc
            }, 0) || 0

          update(index, {
            ...field,
            items: packageItems,
            total_quantity: totalQuantity,
          })

          break
        }
      }
      setItemsDialogOpen(false)
    },
    [fields, update],
  )

  const onPackageItemsUpdateExistingShipment = useCallback(
    async (packageItems: ShipmentPackage['items'], packageId?: string) => {
      const shipmentPackage = fields.find((x) => x.id === packageId)

      if (!shipmentPackage) return

      await addShipmentAction({
        variables: {
          shipmentId: shipment.id || '',
          data: {
            shipment_version: shipment.version || 0,
            action: ActionType.UpdatePackage,
            package_id: packageId,
            package: formatPackage(
              {
                ...shipmentPackage,
                items: packageItems,
                total_quantity:
                  packageItems?.reduce((acc, item) => {
                    acc += item.quantity
                    return acc
                  }, 0) || 0,
              },
              shipmentState,
            ),
          },
        },
      })

      await client.refetchQueries({
        include: [GetShipmentDocument, AllShipmentActionsDocument],
      })
      enqueueSnackbar(t('shipments:shipment_message.success_update'))
    },
    [
      addShipmentAction,
      client,
      enqueueSnackbar,
      fields,
      shipment.id,
      shipmentState,
      shipment.version,
      t,
    ],
  )

  return (
    <>
      <SetPackageInfoDialog
        open={isInfoDialogOpen}
        package={selectedPackage}
        shipmentState={shipmentState}
        onClose={() => setInfoDialogOpen(false)}
        onDelete={
          isNewShipment
            ? onDeletePackageNewShipment
            : onDeletePackageExistingShipment
        }
        onDeleteSuccess={() => setInfoDialogOpen(false)}
        onDeleteError={isNewShipment ? undefined : onServerError}
        onUpdate={
          isNewShipment
            ? onPackageInfoUpdateNewShipment
            : onPackageInfoUpdateExistingShipment
        }
        onUpdateSuccess={() => setInfoDialogOpen(false)}
        onUpdateError={isNewShipment ? undefined : onServerError}
      />

      <SetPackageItemsDialog
        readOnly={shipmentState !== ShipmentState.New}
        open={isItemsDialogOpen}
        packageId={selectedPackage?.id}
        packageItems={selectedPackage?.items}
        shipmentState={shipmentState}
        onClose={() => setItemsDialogOpen(false)}
        onSubmit={
          isNewShipment
            ? onPackageItemsUpdateNewShipment
            : onPackageItemsUpdateExistingShipment
        }
        onSuccess={() => setItemsDialogOpen(false)}
        onError={isNewShipment ? undefined : onServerError}
      />

      <SetPackageArrivedDialog
        open={isSetPackageArrivedDialogOpen}
        shipmentId={formState.defaultValues?.id || ''}
        shipmentVersion={formState.defaultValues?.version || 0}
        packageIds={selectedSetPackageArrived}
        onClose={() => setSetPackageArrivedDialogOpen(false)}
        onSuccess={() => {
          enqueueSnackbar(t('shipments:shipment_message.success_update'))
          setSetPackageArrivedDialogOpen(false)
        }}
        onError={(errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: 'error' })
          setSetPackageArrivedDialogOpen(false)
        }}
      />

      <SetReceivedPackageItemsDialog
        open={isReceivedPackageItemsDialogOpen}
        shipmentId={formState.defaultValues?.id || ''}
        shipmentVersion={formState.defaultValues?.version || 0}
        warehouseId={shipment.info?.origin_warehouse_id}
        package={selectedPackage || undefined}
        onSuccess={() => {
          enqueueSnackbar(t('shipments:shipment_message.success_update'))
          setReceivedPackageItemsDialogOpen(false)
        }}
        onError={(errorMessage) => {
          enqueueSnackbar(errorMessage, { variant: 'error' })
        }}
        onClose={() => {
          setReceivedPackageItemsDialogOpen(false)
        }}
      />

      <FormFieldset
        label={t('shipments:shipment_form.packages_fieldset')}
        sx={{ '.MuiFormFieldset-Paper': { p: 0 } }}
      >
        <List>
          {fields.map((shipmentPackage, index) => (
            <Fragment key={shipmentPackage.id}>
              <ListItem
                data-testid="package-box"
                secondaryAction={
                  <Stack direction="row" spacing={1}>
                    {[
                      ShipmentState.New,
                      ShipmentState.Packed,
                      ShipmentState.TransportOrdered,
                      ShipmentState.ReadyForPickup,
                    ].includes(shipmentState) && (
                      <Button
                        data-testid="edit-info-button"
                        size="small"
                        color="secondary"
                        onClick={onEditInfo(shipmentPackage.id)}
                      >
                        {t('shipments:packages.edit_info')}
                      </Button>
                    )}
                    {(!shipmentPackage.arrived_at ||
                      (shipmentPackage.arrived_at &&
                        shipmentPackage.total_quantity ===
                          shipmentPackage.total_quantity_received) ||
                      [
                        ShipmentState.Cancelled,
                        ShipmentState.Closed,
                        ShipmentState.ClosedIncomplete,
                      ].includes(shipmentState)) && (
                      <Button
                        data-testid="add-items-button"
                        size="small"
                        color={
                          [ShipmentState.New].includes(shipmentState)
                            ? shipmentPackage.items?.length
                              ? 'secondary'
                              : 'primary'
                            : 'secondary'
                        }
                        onClick={onEditItems(shipmentPackage.id)}
                      >
                        {[ShipmentState.New].includes(shipmentState)
                          ? shipmentPackage.items?.length
                            ? t('shipments:packages.edit_items')
                            : t('shipments:packages.add_items')
                          : t('shipments:packages.view_items')}
                      </Button>
                    )}
                    {[
                      ShipmentState.InTransit,
                      ShipmentState.Arrived,
                      ShipmentState.Received,
                    ].includes(shipmentState) &&
                      !shipmentPackage.arrived_at && (
                        <>
                          <Button
                            data-testid="package-arrived-button"
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setSelectedSetPackageArrived([shipmentPackage.id])
                              setSetPackageArrivedDialogOpen(true)
                            }}
                          >
                            {t('shipments:packages.set_package_arrived')}
                          </Button>
                        </>
                      )}
                    {[ShipmentState.Arrived, ShipmentState.Received].includes(
                      shipmentState,
                    ) &&
                      shipmentPackage.arrived_at &&
                      shipmentPackage.total_quantity !==
                        shipmentPackage.total_quantity_received && (
                        <>
                          <Button
                            size="small"
                            color="secondary"
                            onClick={() => {
                              setSelectedPackage(shipmentPackage)
                              setReceivedPackageItemsDialogOpen(true)
                            }}
                          >
                            {t('shipments:packages.receive_items')}
                          </Button>
                        </>
                      )}
                  </Stack>
                }
              >
                <ListItemIcon>
                  <PackageItemIcon
                    shipmentPackage={shipmentPackage}
                    shipmentState={shipmentState}
                  />
                </ListItemIcon>
                <ListItemText
                  primary={t('shipments:packages.package_title', {
                    property: index + 1,
                  })}
                  secondaryTypographyProps={{
                    sx: {
                      whiteSpace: 'pre-line',
                    },
                  }}
                  secondary={
                    shipmentPackage.arrived_at ? (
                      <>
                        {[
                          shipmentPackage.total_quantity_received > 0
                            ? t('shipments:packages.items_received_total', {
                                received:
                                  shipmentPackage.total_quantity_received || 0,
                                total: shipmentPackage.total_quantity || 0,
                              })
                            : t('shipments:packages.package_amount', {
                                count: shipmentPackage.total_quantity || 0,
                              }),
                          t('shipments:packages.value', {
                            value: formatCurrency(shipmentPackage.value || 0),
                          }),
                          t('shipments:packages.arrived', {
                            value: formatRelativeDate(
                              shipmentPackage.arrived_at,
                            ),
                          }),
                        ].join('\n')}
                        {shipmentPackage.total_quantity_received > 0 && (
                          <>
                            {'\n'}
                            <ReceivedItemChip
                              label={t('shipments:packages.package_received')}
                              quantity={shipmentPackage.total_quantity}
                              quantityReceived={
                                shipmentPackage.total_quantity_received
                              }
                            />
                          </>
                        )}
                      </>
                    ) : (
                      t('shipments:packages.package_amount', {
                        count: shipmentPackage.total_quantity || 0,
                      })
                    )
                  }
                />
              </ListItem>

              {index + 1 < fields?.length && <Divider />}
            </Fragment>
          ))}

          {shipmentState === ShipmentState.New && (
            <ListItem sx={{ p: 0, minHeight: 'auto' }}>
              <LoadingButton
                data-testid="add-package-button"
                fullWidth
                color="tertiary"
                onClick={
                  isNewShipment
                    ? onAddPackageNewShipment
                    : onAddPackageExistingShipment
                }
                loading={loadingAddPackage}
              >
                {t('shipments:packages.add_package')}
              </LoadingButton>
            </ListItem>
          )}

          {[
            ShipmentState.InTransit,
            ShipmentState.Arrived,
            ShipmentState.Received,
          ].includes(shipmentState) &&
            fields.filter((x) => !x.arrived_at).length > 1 && (
              <ListItem sx={{ p: 0, minHeight: 'auto' }}>
                <Button
                  fullWidth
                  color="tertiary"
                  onClick={() => {
                    setSelectedSetPackageArrived(
                      fields.filter((x) => !x.arrived_at).map((x) => x.id),
                    )
                    setSetPackageArrivedDialogOpen(true)
                  }}
                >
                  {t('shipments:packages.set_all_packages_arrived')}
                </Button>
              </ListItem>
            )}
        </List>
      </FormFieldset>
    </>
  )
}
