import {
  CartIcon,
  ClothingShirtIcon,
  OrderIcon,
  PackageIcon,
} from '@sitoo/mui-components'
import { JSX } from 'react'
import { Campaign, CampaignType } from '../../generated/graphql'

type GenericCampaignType = {
  types: CampaignType[]
  icon: JSX.Element

  title: string
  caption: string

  name: string
}

export const CAMPAIGN_TYPE_CART: GenericCampaignType = {
  types: [
    CampaignType.NUMBER_100,
    CampaignType.NUMBER_110,
    CampaignType.NUMBER_120,
  ],
  icon: <CartIcon data-testid="cart-icon" />,

  title: 'campaigns:campaign_type_selection.cart_option',
  caption: 'campaigns:campaign_type_selection.cart_option_caption',

  name: 'campaigns:campaign_type_groups.cart',
}

export const CAMPAIGN_TYPE_PRODUCT: GenericCampaignType = {
  types: [
    CampaignType.NUMBER_200,
    CampaignType.NUMBER_210,
    CampaignType.NUMBER_220,
    CampaignType.NUMBER_230,
    CampaignType.NUMBER_240,
    CampaignType.NUMBER_250,
    CampaignType.NUMBER_260,
    CampaignType.NUMBER_265,
    CampaignType.NUMBER_270,
  ],
  icon: <ClothingShirtIcon data-testid="product-icon" />,

  title: 'campaigns:campaign_type_selection.product_option',
  caption: 'campaigns:campaign_type_selection.product_option_caption',

  name: 'campaigns:campaign_type_groups.product',
}

export const CAMPAIGN_TYPE_PACKAGE: GenericCampaignType = {
  types: [
    CampaignType.NUMBER_300,
    CampaignType.NUMBER_310,
    CampaignType.NUMBER_320,
  ],
  icon: <PackageIcon data-testid="package-icon" />,

  title: 'campaigns:campaign_type_selection.package_option',
  caption: 'campaigns:campaign_type_selection.package_option_caption',

  name: 'campaigns:campaign_type_groups.package',
}

export const CAMPAIGN_TYPE_PRICELIST: GenericCampaignType = {
  types: [CampaignType.NUMBER_400, CampaignType.NUMBER_410],
  icon: <OrderIcon data-testid="pricelist-icon" />,

  title: 'campaigns:campaign_type_selection.pricelist_option',
  caption: 'campaigns:campaign_type_selection.pricelist_option_caption',

  name: 'campaigns:campaign_type_groups.pricelist',
}

export type VoucherStates = 'active' | 'planned' | 'inactive'

export const getCampaignState = (
  campaign: Pick<Campaign, 'activepos' | 'datestart' | 'dateend'>,
): VoucherStates => {
  const { activepos, datestart, dateend } = campaign

  if (!activepos || (dateend && new Date(dateend) < new Date())) {
    return 'inactive'
  }

  if (activepos && datestart && new Date(datestart) > new Date()) {
    return 'planned'
  }

  return 'active'
}

export const getCampaignType = (
  campaignType?: CampaignType,
): GenericCampaignType | null => {
  if (!campaignType) return null

  if (CAMPAIGN_TYPE_CART.types.includes(campaignType)) return CAMPAIGN_TYPE_CART

  if (CAMPAIGN_TYPE_PRODUCT.types.includes(campaignType))
    return CAMPAIGN_TYPE_PRODUCT

  if (CAMPAIGN_TYPE_PACKAGE.types.includes(campaignType))
    return CAMPAIGN_TYPE_PACKAGE

  if (CAMPAIGN_TYPE_PRICELIST.types.includes(campaignType))
    return CAMPAIGN_TYPE_PRICELIST

  return null
}
