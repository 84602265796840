/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { Button, MenuItem, TextField } from '@mui/material'
import {
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  SectionHeader,
} from '@sitoo/mui-components'
import { useEffect, useMemo, useRef, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CountriesInput } from '../../../components/countries-input'
import {
  AddUpdateSettingsStoreDocument,
  AllStoreTagsDocument,
  DeleteSettingsStoreDocument,
  GetStoresDocument,
  SettingsStoreDocument,
  SettingsStoreQuery,
  SettingsStoresDocument,
  StoreType,
  WarehouseUseType,
} from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { validateCoordinate } from '../../../utils/validate'
import { LoadingButton } from '@mui/lab'
import { StoreTagsInput } from '../../../components/store-tags-input'
import { StoresInput } from '../../../components/stores-input'
import { KeyValueInput } from '../../../components/key-value-input'
import { useNavigate, useParams } from 'react-router-dom'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { RootRoute } from '../..'
import { useSnackbar } from 'notistack'
import { useScrollToError } from '../../../hooks/scroll-to-error'
import { FieldHighlight } from '../../../components/field-highlight'

type Store = Omit<SettingsStoreQuery['store'], 'nearby_stores'> & {
  nearby_stores?: { id: number }[]
}

export const StoreDialog = () => {
  const { t } = useTranslation(['shared', 'settings', 'stores', 'warehouses'])

  const { id } = useParams()
  const storeId = Number(id)
  const isNewStore = !storeId

  const { trackDialogOpen, trackDialogClose } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const dialogName = storeId ? 'edit-store' : 'add-store'
  const formId = 'store-form'

  const { data, loading: fetchLoading } = useQuery(SettingsStoreDocument, {
    variables: { storeId: Number(storeId) },
    fetchPolicy: 'cache-and-network',
    skip: !storeId,
  })

  const [addUpdateStoreMutation, { loading: addLoading }] = useMutation(
    AddUpdateSettingsStoreDocument,
    { refetchQueries: [SettingsStoresDocument, AllStoreTagsDocument] },
  )

  const [showDetailDialog, setDetailDialog] = useState(true)

  const [showDeleteDialog, setDeleteDialog] = useState(false)

  const { data: storesData, loading: fetchStoresLoading } =
    useQuery(GetStoresDocument)

  const [deleteStoreMutation, { loading: deleteLoading }] = useMutation(
    DeleteSettingsStoreDocument,
    {
      variables: {
        storeId: Number(id),
      },
      refetchQueries: [SettingsStoresDocument],
    },
  )

  const isLoading =
    fetchLoading || fetchStoresLoading || addLoading || deleteLoading

  const storeType = useMemo(() => {
    return Object.values(StoreType).map((value) => ({
      value,
      name: t(`stores:store_type.${value}`),
    }))
  }, [t])

  const warehouseUseType = useMemo(() => {
    return Object.values(WarehouseUseType).map((value) => ({
      value,
      name: t(`warehouses:warehouse_use_type.${value}`),
    }))
  }, [t])

  const { formState, reset, control, register, handleSubmit, watch } =
    useForm<Store>({
      defaultValues: {
        storetype: StoreType.Store,
      },
    })

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    navigate(generatePath(RootRoute.SettingsStores))
  }

  const [isFormReady, setFormReady] = useState(false)

  const useTypeNames = [
    'usetype_movein',
    'usetype_moveout',
    'usetype_manualin',
    'usetype_manualout',
    'usetype_shipmentin',
    'usetype_shipmentout',
  ] as const

  const nameRef = useRef<HTMLDivElement>(null)
  const countryRef = useRef<HTMLDivElement>(null)

  useScrollToError({ ref: nameRef, name: 'name', control })
  useScrollToError({ ref: countryRef, name: 'countryid', control })

  const onSubmit = (store: Store) => {
    const { id, nearby_stores, ...fields } = store

    void addUpdateStoreMutation({
      variables: {
        store: {
          ...fields,
          nearby_stores: nearby_stores?.map(({ id }) => id),
          storeid: store.id,
          externalid: store.externalid || undefined,
          externalgroupid: store.externalgroupid || undefined,
          usetype_movein: store.usetype_movein || undefined,
          usetype_moveout: store.usetype_moveout || undefined,
          usetype_manualin: store.usetype_manualin || undefined,
          usetype_manualout: store.usetype_manualout || undefined,
          usetype_shipmentin: store.usetype_shipmentin || undefined,
          usetype_shipmentout: store.usetype_shipmentout || undefined,
        },
      },
    })
      .then(() => {
        trackDialogClose({ name: dialogName })
        enqueueSnackbar(t('settings:store.dialog_update_success'))
        onClose()
      })
      .catch(() => {
        enqueueSnackbar(t('settings:store.dialog_update_fail'))
      })
  }

  const onDeleteDialogOpen = () => {
    setDeleteDialog(true)
    setDetailDialog(false)
  }

  const onDeleteDialogClose = () => {
    setDeleteDialog(false)
    setDetailDialog(true)
  }

  useEffect(() => {
    if (!storeId) {
      reset(undefined, { keepDefaultValues: true })
      setFormReady(true)
    }

    if (data?.store) {
      reset(
        {
          ...data.store,
          nearby_stores: data.store.nearby_stores?.map((id) => ({ id })),
        },
        { keepDefaultValues: true },
      )
      setFormReady(true)
    }
  }, [data, reset, storeId])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  return (
    <>
      <Dialog open={showDetailDialog} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>
          {isNewStore
            ? t('settings:store.add_store')
            : t('settings:store.edit_store')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.general_settings')}
            </SectionHeader>

            <Controller
              control={control}
              name="storetype"
              render={({ field, fieldState: { error } }) => (
                <TextField
                  select
                  value={field.value}
                  onChange={(event) => field.onChange(event.target.value)}
                  data-testid="store-type"
                  error={!!error?.message}
                  label={t('settings:store.store_type')}
                  helperText={error?.message}
                  sx={{ mb: 2 }}
                >
                  {storeType.map(({ name, value }) => (
                    <MenuItem value={value} key={name}>
                      {name}
                    </MenuItem>
                  ))}
                </TextField>
              )}
            />

            <TextField
              error={!!formState.errors.name}
              helperText={formState.errors.name?.message}
              label={t('settings:store.name')}
              required
              {...register('name', {
                required: t('shared:validation.field_required', {
                  field: t('settings:store.name'),
                }),
              })}
              inputRef={nameRef}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-name' }}
            />

            <TextField
              error={!!formState.errors.externalid}
              helperText={
                formState.errors.externalid?.message ||
                t('settings:store.external_id_helper')
              }
              label={t('settings:store.external_id')}
              {...register('externalid')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-externalid' }}
            />
            {!isLoading && !watch('externalid') && (
              <FieldHighlight
                label={t('shared:validation.external_id_warning')}
                sx={{ mt: -1, mb: 2 }}
                dataTestId="no-external-id-highlight"
              />
            )}

            <TextField
              error={!!formState.errors.externalgroupid}
              helperText={formState.errors.externalgroupid?.message}
              label={t('settings:store.external_group_id')}
              {...register('externalgroupid')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-externalgroupid' }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.contact_information')}
            </SectionHeader>

            <TextField
              error={!!formState.errors.phone}
              helperText={formState.errors.phone?.message}
              label={t('settings:store.phone')}
              {...register('phone')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-phone' }}
            />

            <TextField
              error={!!formState.errors.email}
              helperText={formState.errors.email?.message}
              label={t('settings:store.email')}
              {...register('email')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-email' }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.location')}
            </SectionHeader>

            <TextField
              error={!!formState.errors.address}
              helperText={formState.errors.address?.message}
              label={t('settings:store.address')}
              {...register('address')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-address' }}
            />

            <TextField
              error={!!formState.errors.address2}
              helperText={formState.errors.address2?.message}
              label={t('settings:store.address2')}
              {...register('address2')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-address2' }}
            />

            <TextField
              error={!!formState.errors.zip}
              helperText={formState.errors.zip?.message}
              label={t('settings:store.zip')}
              {...register('zip')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-zip' }}
            />

            <TextField
              error={!!formState.errors.city}
              helperText={formState.errors.city?.message}
              label={t('settings:store.city')}
              {...register('city')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-city' }}
            />

            <TextField
              error={!!formState.errors.state}
              helperText={formState.errors.state?.message}
              label={t('settings:store.state')}
              {...register('state')}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-state' }}
            />

            <CountriesInput
              innerRef={countryRef}
              name="countryid"
              control={control}
              isRequired
              sx={{ mb: 2 }}
            />

            <TextField
              error={!!formState.errors.latitude}
              helperText={formState.errors.latitude?.message}
              label={t('settings:store.latitude')}
              {...register('latitude', {
                setValueAs: (value) => (value ? String(value) : undefined),
                validate: (value) => {
                  if (value && !validateCoordinate(value)) {
                    return t('settings:store.validation.coordinate')
                  }
                },
              })}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-latitude' }}
            />

            <TextField
              error={!!formState.errors.longitude}
              helperText={formState.errors.longitude?.message}
              label={t('settings:store.longitude')}
              {...register('longitude', {
                setValueAs: (value) => (value ? String(value) : undefined),
                validate: (value) => {
                  if (value && !validateCoordinate(value)) {
                    return t('settings:store.validation.coordinate')
                  }
                },
              })}
              sx={{ mb: 2 }}
              inputProps={{ 'data-testid': 'store-longitude' }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.nearby_stores')}
            </SectionHeader>

            <StoresInput
              stores={storesData?.stores || []}
              isLoading={fetchStoresLoading}
              name="nearby_stores"
              control={control}
              multiple
              sx={{ '.MuiFormControl-root': { mb: 2 } }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.default_warehouse_use_type')}
            </SectionHeader>

            {useTypeNames.map((name) => (
              <Controller
                control={control}
                name={name}
                key={name}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    select
                    value={field.value || ''}
                    onChange={(event) => field.onChange(event.target.value)}
                    data-testid={name}
                    error={!!error?.message}
                    label={t(`settings:store.${name}`)}
                    helperText={error?.message}
                    sx={{ mb: 2 }}
                  >
                    {warehouseUseType.map(({ name, value }) => (
                      <MenuItem value={value} key={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            ))}

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.store_tags')}
            </SectionHeader>

            <StoreTagsInput
              name="tags"
              freeSolo
              control={control}
              helperText={t('settings:store.store_tags_description')}
              sx={{ '.MuiFormControl-root': { mb: 2 } }}
            />

            <SectionHeader variant="transparent" sx={{ p: 0, mt: 2, mb: 1 }}>
              {t('settings:store.extra_settings')}
            </SectionHeader>

            {isFormReady && (
              <KeyValueInput name="additional_data" control={control} />
            )}

            {!isNewStore && (
              <LoadingButton
                fullWidth
                loading={isLoading}
                color="error"
                sx={{ mt: 2 }}
                onClick={onDeleteDialogOpen}
              >
                {t('shared:action.delete')}
              </LoadingButton>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={onClose}
            data-testid="dialog-cancel-store"
          >
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            color="primary"
            onClick={handleSubmit(onSubmit)}
            data-testid="dialog-add-store"
            form={formId}
          >
            {isNewStore ? t('shared:action.add') : t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        confirmAction={() => {
          void deleteStoreMutation()

          onDeleteDialogClose()
          onClose()
        }}
        title={t('settings:store.dialog_delete_label')}
        text={t('settings:store.dialog_delete_description', {
          store: data?.store.name,
        })}
        variant="destructive"
        open={showDeleteDialog}
        onClose={onDeleteDialogClose}
      />
    </>
  )
}
