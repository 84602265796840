import { useReactiveVar } from '@apollo/client'
import { Button, Container, Grid } from '@mui/material'
import { useTranslation } from 'react-i18next'
import { FilterProvider } from '../../components/data-grid/context'
import { PageHeader } from '../../components/page-header'
import { GetInfluencerCodesQuery } from '../../generated/graphql'
import { usePageTitle } from '../../hooks/title'
import { useTracking } from '../../hooks/tracking'
import { ArrayElement } from '../../utils/types'
import { InfluencerCodesList } from './influencer-codes-list'
import {
  influencerCodesFilterPanelVar,
  influencerCodesViewPanelVar,
} from './variables'
import { InfluencerCodesViewPanel } from './influencer-codes-view-panel'
import { InfluencerCodesFilterPanel } from './influencer-codes-filter-panel'
import { Link } from 'react-router-dom'
import { useAbsolutePath } from '../../hooks/absolute-path'
import { RootRoute } from '..'
import { InfluencerCodesContextMenu } from './influence-codes-context-menu'

export type Row = ArrayElement<
  GetInfluencerCodesQuery['influencerCodes']['items']
>

export const InfluencerCodesPage = () => {
  const { t } = useTranslation(['influencer_codes', 'shared'])
  usePageTitle(t('influencer_codes:page_title'))
  const viewPanelState = useReactiveVar(influencerCodesViewPanelVar)
  const filterPanelState = useReactiveVar(influencerCodesFilterPanelVar)
  const generatePath = useAbsolutePath()
  const { trackButtonClick, trackButtonClickEvent } = useTracking()

  const openViewPanel = (item: Row) => {
    if (filterPanelState.isOpen) {
      closeFilterViewPanel()
    }

    trackButtonClick({
      name: 'influencer-codes-view-panel-open',
      influencerCode: item?.code,
    })
    influencerCodesViewPanelVar({ isOpen: true, selectedItem: item })
  }

  const closeViewPanel = () => {
    trackButtonClick({ name: 'influencer-code-view-panel-close' })
    influencerCodesViewPanelVar({ isOpen: false, selectedItem: undefined })
  }

  const closeFilterViewPanel = () => {
    trackButtonClick({ name: 'influencer-codes-filter-panel-close' })
    influencerCodesFilterPanelVar({ isOpen: false })
  }

  const openFilterViewPanel = () => {
    trackButtonClick({ name: 'influencer-codes-filter-panel-open' })
    influencerCodesFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterPanelState.isOpen) {
      closeFilterViewPanel()
    } else {
      openFilterViewPanel()
    }
  }

  return (
    <FilterProvider>
      <Grid container flexWrap="nowrap">
        <Grid item flexGrow={1}>
          <PageHeader
            title={t('influencer_codes:page_title')}
            isFlexible
            rightColumn={
              <>
                <InfluencerCodesContextMenu />
                <Button
                  component={Link}
                  to={generatePath(RootRoute.InfluencerCodeNew)}
                  onClick={trackButtonClickEvent({
                    name: 'influencer-codes-add',
                  })}
                  data-testid="add-influencer-code-button"
                >
                  {t('shared:action.add')}
                </Button>
              </>
            }
          />
          <Container maxWidth={false}>
            <InfluencerCodesList
              onDetail={openViewPanel}
              selectedId={viewPanelState.selectedItem?.['code']}
              onShowFilter={toggleFilterViewPanel}
            />
          </Container>
        </Grid>
        <Grid item>
          <InfluencerCodesViewPanel
            open={viewPanelState.isOpen}
            selectedId={viewPanelState.selectedItem?.code}
            onClose={closeViewPanel}
          />

          <InfluencerCodesFilterPanel
            isOpen={filterPanelState.isOpen}
            onClose={closeFilterViewPanel}
          />
        </Grid>
      </Grid>
    </FilterProvider>
  )
}
