import { useEffect, useRef } from 'react'

export const usePreviousNotNullOrUndefined = <T>(state: T) => {
  const ref = useRef<T>()

  useEffect(() => {
    if (state !== undefined && state !== null) ref.current = state
  })

  return ref.current
}

export const usePreviousNotValue = <T>(state: T, notValue: unknown) => {
  const ref = useRef<T>()

  useEffect(() => {
    if (state !== notValue) ref.current = state
  })

  return ref.current
}
