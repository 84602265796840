import { Button, InputLabel, MenuItem, Select, TextField } from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { useTracking } from '../../../../hooks/tracking'
import { codes } from 'currency-codes'
import { PurchasePriceList } from '..'
import { useAuthorization } from '../../../../hooks/authorization'

type Props = {
  open: boolean
  priceList?: PurchasePriceList | null
  existingIds: string[]

  onClose: () => void
  onSuccess: (
    priceList: PurchasePriceList,
    previousPriceList: PurchasePriceList | undefined,
  ) => void
}

export const PurchasePriceListDialog = (props: Props) => {
  const { i18n, t } = useTranslation(['shared', 'settings'])
  const {
    modules: { writeSettingsPurchasePriceList },
  } = useAuthorization()

  const { trackDialogOpen, trackDialogClose } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = props.priceList
    ? 'edit-purchase-price-list'
    : 'add-purchase-price-list'

  const isNewPricelist = !props.priceList
  const canUpdateId = isNewPricelist || !props.priceList?.__typename

  const formContext = useForm<PurchasePriceList>()

  const submit = (data: PurchasePriceList) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess(data, props.priceList || undefined)
  }

  useEffect(
    () => formContext.reset(props.priceList || {}),
    [formContext, props.priceList, props.open],
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const currencyCodes = useMemo(() => {
    const currencyNames = new Intl.DisplayNames([i18n.language], {
      type: 'currency',
    })

    return codes().map((code) => ({
      code,
      name: currencyNames.of(code) as string,
    }))
  }, [i18n.language])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  const isCurrencyFieldDisabled =
    !writeSettingsPurchasePriceList || !isNewPricelist

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {!writeSettingsPurchasePriceList
          ? t('settings:purchase_price_lists.view_purchase_price_list')
          : isNewPricelist
            ? t('settings:purchase_price_lists.add_purchase_price_list')
            : t('settings:purchase_price_lists.edit_purchase_price_list')}
      </DialogTitle>

      <DialogContent>
        <TextField
          fullWidth
          error={!!formContext.formState.errors.id}
          helperText={formContext.formState.errors.id?.message}
          label={t('settings:purchase_price_lists.id')}
          {...formContext.register('id', {
            required: t('shared:validation.field_required', {
              field: t('settings:purchase_price_lists.id'),
            }),

            validate: (id) =>
              props.existingIds
                .filter((x) => x !== props.priceList?.id)
                .some((x) => x === id) && canUpdateId
                ? t('shared:validation.field_already_exists', {
                    field: t('settings:purchase_price_lists.id'),
                  })
                : true,
          })}
          inputProps={{ 'data-testid': 'purchase-price-list-id' }}
          sx={{ mb: 2 }}
          disabled={!canUpdateId || !writeSettingsPurchasePriceList}
        />

        <TextField
          fullWidth
          error={!!formContext.formState.errors.name}
          helperText={formContext.formState.errors.name?.message}
          label={t('settings:purchase_price_lists.name')}
          {...formContext.register('name', {
            required: t('shared:validation.field_required', {
              field: t('settings:purchase_price_lists.name'),
            }),
          })}
          inputProps={{ 'data-testid': 'purchase-price-list-name' }}
          sx={{ mb: 2 }}
          disabled={!writeSettingsPurchasePriceList}
        />

        <Controller
          control={formContext.control}
          name="currency"
          //  TODO: get from settings endpoint the default account currency
          defaultValue="SEK"
          render={({ field }) => (
            <>
              <InputLabel>
                {t('settings:purchase_price_lists.currency')}
              </InputLabel>
              <Select
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                data-testid="purchase-price-list-currency-select"
                sx={{ mb: 2 }}
                disabled={isCurrencyFieldDisabled}
              >
                {currencyCodes.map(({ code, name }) => (
                  <MenuItem value={code} key={code}>
                    {`${code}, ${name}`}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-purchase-price-list"
        >
          {t(
            writeSettingsPurchasePriceList
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsPurchasePriceList && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-purchase-price-list"
          >
            {isNewPricelist ? t('shared:action.add') : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
