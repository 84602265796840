import { Dialog } from '@sitoo/mui-components'
import { useState } from 'react'
import { FormProvider, useForm, useFormContext } from 'react-hook-form'
import {
  GetProductQuery,
  VariantGroupInput,
} from '../../../../../generated/graphql'
import { ArrayElement } from '../../../../../utils/types'
import { ActivateVariantsFirstStep } from './first-step'
import { ActivateVariantsSecondStep } from './second-step'

export type ActivateVariantsDialogProps = {
  open: boolean
  onSuccess(): void
  onClose(): void
}

export type ActiveVariantsForm = {
  variantGroups: VariantGroupInput[]
  generateVariants: boolean
  generateBarcode: boolean
  generateSku: boolean
}

export const ActivateVariantsDialog = ({
  open,
  onSuccess,
  onClose,
}: ActivateVariantsDialogProps) => {
  const [isFirstStep, setIsFirstStep] = useState<boolean>(true)
  const { setValue, getValues } = useFormContext<GetProductQuery>()
  const defaultValues: ActiveVariantsForm = {
    variantGroups: [],
    generateVariants: true,
    generateBarcode: false,
    generateSku: false,
  }
  const form = useForm<ActiveVariantsForm>({
    defaultValues,
  })

  const handleSubmit = (data: ActiveVariantsForm) => {
    const mainVariant = getValues('product')
    setValue('product.variantGroups', data.variantGroups, {
      shouldDirty: true,
    })
    setValue('product.isMainVariant', true)
    setValue('product.isSingleProduct', false)

    const baseProduct: ArrayElement<
      GetProductQuery['product']['childVariants']
    > = {
      isMainVariant: false,
      isSingleProduct: false,
      customattributes: mainVariant.customattributes,
      moneyprice: mainVariant.moneyprice,
      moneypriceorg: mainVariant.moneypriceorg,
      title: mainVariant.title,
      productImages: mainVariant.productImages,

      id: 0,
      sku: '',
      barcode: '',
      variant: [],
      // id: mainVariant.id,
      // sku: mainVariant.sku,
      // barcode: mainVariant.barcode,
      // variant: data.variantGroups.map((x) => ({
      //   name: x.name,
      //   value: '',
      // })),
    }

    const variants: GetProductQuery['product'][] = [
      {
        ...baseProduct,
        isMainVariant: true,
        isSingleProduct: false,
        id: mainVariant.id || 0,
        sku: mainVariant.sku,
        barcode: mainVariant.barcode,
        variant: data.variantGroups.map((x) => ({
          name: x.name,
          value: '',
        })),
      },
    ]

    const variantGroupsWithOptions = data.variantGroups.filter(
      (x) => x.options.length > 0,
    )
    if (data.generateVariants && variantGroupsWithOptions.length > 0) {
      const allOptions = variantGroupsWithOptions.reduce<
        {
          value: string
          variants: { name: string; value: string }[]
        }[]
      >(
        (p, c) =>
          p.flatMap((x) =>
            c.options.map((o) => ({
              variants: [...x.variants, { name: c.name, value: o }],
              value: `${x.value}${x.value ? '-' : ''}${o}`.toLocaleUpperCase(),
            })),
          ),
        [{ value: '', variants: [] }],
      )

      const baseBarcode = mainVariant.barcode || ''
      const baseSku = mainVariant.sku || ''
      let baseId = mainVariant.id || 0
      for (const [index, option] of allOptions.entries()) {
        const generatedSku = data.generateSku
          ? `${baseSku}${baseSku ? '-' : ''}${option.value}`
          : ''
        const generatedBarcode = data.generateBarcode
          ? `${baseBarcode}${baseBarcode ? '-' : ''}${option.value}`
          : ''

        //  We apply the first generated sku and barcode to the main variant
        if (index === 0) {
          variants[0]!.sku = generatedSku || baseSku
          variants[0]!.barcode = generatedBarcode || baseBarcode
          variants[0]!.variant = option.variants

          setValue('product.sku', variants[0]!.sku)
          setValue('product.barcode', variants[0]!.barcode)
        } else {
          baseId++
          variants.push({
            ...baseProduct,
            id: baseId,
            sku: generatedSku,
            barcode: generatedBarcode,
            variant: option.variants,
          })
        }
      }
    }

    setValue('product.childVariants', variants)
    onSuccess()
  }

  const internalOnClose = () => {
    form.reset(defaultValues)
    setIsFirstStep(true)
    onClose()
  }

  return (
    <Dialog open={open} onClose={internalOnClose} fullWidth maxWidth="xs">
      <FormProvider {...form}>
        {isFirstStep ? (
          <ActivateVariantsFirstStep
            onNext={() => {
              setIsFirstStep(false)
            }}
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onSubmit={form.handleSubmit(handleSubmit)}
            onCancel={internalOnClose}
          />
        ) : (
          <ActivateVariantsSecondStep
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onNext={form.handleSubmit(handleSubmit)}
            onBack={() => setIsFirstStep(true)}
            onCancel={internalOnClose}
          />
        )}
      </FormProvider>
    </Dialog>
  )
}
