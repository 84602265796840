import { Divider, FormControl, IconButton, TextField } from '@mui/material'
import { CloseIcon, SearchIcon } from '@sitoo/mui-components'
import { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { useStateParams } from '../../../hooks/state-params'
import { useTracking } from '../../../hooks/tracking'
import { FilterContext } from '../context'

export type TextFilterProps = {
  initialValue?: string
  placeholder?: string
  name: string
}

type QueryParamState = {
  text?: string
}

export const TextFilter = (props: TextFilterProps) => {
  const { t } = useTranslation(['shared', 'filter'])
  const { setFilter, removeFilter, subscribeOnResetFilter, registerFilter } =
    useContext(FilterContext)
  const { trackInputFocus, trackInputBlur } = useTracking()
  const filterKey = 'text'

  const [queryParams, setQueryState, setQueryParams] =
    useStateParams<QueryParamState>(
      { [filterKey]: undefined },
      { persistState: false },
    )

  const searchText = queryParams[filterKey] || ''

  const [debouncedSearchText, setDebouncedSearchText] =
    useState<string>(searchText)

  useDebounce(
    () => {
      setDebouncedSearchText(searchText)
      // Update queryParams with debounced value
      setQueryParams({ [filterKey]: searchText || undefined })
    },
    300,
    [searchText],
  )

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // Update queryParams state and skip updating url until debounce is fired
    setQueryState({ [filterKey]: e.target.value || undefined }, false)
  }

  useEffect(() => {
    registerFilter({
      key: filterKey,
    })
  }, [registerFilter])

  useEffect(() => {
    const unsubscribe = subscribeOnResetFilter((key) => {
      if (!key || key === filterKey) setQueryState({ [filterKey]: undefined })
    })
    return () => {
      unsubscribe()
    }
  }, [setQueryState, subscribeOnResetFilter])

  useEffect(() => {
    if (debouncedSearchText) {
      setFilter(filterKey, {
        label: t('filter:text_filter.label'),
        labelValues: [debouncedSearchText],
        value: debouncedSearchText,
      })
    } else {
      removeFilter(filterKey)
    }
  }, [debouncedSearchText, removeFilter, setFilter, setQueryState, t])

  return (
    <FormControl sx={{ flexGrow: 1 }}>
      <TextField
        type="text"
        placeholder={props.placeholder || t('shared:grid.search_list')}
        value={searchText}
        onFocus={trackInputFocus({ name: `grid-text-filter-${props.name}` })}
        onBlur={trackInputBlur({ name: `grid-text-filter-${props.name}` })}
        onChange={onChange}
        InputProps={{
          startAdornment: <SearchIcon fontSize="medium" />,
          endAdornment: searchText && (
            <IconButton
              sx={{ marginRight: 0.5 }}
              onClick={() =>
                onChange({
                  target: { value: '' },
                } as React.ChangeEvent<HTMLInputElement>)
              }
            >
              <CloseIcon
                sx={{
                  margin: '0 !important',
                }}
              />
            </IconButton>
          ),
        }}
        inputProps={{
          background: 'paper',
          'data-testid': 'search-text-field',
        }}
        sx={{
          '&.MuiTextField-root.MuiFormControl-root .MuiInputBase-root.MuiInput-root::before, &.MuiTextField-root.MuiFormControl-root .MuiInputBase-root.MuiInput-root::after':
            {
              borderBottom: 0,
            },
          '.MuiInputBase-root': {
            height: (theme) => theme.spacing(6),
          },
        }}
      />
      <Divider
        sx={{
          borderWidth: '1px',
        }}
      />
    </FormControl>
  )
}
