/* eslint-disable @typescript-eslint/no-misused-promises */
import LoadingButton from '@mui/lab/LoadingButton'
import { Container } from '@mui/material'
import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { RootRoute } from '../..'
import { PageHeader } from '../../../components/page-header'
import { ShipmentInput } from '../../../generated/graphql'
import { usePageTitle } from '../../../hooks/title'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { Information } from '../information'
import { useNewShipmentFormSubmit } from './submit'
import { BaseShipmentFormContext } from '../shared'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

export type NewShipmentFormContext = BaseShipmentFormContext & ShipmentInput

export const NewShipmentPage = () => {
  const { t } = useTranslation(['shipments', 'shared'])
  usePageTitle(t('shipments:page_title_add_shipment'))
  const formContext = useForm<NewShipmentFormContext>({
    defaultValues: {
      packages: [],
    },
  })
  const { onSubmit, isSubmitting, onError } = useNewShipmentFormSubmit({
    setError: formContext.setError,
  })
  const generatePath = useAbsolutePath()

  const isLoading = isSubmitting

  return (
    <>
      <RouteLeavingGuard
        when={
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shipments:page_title_add_shipment')}
        backTo={generatePath(RootRoute.Shipments)}
        backText={t('shared:menu.shipments')}
        showBackButton={true}
        isSticky={true}
        rightColumn={
          <>
            <LoadingButton
              loading={isLoading}
              data-testid="shipment-submit-button"
              onClick={formContext.handleSubmit(onSubmit, onError)}
            >
              {t('shipments:action_title_add_shipment')}
            </LoadingButton>
          </>
        }
      />

      <Container>
        <FormProvider {...formContext}>
          <Information />
        </FormProvider>
      </Container>
    </>
  )
}
