import {
  Container,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
} from '@mui/material'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { FormProvider, useForm } from 'react-hook-form'
import {
  UpdateAdyenApiPluginDocument,
  GetPluginsDocument,
  PluginId,
  PluginAdyenApi,
} from '../../../generated/graphql'
import { PosSettingsForm } from '../pos-settings/pos-settings-form'
import { useMutation, useQuery } from '@apollo/client'
import { usePageTitle } from '../../../hooks/title'
import { SwitchInput } from '../../../components/switch-input'

type Form = PluginAdyenApi

export const SettingsAdyenTerminalApiPage = () => {
  const { t } = useTranslation(['shared', 'apps'])
  usePageTitle(t('shared:menu.adyen_terminal_api'))

  const defaultValues: Form = {
    keyidentifier: null,
    keypassphrase: null,
    keyversion: null,
    istestmode: null,
  }

  const formContext = useForm<Form>({ defaultValues })
  const { register, reset, control, formState } = formContext
  const { isSubmitting } = formState

  useQuery(GetPluginsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: { pluginId: PluginId.Adyenapi },
    onCompleted: (data) =>
      reset(data.plugin as Form, { keepDefaultValues: false }),
  })

  const [updateSettings] = useMutation(UpdateAdyenApiPluginDocument)

  const onSubmit = async (data: Form) => {
    await updateSettings({ variables: { data } })
  }

  return (
    <>
      <PageHeader title={t('shared:menu.adyen_terminal_api')} />

      <Container>
        <Paper elevation={0} sx={{ pt: 1 }}>
          <FormProvider {...formContext}>
            <PosSettingsForm onSubmit={onSubmit}>
              <List>
                <ListItem divider>
                  <TextField
                    {...register('keyidentifier')}
                    label={t('apps:adyen_terminal_api.key_identifier_label')}
                    helperText={t(
                      'apps:adyen_terminal_api.key_identifier_helper',
                    )}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <TextField
                    {...register('keypassphrase')}
                    label={t('apps:adyen_terminal_api.key_passphrase_label')}
                    helperText={t(
                      'apps:adyen_terminal_api.key_passphrase_helper',
                    )}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <TextField
                    {...register('keyversion')}
                    label={t('apps:adyen_terminal_api.key_version_label')}
                    helperText={t('apps:adyen_terminal_api.key_version_helper')}
                    disabled={isSubmitting}
                    sx={{ mb: 2 }}
                  />
                </ListItem>

                <ListItem divider>
                  <ListItemText>
                    {t('apps:adyen_terminal_api.is_test_mode_label')}
                  </ListItemText>
                  <SwitchInput
                    name="istestmode"
                    control={control}
                    disabled={isSubmitting}
                  />
                </ListItem>
              </List>
            </PosSettingsForm>
          </FormProvider>
        </Paper>
      </Container>
    </>
  )
}
