import { IconButton } from '@mui/material'
import { GridRowModel, useGridApiContext } from '@mui/x-data-grid-pro'
import { DeleteIcon } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { DeleteShipmentDialog } from '../../delete-shipment-dialog'

export const DeleteShipment = () => {
  const { t } = useTranslation(['shipments'])
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const { enqueueSnackbar } = useSnackbar()
  const apiRef = useGridApiContext()
  const selectedIds = (
    Array.from(apiRef.current.getSelectedRows().values()) as GridRowModel<{
      id: string
    }>[]
  ).map((c) => c.id)

  const onClose = () => {
    setIsDialogOpen(false)
  }

  const onSuccess = (deletedIds: string[]) => {
    if (deletedIds.length) {
      enqueueSnackbar(
        t('shipments:success_delete_shipment_snackbar', {
          count: deletedIds.length,
        }),
      )
    }

    onClose()
  }

  const onError = () => {
    onClose()
  }

  return (
    <>
      <DeleteShipmentDialog
        open={isDialogOpen}
        onClose={onClose}
        selectedIds={selectedIds}
        onSuccess={onSuccess}
        onError={onError}
      />
      <IconButton
        onClick={() => setIsDialogOpen(true)}
        data-testid="bulk-delete-shipments"
      >
        <DeleteIcon />
      </IconButton>
    </>
  )
}
