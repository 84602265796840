import { Alert, SxProps, Typography } from '@mui/material'

type Props = {
  label: string
  dataTestId?: string
  sx?: SxProps
}

export const FieldHighlight = ({ label, dataTestId, sx }: Props) => {
  return (
    <Alert
      data-testid={dataTestId}
      severity="info"
      sx={{
        backgroundColor: (theme) => theme.palette.yellow,
        '.MuiAlert-message': { p: (theme) => theme.spacing(0.25, 0) },
        '.MuiAlert-icon': { mr: 1, p: (theme) => theme.spacing(0.5, 0) },
        '.MuiAlert-icon svg': { fontSize: '1rem' },
        ...sx,
      }}
    >
      <Typography variant="caption">{label}</Typography>
    </Alert>
  )
}
