import {
  GridCellParams,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useSnackbar } from 'notistack'
import { useCallback, useContext, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import {
  GetWarehouseBatchItemsQueryVariables,
  DeleteWarehouseBatchItemsDocument,
  GetWarehouseBatchItemsDocument,
  UpdateWarehouseBatchItemsDocument,
  GetWarehouseBatchItemsQuery,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { ArrayElement } from '../../../../utils/types'
import { useMoney } from '../../../../hooks/money'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { ProductStateRenderer } from '../../../products/product-list/product-state'
import { StockRenderer } from '../../../../components/data-grid/utils/stock-renderer'
import {
  StringEditRenderer,
  StringRenderer,
} from '../../../../components/data-grid/utils/string-renderer'
import { DataGrid } from '../../../../components/data-grid'
import { FolderIcon } from '@sitoo/mui-components'
import { useAuthorization } from '../../../../hooks/authorization'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useMutation, useQuery } from '@apollo/client'
import { FilterContext } from '../../../../components/data-grid/context'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'

type Row = ArrayElement<
  GetWarehouseBatchItemsQuery['warehouseBatchItems']['items']
>

type Props = {
  warehouseId: number
  warehouseBatchId: number
}

const PAGE_SIZE = 100

export const OpenDeliveryInList = (props: Props) => {
  const { t } = useTranslation(['shared', 'delivery_in', 'products'])
  const { warehouseId, warehouseBatchId } = props
  const { trackInputFocus, trackInputBlur, trackFormSuccess, trackFormError } =
    useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const {
    modules: { writeDeliveryIn },
  } = useAuthorization()

  const apiRef = useGridApiRef()

  const [updateWarehouseBatchItems] = useMutation(
    UpdateWarehouseBatchItemsDocument,
  )
  const [deleteWarehouseBatchItems] = useMutation(
    DeleteWarehouseBatchItemsDocument,
  )

  const {
    formatCurrency,
    formatStringCurrencyToNumber,
    formatEditableCurrency,
  } = useMoney()

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'product-images',
      ...ColumnProps.image,
      headerName: '',
      valueGetter: (_value, row): string => row.product?.images?.[0] || '',
      cellClassName: 'image-column',
      headerClassName: 'image-column',
      renderCell: DataGridImageRenderer,
    },
    {
      field: 'product-id',
      ...ColumnProps.productId,
      headerName: t('delivery_in:product_id'),
      valueGetter: (_value, row) => row.product?.id || '',
    },
    {
      field: 'sku',
      ...ColumnProps.sku,
      headerName: t('delivery_in:sku'),
    },
    {
      field: 'product-title',
      ...ColumnProps.productTitle,
      headerName: t('delivery_in:product'),
      valueGetter: (_value, row): CaptionRendererValue => ({
        title: row.product?.title,
        caption: row.product?.variant?.[0]?.value,
      }),
      renderCell: CaptionRenderer,
    },
    {
      field: 'product-activepos',
      ...ColumnProps.productActive,
      headerName: t('products:status'),
      renderCell: (params) =>
        ProductStateRenderer({
          row: { activepos: params.row.product?.activepos || null },
        }),
    },
    {
      field: 'decimaltotal',
      minWidth: 120,
      headerName: t('delivery_in:stock_label'),
      renderCell: StockRenderer,
      valueGetter: (_value, row) => {
        const total = row.product?.warehouseItems?.find(
          (x) => x.warehouse?.id === warehouseId,
        )?.decimaltotal
        return Number(total || 0)
      },
    },
    {
      field: 'decimalquantity',
      minWidth: 120,
      headerName: t('delivery_in:quantity_label'),
      editable: writeDeliveryIn,
      valueGetter: (_value, row): string | undefined => {
        const quantity = row.decimalquantity
        return quantity ? String(Number(quantity)) : undefined
      },

      valueSetter: (value, row) => {
        const decimalquantity = value ? Number(value).toFixed(3) : undefined

        return { ...row, decimalquantity }
      },

      renderCell: (params: GridRenderCellParams<Row, string>) =>
        StringRenderer({ ...params, value: params.formattedValue }),

      renderEditCell: (props) =>
        StringEditRenderer(
          props,
          trackInputFocus,
          trackInputBlur,
          'delivery-in-quantity',
        ),
    },
    {
      field: 'moneypricein',
      ...ColumnProps.editablePrice,
      headerName: t('delivery_in:purchase_price_label'),
      editable: writeDeliveryIn,
      valueSetter: (value, row) => {
        const newPrice =
          typeof value === 'string'
            ? formatStringCurrencyToNumber(value).toFixed(2)
            : undefined
        return { ...row, moneypricein: newPrice }
      },

      valueGetter: (_value, row) => {
        const moneyPrice = row.moneypricein
        return moneyPrice ? formatEditableCurrency(moneyPrice) : undefined
      },

      valueFormatter: (value: string): string => formatCurrency(value || ''),

      renderEditCell: (props) =>
        StringEditRenderer(
          props,
          trackInputFocus,
          trackInputBlur,
          'delivery-in-purchase-price',
        ),
      renderCell: (params: GridRenderCellParams<Row, string>) =>
        StringRenderer({ ...params, value: params.formattedValue }),
    },
  ]

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }
    const variables: GetWarehouseBatchItemsQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      warehouseid: warehouseId,
      warehousebatchid: warehouseBatchId,
    }

    return variables
  }, [apiRef, filter, warehouseBatchId, warehouseId])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
    refetch: refetchQuery,
  } = useQuery(GetWarehouseBatchItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady,
  })

  const isLoading = fetchLoading

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.warehouseBatchItems.totalcount) {
      return fetchMore({
        variables: {
          start: (data.warehouseBatchItems?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const processRowUpdate = useCallback(
    async (newRow: Row, oldRow: Row) => {
      const newPrice = newRow.moneypricein
      const oldPrice = oldRow.moneypricein
      const newQuantity = newRow.decimalquantity
      const oldQuantity = oldRow.decimalquantity

      if (newPrice === oldPrice && newQuantity === oldQuantity) {
        return newRow
      }

      try {
        if (newQuantity !== undefined) {
          await updateWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              warehouseBatchItems: [
                {
                  sku: newRow.sku,
                  decimalquantity: newQuantity,
                  moneypricein: newPrice
                    ? Number(newPrice).toFixed(2)
                    : undefined,
                },
              ],
            },
          })
        } else {
          await deleteWarehouseBatchItems({
            variables: {
              warehouseId,
              warehouseBatchId,
              skus: [newRow.sku],
            },
          })

          void refetchQuery()
        }

        trackFormSuccess({ name: 'delivery-in-list-row' })
        enqueueSnackbar(
          t('delivery_in:success_update_delivery_in_snackbar', {
            count: 1,
          }),
        )

        return newRow
      } catch (error) {
        trackFormError({ name: 'delivery-in-list-row' })
        enqueueSnackbar(
          t('delivery_in:failure_update_delivery_in_snackbar', {
            count: 1,
          }),
          { variant: 'error' },
        )

        return oldRow
      }
    },
    [
      deleteWarehouseBatchItems,
      enqueueSnackbar,
      refetchQuery,
      t,
      trackFormError,
      trackFormSuccess,
      updateWarehouseBatchItems,
      warehouseBatchId,
      warehouseId,
    ],
  )

  const isCellEditable = (params: GridCellParams<Row>) => {
    return !(params.field === 'moneypricein' && !params.row.decimalquantity)
  }

  const rows = useMemo(() => {
    return data?.warehouseBatchItems.items?.map((item) => {
      return { ...item, id: item.warehousebatchitemid }
    })
  }, [data])

  return (
    <DataGrid
      name="open-delivery-in-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      processRowUpdate={processRowUpdate}
      rows={rows}
      rowCount={data?.warehouseBatchItems?.totalcount || 0}
      loading={isLoading}
      fetchMore={fetchMoreItems}
      isCellEditable={isCellEditable}
      slots={{ footer: CursorPagination }}
      noRowsOverlay={{
        icon: <FolderIcon />,
        title: t('delivery_in:open_delivery_in_grid.title'),
        description: t('delivery_in:open_delivery_in_grid.description'),
      }}
      rowHeight={50}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      disableColumnFilter
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      columnVisibilityModel={{
        ['product-id']: false,
        ['product-activepos']: false,
      }}
      showMore={
        Number(data?.warehouseBatchItems.items?.length) <
        Number(data?.warehouseBatchItems.totalcount)
      }
    />
  )
}
