import { DeleteIcon } from '@sitoo/mui-components'
import { useMemo } from 'react'
import {
  CustomAttributeTypeEnum,
  AllCustomAttributesDocument,
} from '../../../../generated/graphql'
import { Box } from '@mui/system'
import { Autocomplete, IconButton, TextField } from '@mui/material'
import { AttributesMenu } from './attributes-menu'
import { useQuery } from '@apollo/client'
import { ALL_PRODUCTS_ATTR } from '../../../../utils/constants'

export type CustomAttributeValue = string | number | null | undefined

export type AttributesMap = {
  [attributeId: string]: NonNullable<CustomAttributeValue>[]
}

type AttributesProps = {
  attributes: AttributesMap
  ['data-testid']?: string
  disabled?: boolean

  onRemoveAttribute(attributeId: string): void
  onChangeAttribute(
    attributeId: string,
    values: NonNullable<CustomAttributeValue>[],
  ): void
}

export const Attributes = (props: AttributesProps) => {
  const { data } = useQuery(AllCustomAttributesDocument)
  const allCustomAttributes = useMemo(
    () =>
      Array.from(data?.allCustomAttributes || []).sort((a, b) =>
        a.title.localeCompare(b.title),
      ),
    [data?.allCustomAttributes],
  )

  const currentAttributes = Object.entries(props.attributes)
    .map((x) => ({
      attributeId: x[0],
      attributeValues: x[1],
      attribute: allCustomAttributes.find((a) => a.id === x[0]),
    }))
    .filter((x) => x.attributeId !== ALL_PRODUCTS_ATTR)

  const missingAttributes = allCustomAttributes.filter(
    (x) => !currentAttributes.find((a) => a.attributeId === x.id),
  )

  return (
    <Box data-testid={props['data-testid']}>
      {currentAttributes.map((c, index) => (
        <Box
          sx={{
            display: 'flex',
            gap: (theme) => theme.spacing(1),
            marginBottom: (theme) =>
              c.attribute?.enums && index !== currentAttributes.length - 1
                ? theme.spacing(2)
                : 0,
          }}
          key={c.attributeId}
        >
          <Autocomplete
            autoHighlight
            autoSelect
            multiple
            options={c.attribute?.enums || []}
            onChange={(_event, value) => {
              props.onChangeAttribute(c.attributeId, [...value])
            }}
            value={c.attributeValues}
            data-testid="campaign-product-attributes-field"
            fullWidth
            freeSolo
            renderInput={(params) => (
              <TextField
                label={c.attribute?.title || c.attributeId}
                type={
                  c.attribute?.type === CustomAttributeTypeEnum.Integer
                    ? 'number'
                    : 'text'
                }
                {...params}
                inputProps={{
                  ...params.inputProps,
                  ['data-id']: c.attributeId,
                  ['data-testid']: 'campaign-product-attributes-input',
                }}
                sx={{ mb: 2 }}
              />
            )}
          />

          <div>
            <IconButton
              onClick={() => props.onRemoveAttribute(c.attributeId)}
              sx={{
                marginTop: (theme) => theme.spacing(3.5),
              }}
            >
              <DeleteIcon />
            </IconButton>
          </div>
        </Box>
      ))}

      {missingAttributes.length > 0 && (
        <AttributesMenu
          disabled={props.disabled}
          customAttributes={missingAttributes}
          onSelectCustomAttribute={(attributeId) =>
            props.onChangeAttribute(attributeId, [])
          }
        />
      )}
    </Box>
  )
}
