export function validateEmail(value: unknown): boolean {
  if (typeof value !== 'string') {
    return false
  }

  const input = document.createElement('input')

  input.type = 'email'
  input.required = true
  input.value = value

  return typeof input.checkValidity === 'function'
    ? input.checkValidity()
    : /\S+@\S+\.\S+/.test(value)
}

export function validateUrl(value: unknown): boolean {
  try {
    const url = new URL(value as string)
    return !!url.protocol
  } catch (err) {
    return false
  }
}

export function validateCoordinate(value: unknown): boolean {
  const COORDIDINATE_REGEX = /^[-+]?[0-9]{1,3}\.[0-9]+$/

  if (typeof value !== 'string') {
    return false
  }

  return Boolean(value.match(COORDIDINATE_REGEX)?.length)
}
