import { useCallback, useMemo } from 'react'
import {
  PosPaymentMethod,
  OrderLookupFilterPaymentMethodEnum as PaymentMethod,
  AllPaymentTypesDocument,
} from '../../../generated/graphql'
import { useQuery } from '@apollo/client'

/**
 * Match PosPaymentMethods (platform api) with PaymentMethod (order lookup api)
 */
export const usePaymentMethod = () => {
  const paymentMethodMap = useMemo(
    () =>
      new Map([
        [PosPaymentMethod.CASH, PaymentMethod.Cash],
        [PosPaymentMethod.CREDIT_NOTE, PaymentMethod.CreditNote],
        [PosPaymentMethod.CARD_VERIFONE_LPP, PaymentMethod.VerifoneLpp],
        [PosPaymentMethod.CARD_HOST2T, PaymentMethod.Host2t],
        [PosPaymentMethod.CARD_EXTERNAL, PaymentMethod.ExternalCard],
        [PosPaymentMethod.CUSTOM_METHOD_5, PaymentMethod.Custom5],
        [PosPaymentMethod.GIFT_CARD, PaymentMethod.Giftcard],
        [
          PosPaymentMethod.CREDIT_NOTE_AS_GIFT_CARD,
          PaymentMethod.GiftcardCreditNote,
        ],
        [PosPaymentMethod.INVOICE, PaymentMethod.Invoice],
        [PosPaymentMethod.CASH_ON_DELIVERY, PaymentMethod.CashOnDelivery],
        [PosPaymentMethod.KLARNA, PaymentMethod.KlarnaV3],
        [PosPaymentMethod.SWISH, PaymentMethod.Swish],
        [PosPaymentMethod.SWISH_WITH_QR, PaymentMethod.SwishQr],
        [PosPaymentMethod.MOBILEPAY, PaymentMethod.MobilePay],
        [PosPaymentMethod.VIPPS, PaymentMethod.Vipps],
        [PosPaymentMethod.CUSTOM_METHOD_1, PaymentMethod.Custom1],
        [PosPaymentMethod.CUSTOM_METHOD_2, PaymentMethod.Custom2],
        [PosPaymentMethod.CUSTOM_METHOD_3, PaymentMethod.Custom3],
        [PosPaymentMethod.CUSTOM_METHOD_4, PaymentMethod.Custom4],
        [PosPaymentMethod.CUSTOM_INVOICE, PaymentMethod.CustomInvoice],
        [PosPaymentMethod.CUSTOM_PAYMENT_API, PaymentMethod.CustomPayment],
        [PosPaymentMethod.CARD_ADYEN_SDK, PaymentMethod.Adyen],
        [PosPaymentMethod.CARD_ADYEN_TERMINAL_API, PaymentMethod.AdyenApi],
        [PosPaymentMethod.CARD_NETS, PaymentMethod.Nets],
        [PosPaymentMethod.CARD_COINEY, PaymentMethod.Coiney],
        [PosPaymentMethod.CARD_IZETTLE, PaymentMethod.IZettle],
      ]),
    [],
  )

  const { data, loading: isLoading } = useQuery(AllPaymentTypesDocument)

  const getPaymentMethods = useCallback(
    (paymentMethodIds: string[]): PaymentMethod[] => {
      if (!data?.allPaymentTypes) return []

      const paymentMethodsSet = new Set<PaymentMethod>()

      paymentMethodIds.forEach((paymentTypeId) => {
        const paymentType = data.allPaymentTypes.find(
          (type) => String(type.paymenttypeid) === String(paymentTypeId),
        )

        const paymentMethodId = paymentType?.paymentmethodid

        const paymentMethod =
          paymentMethodId && paymentMethodMap.get(paymentMethodId)

        if (paymentMethod) {
          paymentMethodsSet.add(paymentMethod)
        }
      })

      return Array.from(paymentMethodsSet)
    },
    [data?.allPaymentTypes, paymentMethodMap],
  )

  return { getPaymentMethods, isLoading }
}
