/* eslint-disable @typescript-eslint/no-misused-promises */
import { LoadingButton } from '@mui/lab'
import { TextField } from '@mui/material'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import { ShipmentAddress } from '../../../generated/graphql'
import { useServerValidation } from '../../../hooks/server-validation'
import { useTracking } from '../../../hooks/tracking'
import { CountriesInput } from '../../../components/countries-input'

export type AddressDialogProps = {
  open: boolean
  action: 'add' | 'edit'
  address?: ShipmentAddress | null
  onClose(): void
  onSubmit?(address: ShipmentAddress): Promise<void> | void
  onSuccess?(): void
  onError?(error: unknown): void
  dataTestid?: string
}

type Form = ShipmentAddress

export const SetAddressDialog = (props: AddressDialogProps) => {
  const { t } = useTranslation(['shipments', 'shared'])
  const dialogName = 'set-address'
  const {
    trackButtonClickEvent,
    trackDialogClose,
    trackDialogOpen,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const prevOpen = usePrevious(props.open)
  const [loading, setLoading] = useState(false)
  const formId = 'set-address-form'
  const { formState, reset, handleSubmit, register, control, setError } =
    useForm<Form>({
      mode: 'onChange',
    })
  const setFormError = useServerValidation<Form>('shipments', setError, {
    resolveFieldFromProperty: (property) =>
      property.split('.').pop() || property,
  })

  const onSubmit = async (formData: Form) => {
    try {
      setLoading(true)
      await props.onSubmit?.(formData)
      trackFormSuccess({ name: `${dialogName}-dialog` })
      props.onSuccess?.()
    } catch (error) {
      trackFormError({ name: `${dialogName}-dialog` })

      setFormError(error)
      props.onError?.(error)
    } finally {
      setLoading(false)
    }
  }

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    if (props.onClose) {
      props.onClose()
    }
  }

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
      reset({ ...props.address })
    }
  }, [props.open, prevOpen, trackDialogOpen, props.address, reset])

  return (
    <Dialog
      open={props.open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      data-testid="set-address-dialog"
    >
      <DialogTitle
        type="extended"
        sx={{
          paddingBottom: (theme) => theme.spacing(2),
        }}
      >
        {t(
          props.action === 'add'
            ? 'shipments:set_address_dialog.add_title'
            : 'shipments:set_address_dialog.edit_title',
        )}
      </DialogTitle>
      <DialogContent>
        <form id={formId} onSubmit={handleSubmit(onSubmit)}>
          <TextField
            error={!!formState?.errors?.name}
            fullWidth
            helperText={formState?.errors?.name?.message}
            label={t('shipments:shipment_form.name')}
            {...register('name')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.address}
            fullWidth
            helperText={formState?.errors?.address?.message}
            label={t('shipments:shipment_form.address')}
            {...register('address')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.address2}
            fullWidth
            helperText={formState?.errors?.address2?.message}
            label={t('shipments:shipment_form.address2')}
            {...register('address2')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.city}
            fullWidth
            helperText={formState?.errors?.city?.message}
            label={t('shipments:shipment_form.city')}
            {...register('city')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.zip}
            fullWidth
            helperText={formState?.errors?.zip?.message}
            label={t('shipments:shipment_form.zip')}
            {...register('zip')}
            sx={{ mb: 2 }}
          />
          <TextField
            error={!!formState?.errors?.state}
            fullWidth
            helperText={formState?.errors?.state?.message}
            label={t('shipments:shipment_form.state')}
            {...register('state')}
            sx={{ mb: 2 }}
          />

          <CountriesInput control={control} name="country_id" isRequired />
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={trackButtonClickEvent(
            {
              name: `${dialogName}-dialog-cancel`,
            },
            onClose,
          )}
          variant="secondary"
          size="compact"
          type="button"
        >
          {t('shared:action.cancel')}
        </Button>
        <LoadingButton
          form={formId}
          type="submit"
          size="small"
          onClick={handleSubmit(onSubmit)}
          data-testid="set-address"
          loading={loading}
        >
          {t('shared:action.save')}
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}
