import { useQuery } from '@apollo/client'
import {
  GridColumnVisibilityModel,
  GridColDef,
  GridRowParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import {
  GetJournalEntriesDocument,
  GetJournalEntriesQueryVariables,
  GetJournalEntriesQuery,
  JournalFilterDateType,
  JournalEntryType,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { JournalIcon } from '@sitoo/mui-components'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { useMemo, useCallback, useContext } from 'react'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../components/data-grid/utils/caption-renderer'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { DateTimeRenderer } from '../../../components/data-grid/utils/date-time-renderer'
import { FilterContext } from '../../../components/data-grid/context'

type Row = ArrayElement<GetJournalEntriesQuery['journalEntries']['items']>

type Props = {
  onDetail(journal: Row): void
  currentDetailedJournal?: Row
  onShowFilter(): void
}

const PAGE_SIZE = 100

export const JournalList = (props: Props) => {
  const { t } = useTranslation(['journals', 'shared'])
  const { formatDate } = useLocalizedDate()

  const apiRef = useGridApiRef()

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'id',
        minWidth: 120,
        headerName: t('journals:id'),
      },
      {
        field: 'datecreated',
        ...ColumnProps.date,
        headerName: t('journals:created_at'),
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'dateadded',
        ...ColumnProps.date,
        headerName: t('journals:added_at'),
        renderCell: (params) => (
          <DateTimeRenderer {...params} formatDate={formatDate} />
        ),
      },
      {
        field: 'store',
        minWidth: 200,
        headerName: t('journals:store'),
        valueGetter: (_value, row) => {
          if (row.data.__typename === 'JournalReceipt') {
            return row.data.additionaldata?.['store-name']
          }

          if ('store_name' in row.data) {
            return row.data.store_name
          }
        },
      },
      {
        field: 'type',
        minWidth: 140,
        headerName: t('journals:type'),
        valueGetter: (_value, row): string =>
          t(`journals:entry_type_enum.${row.type || ''}`),
      },
      {
        field: 'staff',
        minWidth: 260,
        headerName: t('journals:staff'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: 'staff' in row.data ? row.data.staff : '',
          caption: undefined, // params.row.data.staffuserid,
        }),
        renderCell: CaptionRenderer,
      },
      {
        field: 'register',
        minWidth: 260,
        headerName: t('journals:register'),
        valueGetter: (_value, row): CaptionRendererValue => ({
          title: row.registerkey,
          caption: row.registerid,
        }),
        renderCell: CaptionRenderer,
      },
    ],
    [formatDate, t],
  )

  const { filter, isFilterReady } = useContext(FilterContext)

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        start: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetJournalEntriesQueryVariables = {
      num: config.pagination?.pageSize,
      dateType: JournalFilterDateType.Created,
      start: 0,
    }

    const registerIds = config.filter?.['cashRegisterIds']?.value
    if (registerIds && Array.isArray(registerIds)) {
      variables.registerIds = registerIds
    }

    if (typeof config.filter?.['date']?.value === 'string') {
      variables.dateFrom = config.filter['date']?.value
    } else if (Array.isArray(config.filter?.['date']?.value)) {
      const value = config.filter?.['date']?.value

      if (typeof value?.[0] === 'string') {
        variables.dateFrom = value[0]
      }
      if (typeof value?.[1] === 'string') {
        variables.dateTo = value[1]
      }
    }

    if (typeof config.filter?.['storeId']?.value === 'number') {
      variables.storeIds = [config.filter?.['storeId'].value]
    }

    if (
      config.filter?.['entryType']?.value &&
      typeof config.filter?.['entryType']?.value === 'string' &&
      Object.values(JournalEntryType).includes(
        config.filter?.['entryType']?.value as JournalEntryType,
      )
    ) {
      variables.type = config.filter?.['entryType']?.value as JournalEntryType
    }

    return variables
  }, [apiRef, filter])

  const isStoreOrRegisterSelected =
    queryVariables.storeIds || queryVariables.registerIds?.length

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(GetJournalEntriesDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !isFilterReady || !isStoreOrRegisterSelected,
  })

  const isLoading = fetchLoading || !isFilterReady

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.journalEntries.next) {
      return fetchMore({
        variables: {
          start: (data?.journalEntries?.start || 0) + pageSize,
          next: data?.journalEntries.next,
        } as GetJournalEntriesQueryVariables,
      })
    }
  }, [apiRef, data, fetchMore])

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () => ({
      id: false,
      dateadded: false,
      store: false,
    }),
    [],
  )

  const getRowId = (row: Row) => `${row.id}-${row.registerid}`

  return (
    <DataGrid
      name="journals-list"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.journalEntries.items || []}
      rowCount={
        data?.journalEntries?.items?.length
          ? data.journalEntries.items.length + 1
          : 0
      }
      onShowFilter={props.onShowFilter}
      loading={isLoading}
      onRowClick={(row: GridRowParams<Row>) => props.onDetail(row.row)}
      fetchMore={fetchMoreItems}
      noRowsOverlay={{
        icon: <JournalIcon />,
        title: t('journals:grid.empty_title'),
        description: t('journals:grid.empty_description'),
      }}
      slots={{ footer: CursorPagination }}
      disableColumnFilter
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      columnVisibilityModel={columnVisibilityModel}
      showMore={!!data?.journalEntries.next}
      getRowId={getRowId}
      getRowClassName={(params) => {
        const classes = []
        if (
          props.currentDetailedJournal &&
          params.id === getRowId(props.currentDetailedJournal)
        ) {
          classes.push('Mui-selected')
        }

        return classes.join(' ')
      }}
    />
  )
}
