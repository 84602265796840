import { useMutation, useQuery } from '@apollo/client'
import {
  Button,
  Divider,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useCallback, useRef, useState } from 'react'
import { Controller, useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'
import {
  GetWarehousesDocument,
  ShipmentStateV2,
  UpdateShipmentV2Document,
} from '../../../../generated/graphql'
import { useMe } from '../../../../hooks/me'
import { useScrollToError } from '../../../../hooks/scroll-to-error'
import { getErrorMessage } from '../../../../utils/error-mapping'
import { SetAddressDialog } from '../../set-address-dialog'
import { SetContactDialog } from '../../set-contact-dialog'
import {
  BaseShipmentFormContext,
  GenericShipmentAddressFields,
  GenericShipmentContactFields,
} from '../../shared'
import {
  formatFormAddressDataToPayload,
  formatFormContactDataToPayload,
} from '../../utils'
import { AddressListItem } from '../sender/address-list-item'
import { ContactListItem } from '../sender/contact-list-item'

export const ReceiverField = () => {
  const { id: shipmentId } = useParams()
  const isNewShipment = shipmentId === undefined
  const { formState, control, setValue, watch, clearErrors } =
    useFormContext<BaseShipmentFormContext>()
  const { t } = useTranslation(['shipments', 'shared', 'countries'])

  const { enqueueSnackbar } = useSnackbar()

  const [updateShipment] = useMutation(UpdateShipmentV2Document)
  const { me } = useMe()

  const { data } = useQuery(GetWarehousesDocument)
  const warehouses = data?.allWarehouses

  const [showSetAddressDialog, setShowSetAddressDialog] = useState(false)
  const [showSetContactDialog, setShowSetContactDialog] = useState(false)

  const shipment = watch()
  const address = shipment?.receiverAddress
  const contact = shipment?.receiverContact
  const state = formState.defaultValues?.shipmentstate || ShipmentStateV2.New

  const canEdit = state === ShipmentStateV2.New

  const fillWarehouseInfo = useCallback(
    (warehouseId: number | string) => {
      const warehouse = warehouses?.find(({ id }) => id === warehouseId)

      setValue('receiverAddress.warehouseid', warehouse?.id, {
        shouldDirty: true,
      })
      setValue('receiverAddress.name', warehouse?.name || '', {
        shouldDirty: true,
      })
      setValue('receiverAddress.address', warehouse?.address || '', {
        shouldDirty: true,
      })
      setValue('receiverAddress.address2', warehouse?.address2 || '', {
        shouldDirty: true,
      })
      setValue('receiverAddress.city', warehouse?.city || '', {
        shouldDirty: true,
      })
      setValue('receiverAddress.zip', warehouse?.zip || '', {
        shouldDirty: true,
      })
      setValue('receiverAddress.state', warehouse?.state || '', {
        shouldDirty: true,
      })
      setValue(
        'receiverAddress.countryid',
        (warehouse?.countryid || '').toUpperCase(),
        {
          shouldDirty: true,
        },
      )
    },
    [setValue, warehouses],
  )

  const hasAddress = (address?: GenericShipmentAddressFields | null) =>
    Object.values(address ?? []).length > 0

  const hasContact = (contact?: GenericShipmentContactFields | null) =>
    Object.values(contact ?? []).length > 0

  const contactRef = useRef<HTMLLIElement>(null)
  const addressRef = useRef<HTMLLIElement>(null)

  useScrollToError({ ref: contactRef, name: 'receiverContact', control })
  useScrollToError({ ref: addressRef, name: 'receiverAddress', control })

  const submitAddressNewShipment = (
    newAddress: GenericShipmentAddressFields,
  ) => {
    setValue('receiverAddress', newAddress, {
      shouldDirty: true,
    })
    clearErrors('receiverAddress')
    setShowSetAddressDialog(false)
  }

  const submitAddressExistingShipment = async (
    newAddress: GenericShipmentAddressFields,
  ) => {
    if (shipmentId) {
      await updateShipment({
        variables: {
          shipment: {
            shipmentid: Number(shipmentId),
            ...formatFormAddressDataToPayload('receiver', newAddress),
          },
        },
      })
    }

    enqueueSnackbar(t('shipments:shipment_message.success_update'))
    setShowSetAddressDialog(false)
  }

  const submitContactNewShipment = (
    newContact: GenericShipmentContactFields,
  ) => {
    setValue('receiverContact', newContact, {
      shouldDirty: true,
    })
    clearErrors('receiverContact')
    setShowSetContactDialog(false)
  }

  const submitContactExistingShipment = async (
    newContact: GenericShipmentContactFields,
  ) => {
    await updateShipment({
      variables: {
        shipment: {
          shipmentid: Number(shipmentId),
          ...formatFormContactDataToPayload('receiver', newContact),
        },
      },
    })

    enqueueSnackbar(t('shipments_v2:shipment_message.success_update'))
    setShowSetContactDialog(false)
  }

  return (
    <FormFieldset label={t('shipments_v2:shipment_form.receiver_fieldset')}>
      {isNewShipment && (
        <Controller
          control={control}
          name="receiverAddress.warehouseid"
          render={({ field }) => (
            <>
              <InputLabel>
                {t('shipments_v2:shipment_form.warehouse')}
              </InputLabel>
              <Select<number | string>
                value={field.value || ''}
                onChange={(event) => {
                  fillWarehouseInfo(event.target.value)
                }}
                displayEmpty
              >
                <MenuItem value={''}>{t('shared:label.not_selected')}</MenuItem>
                {warehouses?.map(({ id, name }) => (
                  <MenuItem value={id} key={id}>
                    {name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText sx={{ mb: 2 }}>
                {formState?.errors.receiverAddress?.warehouseid?.message ||
                  t(
                    'shipments_v2:shipment_form.receiver_warehouse_description',
                  )}
              </FormHelperText>
            </>
          )}
        />
      )}

      <Grid container sx={{ mb: -2, mt: isNewShipment ? 0 : -2 }}>
        <Grid item xs={12}>
          <SetAddressDialog
            action={hasAddress(address) ? 'edit' : 'add'}
            address={address}
            open={showSetAddressDialog}
            onClose={() => setShowSetAddressDialog(false)}
            onSubmit={
              isNewShipment
                ? submitAddressNewShipment
                : submitAddressExistingShipment
            }
            onError={
              isNewShipment
                ? undefined
                : (error) => {
                    const errorMessage = getErrorMessage(
                      error,
                      'shipments_v2',
                      t('shipments_v2:shipment_message.failure_update'),
                    )

                    enqueueSnackbar(errorMessage, { variant: 'error' })
                  }
            }
          />
          <AddressListItem
            ref={addressRef}
            address={address}
            sx={{
              px: 0,
            }}
            disabled={!hasAddress(address)}
            error={!!formState.errors.receiverAddress}
            fallbackTitle={
              hasAddress(address)
                ? t('shipments_v2:shipment_form.address')
                : t('shipments_v2:shipment_form.no_address_added')
            }
            secondaryAction={
              <>
                {canEdit && (
                  <Button
                    size="small"
                    color="secondary"
                    onClick={() => {
                      setShowSetAddressDialog(true)
                    }}
                  >
                    {hasAddress(address)
                      ? t('shared:action.edit')
                      : t('shipments_v2:shipment_form.add_address')}
                  </Button>
                )}
              </>
            }
          />
        </Grid>
        <Grid item xs={12}>
          <Divider sx={{ ml: -2, mr: -2 }} />
        </Grid>
        <Grid item container spacing={2}>
          <Grid item xs={12}>
            <SetContactDialog
              action={hasContact(contact) ? 'edit' : 'add'}
              contact={contact}
              open={showSetContactDialog}
              onClose={() => setShowSetContactDialog(false)}
              onSubmit={
                isNewShipment
                  ? submitContactNewShipment
                  : submitContactExistingShipment
              }
              onError={
                isNewShipment
                  ? undefined
                  : (error) => {
                      const errorMessage = getErrorMessage(
                        error,
                        'shipments_v2',
                        t('shipments_v2:shipment_message.failure_update'),
                      )

                      enqueueSnackbar(errorMessage, { variant: 'error' })
                    }
              }
            />
            <ContactListItem
              ref={contactRef}
              sx={{
                px: 0,
              }}
              contact={contact}
              disabled={!hasContact(contact)}
              error={!!formState.errors.receiverContact}
              fallbackTitle={
                hasContact(contact)
                  ? t('shipments_v2:shipment_form.contact')
                  : t('shipments_v2:shipment_form.no_contact_added')
              }
              secondaryAction={
                <>
                  {canEdit && (
                    <Button
                      size="small"
                      color="secondary"
                      onClick={() => {
                        setShowSetContactDialog(true)
                      }}
                    >
                      {hasContact(contact)
                        ? t('shared:action.edit')
                        : t('shipments_v2:shipment_form.add_contact')}
                    </Button>
                  )}
                </>
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </FormFieldset>
  )
}
