import { useQuery } from '@apollo/client'
import { FormFieldset } from '@sitoo/mui-components'
import { AllStoreTagsDocument } from '../../../../generated/graphql'
import { StoreTagsInput } from '../../../../components/store-tags-input'
import { useFormContext } from 'react-hook-form'
import { CampaignFormContext } from '../..'
import { useTranslation } from 'react-i18next'

export const StoreTagsLocalCampaignField = () => {
  const { loading } = useQuery(AllStoreTagsDocument)

  const { t } = useTranslation(['campaigns'])
  const { control } = useFormContext<CampaignFormContext>()

  control._formState.errors.campaign?.tags

  return (
    <FormFieldset
      isLoading={loading}
      sx={{ '.MuiFormFieldset-Paper': { pb: 2 } }}
    >
      <StoreTagsInput
        name="campaign.tags"
        control={control}
        rules={{ required: true }}
        helperText={t('campaign_form.tags_selection_custom_description')}
      />
    </FormFieldset>
  )
}
