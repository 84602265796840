import { useMutation, useQuery } from '@apollo/client'
import LoadingButton from '@mui/lab/LoadingButton'
import {
  Button,
  Container,
  ListItemText,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from '@mui/material'
import { ChevronSmallDownIcon } from '@sitoo/mui-components'
import { GraphQLError } from 'graphql'
import { useSnackbar } from 'notistack'
import { MouseEventHandler, useEffect, useState } from 'react'
import { useForm, useFieldArray, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { PageHeader } from '../../../components/page-header'
import { RouteLeavingGuard } from '../../../components/route-leaving-guard'
import {
  AddUpdatePurchasePriceListInput,
  AllPurchasePriceListsQuery,
  AddPurchasePriceListDocument,
  AllPurchasePriceListsDocument,
  DeletePurchasePriceListDocument,
  UpdatePurchasePriceListDocument,
} from '../../../generated/graphql'
import { useAuthorization } from '../../../hooks/authorization'
import { useMe } from '../../../hooks/me'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ProductSettingsList } from '../products/product-settings-list'
import { PurchasePriceListDialog } from './purchase-price-list-dialog'
import { SetPurchasePriceMappingDialog } from './purchase-price-mapping-dialog'
import { containsDirtyFields } from '../../../utils/contains-dirty-fields'

export type PurchasePriceList = ArrayElement<
  NonNullable<AllPurchasePriceListsQuery['allPurchasePriceLists']>
>

export type PriceListsForm = {
  priceLists: NonNullable<AllPurchasePriceListsQuery['allPurchasePriceLists']>
  deletePriceLists: string[]
}

export const SettingsPurchasePriceListsPage = () => {
  const { t } = useTranslation(['shared', 'settings'])
  usePageTitle(t('shared:menu.purchase_price_lists'))
  const {
    modules: { writeSettingsPurchasePriceList },
  } = useAuthorization()

  const {
    trackButtonClick,
    trackButtonClickEvent,
    trackFormError,
    trackFormSuccess,
  } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const formContext = useForm<PriceListsForm>({
    defaultValues: { priceLists: [], deletePriceLists: [] },
  })
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const isOpen = !!anchorEl
  const handleClick: MouseEventHandler<HTMLButtonElement> = (event) => {
    setAnchorEl(isOpen ? null : event.currentTarget)
  }
  const closeMenu = () => {
    setAnchorEl(null)
  }
  const [showPurchasePriceMappingDialog, setShowPurchasePriceMappingDialog] =
    useState(false)

  const {
    fields: priceLists,
    remove: removePriceLists,
    update: updatePriceLists,
    append: appendPriceList,
  } = useFieldArray({
    control: formContext.control,
    name: 'priceLists',
    keyName: 'priceListId',
  })

  const { me } = useMe()

  const [selectedPriceList, setSelectedPriceList] =
    useState<PurchasePriceList | null>(null)

  const [showDialog, setShowDialog] = useState(false)

  const {
    loading: fetchLoading,
    refetch,
    data: priceListsData,
  } = useQuery(AllPurchasePriceListsDocument, {
    fetchPolicy: 'cache-and-network',
  })

  const [addPriceList, { loading: addLoading }] = useMutation(
    AddPurchasePriceListDocument,
  )

  const [updatePriceList, { loading: updateLoading }] = useMutation(
    UpdatePurchasePriceListDocument,
  )

  const [deletePriceList, { loading: deleteLoading }] = useMutation(
    DeletePurchasePriceListDocument,
  )

  const isLoading = fetchLoading || addLoading || updateLoading || deleteLoading

  useEffect(() => {
    formContext.reset({
      priceLists: priceListsData?.allPurchasePriceLists || [],
      deletePriceLists: [],
    })
  }, [formContext, priceListsData])

  const onPriceListUpdate = (
    priceList: PurchasePriceList,
    previous: PurchasePriceList | undefined,
  ) => {
    const existingPosition = priceLists.findIndex(
      (x) => x.id === (previous?.id || priceList.id),
    )

    if (existingPosition !== -1) {
      updatePriceLists(existingPosition, priceList)
    } else {
      appendPriceList(priceList)
    }
    setShowDialog(false)
  }

  const edit = (priceList: PurchasePriceList) => {
    trackButtonClick({ name: 'settings-purchase-price-list-edit' })
    setSelectedPriceList(priceList)
    setShowDialog(true)
  }

  const add = () => {
    trackButtonClick({ name: 'settings-purchase-price-list-add' })
    setSelectedPriceList(null)
    setShowDialog(true)
  }

  const remove = (priceList: PurchasePriceList) => {
    trackButtonClick({ name: 'settings-purchase-price-list-remove' })
    removePriceLists(priceLists.findIndex(({ id }) => id === priceList.id))
    if (priceList.__typename) {
      formContext.setValue('deletePriceLists', [
        ...formContext.getValues('deletePriceLists'),
        priceList.id,
      ])
    }
  }

  const submitAddPriceLists = async (
    priceLists: AddUpdatePurchasePriceListInput[],
  ) => {
    const { data } = await addPriceList({
      variables: {
        priceLists,
      },
    })

    const failedPriceLists =
      data?.addPurchasePriceList?.filter((x) => x.success === false) || []
    if (failedPriceLists.length > 0) {
      enqueueSnackbar(
        t('settings:purchase_price_lists.error.add_generic', {
          count: failedPriceLists.length,
          purchasePriceList: failedPriceLists
            .map(
              (x) =>
                `${x.priceList.name}${
                  x.error?.code === 'PURCHASE_PRICE_LIST_DUPLICATED_ID'
                    ? ` (${t(
                        'settings:purchase_price_lists.error.duplicated_id',
                      )})`
                    : ''
                }`,
            )
            .join(', '),
        }),
        { variant: 'error' },
      )
    }

    return (data?.addPurchasePriceList || []).some((x) => x.success === true)
  }

  const submitUpdatePriceLists = async (
    priceLists: AddUpdatePurchasePriceListInput[],
  ) => {
    const { data } = await updatePriceList({
      variables: {
        priceLists,
      },
    })

    const failedPriceLists =
      data?.updatePurchasePriceList?.filter((x) => x.success === false) || []
    if (failedPriceLists.length > 0) {
      enqueueSnackbar(
        t('settings:purchase_price_lists.error.update_generic', {
          count: failedPriceLists.length,
          purchasePriceList: failedPriceLists
            .map((x) => x.priceList.name)
            .join(', '),
        }),
        { variant: 'error' },
      )
    }

    return (data?.updatePurchasePriceList || []).some((x) => x.success === true)
  }

  const submitDeletePriceLists = async (priceListIds: string[]) => {
    const { data } = await deletePriceList({
      variables: {
        priceListIds,
      },
    })

    const allFailedPriceLists =
      data?.deletePurchasePriceList?.filter((x) => x.success === false) || []

    const shareFailedPriceLists = allFailedPriceLists?.filter(
      (x) => x.error?.code === 'PURCHASE_PRICE_LIST_SHARED_CANT_BE_DELETED',
    )
    if (shareFailedPriceLists.length > 0) {
      enqueueSnackbar(
        t('settings:purchase_price_lists.error.delete_price_list_is_shared', {
          count: shareFailedPriceLists.length,
          purchasePriceList: shareFailedPriceLists
            .map(
              (x) =>
                priceListsData?.allPurchasePriceLists.find(
                  (y) => y.id === x.priceListId,
                )?.name,
            )
            .join(', '),
        }),
        { variant: 'error' },
      )
    }

    const otherFailedPriceLists = allFailedPriceLists.filter(
      (x) =>
        !shareFailedPriceLists.find((y) => y.priceListId === x.priceListId),
    )
    if (otherFailedPriceLists.length > 0) {
      enqueueSnackbar(
        t('settings:purchase_price_lists.error.delete_generic', {
          count: otherFailedPriceLists.length,
          purchasePriceList: otherFailedPriceLists
            .map(
              (x) =>
                priceListsData?.allPurchasePriceLists.find(
                  (y) => y.id === x.priceListId,
                )?.name,
            )
            .join(', '),
        }),
        { variant: 'error' },
      )
    }

    return !!data?.deletePurchasePriceList.some((x) => x.success === true)
  }

  const onSubmit = async (formData: PriceListsForm) => {
    try {
      trackButtonClick({ name: 'settings-purchase-price-list-save' })
      let success = false

      const priceListsToAdd = formData.priceLists.filter((x) => !x.__typename)

      if (priceListsToAdd.length > 0) {
        const result = await submitAddPriceLists(
          priceListsToAdd.map(({ id, currency, name }) => ({
            id,
            currency,
            name,
          })),
        )

        if (!success) {
          success = result
        }
      }

      const priceListsToUpdate = formData.priceLists.filter(
        (x) => x.__typename && x.owner_eshop_id === me?.siteId,
      )
      if (priceListsToUpdate.length > 0) {
        const result = await submitUpdatePriceLists(
          priceListsToUpdate.map(({ id, currency, name }) => ({
            id,
            currency,
            name,
          })),
        )

        if (!success) {
          success = result
        }
      }

      if (formData.deletePriceLists.length > 0) {
        const result = await submitDeletePriceLists(formData.deletePriceLists)

        if (!success) {
          success = result
        }
      }

      if (success) {
        trackFormSuccess({ name: 'settings-purchase-price-list' })
        enqueueSnackbar(
          t('settings:purchase_price_lists.success_saved', {
            count:
              (formData?.deletePriceLists.length || 0) +
              priceListsToAdd.length +
              priceListsToUpdate.length,
          }),
          { variant: 'success' },
        )
      }

      await refetch()
    } catch {
      trackFormError({ name: 'settings-purchase-price-list' })
    }
  }

  const onMappingSuccess = () => {
    setShowPurchasePriceMappingDialog(false)
    enqueueSnackbar(t('settings:purchase_price_lists.success_mapping'))
  }

  const onMappingError = (error: GraphQLError | null) => {
    const code = error?.extensions?.['code']

    let message = t('settings:purchase_price_lists.error.mapping_generic')

    if (code === 'PURCHASE_PRICE_LIST_MAPPING_INVALID_ID') {
      const priceListId = error?.extensions?.['value'] as string

      message = t('settings:purchase_price_lists.error.invalid_mapping_id', {
        priceListId,
      })
    }

    enqueueSnackbar(message, {
      variant: 'error',
    })
  }

  return (
    <>
      <RouteLeavingGuard
        shouldBlockNavigation={() => writeSettingsPurchasePriceList}
        when={
          !isLoading &&
          containsDirtyFields(formContext.formState.dirtyFields) &&
          !formContext.formState.isSubmitSuccessful
        }
      />
      <PageHeader
        title={t('shared:menu.purchase_price_lists')}
        rightColumn={
          <>
            <>
              <Button
                id="settings-purchase-price-bulk-actions-menu-button"
                aria-controls={
                  isOpen
                    ? 'settings-purchase-price-bulk-actions-menu'
                    : undefined
                }
                data-testid="settings-purchase-price-bulk-actions-menu-button"
                aria-haspopup="true"
                aria-expanded={isOpen ? 'true' : undefined}
                onClick={handleClick}
                color="secondary"
                endIcon={<ChevronSmallDownIcon />}
                size="small"
              >
                {t('shared:label.actions')}
              </Button>
              <Menu
                id="settings-purchase-price-bulk-actions-menu"
                anchorEl={anchorEl}
                open={isOpen}
                onClose={closeMenu}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                MenuListProps={{
                  'aria-labelledby':
                    'settings-purchase-price-bulk-actions-menu-button',
                }}
              >
                <MenuItem
                  onClick={trackButtonClickEvent(
                    {
                      name: 'settings-purchase-price-bulk-actions-menu-set-mapping',
                    },
                    () => {
                      setShowPurchasePriceMappingDialog(true)
                      closeMenu()
                    },
                  )}
                  data-testid="purchase-price-bulk-menu-set-mapping"
                >
                  <Typography variant="body02">
                    {t(
                      writeSettingsPurchasePriceList
                        ? 'settings:purchase_price_lists.edit_mapping'
                        : 'settings:purchase_price_lists.view_mapping',
                    )}
                  </Typography>
                </MenuItem>
              </Menu>
              <SetPurchasePriceMappingDialog
                onClose={() => setShowPurchasePriceMappingDialog(false)}
                onSuccess={onMappingSuccess}
                onError={onMappingError}
                open={showPurchasePriceMappingDialog}
              />
              {writeSettingsPurchasePriceList && (
                <LoadingButton
                  disabled={!formContext.formState.isDirty}
                  type="submit"
                  // eslint-disable-next-line @typescript-eslint/no-misused-promises
                  onClick={formContext.handleSubmit(onSubmit)}
                  loading={isLoading}
                  data-testid="save-changes"
                >
                  {t('shared:action.save')}
                </LoadingButton>
              )}
            </>
          </>
        }
      />
      <FormProvider {...formContext}>
        <Container>
          <Paper elevation={0}>
            <ProductSettingsList
              emptyTitle={t('settings:purchase_price_lists.empty_title')}
              emptyDescription={t(
                'settings:purchase_price_lists.empty_description',
              )}
              items={priceLists}
              onEdit={edit}
              canAdd
              onAdd={add}
              addText={t(
                'settings:purchase_price_lists.add_purchase_price_list',
              )}
              baseDataTestId="purchase-price-list"
              onDelete={remove}
              readOnly={!writeSettingsPurchasePriceList}
              loading={isLoading}
              editable={(x) =>
                x.owner_eshop_id === undefined ||
                x.owner_eshop_id === me?.siteId
              }
              itemRender={(x) => (
                <>
                  <ListItemText
                    secondaryTypographyProps={{
                      sx: { whiteSpace: 'break-spaces' },
                    }}
                  >
                    {x.name} ({x.id})<Typography>{x.currency}</Typography>
                    <Typography>
                      {x.ownerSite?.eshopname || x.owner_eshop_id}
                    </Typography>
                    {(x.shared_store_ids || []).length > 0 && (
                      <Typography>
                        {x.sharedStores?.map((s) => s.name).join(', ') ||
                          x.shared_store_ids?.join(', ')}
                      </Typography>
                    )}
                  </ListItemText>
                </>
              )}
            />
          </Paper>
        </Container>
      </FormProvider>

      <PurchasePriceListDialog
        open={showDialog}
        existingIds={[
          ...(priceListsData?.allPurchasePriceLists?.map((x) => x.id) || []),
          ...priceLists.map((x) => x.id),
        ]}
        priceList={selectedPriceList}
        onClose={() => setShowDialog(false)}
        onSuccess={onPriceListUpdate}
      />
    </>
  )
}
