import { Switch, SwitchProps } from '@mui/material'
import { Controller, FieldValues, UseControllerProps } from 'react-hook-form'

export const SwitchInput = <TFieldValues extends FieldValues>(
  props: SwitchProps & UseControllerProps<TFieldValues>,
) => {
  const { control, name, ...switchProps } = props

  return (
    <Controller
      control={control}
      name={name}
      render={({ formState, field: { onChange, value } }) => (
        <Switch
          {...switchProps}
          checked={!!value}
          value={value ? 'on' : 'off'}
          disabled={props.disabled ?? formState.isSubmitting}
          onChange={onChange}
        />
      )}
    />
  )
}
