import { useQuery } from '@apollo/client'
import {
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import { DataGrid } from '../../../components/data-grid'
import { SortItem } from '../../../components/data-grid/filters/sort'
import {
  GetCashRegistersDocument,
  GetCashRegistersQueryVariables,
  GetCashRegistersQuery,
  GetRegistersSort,
} from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'
import { RegisterCashIcon } from '@sitoo/mui-components'
import { ResetFilterButton } from '../../../components/data-grid/filters/reset-filter-button'
import { useMemo, useContext, useCallback } from 'react'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { RelativeDateRenderer } from '../../../components/data-grid/utils/relative-date-renderer'
import { useLocalizedDate } from '../../../hooks/localized-date'
import { FilterContext } from '../../../components/data-grid/context'
import { CursorPagination } from '../../../components/data-grid/cursor-pagination'
import { Box, Chip, Typography } from '@mui/material'
import { Indicator } from '../../../components/indicator'
import { useDayJs } from '../../../hooks/day-js'

type Row = ArrayElement<GetCashRegistersQuery['cashRegisters']['items']>

type Props = {
  onDetail(selectedId: string): void
  selectedId?: string
}

const PAGE_SIZE = 100

export const CashRegisterList = (props: Props) => {
  const { t } = useTranslation(['cash_registers', 'shared', 'filter'])
  const { formatRelativeDate, formatDate } = useLocalizedDate()
  const dayjs = useDayJs()

  const sortItems = useMemo<SortItem<GetRegistersSort>[]>(
    () => [
      {
        field: 'orderid',
        sort: 'asc',
        title: t('cash_registers:id'),
        type: 'number',
        value: GetRegistersSort.RegisterIdAsc,
      },
      {
        field: 'orderid',
        sort: 'desc',
        title: t('cash_registers:id'),
        type: 'number',
        value: GetRegistersSort.RegisterIdDesc,
        isDefault: true,
      },
      {
        field: 'registerkey',
        sort: 'asc',
        title: t('cash_registers:register_key'),
        type: 'number',
        value: GetRegistersSort.RegisterKeyAsc,
      },
      {
        field: 'registerkey',
        sort: 'desc',
        title: t('cash_registers:register_key'),
        type: 'number',
        value: GetRegistersSort.RegisterKeyDesc,
      },
      {
        field: 'datelastping',
        sort: 'asc',
        title: t('cash_registers:last_updated'),
        type: 'number',
        value: GetRegistersSort.DateLastPingAsc,
      },
      {
        field: 'datelastping',
        sort: 'desc',
        title: t('cash_registers:last_updated'),
        type: 'number',
        value: GetRegistersSort.DateLastPingDesc,
      },
    ],
    [t],
  )

  const { filter } = useContext(FilterContext)

  const apiRef = useGridApiRef()

  const queryVariables = useMemo(() => {
    const config = {
      filter,
      pagination: {
        page: apiRef.current.state?.pagination.paginationModel.page,
        pageSize: PAGE_SIZE,
        start: 0,
      },
      sorting: apiRef.current.state?.sorting.sortModel,
    }

    const variables: GetCashRegistersQueryVariables = {
      num: config.pagination?.pageSize,
      start:
        (config.pagination?.page || 0) * (config.pagination?.pageSize || 0),
      includeNotRegistered: true,
    }

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [apiRef, filter, sortItems])

  const {
    data,
    loading: isLoading,
    fetchMore,
  } = useQuery(GetCashRegistersDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    notifyOnNetworkStatusChange: true,
  })

  const dataGridColumns: GridColDef<Row>[] = useMemo(
    () => [
      {
        field: 'registerid',
        minWidth: 200,
        headerName: t('cash_registers:id'),
        valueGetter: (_value, row): string => row.registerid,
      },
      {
        field: 'clientdescription',
        headerName: t('cash_registers:client_name'),
        minWidth: 200,
      },
      {
        field: 'datelastping',
        ...ColumnProps.date,
        headerName: t('cash_registers:last_updated'),
        renderCell: (params) => (
          <Indicator active={dayjs(params.row.datelastping).isToday()}>
            {params.row.datelastping ? (
              <RelativeDateRenderer
                {...params}
                formatDate={formatDate}
                formatRelativeDate={formatRelativeDate}
              />
            ) : (
              <Box color="gray60">
                {t('cash_registers:last_updated_unavailable')}
              </Box>
            )}
          </Indicator>
        ),
      },
      {
        field: 'warehouse',
        minWidth: 200,
        headerName: t('cash_registers:warehouse_name'),
        valueGetter: (_value, row): string => {
          return row.warehouse?.name || ''
        },
        renderCell: (params: GridRenderCellParams<Row, string>) => (
          <Chip label={params.formattedValue} size="small" />
        ),
      },
      {
        field: 'loglevel',
        minWidth: 120,
        headerName: t('cash_registers:log_level_label'),
        valueGetter: (_value, row): string => {
          return row.loglevel
            ? t(`cash_registers:log_level.${row.loglevel}`)
            : ''
        },
      },

      {
        field: 'registerkey',
        minWidth: 160,
        headerName: t('cash_registers:register_key'),
        valueGetter: (_value, row): string => {
          return row.registerkey || ''
        },

        renderCell: (params) => (
          <>
            {params.row.registerkey || (
              <Chip
                label={t('cash_registers:not_registered')}
                color="red"
                size="small"
              />
            )}
          </>
        ),
      },
      {
        field: 'manufacturerid',
        minWidth: 200,
        headerName: t('cash_registers:manufacturer_id'),
        valueGetter: (_value, row): string => {
          return row.manufacturerid || ''
        },
      },
    ],
    [dayjs, formatDate, formatRelativeDate, t],
  )

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.cashRegisters.totalcount) {
      return fetchMore({
        variables: {
          start: (data.cashRegisters?.start || 0) + pageSize,
        },
      })
    }
  }, [apiRef, data, fetchMore])

  const columnVisibilityModel = useMemo(
    () => ({
      ['registerid']: false,
    }),
    [],
  )

  return (
    <>
      <DataGrid
        name="cash-register-list"
        apiRef={apiRef}
        columns={dataGridColumns}
        rows={data?.cashRegisters.items}
        rowCount={data?.cashRegisters.totalcount || 0}
        loading={isLoading}
        getRowId={(row) => row.registerid}
        onRowClick={(row) => props.onDetail(String(row.id))}
        fetchMore={fetchMoreItems}
        noRowsOverlay={{
          icon: <RegisterCashIcon />,
          title: t('cash_registers:grid.empty_title'),
          description: t('cash_registers:grid.empty_description'),
        }}
        slots={{ footer: CursorPagination }}
        sx={{
          '.filter-row-container': {
            gridTemplateColumns: 'auto auto auto 1fr auto',
          },
        }}
        disableColumnFilter
        rowHeight={50}
        hasPageHeader
        paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
        updateSearchParams
        sorting={sortItems}
        columnVisibilityModel={columnVisibilityModel}
        showMore={
          Number(data?.cashRegisters.items?.length) <
          Number(data?.cashRegisters.totalcount)
        }
        getRowClassName={(params) => {
          const classes = []
          if (params.id === props.selectedId) {
            classes.push('Mui-selected')
          }

          return classes.join(' ')
        }}
      />
    </>
  )
}
