import { Box, Button, Radio, RadioGroup, Stack } from '@mui/material'
import {
  FormFieldset,
  PlusIcon,
  RadioGroupLabel,
  SectionHeader,
} from '@sitoo/mui-components'
import { useCallback, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { ALL_PRODUCTS_ATTR } from '../../../../utils/constants'
import { SetProductsDialog } from '../set-products'
import { Attributes, AttributesMap, CustomAttributeValue } from './attributes'

enum Selection {
  All,
  Exclude,
  Custom,
}

export const ProductAppliesToField = () => {
  const { t } = useTranslation(['campaigns'])
  const { watch, setValue, getValues } = useFormContext<CampaignFormContext>()

  const productAttributes = watch('campaign.productattributes')

  const includeAttributes = (productAttributes?.include as AttributesMap) || {}
  const excludeAttributes = (productAttributes?.exclude as AttributesMap) || {}

  const hasAllProducts = includeAttributes[ALL_PRODUCTS_ATTR]?.[0] === 'all'

  const products = getValues('campaign.products') || []

  const [selection, setSelection] = useState<Selection>(
    hasAllProducts
      ? Object.keys(excludeAttributes).length
        ? Selection.Exclude
        : Selection.All
      : Selection.Custom,
  )

  const [persistedData, setPersistedData] = useState(
    {} as Record<
      Selection,
      {
        include: AttributesMap
        exclude: AttributesMap
        products: CampaignFormContext['campaign']['products']
      }
    >,
  )

  const [productsDialogOpen, setProductsDialogOpen] = useState(false)

  const onSelectionChange = useCallback(
    (_event: React.ChangeEvent<HTMLInputElement>, value: string) => {
      setPersistedData((state) => ({
        ...state,
        [selection]: {
          ...getValues('campaign.productattributes'),
          products: getValues('campaign.products'),
        },
      }))

      const selectionValue = Number(value) as Selection

      if (Selection.All === selectionValue) {
        setSelection(Selection.All)

        setValue('campaign.productattributes', {
          include: { [ALL_PRODUCTS_ATTR]: ['all'] },
          exclude: {},
        })
        setValue('campaign.products', [])
      } else if (Selection.Exclude === selectionValue) {
        setSelection(Selection.Exclude)

        setValue('campaign.productattributes', {
          include: { [ALL_PRODUCTS_ATTR]: ['all'] },
          exclude: persistedData[Selection.Exclude]?.exclude,
        })
        setValue('campaign.products', [])
      } else {
        setSelection(Selection.Custom)

        setValue('campaign.productattributes', {
          include: persistedData[Selection.Custom]?.include,
          exclude: persistedData[Selection.Custom]?.exclude,
        })
        setValue('campaign.products', persistedData[Selection.Custom]?.products)
      }
    },
    [getValues, persistedData, selection, setValue],
  )

  const onChangeIncludeAttribute = (
    attributeId: string,
    values: NonNullable<CustomAttributeValue>[],
  ) => {
    setValue('campaign.productattributes.include', {
      ...includeAttributes,
      [attributeId]: values,
    })
  }

  const onRemoveIncludeAttribute = (attributeId: string) => {
    delete includeAttributes[attributeId]
    setValue('campaign.productattributes.include', includeAttributes)
  }

  const onChangeExcludeAttribute = (
    attributeId: string,
    values: NonNullable<CustomAttributeValue>[],
  ) => {
    setValue('campaign.productattributes.exclude', {
      ...excludeAttributes,
      [attributeId]: values,
    })
  }

  const onRemoveExcludeAttribute = (attributeId: string) => {
    delete excludeAttributes[attributeId]
    setValue('campaign.productattributes.exclude', excludeAttributes)
  }

  return (
    <FormFieldset
      label={t('campaigns:campaign_form.applies_to')}
      sx={{
        '.MuiFormFieldset-Paper': {
          p: 0,
        },
      }}
    >
      <SetProductsDialog
        open={productsDialogOpen}
        onClose={() => setProductsDialogOpen(false)}
        items={products}
        onSubmit={(products) => {
          setValue('campaign.products', products)

          setProductsDialogOpen(false)
        }}
      />

      <RadioGroup value={selection} onChange={onSelectionChange}>
        <RadioGroupLabel
          divider
          label={t('campaigns:campaign_form.all_products')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.All}
              data-testid="applies-to-all-products"
            />
          }
        />

        <RadioGroupLabel
          divider
          label={t('campaigns:campaign_form.exclude_attributes_label')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio
              value={Selection.Exclude}
              data-testid="applies-to-all-products-except"
            />
          }
        >
          <Stack sx={{ pb: 2 }} spacing={2}>
            <Box>
              <SectionHeader sx={{ px: 0 }} variant="transparent">
                {t('campaigns:campaign_form.exclude_attributes')}
              </SectionHeader>

              <Attributes
                attributes={excludeAttributes}
                onChangeAttribute={onChangeExcludeAttribute}
                onRemoveAttribute={onRemoveExcludeAttribute}
                data-testid="attributes-exclude-section"
              />
            </Box>
          </Stack>
        </RadioGroupLabel>

        <RadioGroupLabel
          label={t('campaigns:campaign_form.custom_products')}
          sx={{ px: 2 }}
          secondaryAction={
            <Radio value={Selection.Custom} data-testid="applies-to-custom" />
          }
        >
          <Stack sx={{ pb: 2 }} spacing={2}>
            <Box data-testid="include-products-section">
              <SectionHeader sx={{ px: 0 }} variant="transparent">
                {t('campaigns:campaign_form.include_products')}
              </SectionHeader>

              <Button
                startIcon={<PlusIcon fontSize="medium" />}
                color="secondary"
                fullWidth
                data-testid="attribute-menu-button"
                aria-haspopup="true"
                onClick={() => setProductsDialogOpen(true)}
              >
                {products.length
                  ? t('campaigns:campaign_form.edit_products', {
                      count: products.length,
                    })
                  : t('campaigns:campaign_form.add_products')}
              </Button>
            </Box>

            <Box>
              <SectionHeader sx={{ px: 0, pb: 0 }} variant="transparent">
                {t('campaigns:campaign_form.include_attributes')}
              </SectionHeader>

              <Attributes
                attributes={includeAttributes}
                onChangeAttribute={onChangeIncludeAttribute}
                onRemoveAttribute={onRemoveIncludeAttribute}
                data-testid="attributes-include-section"
              />
            </Box>

            <Box>
              <SectionHeader sx={{ px: 0 }} variant="transparent">
                {t('campaigns:campaign_form.exclude_attributes')}
              </SectionHeader>

              <Attributes
                attributes={excludeAttributes}
                onChangeAttribute={onChangeExcludeAttribute}
                onRemoveAttribute={onRemoveExcludeAttribute}
                data-testid="attributes-exclude-section"
              />
            </Box>
          </Stack>
        </RadioGroupLabel>
      </RadioGroup>
    </FormFieldset>
  )
}
