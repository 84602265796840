import {
  Autocomplete,
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormLabelField,
  Toggle,
} from '@sitoo/mui-components'
import { SyntheticEvent, useEffect, useMemo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { usePrevious } from 'react-use'
import {
  Pricelist,
  AllPricelistTagsDocument,
} from '../../../../generated/graphql'
import { useTracking } from '../../../../hooks/tracking'
import { codes } from 'currency-codes'
import { useAuthorization } from '../../../../hooks/authorization'
import { useQuery } from '@apollo/client'

type Props = {
  open: boolean
  pricelist?: Pricelist | null
  onClose: () => void
  onSuccess: (pricelist: Pricelist) => void
}

export const PriceListDialog = (props: Props) => {
  const { i18n, t } = useTranslation(['shared', 'settings'])
  const {
    modules: { writeSettingsPriceList },
  } = useAuthorization()
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const prevOpen = usePrevious(props.open)
  const dialogName = props.pricelist ? 'edit-pricelist' : 'add-pricelist'

  const isNewPricelist = !props.pricelist

  const formContext = useForm<Pricelist>()

  const { data: pricelistTags } = useQuery(AllPricelistTagsDocument)

  const TAG_REGEX = /^[\w-]{1,16}$/

  const submit = (data: Pricelist) => {
    trackDialogClose({ name: dialogName })
    props.onSuccess(data)
  }

  useEffect(
    () => formContext.reset(props.pricelist || {}),
    [formContext, props.pricelist, props.open],
  )

  useEffect(() => {
    if (props.open && !prevOpen) {
      trackDialogOpen({ name: dialogName })
    }
  }, [props.open, prevOpen, trackDialogOpen, dialogName])

  const currencyCodes = useMemo(() => {
    const currencyNames = new Intl.DisplayNames([i18n.language], {
      type: 'currency',
    })

    return codes().map((code) => ({
      code,
      name: currencyNames.of(code) as string,
    }))
  }, [i18n.language])

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    props.onClose?.()
  }

  const isCurrencyFieldDisabled = !writeSettingsPriceList || !isNewPricelist

  return (
    <Dialog open={props.open} maxWidth="xs" fullWidth onClose={onClose}>
      <DialogTitle>
        {!writeSettingsPriceList
          ? t('settings:price_lists.view_price_list')
          : isNewPricelist
            ? t('settings:price_lists.add_price_list')
            : t('settings:price_lists.edit_price_list')}
      </DialogTitle>

      <DialogContent>
        <FormLabelField
          label={t('settings:price_lists.active')}
          secondaryAction={
            <Toggle
              {...formContext.register('active')}
              dataTestid="pricelist-active"
              disabled={!writeSettingsPriceList}
            />
          }
        />

        <TextField
          fullWidth
          error={!!formContext.formState.errors.pricelistname}
          helperText={formContext.formState.errors.pricelistname?.message}
          label={t('settings:price_lists.name')}
          {...formContext.register('pricelistname', {
            required: t('shared:validation.field_required', {
              field: t('settings:price_lists.name'),
            }),
          })}
          inputProps={{ 'data-testid': 'pricelist-name' }}
          sx={{ mb: 2 }}
          disabled={!writeSettingsPriceList}
        />

        <TextField
          fullWidth
          error={!!formContext.formState.errors.externalid}
          helperText={formContext.formState.errors.externalid?.message}
          label={t('settings:price_lists.external_id')}
          {...formContext.register('externalid')}
          inputProps={{ 'data-testid': 'external-id' }}
          sx={{ mb: 2 }}
          disabled={!writeSettingsPriceList}
        />

        <TextField
          fullWidth
          multiline
          rows={4}
          error={!!formContext.formState.errors.pricelistdescription}
          helperText={
            formContext.formState.errors.pricelistdescription?.message
          }
          label={t('settings:price_lists.description')}
          {...formContext.register('pricelistdescription')}
          inputProps={{ 'data-testid': 'description' }}
          sx={{ mb: 2 }}
          disabled={!writeSettingsPriceList}
        />

        <Controller
          control={formContext.control}
          name="currencycode"
          //  TODO: get from settings endpoint the default account currency
          defaultValue="SEK"
          render={({ field }) => (
            <>
              <InputLabel>{t('settings:price_lists.currency')}</InputLabel>
              <Select
                value={field.value}
                onChange={(event) => {
                  field.onChange(event.target.value)
                }}
                data-testid="currencycode-select"
                inputProps={{ 'data-testid': 'currencycode-input' }}
                sx={{ mb: 2 }}
                disabled={isCurrencyFieldDisabled}
              >
                {currencyCodes.map(({ code, name }) => (
                  <MenuItem value={code} key={code}>
                    {`${code}, ${name}`}
                  </MenuItem>
                ))}
              </Select>
            </>
          )}
        />

        <Controller
          control={formContext.control}
          name="tags"
          render={({ field, fieldState: { error } }) => (
            <Autocomplete
              autoHighlight
              autoSelect
              multiple
              freeSolo
              disabled={!writeSettingsPriceList}
              options={pricelistTags?.allPricelistTags || []}
              onChange={(_event, tags) => {
                const validatedTags: string[] = tags
                  .map((tag) => tag.toLocaleUpperCase())
                  .filter((tag) => TAG_REGEX.test(tag))

                field.onChange([...new Set(validatedTags)])
              }}
              onInputChange={(event, newInputValue) => {
                formContext.clearErrors('tags')

                // add tag when ',' pressed
                if (newInputValue.endsWith(',')) {
                  const inputEvent = event as SyntheticEvent<HTMLInputElement>
                  inputEvent.currentTarget.blur()
                  inputEvent.currentTarget.focus()
                } else if (newInputValue && !TAG_REGEX.test(newInputValue)) {
                  formContext.setError('tags', {
                    message: t('settings:price_lists.tag_validation'),
                  })
                }
              }}
              value={field.value || []}
              data-testid="pricelisttags-field"
              renderInput={(params) => (
                <TextField
                  {...params}
                  label={t('settings:price_lists.tags')}
                  error={!!error?.message}
                  sx={{ mb: 2 }}
                  helperText={error?.message || undefined}
                  placeholder={
                    field.value?.length
                      ? undefined
                      : t('settings:price_lists.tags_placeholder')
                  }
                />
              )}
            />
          )}
        />
      </DialogContent>

      <DialogActions>
        <Button
          color="secondary"
          onClick={onClose}
          data-testid="dialog-cancel-pricelist"
        >
          {t(
            writeSettingsPriceList
              ? 'shared:action.cancel'
              : 'shared:action.close',
          )}
        </Button>
        {writeSettingsPriceList && (
          <Button
            color="primary"
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            onClick={formContext.handleSubmit(submit)}
            data-testid="dialog-add-pricelist"
          >
            {isNewPricelist ? t('shared:action.add') : t('shared:action.save')}
          </Button>
        )}
      </DialogActions>
    </Dialog>
  )
}
