import { Box, Chip, Typography } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { SalesTax, SalesTaxProductGroupRule } from '../../../generated/graphql'
import { useSalesTaxFormatter } from './useSalesTaxFormatter'

export const SalesTaxRulesRenderer = (
  params: GridRenderCellParams<SalesTax>,
): React.ReactElement => {
  return <SalesTaxRules rules={params.row.productgrouprules} />
}
type Props = {
  rules?: SalesTaxProductGroupRule[] | null
}

export const SalesTaxRules = ({ rules }: Props) => {
  const { formatNameAndTax, formatRuleTypeAndAmounts } = useSalesTaxFormatter()
  const firstRule = rules?.[0]

  return !rules?.length || !firstRule?.groupName ? (
    <>-</>
  ) : (
    <Box display="flex" alignItems="center" height="100%">
      <Typography variant="caption">{`${formatNameAndTax(firstRule?.groupName, firstRule?.decimalvalue)}: ${formatRuleTypeAndAmounts(firstRule)}`}</Typography>
      {rules.length > 1 && (
        <Chip sx={{ ml: 1 }} label={`+ ${rules.length - 1}`} />
      )}
    </Box>
  )
}
