import { Box, Button, IconButton, TextField } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import { Ref, useEffect } from 'react'
import {
  FieldValues,
  UseControllerProps,
  useController,
  useFieldArray,
  useForm,
} from 'react-hook-form'
import { nanoid } from 'nanoid'
import { DeleteIcon, PlusIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'

type Props = {
  sx?: SxProps
  innerRef?: Ref<HTMLElement>
}

type Field = {
  id: string
  key: string
  value: string
}

export const KeyValueInput = <TFieldValues extends FieldValues>(
  props: Props & UseControllerProps<TFieldValues>,
) => {
  const { name, control, sx, innerRef } = props
  const { t } = useTranslation(['shared'])

  // Original Controller
  const { field } = useController({ name, control })

  const formatValues = (valueMap: Record<string, string>) => {
    return Object.entries(valueMap || {}).map(([key, value]) => ({
      key,
      value,
    })) as Field[]
  }

  // Local form
  const {
    register,
    control: localControl,
    watch,
  } = useForm<{ root: Field[] }>({
    defaultValues: { root: formatValues(field.value) },
  })

  // Local array handler
  const { fields, append, remove } = useFieldArray({
    name: 'root',
    control: localControl,
  })

  const updatedFields = watch('root')

  useEffect(() => {
    // Convert fields back to a map structure
    const valuesMap = updatedFields?.reduce(
      (acc, { key, value }) => {
        if (key) acc[key] = value
        return acc
      },
      {} as Record<string, string>,
    )

    if (field.value === undefined) return

    if (!valuesMap || !Object.values(valuesMap).length) return

    // Update Original Controller
    if (JSON.stringify(field.value) !== JSON.stringify(valuesMap)) {
      field.onChange(valuesMap)
    }
  }, [updatedFields, field])

  return (
    <Box ref={innerRef}>
      {fields.map(({ id, key, value }, index) => (
        <Stack direction="row" spacing={1} key={id} sx={{ ...sx, mb: 1 }}>
          <TextField
            defaultValue={key}
            placeholder={t('shared:label.key')}
            {...register(`root.${index}.key`)}
          />
          <TextField
            defaultValue={value}
            placeholder={t('shared:label.value')}
            {...register(`root.${index}.value`)}
          />
          <IconButton
            onClick={() => remove(index)}
            disabled={fields.length === 1}
          >
            <DeleteIcon />
          </IconButton>
        </Stack>
      ))}

      <Button
        color="tertiary"
        fullWidth
        startIcon={<PlusIcon />}
        onClick={(event: React.SyntheticEvent<HTMLButtonElement>) => {
          append({ id: nanoid(), key: '', value: '' })
          const target = event.target as HTMLButtonElement
          setTimeout(() => target.scrollIntoView())
        }}
      >
        {t('shared:action.add_new')}
      </Button>
    </Box>
  )
}
