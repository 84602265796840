import { makeVar, useQuery, useReactiveVar } from '@apollo/client'
import { Button, Chip } from '@mui/material'
import { GridColDef, useGridApiRef } from '@mui/x-data-grid-pro'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { RootRoute } from '../..'
import { FilterProvider } from '../../../components/data-grid/context'
import { SortItem } from '../../../components/data-grid/filters/sort'
import { useFilterContext } from '../../../components/data-grid/hooks/use-filter-context'
import { PageHeader } from '../../../components/page-header'
import { SettingsList } from '../../../components/settings-list'
import {
  GetStoresSort,
  SettingsStoresDocument,
  SettingsStoresQuery,
  SettingsStoresQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { usePageTitle } from '../../../hooks/title'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { SiteFilterContext } from './sites-filter'
import { StoreFilterPanel } from './store-filter-panel'
import { StoreIcon } from '@sitoo/mui-components'
import { StoresContextMenu } from './stores-context-menu'

type Row = ArrayElement<SettingsStoresQuery['stores']>

export const storesFilterPanelVar = makeVar({ isOpen: false })

export const SettingsStoresPage = () => {
  const name = 'stores'
  const { t } = useTranslation([
    'shared',
    'settings',
    'stores',
    'countries',
    'warehouses',
  ])
  usePageTitle(t('shared:menu.stores'))
  const apiRef = useGridApiRef()
  const { trackButtonClick } = useTracking()

  const navigate = useNavigate()
  const generatePath = useAbsolutePath()
  const filterPanelState = useReactiveVar(storesFilterPanelVar)
  const filterContext = useFilterContext<SiteFilterContext>()

  const siteId = filterContext.filter.siteId?.value
  const sortItems = useMemo<SortItem<GetStoresSort>[]>(
    () => [
      {
        field: 'name',
        sort: 'asc',
        title: t('settings:store.name'),
        type: 'text',
        value: GetStoresSort.NameAsc,
      },
      {
        field: 'name',
        sort: 'desc',
        title: t('settings:store.name'),
        type: 'text',
        value: GetStoresSort.NameDesc,
        isDefault: true,
      },
      {
        field: 'externalid',
        sort: 'asc',
        title: t('settings:store.external_id'),
        type: 'text',
        value: GetStoresSort.ExternalIdAsc,
      },
      {
        field: 'externalid',
        sort: 'desc',
        title: t('settings:store.external_id'),
        type: 'text',
        value: GetStoresSort.ExternalIdDesc,
      },
      {
        field: 'externalgroupid',
        sort: 'asc',
        title: t('settings:store.external_group_id'),
        type: 'text',
        value: GetStoresSort.ExternalGroupIdAsc,
      },
      {
        field: 'externalgroupid',
        sort: 'desc',
        title: t('settings:store.external_group_id'),
        type: 'text',
        value: GetStoresSort.ExternalGroupIdDesc,
      },
    ],
    [t],
  )

  const sortModel = apiRef.current.state?.sorting.sortModel

  const queryVariables = useMemo(() => {
    const variables: SettingsStoresQueryVariables = { siteId: siteId }

    if (sortModel) {
      const sortItem = sortModel[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [siteId, sortItems, sortModel])

  const { loading: fetchLoading, data } = useQuery(SettingsStoresDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
  })

  const onEdit = (storeId: number) => {
    trackButtonClick({ name: `${name}-edit` })
    navigate(generatePath(RootRoute.SettingsStore, { id: storeId }))
  }

  const onAdd = () => {
    trackButtonClick({ name: `${name}-add` })
    navigate(generatePath(RootRoute.SettingsStoreNew))
  }

  const isLoading = fetchLoading

  const columns = useMemo<GridColDef<Row>[]>(
    () => [
      {
        field: 'name',
        minWidth: 240,
        headerName: t('settings:store.name'),
      },
      {
        field: 'storetype',
        minWidth: 100,
        headerName: t('settings:store.store_type'),
      },
      {
        field: 'externalid',
        minWidth: 100,
        headerName: t('settings:store.external_id'),
      },
      {
        field: 'externalgroupid',
        minWidth: 140,
        headerName: t('settings:store.external_group_id'),
      },
      {
        field: 'address',
        minWidth: 240,
        headerName: t('settings:store.address'),
      },
      {
        field: 'nearby_stores',
        minWidth: 120,
        headerName: t('settings:store.nearby_stores'),
        valueGetter: (_value, row) => {
          return t('settings:store.nearby_stores_count', {
            count: row.nearby_stores?.length,
          }).toString()
        },
      },
      {
        field: 'tags',
        minWidth: 120,
        headerName: t('settings:store.store_tags'),

        renderCell: (params) =>
          params.row.tags?.map((tag) => (
            <Chip
              key={tag}
              label={tag}
              size="small"
              color="grayLight"
              sx={{ mr: 0.5 }}
            />
          )),
      },
    ],
    [t],
  )

  const closeFilterPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-close` })
    storesFilterPanelVar({ isOpen: false })
  }

  const openFilterPanel = () => {
    trackButtonClick({ name: `${name}-filter-panel-open` })
    storesFilterPanelVar({ isOpen: true })
  }

  const toggleFilterViewPanel = () => {
    if (filterPanelState.isOpen) {
      closeFilterPanel()
    } else {
      openFilterPanel()
    }
  }

  return (
    <FilterProvider value={filterContext}>
      <SettingsList
        name="store"
        apiRef={apiRef}
        rows={data?.stores}
        columns={columns}
        loading={isLoading}
        onShowFilter={toggleFilterViewPanel}
        onRowClick={({ id }) => onEdit(Number(id))}
        sortItems={sortItems}
        noRowsOverlay={{
          icon: <StoreIcon />,
          title: t('settings:store.empty_title'),
          description: t('settings:store.empty_description'),
          action: (
            <Button
              onClick={() =>
                navigate(generatePath(RootRoute.SettingsImportData))
              }
            >
              {t('settings:store.import_stores')}
            </Button>
          ),
        }}
        columnVisibilityModel={{
          externalid: false,
          externalgroupid: false,
          address: false,
        }}
        slots={{
          PageHeader,
          FilterPanel: StoreFilterPanel,
        }}
        slotProps={{
          pageHeader: {
            title: t('shared:menu.stores'),
            isFlexible: true,
            rightColumn: (
              <>
                <StoresContextMenu />

                <Button data-testid="add-store-button" onClick={onAdd}>
                  {t('settings:store.add_store')}
                </Button>
              </>
            ),
          },
          filterPanel: {
            isOpen: filterPanelState.isOpen,
            onClose: closeFilterPanel,
          },
        }}
      />
    </FilterProvider>
  )
}
