import { TextField } from '@mui/material'
import { FormFieldset } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { GetProductQuery } from '../../../../generated/graphql'
import { useAuthorization } from '../../../../hooks/authorization'

export const DescriptionField = () => {
  const { register, formState } = useFormContext<GetProductQuery>()
  const { t } = useTranslation(['products', 'shared'])
  const {
    modules: { writeProducts },
  } = useAuthorization()

  return (
    <FormFieldset label={t('products:product_form.description_fieldset')}>
      <TextField
        data-testid="description-field"
        error={!!formState?.errors.product?.description}
        fullWidth
        multiline
        rows={4}
        helperText={t('products:product_form.description_description')}
        label={t('products:product_form.description_label')}
        {...register('product.description')}
        disabled={!writeProducts}
      />
    </FormFieldset>
  )
}
