import { IconButton, List, ListItemText } from '@mui/material'
import { DragHandleIcon } from '@sitoo/mui-components'
import { useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { Category } from '../../../../../generated/graphql'
import { useDrag } from '../../../../../hooks/drag'
import { useTracking } from '../../../../../hooks/tracking'
import { ProductSettingsList } from '../../product-settings-list'

type TreeProps = {
  categories: Category[]
  onReorder: (categories: Category[]) => void
  onEdit: (category: Category) => void
  loading: boolean
  onAdd?: () => void
  onDelete: (category: Category, index: number) => void
  parentId?: number | null
  level?: number
  rootRef?: React.RefObject<HTMLUListElement>
  readOnly?: boolean
}

export default function CategoryList({
  categories,
  onReorder,
  onEdit,
  onAdd,
  onDelete,
  parentId = null,
  level = 0,
  loading: isLoading,
  rootRef,
  readOnly = false,
}: TreeProps) {
  const { onDragEnd, onDragStart, onDragOver, dragId } = useDrag()
  const setRootRef = useRef<HTMLUListElement>(null)
  const listRef = rootRef || setRootRef
  const { t } = useTranslation(['shared', 'settings'])
  const { trackDragEnd, trackDragStart } = useTracking()

  const listItems = categories.filter(
    (item) => item.categoryparentid === parentId,
  )

  const activeItem = listItems.find(({ id }) => id === dragId)

  const getClassName = (item: Category) => {
    if (item.id === dragId) {
      return 'dragging-current'
    }

    if (activeItem?.categoryparentid === item.categoryparentid) {
      return 'dragging-target'
    }

    return 'dragging-not-target'
  }

  return (
    <List
      ref={level === 0 ? setRootRef : null}
      sx={{
        '&.MuiList-root': {
          gridColumn: '1 / 3',
          gridRowStart: 'none',

          '&.dragging .dragging-current': {
            background: (theme) => theme.palette.blue20,
            color: (theme) => theme.palette.text.primary,
          },
          '&.dragging .dragging-target': {
            background: (theme) => theme.palette.background.paper,
            color: (theme) => theme.palette.text.primary,
          },
          '&.dragging .dragging-not-target, &.dragging .dragging-not-target .MuiIconButton-root':
            {
              background: (theme) => theme.palette.gray10,
              color: (theme) => theme.palette.gray50,
            },
        },
        '& .MuiListItem-root': {
          display: 'grid',
          gap: 0,
          gridTemplateColumns: '1fr 1fr',
          p: 0,
          minHeight: 'auto',

          borderTop: (theme) => `1px solid ${theme.palette.divider}`,
        },
        '& .MuiListItemSecondaryAction-root': {
          marginLeft: 'auto',
        },
        '.MuiPaper-root > & > .MuiListItem-root:first-of-type': {
          borderTop: 'none',
        },
        '& .MuiListItemText-root': {
          pl: 2 + 2 * level,
          py: 1,
        },
      }}
    >
      {(listItems.length > 0 || level == 0) && (
        <ProductSettingsList
          items={listItems}
          baseDataTestId="product-category"
          addText={t('settings:categories.add_category')}
          loading={isLoading}
          canAdd={level === 0}
          onAdd={onAdd}
          onDelete={onDelete}
          onEdit={onEdit}
          readOnly={readOnly}
          secondaryActionRenderer={() => (
            <>
              {!readOnly && (
                <IconButton disabled={isLoading}>
                  <DragHandleIcon />
                </IconButton>
              )}
            </>
          )}
          itemRender={(x) => (
            <>
              <ListItemText
                secondaryTypographyProps={{
                  sx: { whiteSpace: 'break-spaces' },
                }}
              >
                {x.title}
              </ListItemText>

              <CategoryList
                categories={categories}
                onReorder={onReorder}
                onEdit={onEdit}
                onDelete={onDelete}
                parentId={x.id}
                level={level + 1}
                loading={isLoading}
                rootRef={listRef}
                readOnly={readOnly}
              />
            </>
          )}
          emptyTitle={t('settings:categories.empty_title')}
          emptyDescription={t('settings:categories.empty_description')}
          listItemProps={(item) => ({
            draggable: true,
            divider: false,
            onDragStart: (event) => {
              event.stopPropagation()

              trackDragStart({
                name: 'settings-product-category-category-list',

                categoryId: item.id,
              })
              setTimeout(() => {
                onDragStart(item.id)
                listRef.current?.classList.add('dragging')
              })
            },
            onDragOver: (event) => {
              event.stopPropagation()
              event.preventDefault()

              trackDragEnd({
                name: 'settings-product-category-category-list',

                categoryId: item.id,
              })
              onDragOver(
                categories.findIndex(({ id }) => id === item.id),
                categories,
                'id',
                onReorder,
                (element, target) =>
                  element.categoryparentid === target?.categoryparentid,
              )
            },
            onDragEnd: () => {
              listRef.current?.classList.remove('dragging')
              onDragEnd()
            },

            className: getClassName(item),
          })}
        />
      )}
    </List>
  )
}
