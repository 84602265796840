import { CampaignType } from '../../../generated/graphql'
import { useTracking } from '../../../hooks/tracking'
import { translateCampaignTypeTitle } from '../i18n'
import { CampaignTypeListItem } from './campaign-type-list-item'

export type PackageCampaignSelectionProps = {
  onSelectCampaignType: (campaignType: CampaignType) => void
  ['data-testid']?: string
}

export const PackageCampaignSelection = (
  props: PackageCampaignSelectionProps,
) => {
  const { trackButtonClickEvent } = useTracking()

  return (
    <div data-testid={props['data-testid']}>
      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          { name: 'add-campaign-dialog-package-price' },
          () => props.onSelectCampaignType(CampaignType.NUMBER_300),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_300)}
        data-testid="package-price-campaign-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-package-percentage-discount',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_310),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_310)}
        data-testid="package-percentage-discount-campaign-listItem"
      />

      <CampaignTypeListItem
        onClick={trackButtonClickEvent(
          {
            name: 'add-campaign-dialog-package-price-discount',
          },
          () => props.onSelectCampaignType(CampaignType.NUMBER_320),
        )}
        primary={translateCampaignTypeTitle(CampaignType.NUMBER_320)}
        data-testid="package-price-discount-campaign-listItem"
      />
    </div>
  )
}
