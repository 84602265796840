import { DataGrid } from '../../../../components/data-grid'
import {
  GridColDef,
  GridColumnVisibilityModel,
  useGridApiRef,
} from '@mui/x-data-grid-pro'
import { WarehouseIcon } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { DataGridImageRenderer } from '../../../../components/data-grid-image'
import { SortItem } from '../../../../components/data-grid/filters/sort'
import {
  GetReplenishmentItemsSort,
  StoreReplenishmentDocument,
  StoreReplenishmentQuery,
  StoreReplenishmentQueryVariables,
} from '../../../../generated/graphql'
import { ArrayElement } from '../../../../utils/types'
import { StockRenderer } from '../../../../components/data-grid/utils/stock-renderer'
import { useCallback, useContext, useEffect, useMemo } from 'react'
import {
  CaptionRenderer,
  CaptionRendererValue,
} from '../../../../components/data-grid/utils/caption-renderer'
import { ColumnProps } from '../../../../components/data-grid/utils/column-props'
import { useQuery } from '@apollo/client'
import { CursorPagination } from '../../../../components/data-grid/cursor-pagination'
import { useMe } from '../../../../hooks/me'
import { useSearchParams } from 'react-router-dom'
import { BulkActions } from './bulk-actions'
import { FilterContext } from '../../../../components/data-grid/context'

export type Row = ArrayElement<
  StoreReplenishmentQuery['replenishmentItems']['items']
>

const PAGE_SIZE = 100

export const InStoreReplenishmentList = () => {
  const { t } = useTranslation(['stock'])

  const apiRef = useGridApiRef()

  const { me } = useMe()

  const customAttributes =
    me?.currentSite.customattributes_in_store_replenishment

  const dataGridColumns: GridColDef<Row>[] = [
    {
      field: 'images',
      ...ColumnProps.image,
      headerName: '',
      valueGetter: (_value, row): string => row.images?.[0] || '',
      cellClassName: 'image-column',
      headerClassName: 'image-column',
      renderCell: DataGridImageRenderer,
    },
    {
      field: 'sku',
      ...ColumnProps.sku,
      headerName: t('stock:sku'),
    },
    {
      field: 'title',
      ...ColumnProps.productTitle,
      headerName: t('stock:product'),
      valueGetter: (_value, row): CaptionRendererValue => ({
        title: row.title,
        caption: row.variant?.[0]?.value,
      }),
      renderCell: CaptionRenderer,
    },
    {
      field: 'to_pick',
      headerName: 'To Pick',
      minWidth: 80,
      valueGetter: (_value, row) => Number(row.toPick) || '',
    },
    {
      field: 'to_decimal',
      minWidth: 80,
      headerName: t('stock:in_store_replenishment.to_warehouse'),
      renderCell: StockRenderer,
      valueGetter: (_value, row) => Number(row.decimalTotalTo) || 0,
    },

    {
      field: 'from_decimal',
      minWidth: 80,
      headerName: t('stock:in_store_replenishment.from_warehouse'),
      renderCell: StockRenderer,
      valueGetter: (_value, row) => Number(row.decimalTotalFrom) || 0,
    },

    {
      headerName: t('stock:in_store_replenishment.bin_location_to'),
      width: 160,
      field: 'binLocationTo',
    },

    {
      headerName: t('stock:in_store_replenishment.bin_location_from'),
      width: 160,
      field: 'binLocationFrom',
    },

    {
      headerName: t('stock:in_store_replenishment.reorder_point_to'),
      width: 160,
      field: 'decimalThresholdTo',
      valueGetter: (_value, row) => Number(row.decimalThresholdTo) || 0,
    },

    ...(customAttributes?.map((attribute) => ({
      field: attribute,
      headerName: attribute,
      valueGetter: (_value, row) => row.customattributes?.[attribute],
    })) as GridColDef<Row>[]),
  ]

  const sortItems = useMemo<SortItem<GetReplenishmentItemsSort>[]>(
    () => [
      {
        field: 'sku',
        sort: 'asc',
        title: t('stock:sku'),
        type: 'text',
        value: GetReplenishmentItemsSort.sku_asc,
        isDefault: true,
      },
      {
        field: 'sku',
        sort: 'desc',
        title: t('stock:sku'),
        type: 'text',
        value: GetReplenishmentItemsSort.sku_desc,
      },
      {
        field: 'binLocationTo',
        sort: 'asc',
        title: t('stock:in_store_replenishment.bin_location_from'),
        type: 'text',
        value: GetReplenishmentItemsSort.binlocation_asc,
      },
      {
        field: 'binLocationTo',
        sort: 'desc',
        title: t('stock:in_store_replenishment.bin_location_from'),
        type: 'text',
        value: GetReplenishmentItemsSort.binlocation_desc,
      },
    ],
    [t],
  )

  const [searchParams] = useSearchParams()

  const warehouseToId = searchParams.get('warehouseToId')
    ? Number(searchParams.get('warehouseToId'))
    : undefined

  const warehouseFromId = searchParams.get('warehouseFromId')
    ? Number(searchParams.get('warehouseFromId'))
    : undefined

  const sortModel = apiRef.current.state?.sorting.sortModel

  const queryVariables = useMemo(() => {
    const config = {
      pagination: {
        start: 0,
        page: 0,
        pageSize: PAGE_SIZE,
      },
      sorting: sortModel,
    }

    const variables: Partial<StoreReplenishmentQueryVariables> = {
      num: config.pagination?.pageSize,
    }

    variables.warehouseToId = warehouseToId

    variables.warehouseFromId = warehouseFromId

    if (config.sorting) {
      const sortItem = config.sorting[0]

      if (sortItem) {
        variables.sort = sortItems.find(
          (s) => s.field === sortItem.field && s.sort === sortItem.sort,
        )?.value
      }
    }

    return variables
  }, [sortModel, warehouseToId, warehouseFromId, sortItems])

  const columnVisibilityModel: GridColumnVisibilityModel = useMemo(
    () => ({
      binlocation: false,
    }),
    [],
  )

  const { isFilterReady, registerFilter } = useContext(FilterContext)

  // Delay rendering of the component due to parsing of a query string
  useEffect(() => {
    registerFilter({ key: '', isReady: true })
  }, [registerFilter])

  const {
    data,
    loading: fetchLoading,
    fetchMore,
  } = useQuery(StoreReplenishmentDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables as StoreReplenishmentQueryVariables,
    notifyOnNetworkStatusChange: true,
    skip: !warehouseToId || !warehouseFromId || !isFilterReady,
  })

  const fetchMoreItems = useCallback(() => {
    const { pageSize } = apiRef.current.state.pagination.paginationModel

    if (data?.replenishmentItems.next) {
      return fetchMore({
        variables: {
          start: (data?.replenishmentItems?.start || 0) + pageSize,
          next: data?.replenishmentItems.next,
        } as StoreReplenishmentQueryVariables,
      })
    }
  }, [apiRef, data, fetchMore])

  const isLoading = fetchLoading

  return (
    <DataGrid
      name="store-replenishment"
      apiRef={apiRef}
      columns={dataGridColumns}
      rows={data?.replenishmentItems.items || []}
      getRowId={(row) => row.sku}
      rowCount={
        data?.replenishmentItems?.items?.length
          ? data.replenishmentItems.items.length + 1
          : 0
      }
      loading={isLoading}
      fetchMore={fetchMoreItems}
      slots={{ footer: CursorPagination }}
      noRowsOverlay={{
        icon: <WarehouseIcon />,
        title: t('stock:stock_levels.empty_title'),
        description: t('stock:stock_levels.empty_description'),
      }}
      sx={{
        '.image-column': {
          padding: '0 !important',
        },
      }}
      bulkAction={
        <BulkActions
          warehouseToId={warehouseToId}
          warehouseFromId={warehouseFromId}
        />
      }
      disableColumnFilter
      checkboxSelection
      rowHeight={50}
      hasPageHeader
      paginationModel={{ page: 0, pageSize: PAGE_SIZE }}
      updateSearchParams
      sorting={sortItems}
      columnVisibilityModel={columnVisibilityModel}
      showMore={!!data?.replenishmentItems.next}
    />
  )
}
