import { ChipProps, Chip } from '@mui/material'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { useTranslation } from 'react-i18next'
import {
  OrderDeliveryItemState,
  OrderDeliveryState,
} from '../../../generated/graphql'

export const DeliveryStateRenderer = (
  params: GridRenderCellParams<
    { orderDeliveryState?: OrderDeliveryState | null },
    DeliveryStateProps
  >,
): React.ReactElement => (
  <DeliveryStateTag state={params.row.orderDeliveryState} />
)

export type DeliveryStateProps = {
  state: OrderDeliveryState | undefined | null
}

export const DeliveryStateTag = (props: DeliveryStateProps) => {
  const { t } = useTranslation('orders')
  const { state } = props

  const getColor = (paymentStateId: OrderDeliveryState): ChipProps['color'] => {
    switch (paymentStateId) {
      case OrderDeliveryState.Delivered:
        return 'green'

      case OrderDeliveryState.PartialDelivery:
        return 'orange'

      case OrderDeliveryState.NotDelivered:
      default:
        return 'red'
    }
  }

  if (!state) return null

  return (
    <Chip
      data-testid="delivery-state-tag"
      size="small"
      color={getColor(state)}
      label={t(`delivery_state.${state}`)}
    />
  )
}

type OrderDeliveryItemStateProps = {
  state: OrderDeliveryItemState
}

export const OrderDeliveryStateTag = (props: OrderDeliveryItemStateProps) => {
  const { t } = useTranslation('orders')
  const { state } = props

  const getColor = (state: OrderDeliveryItemState): ChipProps['color'] => {
    switch (state) {
      case OrderDeliveryItemState.New:
        return 'grayLight'
      case OrderDeliveryItemState.Delivered:
        return 'green'
      case OrderDeliveryItemState.Cancelled:
        return 'red'
      default:
        return 'black'
    }
  }

  return (
    <Chip
      label={t(`orders:delivery_item_state.${state}`)}
      size="small"
      color={getColor(state)}
    />
  )
}
