/* eslint-disable no-console */
import { useApolloClient, useMutation } from '@apollo/client'
import { useEffect, useState } from 'react'
import { Navigate, Path, useLocation } from 'react-router-dom'
import { RootRoute } from '..'
import { DeauthenticateDocument } from '../../generated/graphql'
import { useTracking } from '../../hooks/tracking'
import { createRedirectParam } from '../../utils/redirect'
import { useLocalStorage } from 'react-use'
import { REDIRECT_QUERY_PARAM } from '../../utils/constants'

interface Location extends Path {
  state: { reset: boolean }
}

export const LogoutPage = () => {
  const client = useApolloClient()
  const { reset } = useTracking()
  const [loading, setLoading] = useState(true)
  const { state } = useLocation() as Location
  const [, , removeRedirectPage] = useLocalStorage(REDIRECT_QUERY_PARAM)

  const [removeSessionMutation] = useMutation(DeauthenticateDocument)

  useEffect(() => {
    void (async () => {
      try {
        removeRedirectPage()
        await reset()
        await client.clearStore()
        await removeSessionMutation()
      } catch (error) {
        console.warn('Log Out:', error)
      }
      setLoading(false)
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) return null

  return (
    <Navigate
      to={{
        pathname: RootRoute.Login,
        search: state?.reset ? '' : createRedirectParam(),
      }}
    />
  )
}
