import { Button, IconButton } from '@mui/material'
import { GridColDef, GridPreProcessEditCellProps } from '@mui/x-data-grid-pro'
import { GridApiPro } from '@mui/x-data-grid-pro/models/gridApiPro'
import { DeleteIcon, PlusIcon } from '@sitoo/mui-components'
import { MutableRefObject, useCallback, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { ShipmentPackageItem } from '.'
import { DataGrid } from '../../../components/data-grid'
import { DataGridImageRenderer } from '../../../components/data-grid-image'
import { ColumnProps } from '../../../components/data-grid/utils/column-props'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'
import { ReceivedItemChip } from '../../shipments/shipment-view-panel/received-item-chip'
import { ShipmentPackage } from '../fields/packages'
import { GridCellParams } from '@mui/x-data-grid-pro'

type Props = {
  items: ShipmentPackage['items']
  addItem: (data: ShipmentPackageItem) => void
  updateItem: (index: number, data: ShipmentPackageItem) => void
  removeItem: (index: number) => void
  apiRef: MutableRefObject<GridApiPro>

  readOnly?: boolean
}

type Row = ArrayElement<ShipmentPackage['items']>

type EditCellProps = Pick<
  GridPreProcessEditCellProps<unknown, Row>,
  'id' | 'props'
>

export const PackageItems = (props: Props) => {
  const { t } = useTranslation(['shared', 'shipments_v2'])
  const { items } = props

  const { trackButtonClick } = useTracking()

  const setCellValue = useCallback(
    (name: string, setValue?: (value: unknown) => unknown) =>
      (params: EditCellProps) => {
        items?.forEach((item, index) => {
          if (item.shipmentitemid === params.id) {
            props.updateItem(index, {
              ...item,
              [name]: setValue
                ? setValue(params.props.value)
                : params.props.value,
            })
          }
        })

        return params.props
      },
    [items, props],
  )

  const dataGridColumns = useMemo<GridColDef<Row>[]>(() => {
    const columns: GridColDef<Row>[] = [
      {
        field: 'product_image',
        headerName: '',
        ...ColumnProps.image,
        valueGetter: (_value, row) => row.product?.images?.at(0),
        renderCell: DataGridImageRenderer,
      },
      {
        field: 'product_name',
        ...ColumnProps.productTitle,
        editable: !props.readOnly,
        headerName: t('shipments_v2:packages.products_title'),
        flex: 3,
        valueGetter: (_value, row) => row.productname,
        preProcessEditCellProps: setCellValue('productname'),
      },
      {
        field: 'sku',
        ...ColumnProps.productTitle,
        editable: !props.readOnly,
        headerName: t('shipments_v2:packages.sku'),
        flex: 2,
        valueGetter: (_value, row) => row.sku,
        preProcessEditCellProps: setCellValue('sku'),
      },
      {
        field: 'externalid',
        headerName: t('shipments_v2:packages.external_id'),
        editable: !props.readOnly,
        flex: 1,
        preProcessEditCellProps: setCellValue('externalid'),
      },

      {
        field: 'moneypricein',
        headerName: t('shipments_v2:packages.price'),
        editable: !props.readOnly,
        flex: 1,
        preProcessEditCellProps: setCellValue('moneypricein'),
      },

      {
        field: 'decimalunitquantity',
        headerName: t('shipments_v2:packages.unit_quantity'),
        editable: !props.readOnly,
        flex: 1,
        preProcessEditCellProps: setCellValue('decimalunitquantity'),
      },

      {
        field: 'unitlabel',
        headerName: t('shipments_v2:packages.unit_label'),
        editable: !props.readOnly,
        flex: 1,
        preProcessEditCellProps: setCellValue('unitlabel'),
        valueGetter: (_value, row) => row.unitlabel || row.product?.unitlabel,
      },
    ]

    if (props.readOnly) {
      columns.push({
        field: 'quantity',
        headerName: t('shipments_v2:packages.quantity'),
        editable: !props.readOnly,
        flex: 1,
        renderCell: ({ row }) => (
          <ReceivedItemChip
            label={t('shipments_v2:packages.received_of_total', {
              received: row.quantityreceived,
              total: row.quantity,
            })}
            quantity={row.quantity || 0}
            quantityReceived={row.quantityreceived || 0}
          />
        ),
      })
    } else {
      columns.push({
        field: 'quantity',
        headerName: t('shipments_v2:packages.quantity'),
        editable: !props.readOnly,
        flex: 1,
        preProcessEditCellProps: setCellValue(
          'quantity',
          (value) => Math.floor(Number(value)) || 0,
        ),
      })
    }

    if (!props.readOnly) {
      columns.push({
        field: 'remove',
        headerName: '',
        flex: 0.4,
        renderCell: (params) => (
          <IconButton
            onClick={() => {
              const index = items?.findIndex(
                (item) => item.shipmentitemid === params.id,
              )

              if (typeof index === 'number') {
                props.removeItem(index)
              }
              trackButtonClick({ name: 'delete-package-item' })
            }}
          >
            <DeleteIcon />
          </IconButton>
        ),
      })
    }

    return columns
  }, [items, props, setCellValue, t, trackButtonClick])

  const isCellEditable = useCallback((cell: GridCellParams<Row>) => {
    const { colDef, row } = cell

    if (
      colDef.field === 'decimalunitquantity' ||
      colDef.field === 'unitlabel'
    ) {
      const isNewRow = !row.product
      const allowDecimals = Boolean(row.product?.allowdecimals)

      return isNewRow || allowDecimals
    }

    return Boolean(colDef.editable)
  }, [])

  return (
    <DataGrid
      apiRef={props.apiRef}
      name="set-package-items-list"
      columns={dataGridColumns}
      getRowId={(item) => Number(item.shipmentitemid)}
      rows={items}
      rowCount={items?.length}
      rowHeight={50}
      ignoreRowHover
      sx={{ height: '300px' }}
      noRowsOverlay={{
        title: t('shipments_v2:packages.no_rows_title'),
        description: t('shipments_v2:packages.no_rows_description'),
      }}
      isCellEditable={isCellEditable}
      disableColumnVisibilityControl
      pagination={false}
      slotProps={{ footer: {} }}
      slots={{
        footer: () =>
          props.readOnly ? null : (
            <Button
              startIcon={<PlusIcon />}
              color="tertiary"
              onClick={() => props.addItem({})}
            >
              {t('shared:action.add')}
            </Button>
          ),
      }}
    />
  )
}
