import { useQuery } from '@apollo/client'
import { Button, List, ListItemText, Paper, Typography } from '@mui/material'
import { Container } from '@mui/system'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../../components/list-item-skeleton'
import {
  AllPaymentTypesDocument,
  PosPaymentSettingsDocument,
} from '../../../../generated/graphql'
import { PaymentSettingsTabDialog } from './payment-settings-tab-dialog'
import { getPaymentTypesFromIds } from './utils'

const paymentSettingsFields = [
  'payments_refund_manual',
  'payments_refund_validated',
  'payments_refund_validated_omni',
  'payments_sales_gift_card',
  'payments_sales_non_delivered',
] as const

export type PaymentSettingsKeys = (typeof paymentSettingsFields)[number]

export const PaymentSettingsTab = () => {
  const { t } = useTranslation(['pos_payment'])

  const { data: paymentTypesData } = useQuery(AllPaymentTypesDocument)
  const { data: paymentSettingsData } = useQuery(PosPaymentSettingsDocument)
  const [selectedField, setSelectedField] = useState<
    PaymentSettingsKeys | undefined
  >()

  return (
    <Container>
      {selectedField && (
        <PaymentSettingsTabDialog
          fieldName={selectedField}
          open={!!selectedField}
          onClose={() => setSelectedField(undefined)}
        />
      )}
      <Paper elevation={0}>
        <Typography variant="subheader" color="gray60" p={2}>
          {t('pos_payment:limit_payment_options_title').toLocaleUpperCase()}
        </Typography>

        <List>
          {paymentSettingsFields.map((key) => {
            const paymentOptions = getPaymentTypesFromIds(
              paymentSettingsData?.settings[key] ?? [],
              paymentTypesData?.allPaymentTypes,
            )
            const hasPaymentOptions = paymentOptions.length > 0
            return (
              <ListItemSkeleton key={key} sx={{ px: 2 }} divider>
                <ListItemText
                  primary={t(`pos_payment:limit_payment_options.${key}`)}
                  sx={{
                    '.MuiListItemText-secondary': {
                      color: hasPaymentOptions ? 'inherit' : 'gray60',
                    },
                  }}
                  secondary={
                    hasPaymentOptions
                      ? paymentOptions
                          .map((paymentOption) => paymentOption.name)
                          .join(', ')
                      : t(`pos_payment:not_limited`)
                  }
                />
                <Button
                  color="secondary"
                  onClick={() => setSelectedField(key)}
                  size="small"
                >
                  {t('shared:action.edit')}
                </Button>
              </ListItemSkeleton>
            )
          })}
        </List>
      </Paper>
    </Container>
  )
}
