import { NetworkStatus, useQuery } from '@apollo/client'
import {
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material'
import { tableCellClasses } from '@mui/material/TableCell'
import { tableHeadClasses } from '@mui/material/TableHead'
import { Box } from '@mui/system'
import { Suspense } from 'react'
import { useTranslation } from 'react-i18next'
import { Navigate, useParams } from 'react-router-dom'
import { useSessionStorage } from 'react-use'
import { RootRoute } from '../..'
import {
  AllReplenishmentItemsDocument,
  AllReplenishmentItemsQueryVariables,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useMe } from '../../../hooks/me'
import { useWindowPrint } from '../../../hooks/window-print'

type HeaderProps = {
  attributes: string[]
}

const TableHeader = (props: HeaderProps) => {
  const { attributes } = props
  const { t } = useTranslation(['stock'])

  return (
    <TableHead>
      <TableRow>
        <TableCell>{t('stock:sku')}</TableCell>

        <TableCell>{t('stock:product')}</TableCell>

        <TableCell>{t('stock:in_store_replenishment.to_warehouse')}</TableCell>

        <TableCell>
          {t('stock:in_store_replenishment.from_warehouse')}
        </TableCell>

        <TableCell>
          {t('stock:in_store_replenishment.bin_location_to')}
        </TableCell>

        <TableCell>
          {t('stock:in_store_replenishment.bin_location_from')}
        </TableCell>

        <TableCell>
          {t('stock:in_store_replenishment.reorder_point_to')}
        </TableCell>

        <TableCell>{t('stock:in_store_replenishment.to_pick')}</TableCell>

        {attributes?.map((attribute) => (
          <TableCell key={attribute}>{attribute}</TableCell>
        ))}
      </TableRow>
    </TableHead>
  )
}

type PrintData = {
  warehouseToId: number
  warehouseFromId: number
  skus?: string[]
}

export const InStoreReplenishmentPrintPage = () => {
  const { id } = useParams<{ id: string }>()
  const [printData] = useSessionStorage<PrintData | undefined>(String(id))
  const generatePath = useAbsolutePath()

  const { me } = useMe()

  const customAttributes =
    me?.currentSite.customattributes_in_store_replenishment || []

  const queryVariables: AllReplenishmentItemsQueryVariables = {
    warehouseToId: Number(printData?.warehouseToId),
    warehouseFromId: Number(printData?.warehouseFromId),
    skus: printData?.skus,
  }

  const {
    data,
    loading: isLoading,
    networkStatus,
  } = useQuery(AllReplenishmentItemsDocument, {
    fetchPolicy: 'cache-and-network',
    variables: queryVariables,
    skip: !printData?.warehouseToId || !printData.warehouseFromId,
  })

  useWindowPrint({ isReady: networkStatus === NetworkStatus.ready })

  const items = data?.allReplenishmentItems

  if (!printData) {
    return <Navigate to={generatePath(RootRoute.InStoreReplenishment)} />
  }

  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh"
      >
        <CircularProgress />
      </Box>
    )
  }

  return (
    <Suspense>
      <Table
        sx={{
          [`.${tableCellClasses.root}`]: {
            borderColor: (theme) => theme.palette.divider,
          },
          [`.${tableHeadClasses.root} .${tableCellClasses.root}`]: {
            fontWeight: 'bold',
          },
        }}
      >
        <TableHeader attributes={customAttributes} />

        <TableBody>
          {items?.map((item) => {
            const { sku, title, binLocationTo, binLocationFrom } = item
            const caption = item.variant?.[0]?.value
            const decimalToTotal = Number(item.decimalTotalTo) || 0
            const decimalTotalFrom = Number(item.decimalTotalFrom) || 0
            const decimalThresholdTo = Number(item.decimalThresholdTo) || 0
            const toPick = Number(item.toPick) || ''

            return (
              <TableRow key={sku}>
                <TableCell>{sku}</TableCell>

                <TableCell>
                  {title && <Typography>{title}</Typography>}

                  {caption && (
                    <Typography variant="caption" color="textSecondary">
                      {caption}
                    </Typography>
                  )}
                </TableCell>

                <TableCell>{decimalToTotal}</TableCell>

                <TableCell>{decimalTotalFrom}</TableCell>

                <TableCell>{binLocationTo}</TableCell>

                <TableCell>{binLocationFrom}</TableCell>

                <TableCell>{decimalThresholdTo}</TableCell>

                <TableCell>{toPick}</TableCell>

                {customAttributes?.map((attribute) => (
                  <TableCell key={attribute}>
                    {item.customattributes?.[attribute] as string}
                  </TableCell>
                ))}
              </TableRow>
            )
          })}
        </TableBody>
      </Table>
    </Suspense>
  )
}
