/* eslint-disable @typescript-eslint/no-misused-promises */
import { useMutation, useQuery } from '@apollo/client'
import { LoadingButton } from '@mui/lab'
import {
  Button,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import {
  ConfirmationDialog,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@sitoo/mui-components'
import { useSnackbar } from 'notistack'
import { useEffect, useMemo, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'
import { RootRoute } from '../..'
import {
  AddReasonCodeDocument,
  AllReasonCodesDocument,
  AllReasonCodesQuery,
  DeleteReasonCodeDocument,
  ReasonCodeDocument,
  ReasonCodeType,
  UpdateReasonCodeDocument,
} from '../../../generated/graphql'
import { useAbsolutePath } from '../../../hooks/absolute-path'
import { useTracking } from '../../../hooks/tracking'
import { ArrayElement } from '../../../utils/types'

type ReasonCode = ArrayElement<AllReasonCodesQuery['allReasonCodes']>

export const ReasonCodeDialog = () => {
  const [showDetailDialog, setDetailDialog] = useState(true)
  const [showDeleteDialog, setDeleteDialog] = useState(false)

  const { t } = useTranslation(['shared', 'settings'])
  const { id } = useParams()
  const reasonCodeId = Number(id)
  const isNewReasonCode = !reasonCodeId
  const { trackDialogOpen, trackDialogClose } = useTracking()
  const { enqueueSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const generatePath = useAbsolutePath()

  const dialogName = reasonCodeId ? 'edit-reason-code' : 'add-reason-code'
  const formId = 'reason-code-form'

  const { data, loading: fetchLoading } = useQuery(ReasonCodeDocument, {
    variables: { reasonCodeId: Number(reasonCodeId) },
    fetchPolicy: 'cache-and-network',
    skip: !reasonCodeId,
  })

  const [updateReasonCodeMutation, { loading: updateLoading }] = useMutation(
    UpdateReasonCodeDocument,
  )

  const [addReasonCodeMutation, { loading: addLoading }] = useMutation(
    AddReasonCodeDocument,
    { refetchQueries: [AllReasonCodesDocument] },
  )

  const [deleteReasonCodeMutation, { loading: deleteLoading }] = useMutation(
    DeleteReasonCodeDocument,
    {
      variables: {
        reasonCodeId,
      },
      refetchQueries: [AllReasonCodesDocument],
    },
  )
  const isLoading = fetchLoading || updateLoading || deleteLoading || addLoading

  const reasonCodeType = useMemo(() => {
    return Object.values(ReasonCodeType).map((value) => ({
      value,
      name: t(`settings:reason_codes.reason_code_types.${value}`),
    }))
  }, [t])

  const onSubmit = async (reasonCode: ReasonCode) => {
    const mutation = reasonCodeId
      ? updateReasonCodeMutation
      : addReasonCodeMutation

    try {
      await mutation({
        variables: { reasonCode: reasonCode },
      })
      trackDialogClose({ name: dialogName })
      enqueueSnackbar(
        reasonCodeId
          ? t('settings:reason_codes.dialog_update_success')
          : t('settings:reason_codes.dialog_add_success'),
      )
      onClose()
    } catch (err) {
      enqueueSnackbar(
        reasonCodeId
          ? t('settings:reason_codes.dialog_update_fail')
          : t('settings:reason_codes.dialog_add_fail'),
        { variant: 'error' },
      )
    }
  }

  const { formState, reset, control, register, handleSubmit } =
    useForm<ReasonCode>()

  const onClose = () => {
    trackDialogClose({ name: dialogName })
    navigate(generatePath(RootRoute.SettingsReasonCodes))
  }

  const onDeleteDialogOpen = () => {
    setDeleteDialog(true)
    setDetailDialog(false)
  }

  const onDeleteDialogClose = () => {
    setDeleteDialog(false)
    setDetailDialog(true)
  }

  useEffect(() => {
    if (!reasonCodeId) {
      reset(undefined, { keepDefaultValues: true })
    }

    if (data?.reasonCode) {
      reset(data?.reasonCode, { keepDefaultValues: true })
    }
  }, [data, reset, reasonCodeId])

  useEffect(() => {
    trackDialogOpen({ name: dialogName })
  }, [trackDialogOpen, dialogName])

  return (
    <>
      <Dialog open={showDetailDialog} maxWidth="xs" fullWidth onClose={onClose}>
        <DialogTitle>
          {isNewReasonCode
            ? t('settings:reason_codes.add_reason_code')
            : t('settings:reason_codes.edit_reason_code')}
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmit(onSubmit)} id={formId}>
            <Controller
              control={control}
              name="type"
              rules={{
                required: t('shared:validation.field_required', {
                  field: t('settings:reason_codes.type'),
                }),
              }}
              render={({ field, fieldState: { error } }) => (
                <>
                  <InputLabel>{t('settings:reason_codes.type')}</InputLabel>
                  <Select
                    value={field.value || ''}
                    onChange={(event) => field.onChange(event.target.value)}
                    error={!!error?.message}
                    displayEmpty
                  >
                    <MenuItem disabled value="">
                      {t('settings:reason_codes.type_placeholder')}
                    </MenuItem>
                    {reasonCodeType.map(({ name, value }) => (
                      <MenuItem value={value} key={name}>
                        {name}
                      </MenuItem>
                    ))}
                  </Select>

                  <FormHelperText error={!!error?.message} sx={{ mb: 2 }}>
                    {error?.message ||
                      t('settings:reason_codes.type_helper_text')}
                  </FormHelperText>
                </>
              )}
            />

            <TextField
              error={!!formState.errors.reasoncode}
              helperText={
                formState.errors.reasoncode?.message ||
                t('settings:reason_codes.reason_code_helper_text')
              }
              label={t('settings:reason_codes.reason_code')}
              slotProps={{ htmlInput: { maxLength: 32 } }}
              placeholder={t('settings:reason_codes.reason_code_placeholder')}
              {...register('reasoncode')}
              sx={{ mb: 2 }}
            />

            <TextField
              error={!!formState.errors.name}
              helperText={
                formState.errors.name?.message ||
                t('settings:reason_codes.name_helper_text')
              }
              label={t('settings:reason_codes.name')}
              slotProps={{ htmlInput: { maxLength: 64 } }}
              placeholder={t('settings:reason_codes.name_placeholder')}
              {...register('name')}
              sx={{ mb: 2 }}
            />

            {!isNewReasonCode && (
              <LoadingButton
                fullWidth
                loading={isLoading}
                color="error"
                sx={{ mt: 2 }}
                onClick={onDeleteDialogOpen}
              >
                {t('shared:action.delete')}
              </LoadingButton>
            )}
          </form>
        </DialogContent>
        <DialogActions>
          <Button color="secondary" onClick={onClose}>
            {t('shared:action.cancel')}
          </Button>

          <LoadingButton
            type="submit"
            loading={isLoading}
            disabled={isLoading}
            color="primary"
            onClick={handleSubmit(onSubmit)}
            form={formId}
          >
            {isNewReasonCode ? t('shared:action.add') : t('shared:action.save')}
          </LoadingButton>
        </DialogActions>
      </Dialog>

      <ConfirmationDialog
        confirmAction={() => {
          void deleteReasonCodeMutation({
            variables: { reasonCodeId: reasonCodeId },
          })
          onDeleteDialogClose()
          onClose()
        }}
        title={t('settings:reason_codes.dialog_delete_label')}
        text={t('settings:reason_codes.dialog_delete_description', {
          reasonCode: data?.reasonCode.name,
        })}
        variant="destructive"
        open={showDeleteDialog}
        onClose={onDeleteDialogClose}
      />
    </>
  )
}
