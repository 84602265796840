import { useQuery } from '@apollo/client'
import {
  Divider,
  IconButton,
  ListItem,
  ListItemButton,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import {
  DeleteIcon,
  MoreHorizontalIcon,
  SectionHeader,
} from '@sitoo/mui-components'
import { useCallback, useContext, useRef, useState } from 'react'
import { nanoid } from 'nanoid'
import { filterVar } from '../../cache'
import { FilterDocument, FilterValue } from '../../generated/graphql'
import { useStateParams } from '../../hooks/state-params'
import { FilterContext } from '../data-grid/context'
import { FilterPresetSaveDialog } from './filter-preset-save-dialog'
import { FilterPresetDeleteDialog } from './filter-preset-delete-dialog'
import { useTranslation } from 'react-i18next'
import { useMe } from '../../hooks/me'

export const FilterPreset = () => {
  const { t } = useTranslation(['filter'])
  const { me } = useMe()
  const anchorRef = useRef<HTMLDivElement>(null)
  const [isMenuOpen, setMenuOpen] = useState(false)
  const [isSaveDialogOpen, setSaveDialogOpen] = useState(false)
  const [deleteItemId, setDeleteItemId] = useState<string>()

  const [, , , setQueryStateFromString] = useStateParams()

  const filterKey = [me?.user?.id, location.pathname].filter(Boolean).join(':')

  const { hasApply, applyFilter } = useContext(FilterContext)

  const { data } = useQuery(FilterDocument)

  const presets = data?.filter.filter(({ key }) => key === filterKey) || []

  const onSave = useCallback(
    (filterData: { name: string }) => {
      filterVar([
        ...(data?.filter || []),
        {
          id: nanoid(),
          key: filterKey,
          name: filterData.name,
          value: location.search.replace(/^\?/, ''),
        },
      ])

      setSaveDialogOpen(false)
    },
    [data?.filter, filterKey],
  )

  const onDelete = useCallback(
    (itemId: string) => {
      filterVar(data?.filter.filter(({ id }) => id !== itemId))
      setDeleteItemId(undefined)
    },
    [data?.filter],
  )

  const onItemSelect = useCallback(
    (preset: FilterValue) => {
      setMenuOpen(false)
      setQueryStateFromString(preset.value, true, true)

      setTimeout(() => {
        if (hasApply) {
          void applyFilter()
        }
      })
    },
    [applyFilter, hasApply, setQueryStateFromString],
  )

  return (
    <>
      <FilterPresetSaveDialog
        open={isSaveDialogOpen}
        onSubmit={onSave}
        onClose={() => setSaveDialogOpen(false)}
      />

      <FilterPresetDeleteDialog
        open={Boolean(deleteItemId)}
        presetId={deleteItemId}
        onClose={() => setDeleteItemId(undefined)}
        onSuccess={onDelete}
      />

      <Box sx={{ display: 'flex', flexDirection: 'column' }} ref={anchorRef}>
        <IconButton
          sx={{
            width: (theme) => theme.spacing(6.25),
            backgroundColor: (theme) => theme.palette.white,
            borderRadius: 0,
            flexGrow: 1,
          }}
          onClick={() => setMenuOpen(true)}
        >
          <MoreHorizontalIcon />
        </IconButton>
        <Divider sx={{ borderWidth: '1px' }} />

        <Menu
          open={isMenuOpen}
          anchorEl={anchorRef.current}
          onClose={() => setMenuOpen(false)}
          MenuListProps={{
            sx: { minWidth: 200 },
          }}
        >
          <SectionHeader variant="transparent">
            {t('filter:filter_preset.menu_label')}
          </SectionHeader>
          {presets.map((preset) => (
            <ListItem
              key={preset.id}
              disablePadding
              secondaryAction={
                <IconButton onClick={() => setDeleteItemId(preset.id)}>
                  <DeleteIcon />
                </IconButton>
              }
              sx={{
                '.MuiListItemSecondaryAction-root': {
                  position: 'absolute',
                  right: '8px',
                },
              }}
            >
              <ListItemButton onClick={() => onItemSelect(preset)}>
                <ListItemText>{preset.name}</ListItemText>
              </ListItemButton>
            </ListItem>
          ))}

          <Divider />
          <MenuItem
            onClick={() => {
              setSaveDialogOpen(true)
            }}
          >
            <Typography variant="body02">Save Filter</Typography>
          </MenuItem>
        </Menu>
      </Box>
    </>
  )
}
