import { Chip } from '@mui/material'
import { Stack, SxProps } from '@mui/system'
import { GridRenderCellParams } from '@mui/x-data-grid-pro'
import { GetUsersQuery } from '../../../generated/graphql'
import { ArrayElement } from '../../../utils/types'

type Props = {
  warehouses?:
    | ArrayElement<GetUsersQuery['users']['items']>['allWarehouses']
    | null
  sx?: SxProps
}

export const WarehouseRenderer = (
  params: GridRenderCellParams<{
    allWarehouses?: ArrayElement<
      GetUsersQuery['users']['items']
    >['allWarehouses']
  }>,
): React.ReactElement => (
  <WarehouseChips
    warehouses={params.row.allWarehouses}
    sx={{ flexWrap: 'nowrap' }}
  />
)

export const WarehouseChips = ({ warehouses, sx }: Props) => {
  if (!warehouses?.length) return

  return (
    <Stack
      direction="row"
      gap={0.5}
      py={0.5}
      alignItems="center"
      height="100%"
      sx={{ flexWrap: 'wrap', ...sx }}
    >
      {warehouses.map((warehouse) => (
        <Chip
          data-testid="warehouse-chip"
          key={warehouse.id}
          size="small"
          label={warehouse.name}
        />
      ))}
    </Stack>
  )
}
