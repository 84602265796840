import {
  Button,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Stack,
  Typography,
} from '@mui/material'
import { DeleteIcon, FormFieldset, ProductGridIcon } from '@sitoo/mui-components'
import { useFormContext } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { CampaignFormContext } from '../..'
import { getFormRules } from './get-form-rules'
import { Fragment, useState } from 'react'
import { ProductCounter } from '../../../../components/product-counter'
import { SetProductsDialog } from './set-products-dialog'
import { ArrayElement } from '../../../../utils/types'

const sanitizeProductOptionValues = (
  options: CampaignFormContext['campaign']['productoptions'] | undefined | null,
) => {
  if (!Array.isArray(options)) {
    return options
  }

  return options.filter(Boolean).map(({ __typename, ...props }) => props)
}

export const ProductOptions = () => {
  const { watch, getValues, setValue } = useFormContext<CampaignFormContext>()
  const type = getValues('campaign.vouchertype')
  const rules = getFormRules(type)
  const { t } = useTranslation(['campaigns'])
  const [showProductOptionsDialog, setShowProductOptionsDialog] =
    useState(false)
  const [selectedProductOptionIndex, setSelectedProductOptionIndex] =
    useState(0)
  const formProductOptions = watch('campaign.productoptions')
  const productOptions = sanitizeProductOptionValues(formProductOptions) || []

  const onAddOption = () => {
    setValue('campaign.productoptions', [
      ...productOptions,
      { num: 1, products: [] },
    ])
  }

  const onDeleteOption = (index: number) => {
    const items = [...productOptions]
    items.splice(index, 1)

    setValue('campaign.productoptions', items)
  }

  const onUpdateAmount = (newQuantity: number, index: number) => {
    const items = [...productOptions]
    const productOption = items[index]

    if (!productOption || newQuantity === productOption.num) return

    productOption.num = newQuantity
    setValue('campaign.productoptions', items)
  }

  const onShowEditProducts = (index: number) => {
    setSelectedProductOptionIndex(index)
    setShowProductOptionsDialog(true)
  }

  const onUpdateProducts = (
    products: ArrayElement<
      CampaignFormContext['campaign']['productoptions']
    >['sitooProducts'],
    index?: number,
  ) => {
    if (index === undefined) return

    const items = [...productOptions]
    const productOption = items[index]
    if (!productOption) return

    productOption.sitooProducts = products
    productOption.products = products?.map((x) => x.sku)

    setValue('campaign.productoptions', items)
  }

  return (
    <>
      {rules?.productoptions && (
        <>
          <SetProductsDialog
            open={showProductOptionsDialog}
            productOptionIndex={selectedProductOptionIndex}
            productOptionProducts={
              productOptions[selectedProductOptionIndex]?.sitooProducts
            }
            onClose={() => setShowProductOptionsDialog(false)}
            onSubmit={onUpdateProducts}
            onSuccess={() => setShowProductOptionsDialog(false)}
          />
          <FormFieldset
            sx={{
              '.MuiFormFieldset-Paper': {
                p: 0,
              },
            }}
          >
            <List>
              <ListItem>
                <ListItemText>
                  {t('campaigns:campaign_form.product_options_label')}
                </ListItemText>
              </ListItem>
              <Divider />
              {productOptions.map((productOption, index) => (
                <Fragment key={index}>
                  <ListItem
                    secondaryAction={
                      <Stack direction="row" spacing={1}>
                        <ProductCounter
                          currentAmount={productOption.num || 1}
                          minAmount={1}
                          updateAmount={(newAmount) =>
                            onUpdateAmount(newAmount, index)
                          }
                        />
                        <Button
                          size="small"
                          color={
                            productOption.products?.length
                              ? 'secondary'
                              : 'primary'
                          }
                          data-testid="add-product-button"
                          onClick={() => onShowEditProducts(index)}
                        >
                          {productOption.products?.length
                            ? t(
                                'campaigns:campaign_form.product_options_edit_product',
                              )
                            : t(
                                'campaigns:campaign_form.product_options_add_product',
                              )}
                        </Button>

                        <IconButton onClick={() => onDeleteOption(index)}>
                          <DeleteIcon />
                        </IconButton>
                      </Stack>
                    }
                  >
                    <ListItemIcon>
                      <ProductGridIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={t(
                        'campaigns:campaign_form.product_options_title',
                        {
                          value: index + 1,
                        },
                      )}
                      secondaryTypographyProps={{
                        sx: {
                          whiteSpace: 'pre-line',
                        },
                      }}
                      secondary={t(
                        'campaigns:campaign_form.product_options_description',
                        {
                          count: productOption.products?.length || 0,
                        },
                      )}
                    />
                  </ListItem>

                  {index + 1 < productOptions?.length && <Divider />}
                </Fragment>
              ))}

              <ListItem sx={{ p: 0, minHeight: 'auto' }}>
                <Button
                  fullWidth
                  color="tertiary"
                  data-testid="add-product-option-button"
                  onClick={onAddOption}
                >
                  {t('campaigns:campaign_form.product_options_add_option')}
                </Button>
              </ListItem>
            </List>
            <Typography
              component="div"
              variant="caption"
              sx={{
                py: 1,
                width: '100%',
                background: (theme) => theme.palette.background.page,
                color: (theme) => theme.palette.text.secondary,
              }}
            >
              {t('campaigns:campaign_form.product_options_helper_text')}
            </Typography>
          </FormFieldset>
        </>
      )}
    </>
  )
}
