import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Chip,
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Stack,
} from '@mui/material'
import { ProductGridIcon, SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { ListItemSkeleton } from '../../../components/list-item-skeleton'
import { GetCampaignQuery } from '../../../generated/graphql'
import { useMoney } from '../../../hooks/money'
import { getFormRules } from '../../campaign/fields/campaign-rules/get-form-rules'
import yaml from 'js-yaml'
import { sanitizeDiscountOptionsValues } from '../../campaign/fields/discount-options'
import { ListItemAccordion } from '../../../components/list-item-accordion'
import { ListItemProductImage } from '../../../components/list-item-product-image'
import { Fragment } from 'react'

type CampaignRulesProps = {
  campaign?: GetCampaignQuery['campaign']
  isLoading: boolean
}

export const CampaignRules = ({ campaign, isLoading }: CampaignRulesProps) => {
  const { t } = useTranslation(['shared', 'campaigns'])
  const rules = getFormRules(campaign?.vouchertype)
  const { formatCurrency } = useMoney()

  return (
    <Accordion defaultExpanded className="MuiAccordionRoot">
      <AccordionSummary aria-controls="details">
        <SectionHeader sx={{ p: 0 }}>{t('campaigns:rules')}</SectionHeader>
      </AccordionSummary>
      <AccordionDetails sx={{ p: 0 }}>
        <List>
          {rules?.value_x && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  primary={campaign?.value_x}
                  data-testid="value-x"
                />
              }
            >
              <ListItemText
                data-testid="value-x-label"
                primary={rules.value_x}
              />
            </ListItemSkeleton>
          )}

          {rules?.value_y && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  data-testid="campaign-value-y"
                  primary={campaign?.value_y}
                />
              }
            >
              <ListItemText
                primary={rules.value_y}
                data-testid="campaign-value-y-label"
              />
            </ListItemSkeleton>
          )}

          {rules?.value_z && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  data-testid="campaign-value-z"
                  primary={campaign?.value_z}
                />
              }
            >
              <ListItemText
                data-testid="campaign-value-z-label"
                primary={rules.value_z}
              />
            </ListItemSkeleton>
          )}

          {rules?.money_m && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  data-testid="money-m-value"
                  primary={formatCurrency(campaign?.money_m || 0)}
                />
              }
            >
              <ListItemText
                data-testid="money-m-label"
                primary={rules.money_m}
              />
            </ListItemSkeleton>
          )}

          {rules?.money_n && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  data-testid="money-n-value"
                  primary={formatCurrency(campaign?.money_n || 0)}
                />
              }
            >
              <ListItemText
                data-testid="money-n-label"
                primary={rules.money_n}
              />
            </ListItemSkeleton>
          )}

          {rules?.vouchername1 && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText primary={campaign?.vouchername1 || '-'} />
              }
            >
              <ListItemText primary={rules.vouchername1} />
            </ListItemSkeleton>
          )}

          {rules?.vouchercode1 && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText primary={campaign?.vouchercode1 || '-'} />
              }
            >
              <ListItemText primary={rules.vouchercode1} />
            </ListItemSkeleton>
          )}

          {rules?.vouchername2 && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText primary={campaign?.vouchername2 || '-'} />
              }
            >
              <ListItemText primary={rules.vouchername2} />
            </ListItemSkeleton>
          )}

          {rules?.vouchercode2 && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText primary={campaign?.vouchercode2 || '-'} />
              }
            >
              <ListItemText primary={rules.vouchercode2} />
            </ListItemSkeleton>
          )}

          {rules?.maxuses && (
            <ListItemSkeleton
              isLoading={isLoading}
              divider
              childrenSkeleton
              secondaryAction={
                <ListItemText
                  data-testid="maxuses-value"
                  primary={campaign?.maxuses || '-'}
                />
              }
            >
              <ListItemText
                data-testid="maxuses-label"
                primary={rules.maxuses}
              />
            </ListItemSkeleton>
          )}

          {rules?.productoptions &&
            campaign?.productoptions?.map((productOption, index) => {
              return (
                <Fragment key={index}>
                  <ListItemAccordion
                    data-testid="product-option-accordion"
                    aria-controls={`product-options-option-${index}`}
                    key={index}
                    summary={
                      <ListItemSkeleton isLoading={isLoading}>
                        <ListItemIcon>
                          <ProductGridIcon />
                        </ListItemIcon>
                        <ListItemText
                          primary={t(
                            'campaigns:campaign_form.product_options_title',
                            { value: index + 1 },
                          )}
                          secondaryTypographyProps={{
                            sx: {
                              whiteSpace: 'pre-line',
                            },
                          }}
                          secondary={`${t(
                            'campaigns:campaign_form.product_options_description',
                            {
                              count: productOption.sitooProducts?.length || 0,
                            },
                          )} • ${t(
                            'campaigns:campaign_form.product_options_activate_text',
                            {
                              count: productOption.num || 0,
                            },
                          )}`}
                        />
                      </ListItemSkeleton>
                    }
                  >
                    <List>
                      {productOption.sitooProducts?.map((product) => (
                        <ListItemSkeleton
                          isLoading={isLoading}
                          key={product.id}
                        >
                          <ListItemProductImage
                            url={product.productImages?.[0]?.fileUrl}
                          />
                          <ListItemText
                            primary={product.title || product.sku}
                            secondaryTypographyProps={{
                              sx: {
                                whiteSpace: 'pre-line',
                              },
                            }}
                            data-testid="product-sku"
                            secondary={[
                              ...(product.variant?.map(
                                (x) => `${x.name || ''}: ${x.value || ''}`,
                              ) || []),
                              product.sku,
                            ]
                              .filter(Boolean)
                              .join('\n')}
                          />
                        </ListItemSkeleton>
                      ))}
                    </List>
                  </ListItemAccordion>

                  {index + 1 < (campaign?.productoptions?.length || 0) && (
                    <Divider />
                  )}
                </Fragment>
              )
            })}

          {rules?.discountoptions && (
            <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
              <ListItemText
                primary={rules.discountoptions}
                secondary={yaml.dump(
                  sanitizeDiscountOptionsValues(campaign?.discountoptions),
                )}
                secondaryTypographyProps={{
                  sx: { whiteSpace: 'pre' },
                }}
              />
            </ListItemSkeleton>
          )}

          {rules?.pricelisttags &&
            (campaign?.pricelisttags || []).length > 0 && (
              <ListItemSkeleton isLoading={isLoading} divider childrenSkeleton>
                <ListItemText
                  data-testid="pricelist-section"
                  primary={rules.pricelisttags}
                  secondaryTypographyProps={{ component: 'div' }}
                  secondary={
                    <Stack direction="row" spacing={0.5}>
                      {campaign?.pricelisttags?.map((tag) => (
                        <Chip
                          key={tag}
                          size="small"
                          data-testid="pricelist-tag"
                          label={tag}
                        />
                      ))}
                    </Stack>
                  }
                />
              </ListItemSkeleton>
            )}
        </List>
      </AccordionDetails>
    </Accordion>
  )
}
