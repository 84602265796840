import { SnackbarProvider as Provider } from 'notistack'
import { SnackbarStyles } from './styled'
import { GlobalStyles } from '@mui/material'
import { PropsWithChildren } from 'react'

export const SnackbarProvider = (props: PropsWithChildren) => (
  <>
    <GlobalStyles styles={SnackbarStyles} />

    <Provider
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      variant="success"
      maxSnack={2}
      hideIconVariant={true}
    >
      {props.children}
    </Provider>
  </>
)
