import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
} from '@mui/material'
import { SectionHeader } from '@sitoo/mui-components'
import { useTranslation } from 'react-i18next'
import { FilterViewPanel } from '../../../../components/filter-view-panel'
import { ProductCategoryFilter } from '../../../products/product-list/product-category-filter'
import { FranchiseSiteFilter } from '../../../products/product-list/franchise-site-filter'
import { ProductStateFilter } from '../../../products/product-list/product-state-filter'

type AllDeliveryOutFilterViewPanelProps = {
  isOpen: boolean
  onClose(): void
}

export const AllDeliveryOutFilterViewPanel = (
  props: AllDeliveryOutFilterViewPanelProps,
) => {
  const { t } = useTranslation(['delivery_out'])

  return (
    <FilterViewPanel open={props.isOpen} onClose={props.onClose}>
      <Accordion defaultExpanded className="MuiAccordionRoot">
        <AccordionSummary aria-controls="filter">
          <SectionHeader sx={{ p: 0 }}>
            {t('delivery_out:page_title')}
          </SectionHeader>
        </AccordionSummary>
        <AccordionDetails sx={{ px: 0, py: 1.5 }}>
          <List>
            <ListItem>
              <ProductCategoryFilter />
            </ListItem>
            <ListItem>
              <ProductStateFilter />
            </ListItem>
            <FranchiseSiteFilter />
          </List>
        </AccordionDetails>
      </Accordion>
    </FilterViewPanel>
  )
}
